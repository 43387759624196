import React from "react";
import DeleteButton from "../button/DeleteButton";

interface Props {
  file: File;
  setFile: (arg: any) => void;
}

/**
 * A file attachment.
 */
export default function FileAttachment({ file, setFile }: Props) {
  const renderSize = (size: number) => {
    return size > 1024
      ? size > 1048576
        ? Math.round(size / 1048576) + "mb"
        : Math.round(size / 1024) + "kb"
      : size + "b";
  };

  return (
    <div
      className="flex items-center gap-3 mt-4 p-4 bg-gray-50 dark:bg-gray-800 
        rounded-lg border border-gray-200 dark:border-gray-700 
        transition-colors duration-200"
      role="group"
    >
      <span className="font-medium text-gray-700 dark:text-gray-200">
        {file.name}
      </span>
      <span className="text-gray-500 dark:text-gray-400 text-sm">
        {renderSize(file.size)}
      </span>
      <DeleteButton onDelete={() => setFile(null)} />
    </div>
  );
}
