import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/common/pageWrapper";
import {
  QUESTION_CATEGORIES,
  Quiz,
  QuizFormData,
  QuizQuestion,
  QuizQuestionFormData,
  QuizQuestionCategory,
  QuizQuestionAnswerOptionImageFormData,
  QUESTION_TYPES,
  QuizQuestionType,
  QuizQuestionImageFormData,
} from "../../../types/quiz";
import { QuizQuestionAnswerOptionFormData } from "../../../types/quiz";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  createQuiz,
  resetQuizzes,
  retrieveQuizQuestions,
  retrieveQuizzes,
  setAggregatedUploadAnswerOptionImageErrorMessages,
  setAggregatedUploadQuestionImageErrorMessages,
  updateQuiz,
  uploadAnswerOptionImage,
  uploadQuestionImage,
} from "../../../redux/quiz/quizSlice";
import { Button, Spinner } from "flowbite-react";
import { isDispatchResponseError } from "../../../redux/utils";
import { history } from "../../../helpers/history";
import { APP_URLS } from "../../../navigation";
import { getNeuralNetworks } from "../../../redux/neuralNetworks/neuralNetworksSlice";
import Header from "../../../components/common/header";
import QuizEditCreateInputs from "../../../components/quiz/form/edit-create/QuizEditCreateInputs";
import { ErrorMessages } from "../../../types/redux/slice";
import MainHeader from "../../../components/MainHeader/MainHeader";
import Footer from "../../../components/Footer/Footer";
import { CheckIcon, FolderIcon, PhotoIcon, PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const INITIAL_QUIZ_QUESTION_ANSWER_DATA: QuizQuestionAnswerOptionFormData = {
  text: "",
  value: "",
  position: 1,
  image: null,
  external_image: "",
};

const INITIAL_QUIZ_QUESTION_DATA: QuizQuestionFormData = {
  quiz: undefined,
  text: "",
  value: "",
  category: QUESTION_CATEGORIES.MOVIES as QuizQuestionCategory,
  type: QUESTION_TYPES.MULTIPLE as QuizQuestionType,
  position: 1,
  required: false,
  answer_options: [],
};

const INITIAL_FORM_DATA: QuizFormData = {
  name: "",
  neural_network: "",
  questions: [],
  is_hidden: false,
};

/**
 * Generate blank question answer data
 */
function generateBlankQuestionAnswerData(
  question: QuizQuestionFormData | undefined = undefined
) {
  // React has issues with nested state data, therefore we have to
  // append objects to nested arrays this way.
  const answerData = {
    ...INITIAL_QUIZ_QUESTION_ANSWER_DATA,
    position: findNextAnswerOptionPosition(question),
  };
  return answerData;
}

/**
 * Generate blank question data
 */
function generateBlankQuestionData(
  formData: QuizFormData | undefined = undefined
) {
  // React has issues with nested state data, therefore we have to
  // append objects to nested arrays this way.
  const questionData = {
    ...INITIAL_QUIZ_QUESTION_DATA,
    answer_options: [generateBlankQuestionAnswerData(undefined)],
    position: findNextQuestionPosition(formData),
  };
  return questionData;
}

/**
 * Generate blank form data
 */
function generateBlankFormData() {
  // React has issues with nested state data, therefore we have to
  // append objects to nested arrays this way.
  return {
    ...INITIAL_FORM_DATA,
    questions: [generateBlankQuestionData()],
  };
}

/**
 * Determine position of the next question to be added
 */
function findNextQuestionPosition(data: QuizFormData | undefined = undefined) {
  if (!data) return 1;

  const positions = data.questions.map((item) => item.position);
  const maxPosition = Math.max(...positions);
  return maxPosition + 1;
}

/**
 * Determine position of the next answer option to be added
 */
function findNextAnswerOptionPosition(
  data: QuizQuestionFormData | undefined = undefined
) {
  if (!data) return 1;

  const positions = data.answer_options.map((item) => item.position);
  const maxPosition = Math.max(...positions);
  return maxPosition + 1;
}

/**
 * Quiz Edit/Create page
 */
export default function QuizEditCreate() {
  const { id: quizId } = useParams();

  const quizzes = useAppSelector((store) => store.quiz.quizzes);
  const pendingGetNeuralNetworks = useAppSelector(
    (store) => store.neuralNetworks.pendingGetNeuralNetworks
  );
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes
  );
  const pendingRetrieveQuizQuestions = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizQuestions
  );
  const pendingQuizEditCreate = useAppSelector(
    (store) => store.quiz.pendingQuizEditCreate
  );
  const quizToEditQuestions = useAppSelector((store) => store.quiz.questions);
  const [quizToEdit, setQuizToEdit] = useState<Quiz | null>(null);
  const [formData, setFormData] = useState<QuizFormData>(
    generateBlankFormData()
  );
  const dispatch = useAppDispatch();

  /**
   * Add another question
   */
  function onAddAnotherQuestion() {
    const newFormData = { ...formData };
    newFormData.questions.push(generateBlankQuestionData(newFormData));
    setFormData(newFormData);
  }

  /**
   * Populate edit form
   */
  function populateEditForm(quizToEditQuestions: QuizQuestion[]) {
    if (quizToEdit) {
      const questions: QuizQuestionFormData[] = quizToEditQuestions.map(
        (question) => {
          const { answer_options: answerOptions, ...rest } = question;
          const questionFormData: QuizQuestionFormData = {
            ...rest,
            quiz: quizToEdit.id,
            answer_options: [],
          };
          questionFormData.answer_options = answerOptions.map((option) => {
            const { question, ...rest } = option;
            return rest;
          });

          return questionFormData;
        }
      );
      const { name, neural_network, is_hidden } = quizToEdit;
      setFormData({ name, neural_network, is_hidden, questions });
    }
  }

  /**
   * Extract images from form data
   */
  function extractImages(data: QuizFormData) {
    // Passed data must be deep copied to preserve state of images in the form
    const deepCopiedData = JSON.parse(JSON.stringify(data));

    const answerOptionImages: Array<QuizQuestionAnswerOptionImageFormData> = [];
    const questionImages: Array<QuizQuestionImageFormData> = [];
    for (const question of deepCopiedData.questions) {
      if (question.id && (question.image || question.external_image)) {
        // image is expected to be a file
        const image = question.image instanceof File && question.image;
        const imageData: QuizQuestionImageFormData = {
          question: question.id,
          external_image: question.external_image,
        };
        if (image) imageData.image = image;
        questionImages.push(imageData);
        delete question.image;
        delete question.external_image;
      }
      for (const answerOption of question.answer_options) {
        if (
          answerOption.id &&
          (answerOption.image || answerOption.external_image)
        ) {
          // image is expected to be a file
          const image =
            answerOption.image instanceof File && answerOption.image;
          const imageData: QuizQuestionAnswerOptionImageFormData = {
            answer_option: answerOption.id,
            external_image: answerOption.external_image,
          };
          if (image) imageData.image = image;
          answerOptionImages.push(imageData);
          delete answerOption.image;
          delete answerOption.external_image;
        }
      }
    }

    return { newData: deepCopiedData, answerOptionImages, questionImages };
  }

  /**
   * Handle form submission
   */
  async function handleSubmit() {
    let response;
    const answerOptionImageResponses: Array<{
      answerOption: number;
      response: any;
    }> = [];
    let questionImageResponses: Array<any> = [];

    if (quizToEdit) {
      // Images have to be uploaded one-by-one. It's a Django limitation.
      const { newData, answerOptionImages, questionImages } = extractImages({
        ...formData,
      });

      // Update quiz(except for images)
      response = await dispatch(
        updateQuiz({ quizId: quizToEdit.id, data: newData })
      );

      // Once quiz is updated - upload images and save API responses
      // to be checked for errors later

      await Promise.allSettled(
        answerOptionImages.map(async (answerOptionImage) => {
          answerOptionImageResponses.push({
            answerOption: answerOptionImage.answer_option,
            response: await dispatch(
              uploadAnswerOptionImage(answerOptionImage)
            ),
          });
        })
      );

      questionImageResponses = new Array(questionImages.length);
      await Promise.allSettled(
        questionImages.map(async (questionImage, idx) => {
          questionImageResponses[idx] = await dispatch(
            uploadQuestionImage(questionImage)
          );
        })
      );

      // Update error state
      const aggregatedUploadAnswerOptionImageErrorMessages: {
        [answerOptionId: number]: ErrorMessages;
      } = {};
      answerOptionImageResponses.forEach(
        (item) =>
          (aggregatedUploadAnswerOptionImageErrorMessages[item.answerOption] =
            item.response.payload)
      );

      dispatch(
        setAggregatedUploadAnswerOptionImageErrorMessages(
          aggregatedUploadAnswerOptionImageErrorMessages
        )
      );
      dispatch(
        setAggregatedUploadQuestionImageErrorMessages(
          questionImageResponses.map((item) => item.payload)
        )
      );
    } else {
      response = await dispatch(createQuiz(formData));
    }

    // Check errors
    const noErrorsEditCreateQuiz = !isDispatchResponseError(response);
    const noErrorsUploadAnswerOptionImages = answerOptionImageResponses.every(
      (item) => !isDispatchResponseError(item.response)
    );
    const noErrorsUploadQuestionImages = questionImageResponses.every(
      (item) => !isDispatchResponseError(item)
    );

    if (
      noErrorsEditCreateQuiz &&
      noErrorsUploadAnswerOptionImages &&
      noErrorsUploadQuestionImages
    ) {
      // Navigate to Quizzes page on success
      const url = APP_URLS.QUIZZES;
      history.navigate(url);
    }
  }

  /**
   * Retrieve quiz to be edited
   */
  useEffect(() => {
    if (quizId) {
      dispatch(retrieveQuizzes({ queryParams: { id: quizId } }));
    } else {
      dispatch(resetQuizzes());
    }
  }, [quizId]);

  /**
   * Extract quiz to be edited and retrieve its questions
   */
  useEffect(() => {
    if (quizzes.results.length > 0) {
      const newQuizToEdit = quizzes.results[0];
      setQuizToEdit({ ...newQuizToEdit });
      dispatch(retrieveQuizQuestions({ quizId: newQuizToEdit.id }));
    } else {
      setQuizToEdit(null);
    }
  }, [quizzes]);

  /**
   * Populate edit form
   */
  useEffect(() => {
    if (quizToEdit) {
      populateEditForm(quizToEditQuestions);
    }
  }, [quizToEditQuestions, quizToEdit]);

  /**
   * Retrieve neural networks
   */
  useEffect(() => {
    dispatch(getNeuralNetworks({ queryParams: { page_size: "undefined" } }));
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-all duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
          {/* Header Section */}
          <div
            className="bg-gradient-to-r from-indigo-600 to-purple-600 dark:from-indigo-700 
                         dark:to-purple-700 rounded-2xl p-6 sm:p-8 shadow-lg mb-6"
          >
            <MainHeader
              title={
                quizToEdit ? `Edit Quiz: ${quizToEdit.name}` : "Create Quiz"
              }
            />
            <p className="text-indigo-50 text-base sm:text-lg mt-2 max-w-2xl">
              {quizToEdit
                ? "Modify your quiz content, questions, and settings"
                : "Create a new quiz with custom questions and interactive answers"}
            </p>
          </div>

          {/* Main Form Content */}
          <div
            className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg 
                         transition-all duration-200 border border-gray-200 dark:border-gray-700"
          >
            <div className="p-4 sm:p-6">
              {pendingRetrieveQuizQuestions || pendingRetrieveQuizzes ? (
                // Loading State
                <div className="max-w-3xl mx-auto space-y-6">
                  <div className="animate-pulse space-y-4">
                    <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-lg w-3/4" />
                    <div className="h-32 bg-gray-200 dark:bg-gray-700 rounded-lg" />
                    <div className="space-y-2">
                      {[1, 2, 3].map((i) => (
                        <div
                          key={i}
                          className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                // Form Content
                <div className="max-w-3xl mx-auto space-y-8">
                  <QuizEditCreateInputs
                    formData={formData}
                    setFormData={setFormData}
                    generateBlankQuestionAnswerData={
                      generateBlankQuestionAnswerData
                    }
                  />

                  {/* Action Buttons */}
                  <div
                    className="flex flex-col sm:flex-row gap-4 pt-6 border-t 
                                border-gray-200 dark:border-gray-700"
                  >
                    <button
                      onClick={onAddAnotherQuestion}
                      className="flex-1 inline-flex justify-center items-center px-4 py-2.5 
                               bg-indigo-50 hover:bg-indigo-100 dark:bg-indigo-900/30 
                               dark:hover:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400 
                               rounded-xl transition-all duration-200 group"
                    >
                      <PlusIcon className="w-5 h-5 mr-2 transition-transform group-hover:scale-110" />
                      {formData.questions.length > 0
                        ? "Add Another Question"
                        : "Add Question"}
                    </button>

                    <button
                      onClick={handleSubmit}
                      disabled={
                        formData.questions.length === 0 ||
                        pendingQuizEditCreate ||
                        pendingGetNeuralNetworks
                      }
                      className="flex-1 inline-flex justify-center items-center px-4 py-2.5 
                               bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 
                               dark:hover:bg-indigo-600 text-white rounded-xl 
                               transition-all duration-200 disabled:opacity-50 
                               disabled:cursor-not-allowed group"
                    >
                      {pendingQuizEditCreate ? (
                        <>
                          <Spinner className="w-5 h-5 mr-2 text-white" />
                          Saving...
                        </>
                      ) : (
                        <>
                          <CheckIcon className="w-5 h-5 mr-2 transition-transform group-hover:scale-110" />
                          {quizToEdit ? "Save Changes" : "Create Quiz"}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Quick Tips Section */}
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {[
              {
                title: "Question Types",
                description:
                  "Mix different question types for better engagement and learning outcomes",
                icon: QuestionMarkCircleIcon,
                color: "indigo",
              },
              {
                title: "Visual Content",
                description:
                  "Add images to make your questions and answers more interactive",
                icon: PhotoIcon,
                color: "purple",
              },
              {
                title: "Organization",
                description:
                  "Use clear identifiers and categories for easy management",
                icon: FolderIcon,
                color: "blue",
              },
            ].map((tip, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-xl p-5 sm:p-6 shadow-sm 
                            border border-gray-200 dark:border-gray-700 hover:shadow-md 
                            transition-all duration-200"
              >
                <div className="flex items-start gap-4">
                  <div
                    className={`flex-shrink-0 p-2 rounded-lg bg-${tip.color}-50 
                                 dark:bg-${tip.color}-900/30`}
                  >
                    <tip.icon
                      className={`w-6 h-6 text-${tip.color}-600 
                                       dark:text-${tip.color}-400`}
                    />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">
                      {tip.title}
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      {tip.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
