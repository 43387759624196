import React from "react";
import LibraryMobileMenuItem from "./LibraryMobileMenuItem";
import {
  ListBulletIcon,
  MusicalNoteIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { APP_URLS } from "../../navigation";

/**
 * Component that renders the library menu for mobile.
 */
export default function LibraryMobile() {
  return (
    <div className="flex flex-col justify-center items-center w-full" aria-label="Library Mobile Menu">
      <LibraryMobileMenuItem
        icon={<ListBulletIcon />}
        label="Playlists"
        url={APP_URLS.PLAYLISTS_MOBILE}
      />
      <LibraryMobileMenuItem
        icon={<MusicalNoteIcon />}
        label="Placeholder"
        url={APP_URLS.LIBRARY_MOBILE}
      />
      <LibraryMobileMenuItem
        icon={<PlayIcon />}
        label="Placeholder"
        url={APP_URLS.LIBRARY_MOBILE}
      />
    </div>
  );
}
