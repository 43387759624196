import { UpdateUserPayload } from "../types/user";
import { get, patch } from "../services/apiService";

/**
 * Send a GET request to the API to retrieve the currently logged-in user data
 */
export const retrieveUser = async () => {
  return await get("/api/accounts/current_user/");
};

/**
 * Send a PATCH request to the API to update the currently logged-in user data
 */
export const patchUser = async (data: UpdateUserPayload) => {
  return await patch("/api/accounts/current_user/", data);
};
