import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { displayErrors } from "../../helpers/errors";
import {
  addItemToPlaylists,
  getMyPlaylists,
} from "../../redux/playlist/playlistSlice";
import { isDispatchResponseError } from "../../redux/utils";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Modal from "../common/modal";
import { AddItemToPlaylistPayload, Playlist } from "../../types/playlist";
import { FeedItem } from "../../types/feed";
import { getRandomFeedItem } from "../../redux/feed/feedItemsSlice";

interface Props {
  feedItem: FeedItem;
  show: boolean;
  onClose: () => void;
  headerText?: string;
}

/**
 * A popup to select playlists to add a feed item to.
 */
export default function SelectPlaylistPopup({
  feedItem,
  show,
  onClose,
  headerText = "Add video to playlist",
}: Props) {
  const dispatch = useAppDispatch();
  const playlists = useAppSelector((state) => state.playlist.myPlaylists);
  const getMyPlaylistsErrors = useAppSelector(
    (state) => state.playlist.getMyPlaylistsErrors
  );
  const [selectedPlaylists, setSelectedPlaylists] = useState<Array<string>>([]);

  /**
   * Add or remove the given playlist from the selected playlists list.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPlaylistId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked && !selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is checked and doesn't exist in selectedPlaylists list
      setSelectedPlaylists([...selectedPlaylists, selectedPlaylistId]);
    } else if (!isChecked && selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is unchecked and exists in selectedPlaylists list
      const newSelectedPlaylists = selectedPlaylists.filter(
        (selectedPlaylist) => selectedPlaylist !== selectedPlaylistId
      );
      setSelectedPlaylists(newSelectedPlaylists);
    }
  };

  /**
   * Add the given feed item to playlists.
   */
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const data: AddItemToPlaylistPayload = {
      playlist_ids: selectedPlaylists,
      feed_item_id: feedItem.id,
    };
    const response = await dispatch(addItemToPlaylists(data));

    if (!isDispatchResponseError(response)) {
      // Close the popup and proceed to next video
      onClose();
      // Retrieve a random video / track from Django API
      dispatch(getRandomFeedItem({ queryParams: { types: feedItem.type } }));
    }
  };

  /**
   * Get the number of items in a playlist.
   */
  function getPlaylistLength(playlist: Playlist) {
    const length = playlist.items.length;
    return length;
  }

  /**
   * Retrieve playlists and preselect playlists associated with the given feed item.
   */
  useEffect(() => {
    // Run the code only when popup is open
    if (show) {
      dispatch(getMyPlaylists({}));
      setSelectedPlaylists(feedItem.playlists || []);
    }
  }, [show, feedItem]);

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 
                    transition-all duration-200 ${
                      show ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
    >
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-gray-900/60 dark:bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div
        className="relative w-full max-w-md bg-white dark:bg-[#1e2533] 
                    rounded-2xl shadow-xl transform transition-all duration-200 
                    border border-gray-200 dark:border-white/5"
      >
        {/* Header */}
        <div
          className="flex items-center justify-between p-6 
                     border-b border-gray-200 dark:border-white/5"
        >
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {headerText}
          </h3>
          <button
            onClick={onClose}
            className="p-2 rounded-lg text-gray-500 hover:text-gray-700 
                     dark:text-gray-400 dark:hover:text-gray-300
                     hover:bg-gray-100 dark:hover:bg-white/5 
                     transition-all duration-200"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Body */}
        <div className="p-6">
          {playlists.length > 0 ? (
            <div className="space-y-4">
              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Your playlists
              </h4>

              <div className="border border-gray-200 dark:border-white/10 rounded-xl overflow-hidden">
                <table className="w-full text-sm">
                  <thead className="bg-gray-50 dark:bg-white/5 text-gray-600 dark:text-gray-400">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Items
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                    {playlists.map((playlist, idx) => (
                      <tr
                        key={idx}
                        className="group hover:bg-gray-50 dark:hover:bg-white/5 
                                   transition-colors duration-200"
                      >
                        <td className="px-6 py-4">
                          <label className="flex items-center space-x-3 cursor-pointer">
                            <input
                              type="checkbox"
                              id={playlist.id}
                              name="video_playlists"
                              value={playlist.id}
                              defaultChecked={feedItem?.playlists?.includes(
                                playlist.id || ""
                              )}
                              onChange={handleChange}
                              className="w-4 h-4 text-blue-600 bg-gray-100 dark:bg-white/5
                                       border-gray-300 dark:border-white/10 rounded 
                                       focus:ring-blue-500/20"
                            />
                            <span className="text-gray-900 dark:text-white">
                              {playlist.name}
                            </span>
                          </label>
                        </td>
                        <td className="px-6 py-4 text-gray-500 dark:text-gray-400">
                          {getPlaylistLength(playlist)} items
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {displayErrors(getMyPlaylistsErrors.name)}
            </div>
          ) : (
            <div className="text-center py-8">
              <div
                className="mx-auto flex items-center justify-center w-12 h-12 
                            rounded-full bg-gray-100 dark:bg-white/5 mb-4"
              >
                <ExclamationCircleIcon className="w-6 h-6 text-gray-400 dark:text-gray-500" />
              </div>
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                No Playlists Found
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                You must create a playlist first!
              </p>
            </div>
          )}
        </div>

        {/* Footer */}
        {playlists.length > 0 && (
          <div className="flex justify-end gap-3 p-6 border-t border-gray-200 dark:border-white/5">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200
                       bg-gray-100 dark:bg-white/5 rounded-lg 
                       hover:bg-gray-200 dark:hover:bg-white/10
                       transition-all duration-200"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 text-sm font-medium text-white
                       bg-blue-600 rounded-lg hover:bg-blue-700
                       disabled:opacity-50 disabled:cursor-not-allowed
                       transition-all duration-200"
            >
              Add to Playlist
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
