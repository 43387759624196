import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PaginationV2 from "components/common/pagination/PaginationV2";
import SearchInput from "components/common/searchInput";
import { FeedItemDetailsLoader } from "components/common/ShimmerLoader";
import { Button, Spinner } from "flowbite-react";
import { formatDateTime } from "helpers/date";
import { APP_URLS } from "navigation";
import React, { useEffect, useState } from "react";
import {
  getFeedItemReports,
  toggleFeedItemReportReviewStatus,
} from "redux/feed/feedItemReportsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isDispatchResponseError } from "redux/utils";
import { QueryParams } from "types/api";
import { FEED_ITEM_TYPES, FeedItem, FeedItemReport } from "types/feed";
import { useDebounce } from "use-debounce";


const DEFAULT_ORDERING = "created_at";

const LABELS = {
  created_at: "Creation date",
  user: "Reported by",
  feed_item: "Feed item",
  description: "Description",
  is_reviewed: "Is reviewed",
};

/**
 * Table used for displaying feed item reports.
 */
export default function ReportListTable() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(DEFAULT_ORDERING);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const pendingGetFeedItemReports = useAppSelector(
    (state) => state.feedItemReports.pendingGetFeedItemReports,
  );
  const feedItemReports = useAppSelector(
    (state) => state.feedItemReports.feedItemReports,
  );
  const pendingToggleFeedItemReportReviewStatus = useAppSelector(
    (state) => state.feedItemReports.pendingToggleFeedItemReportReviewStatus,
  );

  /**
   * Render table header.
   */
  function renderTableHeader({ key, label }: { key: string; label: string }) {
    let icon = null;
    if (ordering === key) {
      icon = <ChevronDownIcon className="w-4" aria-hidden="true" />;
    }
    if (ordering === "-" + key) {
      icon = <ChevronUpIcon className="w-4" aria-hidden="true" />;
    }

    function onClickHeader() {
      if (ordering === key) {
        setOrdering("-" + ordering);
      } else if (ordering === "-" + key) {
        setOrdering(ordering.replaceAll("-", ""));
      } else {
        setOrdering(key);
      }
    }

    return (
      <th className="p-2" key={key}>
        <button onClick={onClickHeader} className="flex flex-row gap-1" aria-label={`Sort by ${label}`}>
          {icon}
          {label}
        </button>
      </th>
    );
  }

  /**
   * Toggle review status.
   */
  async function onToggleReviewStatus(item: FeedItemReport) {
    const data = { id: item.id, is_reviewed: !item.is_reviewed };
    const response = await dispatch(toggleFeedItemReportReviewStatus(data));
    if (!isDispatchResponseError(response)) {
      fetchData(page, query);
    }
  }

  /**
   * Generate link to feed item.
   */
  function generateFeedItemLink(feedItem: FeedItem) {
    const { type } = feedItem;
    if (type === FEED_ITEM_TYPES.NEWS) {
      return APP_URLS.FEED_DATA_NEWS_DETAILS.replace(":newsId", feedItem.id);
    } else if (type === FEED_ITEM_TYPES.TRACK) {
      return APP_URLS.FEED_DATA_TRACK_DETAILS.replace(":trackId", feedItem.id);
    } else {
      return APP_URLS.FEED_DATA_VIDEO_DETAILS.replace(":videoId", feedItem.id);
    }
  }

  /**
   * Fetch feed item reports.
   */
  function fetchData(page: number, query: string) {
    const queryParams: QueryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      query,
      ordering,
    };
    dispatch(getFeedItemReports({ queryParams }));
  }

  /**
   * Fetch data whenever page, query or ordering changes.
   */
  useEffect(() => {
    fetchData(page, debouncedQuery);
  }, [page, debouncedQuery, ordering]);

  return (
    <div className="space-y-4">
      {/* Search Bar */}
      <div className="bg-[#252d3d] rounded-lg p-4">
        <SearchInput 
          value={query} 
          onChange={setQuery} 
        />
      </div>

      {/* Loading State */}
      {pendingGetFeedItemReports || pendingToggleFeedItemReportReviewStatus ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <FeedItemDetailsLoader />
        </div>
      ) : (
        <div className="bg-[#252d3d] rounded-xl border border-white/5 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-gray-300 uppercase border-b border-white/5">
                <tr>
                  {Object.keys(LABELS).map((key) => renderTableHeader({
                    key,
                    label: LABELS[key as keyof typeof LABELS],
                  }))}
                  <th className="p-4">
                    <span className="flex flex-row gap-1">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white/5">
                {feedItemReports.results.map((elem) => (
                  <tr key={elem.id} 
                      className="border-b border-white/5 bg-[#1e2533] 
                                hover:bg-white/5 transition-colors duration-200">
                    <td className="p-4 text-gray-200">{formatDateTime(elem.created_at)}</td>
                    <td className="p-4 text-gray-200">{elem.user.username}</td>
                    <td className="p-4">
                      <a href={generateFeedItemLink(elem.feed_item)}
                         target="_blank"
                         rel="noreferrer"
                         className="text-blue-400 hover:text-blue-300 transition-colors duration-200">
                        {elem.feed_item?.title}
                      </a>
                    </td>
                    <td className="p-4 text-gray-200">{elem.description}</td>
                    <td className="p-4">
                      {elem.is_reviewed ? (
                        <CheckCircleIcon className="w-6 h-6 text-green-400" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red-400" />
                      )}
                    </td>
                    <td className="p-4">
                      <button
                        onClick={() => onToggleReviewStatus(elem)}
                        className="px-4 py-2 text-sm font-medium rounded-lg
                                 text-blue-400 bg-blue-500/10 hover:bg-blue-500/20 
                                 border border-blue-500/20 transition-all duration-200">
                        Toggle review status
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <PaginationV2
            count={feedItemReports.count}
            page={page}
            previous={feedItemReports.previous}
            next={feedItemReports.next}
            onClickPrev={() => setPage(page - 1)}
            onClickNext={() => setPage(page + 1)}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  );
}
