import React, { PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { history } from "./helpers/history";
import Navigation, { APP_URLS } from "./navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WagmiProvider } from "wagmi";
import { config as wagmiConfig } from "components/brainCoin/config";
import { QueryClientProvider } from "@tanstack/react-query";
import { AlchemyAccountProvider, useLogout } from "@account-kit/react";
import { config, queryClient } from "./config";
import { AlchemyClientState } from "@account-kit/core";

function App(props: PropsWithChildren<{ initialState?: AlchemyClientState }>) {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className="App">
      <ToastContainer />
      {/* <WagmiProvider config={wagmiConfig}> */}
        <QueryClientProvider client={queryClient}>
          <AlchemyAccountProvider
            config={config}
            queryClient={queryClient}
            initialState={props.initialState}
          >
            <Navigation />
          </AlchemyAccountProvider>
        </QueryClientProvider>
      {/* </WagmiProvider> */}
    </div>
  );
}

export default App;
