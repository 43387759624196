import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import FileUpload from "../../../components/common/file/FileUpload";
import { Label, TextInput } from "flowbite-react";
import { displayErrors } from "../../../helpers/errors";
import { history } from "../../../helpers/history";
import { addDataSet } from "../../../redux/dataSets/dataSetsSlice";
import { isDispatchResponseError } from "../../../redux/utils";
import { incrementActiveStepIndex } from "../../../redux/auth/authSlice";
import { APP_URLS } from "../../../navigation";

/**
 * Import Dataset page
 */
export default function ImportDataset() {
  const dispatch = useAppDispatch();
  const errorMessages = useAppSelector((state) => state.dataSets.errorMessages);
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  /**
   * Skip this step and navigate to the next one
   */
  const onSkipClick = () => {
    dispatch(incrementActiveStepIndex());
  };

  /**
   * Upload the selected file
   */
  const onFileUpload = async () => {
    const form_data = new FormData();
    form_data.append("name", name);
    if (file) {
      form_data.append("file", file, file.name);
    }

    // Submit Neural Network Create request
    const addDataSetResponse = await dispatch(addDataSet(form_data));

    if (!isDispatchResponseError(addDataSetResponse)) {
      // Navigate user to Dashboard if DataSet is successfully uploaded
      history.navigate(APP_URLS.VIDEOS);
    }
  };

  return (
    <div className="flex flex-col justify-center sm:h-screen">
      <div className="relative overflow-x-auto border shadow-md sm:rounded-lg bg-white">
        <div className="flex flex-col sm:flex-row items-center px-6 py-4 border-b">
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-gray-800 capitalize">
              Upload/Import Dataset
            </span>
            <span className="text-sm text-gray-500">
              Your dataset will be used to train the selected Neural Network
            </span>
          </div>

          <button
            onClick={onSkipClick}
            className="mx-0 sm:ml-16 sm:mr-4 order-2 sm:order-1 flex py-2 text-sm font-semibold text-center text-gray-500 hover:text-gray-600 hover:underline"
            aria-label="Skip this step"
          >
            Skip This Step
          </button>
          <button
            onClick={onFileUpload}
            disabled={!file}
            className={
              "ml-0 sm:ml-2 flex order-1 sm:order-2 px-4 py-2 text-center rounded-lg " +
              (file ? "text-white bg-blue-600" : "text-white bg-gray-300")
            }
            aria-label="Continue to next step"
          >
            <span className="text-sm font-semibold">Continue</span>
          </button>
        </div>

        <div className="flex flex-col gap-5 p-4 w-full">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value="Dataset Name (Optional)" />
            </div>
            <TextInput
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="My Dataset"
              aria-label="Dataset Name"
            />
            {displayErrors(errorMessages.name)}
          </div>

          <div>
            <div className="mb-2 block">
              <Label value="Upload Dataset file" />
            </div>
            <FileUpload file={file} setFile={setFile} />
            {displayErrors(errorMessages.file)}
          </div>
        </div>
      </div>
    </div>
  );
}
