import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { retrieveSpotifyLoginUrl } from "../../services/spotifyService";
import { ErrorMessages } from "../../types/redux/slice";

interface SpotifyState {
  pending: boolean;
  errorMessages: ErrorMessages;
  connectionUrl: string | null;
}

const initialState: SpotifyState = {
  pending: false,
  errorMessages: {},
  connectionUrl: null,
};

export const spotifySlice = createSlice({
  name: "spotify",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSpotifyConnectionUrl.fulfilled, (state, action) => {
      state.pending = false;
      state.errorMessages = {};
      state.connectionUrl = action.payload;
    });
    builder.addCase(getSpotifyConnectionUrl.pending, (state, action) => {
      state.pending = true;
      state.errorMessages = {};
      state.connectionUrl = null;
    });
    builder.addCase(getSpotifyConnectionUrl.rejected, (state, action) => {
      state.pending = false;
      state.errorMessages = action.payload || {};
      state.connectionUrl = null;
    });
  },
});

export const getSpotifyConnectionUrl = createAsyncThunk<
  string,
  void,
  { rejectValue: ErrorMessages }
>("spotify/getConnectionUrl", async (data, thunkApi) => {
  try {
    // Request the currently logged-in user with Django API
    const response = await retrieveSpotifyLoginUrl();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
