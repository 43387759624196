import Footer from "../../components/Footer/Footer";
import { APP_URLS } from "../../navigation";
import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();
  const handleAgree = () => {
    // Implement your logic here, e.g., submit the form or redirect the user
    alert("Thank you for agreeing to our Privacy Policy.");
    navigate(APP_URLS.LOGIN);
    // Example: Redirect to the next page
    // window.location.href = 'next_page.html';
  };

  return (
    <div style={styles.container}>
      <img
        src="https://braincoin.com/assets/Gold-Brain-Coin-DL9cS1jd.png"
        alt="BrainCargo Logo"
        style={{ width: "100px" }}
      />
      <br />
      <h1 style={styles.title}>Privacy Policy</h1>

      <p>
        <strong>Effective Date:</strong> 10/31/2024
      </p>
      <p>
        <strong>Last Updated:</strong> 10/31/2024
      </p>

      <p>
        At BrainCargo ("<strong>we</strong>", "<strong>us</strong>", or "
        <strong>our</strong>"), we are committed to protecting your privacy and
        ensuring that your personal information is handled responsibly. This
        Privacy Policy outlines how we collect, use, disclose, and safeguard
        your information when you visit our website
        <a href="https://www.braincargo.com" style={{ color: "#00539C" }}>
          {" "}
          www.braincargo.com
        </a>{" "}
        (the "<strong>Site</strong>") or use our services.
      </p>

      <p>
        By accessing or using our Site, you agree to the terms of this Privacy
        Policy. If you do not agree with these terms, please do not access the
        Site.
      </p>

      <hr style={styles.hr} />

      <h2 style={{ color: "#00539C" }}>1. Information We Collect</h2>

      <h3 style={{ color: "#00539C" }}>a. Personal Information</h3>
      <p>
        We may collect personal information that you voluntarily provide to us
        when you:
      </p>
      <ul>
        <li>Register an account on our Site</li>
        <li>Subscribe to a newsletter or updates</li>
        <li>Fill out a form or survey</li>
        <li>Participate in promotions or events</li>
        <li>Contact customer support</li>
      </ul>

      <p>This information may include:</p>
      <ul>
        <li>
          <strong>Name</strong>
        </li>
        <li>
          <strong>Email address</strong>
        </li>
        <li>
          <strong>Mailing address</strong>
        </li>
        <li>
          <strong>Phone number</strong>
        </li>
        <li>
          <strong>Payment information</strong>
        </li>
        <li>
          <strong>Usernames and passwords</strong>
        </li>
      </ul>

      <h3 style={{ color: "#00539C" }}>
        b. Automatically Collected Information
      </h3>
      <p>
        When you visit our Site, we may automatically collect certain
        information about your device and usage, such as:
      </p>
      <ul>
        <li>
          <strong>IP address</strong>
        </li>
        <li>
          <strong>Browser type and version</strong>
        </li>
        <li>
          <strong>Operating system</strong>
        </li>
        <li>
          <strong>Referring website</strong>
        </li>
        <li>
          <strong>Pages viewed and time spent</strong>
        </li>
        <li>
          <strong>Clickstream data</strong>
        </li>
      </ul>

      <p>
        We may use cookies, web beacons, and similar tracking technologies to
        collect this information.
      </p>

      <hr style={styles.hr} />

      {/* Repeat the same pattern for other sections */}

      <h2 style={{ color: "#00539C" }}>2. Contact Us</h2>

      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:privacy@braincargo.com" style={{ color: "#00539C" }}>
            privacy@braincargo.com
          </a>
        </li>
        <li>
          <strong>Address:</strong> BrainCargo, 1309 Coffeen Avenue STE 1200
          Sheridan Wyoming 82801
        </li>
      </ul>

      <hr style={styles.hr} />

      <div style={{ marginTop: "20px" }}>
        <button
          onClick={handleAgree}
          style={{
            padding: "10px 20px",
            backgroundColor: "#00539C",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          I Agree
        </button>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    lineHeight: 1.6,
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  } as React.CSSProperties,
  title: {
    textAlign: "center",
    color: "#00539C",
  } as React.CSSProperties,
  heading: {
    color: "#00539C",
    marginTop: "30px",
  } as React.CSSProperties,
  subheading: {
    color: "#00539C",
    marginTop: "20px",
  } as React.CSSProperties,
  link: {
    color: "#00539C",
    textDecoration: "none",
  } as React.CSSProperties,
  list: {
    paddingLeft: "20px",
  } as React.CSSProperties,
  hr: {
    margin: "30px 0",
    border: "none",
    borderTop: "1px solid #ccc",
  } as React.CSSProperties,
};

export default PrivacyPolicy;

// <!DOCTYPE html>
// <html lang="en">
// <head>
//     <meta charset="UTF-8">
//     <title>BrainCargo Privacy Policy</title>
//     <style>
//         body {
//             font-family: Arial, sans-serif;
//             line-height: 1.6;
//             margin: 20px;
//             color: #333;
//         }
//         h1, h2, h3 {
//             color: #00539C;
//         }
//         a {
//             color: #00539C;
//         }
//         .agree-button {
//             margin-top: 20px;
//         }
//     </style>
// </head>
// <body>

// <h1>Privacy Policy</h1>

// <p><strong>Effective Date:</strong> 10/31/2024</p>
// <p><strong>Last Updated:</strong> 10/31/2024</p>

// <p>At BrainCargo ("<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>"), we are committed to protecting your privacy and ensuring that your personal information is handled responsibly. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://www.braincargo.com">www.braincargo.com</a> (the "<strong>Site</strong>") or use our services.</p>

// <p>By accessing or using our Site, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not access the Site.</p>

// <hr>

// <h2>1. Information We Collect</h2>

// <h3>a. Personal Information</h3>

// <p>We may collect personal information that you voluntarily provide to us when you:</p>
// <ul>
//     <li>Register an account on our Site</li>
//     <li>Subscribe to a newsletter or updates</li>
//     <li>Fill out a form or survey</li>
//     <li>Participate in promotions or events</li>
//     <li>Contact customer support</li>
// </ul>

// <p>This information may include:</p>
// <ul>
//     <li><strong>Name</strong></li>
//     <li><strong>Email address</strong></li>
//     <li><strong>Mailing address</strong></li>
//     <li><strong>Phone number</strong></li>
//     <li><strong>Payment information</strong></li>
//     <li><strong>Usernames and passwords</strong></li>
// </ul>

// <h3>b. Automatically Collected Information</h3>

// <p>When you visit our Site, we may automatically collect certain information about your device and usage, such as:</p>
// <ul>
//     <li><strong>IP address</strong></li>
//     <li><strong>Browser type and version</strong></li>
//     <li><strong>Operating system</strong></li>
//     <li><strong>Referring website</strong></li>
//     <li><strong>Pages viewed and time spent</strong></li>
//     <li><strong>Clickstream data</strong></li>
// </ul>

// <p>We may use cookies, web beacons, and similar tracking technologies to collect this information.</p>

// <hr>

// <h2>2. How We Use Your Information</h2>

// <p>We may use the information we collect for various purposes, including:</p>
// <ul>
//     <li><strong>Providing and maintaining our services</strong></li>
//     <li><strong>Personalizing your experience on our Site</strong></li>
//     <li><strong>Processing transactions and sending confirmations</strong></li>
//     <li><strong>Responding to customer service requests</strong></li>
//     <li><strong>Sending administrative information, such as updates and security alerts</strong></li>
//     <li><strong>Marketing and promotional communications (with your consent)</strong></li>
//     <li><strong>Analyzing usage trends and improving our Site</strong></li>
//     <li><strong>Preventing fraudulent transactions and monitoring against threats</strong></li>
// </ul>

// <hr>

// <h2>3. Disclosure of Your Information</h2>

// <p>We may share your information in the following circumstances:</p>

// <h3>a. Service Providers</h3>

// <p>We may share your information with third-party vendors who perform services on our behalf, such as:</p>
// <ul>
//     <li>Payment processing</li>
//     <li>Data analysis</li>
//     <li>Email delivery</li>
//     <li>Hosting services</li>
//     <li>Customer service</li>
//     <li>Marketing assistance</li>
// </ul>

// <h3>b. Legal Obligations</h3>

// <p>We may disclose your information if required to do so by law or in response to valid legal requests, such as:</p>
// <ul>
//     <li>Complying with legal processes</li>
//     <li>Protecting our rights and property</li>
//     <li>Enforcing our terms and conditions</li>
//     <li>Protecting the safety of users or the public</li>
// </ul>

// <h3>c. Business Transfers</h3>

// <p>In the event of a merger, acquisition, financing, or sale of assets, your information may be transferred to a third party as part of that transaction.</p>

// <h3>d. With Your Consent</h3>

// <p>We may share your information for any other purpose disclosed to you and with your consent.</p>

// <hr>

// <h2>4. Third-Party Services</h2>

// <p>Our Site may contain links to third-party websites or services that are not operated by us. We have no control over and assume no responsibility for their content, privacy policies, or practices. We encourage you to review the privacy policies of any third-party sites you visit.</p>

// <hr>

// <h2>5. Security of Your Information</h2>

// <p>We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no internet transmission is entirely secure, and we cannot guarantee absolute security.</p>

// <hr>

// <h2>6. Cookies and Tracking Technologies</h2>

// <p>We use cookies and similar technologies to enhance your experience, such as:</p>
// <ul>
//     <li><strong>Session Cookies:</strong> To operate our Site</li>
//     <li><strong>Preference Cookies:</strong> To remember your preferences</li>
//     <li><strong>Security Cookies:</strong> For security purposes</li>
// </ul>

// <p>You can instruct your browser to refuse all cookies or indicate when a cookie is being sent. However, some Site features may not function properly without cookies.</p>

// <hr>

// <h2>7. Children's Privacy</h2>

// <p>Our Site is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected such information, please contact us immediately.</p>

// <hr>

// <h2>8. International Data Transfers</h2>

// <p>Your information may be transferred to and maintained on servers located outside of your state or country, where data protection laws may differ. By providing your information, you consent to such transfers.</p>

// <hr>

// <h2>9. Your Choices and Rights</h2>

// <p>Depending on your jurisdiction, you may have the following rights regarding your personal information:</p>
// <ul>
//     <li><strong>Access:</strong> Request access to your personal information.</li>
//     <li><strong>Correction:</strong> Request correction of inaccurate information.</li>
//     <li><strong>Deletion:</strong> Request deletion of your personal information.</li>
//     <li><strong>Opt-Out:</strong> Unsubscribe from marketing communications.</li>
// </ul>

// <p>To exercise these rights, please contact us using the information provided below.</p>

// <hr>

// <h2>10. Changes to This Privacy Policy</h2>

// <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this Privacy Policy periodically.</p>

// <hr>

// <h2>11. Contact Us</h2>

// <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
// <ul>
//     <li><strong>Email:</strong> <a href="mailto:privacy@braincargo.com">privacy@braincargo.com</a></li>
//     <li><strong>Address:</strong> BrainCargo, 1309 Coffeen Avenue STE 1200 Sheridan Wyoming 82801</li>
// </ul>

// <hr>

// <div class="agree-button">
//     <button onclick="handleAgree()">I Agree</button>
// </div>

// <script>
//     function handleAgree() {
//         // Implement your logic here, e.g., submit the form or redirect the user
//         alert('Thank you for agreeing to our Privacy Policy.');
//         // Example: Redirect to the next page
//         // window.location.href = 'next_page.html';
//     }
// </script>

// </body>
// </html>
