import {
  AlchemyAccountsUIConfig,
  cookieStorage,
  createConfig,
} from "@account-kit/react";
import { alchemy, sepolia } from "@account-kit/infra";
import { QueryClient } from "@tanstack/react-query";
import { APP_URLS } from "./navigation";
import { getAccessToken } from "services/localStorage";

// const projectId = process.env.REACT_APP_PROJECT_ID as string;
const projectId = "c5ffeddcd4e7b5987f1e5b0c98fb091f";

const token=getAccessToken();
export const uiConfig: AlchemyAccountsUIConfig = {
  illustrationStyle: "outline",
  auth: !token ? {
    sections: [
      [{ type: "email", emailMode: "otp" }],
      [{ type: "passkey" }],
    ],
   
    addPasskeyOnSignup: false,
    
  } : {
    sections: [
      [
        {
          type: "external_wallets",
          walletConnect: { projectId: projectId },
        },
      ],
    ],
    hideSignInText:true,
    addPasskeyOnSignup: false,
  }
};

export const config = createConfig(
  {
    transport: alchemy({ apiKey: "thozTQlb4S5cfaQ7bnZkmHBPk3ON_DS1" }),
    chain: sepolia,
    ssr: false,
    // storage: cookieStorage,
    enablePopupOauth: true,
  },
  uiConfig
);

export const queryClient = new QueryClient();

// import {
//     AlchemyAccountsUIConfig,
//     cookieStorage,
//     createConfig,
//   } from "@account-kit/react";
//   import { alchemy, sepolia } from "@account-kit/infra";
//   import { QueryClient } from "@tanstack/react-query";

//   const uiConfig: AlchemyAccountsUIConfig = {
//     illustrationStyle: "outline",
//     auth: {
//       sections: [
//         [{ type: "email" }],
//         [
//           { type: "passkey" },
//           { type: "social", authProviderId: "google", mode: "popup" },
//           { type: "social", authProviderId: "facebook", mode: "popup" },
//         ],
//         [
//           {
//             type: "external_wallets",
//             walletConnect: { projectId: "your-project-id" },
//           },
//         ],
//       ],
//       addPasskeyOnSignup: false,
//     },
//   };

//   export const config = createConfig(
//     {
//       transport: alchemy({ apiKey: "thozTQlb4S5cfaQ7bnZkmHBPk3ON_DS1" }), // TODO: add your Alchemy API key - https://dashboard.alchemy.com/accounts
//       chain: sepolia,
//       policyId: "87fd4ede-1a0f-429c-9f50-39c7c7a9a486",
//       ssr: true, // more about ssr: https://accountkit.alchemy.com/react/ssr
//       storage: cookieStorage, // more about persisting state with cookies: https://accountkit.alchemy.com/react/ssr#persisting-the-account-state
//       enablePopupOauth: true, // must be set to "true" if you plan on using popup rather than redirect in the social login flow
//     },
//     uiConfig
//   );

//   export const queryClient = new QueryClient();

