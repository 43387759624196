import React, { useState } from "react";
import ModalEditCreatePlaylist from "components/playlist/ModalEditCreatePlaylist";
import { PlusIcon } from "@heroicons/react/24/outline";

interface Props {
  additionalClasses?: string;
  compact?: boolean;
}

/**
 * Component used for creating a new playlist.
 */
export default function ButtonCreatePlaylist({
  additionalClasses = "",
  compact = false,
}: Props) {
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);

  return (
    <>
      <button
        onClick={() => setShowSelectPlaylistPopup(true)}
        className={`group inline-flex items-center justify-center gap-2
                   min-w-[120px] px-4 py-2 text-sm font-medium
                   rounded-lg transition-all duration-200
                   text-blue-400 dark:text-blue-400
                   bg-[#1e2533]/10 dark:bg-blue-500/10
                   hover:bg-[#1e2533]/20 dark:hover:bg-blue-500/20
                   focus:outline-none focus:ring-2 focus:ring-blue-500/20
                   ${additionalClasses}`}
        aria-label="Create Playlist"
      >
        <PlusIcon className="w-4 h-4" />
        <span className="whitespace-nowrap text-sm">
          {compact ? "" : "Create Playlist"}
        </span>
      </button>

      <ModalEditCreatePlaylist
        show={showSelectPlaylistPopup}
        onClose={() => setShowSelectPlaylistPopup(false)}
      />
    </>
  );
}
