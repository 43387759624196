import React, { useEffect, useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { displayErrors } from "../../helpers/errors";
import {
  getMyPlaylists,
  sharePlaylistsWithUsers,
} from "../../redux/playlist/playlistSlice";
import { isDispatchResponseError } from "../../redux/utils";
import { fetchUsers } from "../../redux/users/usersSlice";
import { useDebounce } from "use-debounce";
import Modal from "../../components/common/modal";
import { Playlist } from "../../types/playlist";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  show: boolean;
  onClose: () => void;
}

/**
 * Component for sharing playlists with users.
 */
export default function SharePlaylistsPopup({ show, onClose }: Props) {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users.users);
  const playlists = useAppSelector((state) => state.playlist.myPlaylists);
  const sharePlaylistsWithUsersErrors = useAppSelector(
    (state) => state.playlist.sharePlaylistsWithUsersErrors
  );
  const [selectedPlaylists, setSelectedPlaylists] = useState<Array<string>>([]);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  /**
   * Share playlists with selected user.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const response = await dispatch(
      sharePlaylistsWithUsers({
        user_id: selectedUser,
        playlist_ids: selectedPlaylists,
      })
    );

    // Close the popup if successful
    if (!isDispatchResponseError(response)) {
      onClose();
    }
  };

  /**
   * Render rows of playlists.
   */
  const displayPlaylists = () => {
    return playlists.map((playlist, idx) => (
      <tr key={idx} className="border-b border-gray-200">
        <td className="px-6 py-4 bg-gray-50">
          <input
            id={playlist.id}
            name="video_playlists"
            type="checkbox"
            value={playlist.id}
            onChange={(e) => handleChangePlaylists(e)}
            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-0"
            checked={selectedPlaylists.includes(playlist.id)}
            aria-label={`Select playlist ${playlist.name}`}
          />
          <label htmlFor={playlist.id}>{playlist.name}</label>
        </td>
        <td className="px-6 py-4">{getPlaylistLength(playlist)} items</td>
      </tr>
    ));
  };

  /**
   * Render rows of users.
   */
  const displayUsers = () => {
    return users.map((user, idx) => (
      <tr key={idx} className="border-b border-gray-200">
        <td className="flex items-center px-6 py-4 bg-gray-50">
          <input
            id={user.id}
            name="video_playlists"
            type="radio"
            value={user.id}
            onChange={(e) => handleChangeUsers(e)}
            checked={selectedUser === user.id}
            className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-0"
            aria-label={`Select user ${user.username}`}
          />
          <label htmlFor={user.id}>{user.username}</label>
        </td>
      </tr>
    ));
  };

  /**
   * Handle updating selected playlists form state.
   */
  const handleChangePlaylists = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPlaylistId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked && !selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is checked and doesn't exist in selectedPlaylists list
      setSelectedPlaylists([...selectedPlaylists, selectedPlaylistId]);
    } else if (!isChecked && selectedPlaylists.includes(selectedPlaylistId)) {
      // If playlist item is unchecked and exists in selectedPlaylists list
      const newSelectedPlaylists = selectedPlaylists.filter(
        (selectedPlaylist) => selectedPlaylist !== selectedPlaylistId
      );
      setSelectedPlaylists(newSelectedPlaylists);
    }
  };

  /**
   * Handle updating selected user form state.
   */
  const handleChangeUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedUserId = e.target.value;
    setSelectedUser(selectedUserId);
  };

  /**
   * Get the number of items in a playlist.
   */
  function getPlaylistLength(playlist: Playlist) {
    const length = playlist.items.length;
    return length;
  }

  /**
   * Clear form state when popup display status changes.
   */
  useEffect(() => {
    // Run the code only when popup is open
    if (show) {
      // Retrieve list of Playlists
      dispatch(getMyPlaylists({}));
      dispatch(fetchUsers());
    }

    // Clear selected playlists & users
    setSelectedPlaylists([]);
    setSelectedUser("");
  }, [show]);

  /**
   * Fetch users based on search query.
   */
  useEffect(() => {
    dispatch(fetchUsers(debouncedSearchQuery));
  }, [debouncedSearchQuery]);

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 
                    transition-all duration-200 ${
                      show ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
    >
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-gray-900/60 dark:bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div
        className="relative w-full max-w-md max-h-[70vh] flex flex-col 
                    bg-white dark:bg-[#1e2533] rounded-2xl shadow-xl transform 
                    transition-all duration-200 border border-gray-200 dark:border-white/5"
      >
        {/* Header - Fixed */}
        <div
          className="flex items-center justify-between p-4 
                     border-b border-gray-200 dark:border-white/5"
        >
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Select playlists
          </h3>
          <button
            onClick={onClose}
            className="p-1 rounded-lg text-gray-500 hover:text-gray-700 dark:text-gray-400 
                     dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-white/5 
                     transition-all duration-200"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Body - Scrollable */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 min-h-0">
          {playlists.length === 0 ? (
            <p className="text-gray-500 dark:text-gray-400 text-sm text-center py-4">
              No Playlists added yet!
            </p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Playlists Selection */}
              <div>
                <Label
                  value="Select playlists"
                  className="text-gray-700 dark:text-gray-200 mb-2"
                />
                <div className="border border-gray-200 dark:border-white/10 rounded-lg overflow-hidden">
                  <table className="w-full text-sm text-left">
                    <thead
                      className="text-xs text-gray-600 dark:text-gray-400 uppercase 
                                   bg-gray-50 dark:bg-white/5 sticky top-0 z-10"
                    >
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          Name
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Items
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                      {playlists.map((playlist) => (
                        <tr
                          key={playlist.id}
                          className="hover:bg-gray-50 dark:hover:bg-white/5 transition-colors"
                        >
                          <td className="px-4 py-3">
                            <label className="flex items-center space-x-3">
                              <input
                                type="checkbox"
                                value={playlist.id}
                                onChange={handleChangePlaylists}
                                checked={selectedPlaylists.includes(
                                  playlist.id
                                )}
                                className="w-4 h-4 text-blue-600 bg-gray-100 dark:bg-white/5
                                         border-gray-300 dark:border-white/10 rounded 
                                         focus:ring-blue-500/20"
                              />
                              <span className="text-gray-900 dark:text-white">
                                {playlist.name}
                              </span>
                            </label>
                          </td>
                          <td className="px-4 py-3 text-gray-500 dark:text-gray-400">
                            {getPlaylistLength(playlist)} items
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {displayErrors(sharePlaylistsWithUsersErrors.playlist_ids)}
              </div>

              {/* User Selection */}
              <div className="space-y-2">
                <Label
                  value="Select a user"
                  className="text-gray-700 dark:text-gray-200"
                />
                <TextInput
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-white dark:bg-white/5 
                           border-gray-300 dark:border-white/10 
                           text-gray-900 dark:text-white
                           placeholder-gray-500 dark:placeholder-gray-400"
                />
                <div className="border border-gray-200 dark:border-white/10 rounded-lg overflow-hidden">
                  <table className="w-full text-sm text-left">
                    <thead
                      className="text-xs text-gray-600 dark:text-gray-400 uppercase 
                                   bg-gray-50 dark:bg-white/5 sticky top-0 z-10"
                    >
                      <tr>
                        <th scope="col" className="px-4 py-3">
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-white/5">
                      {users.map((user) => (
                        <tr
                          key={user.id}
                          className="hover:bg-gray-50 dark:hover:bg-white/5 transition-colors"
                        >
                          <td className="px-4 py-3">
                            <label className="flex items-center space-x-3">
                              <input
                                type="radio"
                                value={user.id}
                                onChange={handleChangeUsers}
                                checked={selectedUser === user.id}
                                className="w-4 h-4 text-blue-600 
                                         border-gray-300 dark:border-white/10 
                                         bg-gray-100 dark:bg-white/5
                                         focus:ring-blue-500/20"
                              />
                              <span className="text-gray-900 dark:text-white">
                                {user.username}
                              </span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {displayErrors(sharePlaylistsWithUsersErrors.user_id)}
              </div>
            </form>
          )}
        </div>

        {/* Footer - Fixed */}
        <div className="flex justify-end gap-3 p-4 border-t border-gray-200 dark:border-white/5">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200
                     bg-gray-100 dark:bg-white/5 rounded-lg 
                     hover:bg-gray-200 dark:hover:bg-white/10
                     transition-all duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={selectedPlaylists.length === 0 || !selectedUser}
            className="px-4 py-2 text-sm font-medium text-white
                     bg-blue-600 rounded-lg hover:bg-blue-700
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition-all duration-200"
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
}
