import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import PaginationV2 from "components/common/pagination/PaginationV2";
import SearchInput from "components/common/searchInput";
import { FeedItemDetailsLoader } from "components/common/ShimmerLoader";
import { formatDateTime } from "helpers/date";
import { APP_URLS } from "navigation";
import React, { useEffect, useState } from "react";
import { retrieveFeedbacks } from "redux/feedback/feedbackSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { QueryParams } from "types/api";
import { FEEDBACK_TYPE_LABELS } from "types/feedback";
import { useDebounce } from "use-debounce";

const DEFAULT_ORDERING = "-created_at";

/**
 * Labels for the table headers.
 */
const LABELS = {
  created_at: "Creation date",
  user: "Reported by",
  type: "Type",
  description: "Description",
};

/**
 * Component that renders a table of feedbacks.
 */
export default function FeedbackListTable() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [ordering, setOrdering] = useState(DEFAULT_ORDERING);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const pendingGetFeedbacks = useAppSelector(
    (state) => state.feedback.pendingGetFeedbacks
  );
  const feedbacks = useAppSelector((state) => state.feedback.feedbacks);

  /**
   * Renders a table header.
   */
  function renderTableHeader({ key, label }: { key: string; label: string }) {
    let icon = null;
    if (ordering === key) {
      icon = <ChevronDownIcon className="w-4 h-4" />;
    }
    if (ordering === "-" + key) {
      icon = <ChevronUpIcon className="w-4 h-4" />;
    }

    return (
      <th className="p-4 first:pl-6 last:pr-6" key={key}>
        <button
          onClick={() => {
            if (ordering === key) {
              setOrdering("-" + ordering);
            } else if (ordering === "-" + key) {
              setOrdering(ordering.replaceAll("-", ""));
            } else {
              setOrdering(key);
            }
          }}
          className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors duration-200"
          aria-label={`Sort by ${label}`}
        >
          {icon}
          <span>{label}</span>
        </button>
      </th>
    );
  }

  /**
   * Trims the description to 50 characters.
   */
  function trimDescription(description: string) {
    return description.length > 50
      ? `${description.substring(0, 50)}...`
      : description;
  }

  /**
   * Fetches feedbacks.
   */
  function fetchData(page: number, query: string) {
    const queryParams: QueryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      query,
      ordering,
    };
    dispatch(retrieveFeedbacks({ queryParams }));
  }

  /**
   * Redirects to the feedback details page.
   */
  function onClickRow(feedbackId: string) {
    const url = APP_URLS.FEEDBACK_DETAILS.replace(":id", feedbackId);
    window.location.href = url;
  }

  /**
   * Fetches feedbacks whenever page/ordering/search query changes.
   */
  useEffect(() => {
    fetchData(page, debouncedQuery);
  }, [page, debouncedQuery, ordering]);

  return (
    <div className="space-y-4">
      {/* Search Bar */}
      <div className="bg-[#252d3d] rounded-lg p-4">
        <SearchInput value={query} onChange={setQuery} />
      </div>

      {/* Loading State */}
      {pendingGetFeedbacks ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <FeedItemDetailsLoader />
        </div>
      ) : (
        <div className="bg-[#252d3d] rounded-xl border border-white/5 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase border-b border-white/5 bg-[#1e2533]">
                <tr>
                  {Object.keys(LABELS).map((key) =>
                    renderTableHeader({
                      key,
                      label: LABELS[key as keyof typeof LABELS],
                    })
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-white/5">
                {feedbacks.results.map((elem) => (
                  <tr
                    key={elem.id}
                    onClick={() => onClickRow(elem.id)}
                    className="border-b border-white/5 bg-[#1e2533] hover:bg-white/5 
                             transition-colors duration-200 cursor-pointer"
                    tabIndex={0}
                    aria-label={`Feedback from ${
                      elem.user.username
                    } on ${formatDateTime(elem.created_at)}`}
                  >
                    <td className="p-4 pl-6 text-gray-200">
                      {formatDateTime(elem.created_at)}
                    </td>
                    <td className="p-4 text-gray-200">{elem.user.username}</td>
                    <td className="p-4">
                      <span
                        className="px-3 py-1 text-xs font-medium rounded-full
                                   bg-blue-500/10 text-blue-400 border border-blue-500/20"
                      >
                        {FEEDBACK_TYPE_LABELS[elem.type]}
                      </span>
                    </td>
                    <td className="p-4 pr-6 text-gray-200">
                      {trimDescription(elem.description)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="border-t border-white/5">
            <PaginationV2
              count={feedbacks.count}
              page={page}
              previous={feedbacks.previous}
              next={feedbacks.next}
              onClickPrev={() => setPage(page - 1)}
              onClickNext={() => setPage(page + 1)}
              pageSize={pageSize}
            />
          </div>
        </div>
      )}
    </div>
  );
}
