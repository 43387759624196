import { Button, Checkbox, Radio, Spinner, TextInput } from "flowbite-react";
import { displayErrors } from "../../../../helpers/errors";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  retrieveQuizAnswers,
  retrieveQuizQuestions,
  submitQuizAnswers,
} from "../../../../redux/quiz/quizSlice";
import { isDispatchResponseError } from "../../../../redux/utils";
import {
  QuizQuestion,
  QuizQuestionAnswer,
  QuizQuestionAnswerOption,
  Quiz,
  QUESTION_TYPES,
} from "../../../../types/quiz";
import AnswerOptionImage from "../../AnswerOptionImage";
import { StarIcon } from "@heroicons/react/24/solid";
import QuestionImage from "../../../../components/quiz/QuestionImage";

interface Props {
  onSuccess: () => void;
  successText: string | JSX.Element;
  quiz: Quiz | null;
}

/**
 * Component for filling out a quiz.
 */
export default function QuizForm({ onSuccess, successText, quiz }: Props) {
  const dispatch = useAppDispatch();
  const quizQuestions = useAppSelector((state) => state.quiz.questions);
  const pendingRetrieveQuizAnswers = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizAnswers
  );
  const pendingSubmitQuizAnswers = useAppSelector(
    (state) => state.quiz.pendingSubmitQuizAnswers
  );
  const pendingRetrieveQuizQuestions = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizQuestions
  );
  const questionErrorMessages = useAppSelector(
    (state) => state.quiz.questionErrorMessages
  );
  const answerErrorMessages = useAppSelector(
    (state) => state.quiz.answerErrorMessages
  );
  const [providedAnswers, setProvidedAnswers] = useState<QuizQuestionAnswer[]>(
    []
  );
  const [page, setPage] = useState(0);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);

  /**
   * Go to the next page.
   */
  function nextPage() {
    let newPage = page + 1;
    const maxPage = quizQuestions.length - 1;
    if (newPage > maxPage) {
      newPage = maxPage;
    }
    setPage(newPage);
  }

  /**
   * Go to the previous page.
   */
  function prevPage() {
    let newPage = page - 1;
    const minPage = 0;
    if (newPage < minPage) {
      newPage = 1;
    }
    setPage(newPage);
  }

  /**
   * Check if the answer option is checked.
   */
  function isAnswerChecked(answerOption: QuizQuestionAnswerOption) {
    const providedAnswer = providedAnswers.find(
      (providedAnswer) => providedAnswer.answer === answerOption.id
    );
    return !!providedAnswer;
  }

  /**
   * Check if all required questions are answered.
   */
  function areAllRequiredQuestionsAnswered() {
    const question = quizQuestions[page];
    if (!question?.required) return true;

    let isRequiredQuestionAnswered = false;
    // Look for the answer provided by user
    const providedAnswer = providedAnswers.find(
      (providedAnswer) => providedAnswer.question === question.id
    );
    const questionAnswerOption = question.answer_options.find(
      (answerOption) => answerOption.id === providedAnswer?.answer
    );

    // Open questions must not be left blank
    if (
      question?.type === QUESTION_TYPES.FREEFORM &&
      providedAnswer?.value !== ""
    ) {
      isRequiredQuestionAnswered = true;
    }
    // Non-open questions require an answer be selected
    if (
      questionAnswerOption !== undefined &&
      question?.type !== QUESTION_TYPES.FREEFORM
    ) {
      isRequiredQuestionAnswered = true;
    }

    return isRequiredQuestionAnswered;
  }

  /**
   * Render the questions for a given page.
   */
  function renderQuestions(quizQuestions: QuizQuestion[], page: number) {
    if (quizQuestions.length > 0) {
      const question = quizQuestions[page];
      const isRequired = question.required;
      const hasImages = question.answer_options.some(
        (answerOption) => answerOption.external_image || answerOption.image
      );

      return (
        <div
          key={question.id}
          className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg
                                        border border-gray-200 dark:border-gray-700"
        >
          {/* Question Header */}
          <div className="flex items-start space-x-4 mb-6">
            <div className="flex-1">
              <div className="flex items-center gap-3">
                <span
                  className="px-3 py-1 bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 
                               rounded-full text-sm font-medium"
                >
                  Question {page + 1} of {quizQuestions.length}
                </span>
                {isRequired && (
                  <span
                    className="text-red-500 dark:text-red-400"
                    title="Required"
                  >
                    *
                  </span>
                )}
              </div>
              <h3 className="mt-3 text-xl font-semibold text-gray-900 dark:text-white">
                {question.text}
              </h3>
            </div>
            {question.image && (
              <QuestionImage
                question={question}
                additionalClassName="w-24 h-24 rounded-lg object-cover"
                hideIfNoImage
              />
            )}
          </div>

          {/* Answer Options */}
          <div className="space-y-4">
            {question.answer_options.map((answerOption) => {
              const isChecked = isAnswerChecked(answerOption);
              const baseClasses = `relative flex items-center p-4 rounded-lg transition-all duration-200
                                 ${
                                   isChecked
                                     ? "bg-blue-50 dark:bg-blue-900/30 border-blue-200 dark:border-blue-800"
                                     : "bg-gray-50 dark:bg-gray-900/30 border-gray-200 dark:border-gray-700"
                                 }
                                 border hover:bg-gray-100 dark:hover:bg-gray-700/30 cursor-pointer`;

              if (question.type === QUESTION_TYPES.FREEFORM) {
                return (
                  <div key={answerOption.id} className={baseClasses}>
                    <div className="flex-1 min-w-0">
                      <TextInput
                        className="w-full bg-white dark:bg-gray-800"
                        placeholder="Type your answer here..."
                        value={
                          providedAnswers.find(
                            (a) => a.answer === answerOption.id
                          )?.value || ""
                        }
                        onChange={(e) => handleInputChange(e, answerOption)}
                      />
                    </div>
                  </div>
                );
              }

              if (question.type === QUESTION_TYPES.RATING) {
                const starCount = parseInt(answerOption.text);
                return (
                  <label key={answerOption.id} className={baseClasses}>
                    <div className="flex items-center gap-3 flex-1">
                      <Radio
                        className="text-blue-600"
                        checked={isChecked}
                        onChange={(e) => handleInputChange(e, answerOption)}
                      />
                      <div className="flex items-center">
                        {[...Array(starCount)].map((_, idx) => (
                          <StarIcon
                            key={idx}
                            className="w-5 h-5 text-yellow-400"
                          />
                        ))}
                      </div>
                    </div>
                  </label>
                );
              }

              return (
                <label key={answerOption.id} className={baseClasses}>
                  <div className="flex items-center gap-3 flex-1">
                    {question.type === QUESTION_TYPES.LISTCHOICE ? (
                      <Checkbox
                        className="text-blue-600"
                        checked={isChecked}
                        onChange={(e) => handleInputChange(e, answerOption)}
                      />
                    ) : (
                      <Radio
                        className="text-blue-600"
                        checked={isChecked}
                        onChange={(e) => handleInputChange(e, answerOption)}
                      />
                    )}
                    <div className="flex items-center gap-3 min-w-0">
                      {hasImages && (
                        <AnswerOptionImage
                          answerOption={answerOption}
                          additionalClassName="w-12 h-12 rounded-lg object-cover"
                        />
                      )}
                      <span className="text-gray-900 dark:text-white">
                        {answerOption.text}
                      </span>
                    </div>
                  </div>
                </label>
              );
            })}
          </div>
        </div>
      );
    }
  }

  /**
   * Add an answer to the list of provided answers.
   */
  function addAnswer(answerToAdd: QuizQuestionAnswerOption, textValue = "") {
    let newProvidedAnswers = [...providedAnswers];
    const question = quizQuestions.find(
      (question) => question.id === answerToAdd.question
    );
    const isMultiSelect = question?.type === QUESTION_TYPES.LISTCHOICE;

    if (isMultiSelect) {
      // Remove the answer from newProvidedAnswers if it's present
      // as it will be updated after if statement
      newProvidedAnswers = newProvidedAnswers.filter(
        (providedAnswer) => providedAnswer.answer !== answerToAdd.id
      );
    } else {
      // Remove all other selected answers related to the question
      // if the question does not support multi select.
      newProvidedAnswers = newProvidedAnswers.filter(
        (providedAnswer) => providedAnswer.question !== answerToAdd.question
      );
    }

    newProvidedAnswers.push({
      question: answerToAdd.question,
      answer: answerToAdd.id,
      selected: true,
      value: textValue,
    });

    setProvidedAnswers(newProvidedAnswers);
  }

  /**
   * Remove an answer from the list of provided answers.
   */
  function removeAnswer(answerToRemove: QuizQuestionAnswerOption) {
    let newProvidedAnswers = [...providedAnswers];

    // Remove the answer from the list if it's present
    newProvidedAnswers = newProvidedAnswers.filter(
      (providedAnswer) => providedAnswer.answer !== answerToRemove.id
    );

    setProvidedAnswers(newProvidedAnswers);
  }

  /**
   * Handle input change.
   */
  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    answerOption: QuizQuestionAnswerOption
  ) {
    // Handle checkbox changes
    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        addAnswer(answerOption);
      } else {
        removeAnswer(answerOption);
      }
    }

    // Handle radio changes(allow deselecting options)
    if (e.target.type === "radio") {
      if (
        providedAnswers.some(
          (providedAnswer) => providedAnswer.answer === answerOption.id
        )
      ) {
        removeAnswer(answerOption);
      } else {
        addAnswer(answerOption);
      }
    }

    // Handle text input changes
    if (e.target.type === "text") {
      addAnswer(answerOption, e.target.value);
    }
  }

  /**
   * Submit the quiz answers.
   */
  async function handleSubmit() {
    const response = await dispatch(
      submitQuizAnswers({ data: providedAnswers })
    );
    if (!isDispatchResponseError(response)) {
      setDisplaySuccessMessage(true);
      onSuccess();
    }
  }

  /**
   * Retrieve the quiz questions and answers.
   */
  useEffect(() => {
    if (quiz) {
      dispatch(retrieveQuizQuestions({ quizId: quiz.id }));
      dispatch(retrieveQuizAnswers({ quizId: quiz.id }));
    }
  }, [quiz]);

  if (displaySuccessMessage)
    return (
      <div className="p-4 text-green-500 text-lg font-bold text-center">
        {successText}
      </div>
    );
  else {
    return pendingRetrieveQuizAnswers || pendingRetrieveQuizQuestions ? (
      <div className="flex justify-center">
        <Spinner size="sm" />
      </div>
    ) : (
      <>
        <div className="flex flex-col py-4 px-8">
          {renderQuestions(quizQuestions, page)}
        </div>

        <div>
          {Object.keys(questionErrorMessages).map((key) =>
            displayErrors(questionErrorMessages[key])
          )}
          {answerErrorMessages.map((errorMessage) =>
            Object.keys(errorMessage).map((key) =>
              displayErrors(errorMessage[key])
            )
          )}
        </div>

        <div className="flex flex-row justify-center space-x-2 border-t px-6 py-4">
          <Button
            className="flex-1"
            disabled={page == 0}
            onClick={prevPage}
            aria-label="Previous Page"
          >
            Previous Page
          </Button>
          {page !== quizQuestions.length - 1 ? (
            <Button
              className="flex-1"
              onClick={nextPage}
              disabled={!areAllRequiredQuestionsAnswered()}
              title={
                areAllRequiredQuestionsAnswered()
                  ? undefined
                  : "Answer all required questions before continuing"
              }
              aria-label="Next Page"
            >
              Next Page
            </Button>
          ) : (
            <Button
              className="flex-1"
              onClick={handleSubmit}
              disabled={!areAllRequiredQuestionsAnswered()}
              title={
                areAllRequiredQuestionsAnswered()
                  ? undefined
                  : "Answer all required questions before continuing"
              }
              aria-label="Submit"
            >
              {pendingSubmitQuizAnswers ? <Spinner size="sm" /> : "Submit"}
            </Button>
          )}
        </div>
      </>
    );
  }
}
