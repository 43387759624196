import React, { useEffect, useState } from "react";
import {
  CircleStackIcon,
  ListBulletIcon,
  QuestionMarkCircleIcon,
  ClockIcon,
  ComputerDesktopIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/solid";
import {
  ClipboardDocumentListIcon,
  PlayIcon,
  UserIcon,
  MusicalNoteIcon,
  NewspaperIcon,
  CurrencyDollarIcon,
  FlagIcon,
  PencilSquareIcon,
  FilmIcon,
} from "@heroicons/react/24/outline";
import NeuralNetworkIcon from "../../icons/NeuralNetworkIcon";
import { APP_URLS } from "../../../navigation";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getUser } from "../../../redux/user/userSlice";
import DesktopNavBarItem from "./DesktopNavBarItem";
import DesktopNavBarSeparator from "./DesktopNavBarSeparator";
import { getAccessToken } from "services/localStorage";
import { formatDateTime } from "helpers/date";
import { ShimmerLoader } from "components/common/ShimmerLoader";

const ICON_CLASSNAME =
  "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900";

const DARK_GRAY_FILL = "rgb(107 114 128)";

/**
 * Component that renders the navigation bar for desktop.
 */
export default function DesktopNavBar() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const token = getAccessToken();
  const [deviceInfo, setDeviceInfo] = useState({
    browser: "Unknown Browser",
    os: "Unknown OS",
    device: "Unknown Device",
  });
  const [lastLoginDisplay, setLastLoginDisplay] =
    useState<string>("Not available");
  const [loading, setLoading] = useState(true);

  /**
   * Fetch the user if it is not set.
   */
  useEffect(() => {
    if (!user) {
      dispatch(getUser());
      setLoading(false);
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    // Parse user agent for better device info
    const ua = navigator.userAgent;
    const browserRegex = /(chrome|safari|firefox|edge|opera(?=\/))\/?\s*(\d+)/i;
    const osRegex = /(windows nt|mac os x|linux|android|ios)\s*([0-9_.]+)*/i;

    const browserMatch = ua.match(browserRegex);
    const osMatch = ua.match(osRegex);

    setDeviceInfo({
      browser: browserMatch ? browserMatch[1] : "Unknown Browser",
      os: osMatch ? osMatch[1] : "Unknown OS",
      device: /mobile|tablet/i.test(ua) ? "Mobile" : "Desktop",
    });
  }, []);

  useEffect(() => {
    const updateLastLogin = () => {
      const lastLogin = localStorage.getItem("lastLoginTime");
      if (!lastLogin) {
        const currentLogin = new Date().toISOString();
        localStorage.setItem("lastLoginTime", currentLogin);
        setLastLoginDisplay("Just now");
        return;
      }

      try {
        const date = new Date(lastLogin);
        const now = new Date();
        const diffTime = Math.abs(now.getTime() - date.getTime());
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        // const diffSeconds = Math.floor(diffTime / 1000);

        // if (diffSeconds < 60) {
        //   setLastLoginDisplay(
        //     `${diffSeconds} ${diffSeconds === 1 ? "second" : "seconds"} ago`
        //   );
        // }
        if (diffMinutes === 0) {
          setLastLoginDisplay("Just now");
        } else if (diffMinutes < 60) {
          setLastLoginDisplay(
            `${diffMinutes} ${diffMinutes === 1 ? "minute" : "minutes"} ago`
          );
        } else if (diffHours < 24) {
          setLastLoginDisplay(
            `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`
          );
        } else if (diffDays === 0) {
          setLastLoginDisplay("Today");
        } else if (diffDays === 1) {
          setLastLoginDisplay("Yesterday");
        } else if (diffDays < 7) {
          setLastLoginDisplay(`${diffDays} days ago`);
        } else {
          setLastLoginDisplay(formatDateTime(lastLogin));
        }
      } catch (error) {
        setLastLoginDisplay("Not available");
      }
    };

    // Update immediately
    updateLastLogin();

    // Set up interval to update every second
    const intervalId = setInterval(updateLastLogin, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const SessionInfoLoader = () => (
    <div className="mt-4 p-4 rounded-lg bg-gray-100 dark:bg-gray-700/50 space-y-4">
      {/* Session Info Header Loader */}
      <div className="flex items-center justify-between">
        <ShimmerLoader className="h-4 w-24 rounded-md" />
        <ShimmerLoader className="h-6 w-16 rounded-full" />
      </div>

      {/* Login Time Info Loader */}
      <div className="flex items-center gap-3">
        <ShimmerLoader className="w-8 h-8 rounded-full" />
        <div className="flex flex-col gap-1">
          <ShimmerLoader className="h-3 w-16 rounded-md" />
          <ShimmerLoader className="h-4 w-24 rounded-md" />
        </div>
      </div>

      {/* Device Info Loader */}
      <div className="flex items-center gap-3">
        <ShimmerLoader className="w-8 h-8 rounded-full" />
        <div className="flex flex-col gap-1">
          <ShimmerLoader className="h-3 w-20 rounded-md" />
          <ShimmerLoader className="h-4 w-32 rounded-md" />
        </div>
      </div>

      {/* System Info Loader */}
      <div className="flex items-center gap-3">
        <ShimmerLoader className="w-8 h-8 rounded-full" />
        <div className="flex flex-col gap-1">
          <ShimmerLoader className="h-3 w-14 rounded-md" />
          <ShimmerLoader className="h-4 w-28 rounded-md" />
        </div>
      </div>

      {/* Security Info Loader */}
      <ShimmerLoader className="h-8 w-full rounded-md" />
    </div>
  );

  return (
    <>
      <aside
        id="default-sidebar"
        className="fixed top-0 left-0 z-40 h-screen overflow-hidden w-64"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 flex flex-col">
          <div className="flex-1 flex flex-col gap-2">
            <DesktopNavBarItem
              href={APP_URLS.PROFILE}
              icon={<UserIcon className={ICON_CLASSNAME} />}
              label="Profile"
            />
            <DesktopNavBarItem
              href={APP_URLS.BRAIN_COIN}
              icon={<CurrencyDollarIcon className={ICON_CLASSNAME} />}
              label="Brain Coin"
            />
            <DesktopNavBarItem
              href={APP_URLS.VIDEOS}
              icon={<PlayIcon className={ICON_CLASSNAME} />}
              label="Videos"
            />
            <DesktopNavBarItem
              href={APP_URLS.MUSIC}
              icon={<MusicalNoteIcon className={ICON_CLASSNAME} />}
              label="Music"
            />
            <DesktopNavBarItem
              href={APP_URLS.NEWS}
              icon={<NewspaperIcon className={ICON_CLASSNAME} />}
              label="News"
            />
            <DesktopNavBarItem
              href={APP_URLS.MOVIES}
              icon={<FilmIcon className={ICON_CLASSNAME} />}
              label="Movies"
            />
            <DesktopNavBarItem
              href={APP_URLS.PLAYLISTS_DESKTOP}
              icon={<ListBulletIcon className={ICON_CLASSNAME} />}
              label="Playlists"
            />
            <DesktopNavBarItem
              href={APP_URLS.NEURAL_NETWORKS}
              icon={
                <NeuralNetworkIcon
                  className={ICON_CLASSNAME}
                  fill={DARK_GRAY_FILL}
                />
              }
              label="Neural Networks"
            />
            <DesktopNavBarItem
              href={APP_URLS.DATA_SETS}
              icon={<CircleStackIcon className={ICON_CLASSNAME} />}
              label="Datasets"
            />
            <DesktopNavBarItem
              href={APP_URLS.QUIZZES}
              icon={<ClipboardDocumentListIcon className={ICON_CLASSNAME} />}
              label="Quizzes"
            />
            <DesktopNavBarItem
              href={APP_URLS.FEEDBACK}
              icon={<PencilSquareIcon className={ICON_CLASSNAME} />}
              label="Feedback"
            />
            {user?.is_superuser && (
              <>
                <DesktopNavBarSeparator />
                <DesktopNavBarItem
                  href={APP_URLS.FEED_DATA}
                  icon={<CircleStackIcon className={ICON_CLASSNAME} />}
                  label="Feed Data"
                />
                <DesktopNavBarItem
                  href={APP_URLS.REPORTS_LIST}
                  icon={<FlagIcon className={ICON_CLASSNAME} />}
                  label="Reports"
                />
                <DesktopNavBarItem
                  href={APP_URLS.FEEDBACK_LIST}
                  icon={<PencilSquareIcon className={ICON_CLASSNAME} />}
                  label="Feedbacks"
                />
              </>
            )}
          </div>

          <div className="mt-auto pt-4">
            <div className="space-y-1 mb-4">
              <DesktopNavBarItem
                href={APP_URLS.FAQ}
                icon={<QuestionMarkCircleIcon className={ICON_CLASSNAME} />}
                label="Help & FAQ"
              />
              <DesktopNavBarItem
                href={APP_URLS.SUPPORT}
                icon={<InformationCircleIcon className={ICON_CLASSNAME} />}
                label="Support"
              />
            </div>
          </div>

          {/* Session Info Box with Loading State */}
          {
            loading ? (
              <SessionInfoLoader />
            ) : 
              <div className="mt-4 p-4 rounded-lg bg-gray-100 dark:bg-gray-700/50 space-y-4">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Session Info
                  </span>
                  <div className="flex items-center gap-1 px-2 py-1 rounded-full bg-green-500/10 border border-green-500/20">
                    <div className="w-1.5 h-1.5 rounded-full bg-green-500 animate-pulse" />
                    <span className="text-xs font-medium text-green-500">
                      Active
                    </span>
                  </div>
                </div>

                {/* Login Time Info */}
                <div className="flex items-center gap-3 text-sm text-gray-600 dark:text-gray-400">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-500/10">
                    <ClockIcon className="w-4 h-4 text-blue-500" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-500">
                      Login Time
                    </span>
                    <span className="font-medium dark:text-gray-300">
                      {lastLoginDisplay}
                    </span>
                  </div>
                </div>

                {/* Device Info */}
                <div className="flex items-center gap-3 text-sm text-gray-600 dark:text-gray-400">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-purple-500/10">
                    <ComputerDesktopIcon className="w-4 h-4 text-purple-500" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-500">
                      Current Device
                    </span>
                    <span className="font-medium dark:text-gray-300">
                      {deviceInfo.device} • {deviceInfo.browser}
                    </span>
                  </div>
                </div>

                {/* System Info */}
                <div className="flex items-center gap-3 text-sm text-gray-600 dark:text-gray-400">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-500/10">
                    <GlobeAltIcon className="w-4 h-4 text-orange-500" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-500">
                      System
                    </span>
                    <span className="font-medium dark:text-gray-300">
                      {deviceInfo.os}
                    </span>
                  </div>
                </div>

                {/* Security Info */}
                <div className="flex items-center gap-3 p-2 rounded-md bg-gray-50 dark:bg-gray-800/50 border border-gray-200/10">
                  <ShieldCheckIcon className="w-4 h-4 text-green-500" />
                  <span className="text-xs text-gray-600 dark:text-gray-400">
                    Secure connection established
                  </span>
                </div>
              </div>
            
          }
        </div>
      </aside>
    </>
  );
}
