import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Pagination from "../../components/common/pagination/Pagination";
import { Spinner } from "flowbite-react";
import {
  getDataSets,
  setSelectedFeedDataSet,
} from "../../redux/dataSets/dataSetsSlice";
import { DataSet } from "../../types/dataset";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "use-debounce";

interface Props {
  afterDataSetSelect: () => void;
}

/**
 * A component that allows the user to select which dataset will be used to display feed items in the feed.
 */
export default function SelectFeedDataSetTable({ afterDataSetSelect }: Props) {
  const dispatch = useAppDispatch();
  const dataSets = useAppSelector((state) => state.dataSets.dataSets);
  const pendingGetDataSets = useAppSelector(
    (state) => state.dataSets.pendingGetDataSets,
  );
  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);

  /**
   * Select a dataset.
   */
  const onSelect = (dataset: DataSet | null) => {
    dispatch(setSelectedFeedDataSet(dataset));
    afterDataSetSelect();
  };

  /**
   * Gets previous page of datasets.
   */
  const getPreviousPage = () => {
    setPage(page - 1);
  };

  /**
   * Gets next page of datasets.
   */
  const getNextPage = () => {
    setPage(page + 1);
  };

  /**
   * Maps datasets to table rows.
   */
  const displayRecords = () => {
    let datasets;
    if (page === 0) {
      datasets = [null, ...dataSets.results];
    } else {
      datasets = dataSets.results;
    }

    return datasets.map((item: DataSet | null, idx: number) => (
      <tr
        key={idx}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
      >
        <th scope="row" className="px-6 py-4 font-medium text-gray-900">
          <a
            onClick={() => onSelect(item)}
            className="text-blue-600 font-medium underline"
            aria-label={`Select ${item ? item.name : "None"} dataset`}
          >
            {item ? item.name : "None"}
          </a>
        </th>
      </tr>
    ));
  };

  /**
   * Fetch datasets whenever page changes.
   */
  useEffect(() => {
    dispatch(getDataSets({ queryParams: { query: debouncedQuery } }));
  }, [page]);

  /**
   * Fetch datasets and set page to 0 whenever query changes.
   */
  useEffect(() => {
    setPage(0);
    dispatch(getDataSets({ queryParams: { query: debouncedQuery } }));
  }, [debouncedQuery]);

  return (
    <>
      <div
        className={"flex flex-col sm:flex-row space-y-1 items-center bg-white"}
      >
        <div className="flex flex-col">
          <span className="text-lg font-semibold text-gray-800 capitalize">
            Select which dataset should be used to provide content:
          </span>
        </div>
      </div>

      <div className="w-full flex flex-row rounded-full border-gray-200 border-[1px] bg-white justify-center items-center">
        <MagnifyingGlassIcon className="inline h-6 text-gray-500 px-2" />
        <input
          type="text"
          className="w-full border-0 rounded-r-full bg-transparent pl-0 focus:ring-0"
          placeholder="Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          aria-label="Search datasets"
        />
      </div>
      {pendingGetDataSets ? (
        <div className="flex justify-center">
          <Spinner size="sm" aria-label="Loading spinner" />
        </div>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
            <tr>
              <th scope="col" className="px-6 py-3">
                Dataset Name
              </th>
            </tr>
          </thead>
          <tbody>{displayRecords()}</tbody>
        </table>
      )}

      <Pagination
        count={dataSets.count}
        page={page}
        previous={dataSets.previous}
        getPreviousPage={getPreviousPage}
        next={dataSets.next}
        getNextPage={getNextPage}
      />
    </>
  );
}
