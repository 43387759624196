import React, { CSSProperties, useEffect, useState } from "react";
import { SEEK_BAR_HEIGHT, YOUTUBE_PLAYER_CONTROLS_Z_INDEX } from "../utils";
import ReactPlayer from "react-player";
import SeekBar from "./SeekBar";
import { OnProgressProps } from "react-player/base";
import { useAppDispatch } from "../../../redux/hooks";
import { increaseWatchedFor, watchFeedItem } from "../../../redux/feed/feedItemsSlice";
import { FeedItem } from "../../../types/feed";

interface Props {
  id: string;
  className?: string;
  style: CSSProperties;
  data: { playing: boolean; feedItem: FeedItem | null };
}

const INITIAL_PROGRESS: OnProgressProps = {
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  loadedSeconds: 0,
};

/**
 * Video player for YouTube videos.
 */
export default function VideoPlayer({ id, className, style, data }: Props) {
  // Player holds the YouTube player - it provides functions allowing us to control it
  // Docs: https://developers.google.com/youtube/iframe_api_reference#Functions
  const [player, setPlayer] = useState<ReactPlayer | null>(null);
  const [progress, setProgress] = useState<OnProgressProps>({
    ...INITIAL_PROGRESS,
  });
  const [duration, setDuration] = useState(0);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [prevVideoData, setPrevVideoData] = useState({ ...data });

  const dispatch = useAppDispatch();

  /**
   * Set the player state when the player is ready.
   */
  function onPlayerReady(player: ReactPlayer) {
    setPlayer(player);
  }

  /**
   * Calculate the height of the YouTube player.
   */
  function calculateYoutubeHeight(style: CSSProperties) {
    const height = style.height;
    let calculatedHeight = 0;
    if (height && typeof height === "string") {
      calculatedHeight = parseFloat(height);
    } else if (height && typeof height === "number") {
      calculatedHeight = height;
    } else {
      return height;
    }
    calculatedHeight -= SEEK_BAR_HEIGHT;
    return calculatedHeight + "px";
  }

  /**
   * Update progress state when the video is playing.
   */
  function onProgress(state: OnProgressProps) {
    const prevProgressState = progress;

    const progressIncrease =
      state.playedSeconds - prevProgressState.playedSeconds;
    if (progressIncrease > 0) {
      if (progressIncrease < 1) {
        // if progressIncrease >= 1 then we can assume that the user has seeked forward
        // We need to ignore that case
        setProgressSeconds(progressSeconds + progressIncrease);
      }
    }
    setProgress({ ...state });
  }

  /**
   * Increase watched for.
   */
  function updateWatchedFor() {
    // Update watched_for state
    if (progressSeconds > 0 && data.feedItem) {
      dispatch(
        increaseWatchedFor({
          feed_item: data.feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        }),
      );
      setProgressSeconds(0);
    }
  }

  /**
   * Update watched for when the user leaves the page.
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [progressSeconds]);

  /**
   * Update watched for when the video changes.
   */
  useEffect(() => {
    if (prevVideoData.feedItem?.id !== data.feedItem?.id && data.feedItem) {
      updateWatchedFor();
    }

    if (!prevVideoData.playing && data.playing && data.feedItem) {
      dispatch(watchFeedItem(data.feedItem.id));
    }

    setPrevVideoData({ ...data });
  }, [data]);

  return (
    <div className={className} style={style} id={id}>
      <ReactPlayer
        url={`https://www.youtube.com/watch/${data.feedItem?.youtube_video_id}`}
        playing={data.playing}
        width="100%"
        height={calculateYoutubeHeight(style)}
        onReady={onPlayerReady}
        controls={false}
        onProgress={onProgress}
        onDuration={(duration) => setDuration(duration)}
      />
      <div
        className="w-full bg-black bottom-0 flex flex-col justify-between items-center"
        style={{
          height: SEEK_BAR_HEIGHT,
          zIndex: YOUTUBE_PLAYER_CONTROLS_Z_INDEX,
        }}
      >
        <SeekBar progress={progress} duration={duration} player={player} />
      </div>
    </div>
  );
}
