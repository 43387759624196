import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Onboarding from "../pages/Onboarding";
import Profile from "../pages/Profile";
import GoogleTakeoutGuide from "../pages/Guides/GoogleTakeout";
import ShazamLibraryGuide from "../pages/Guides/ShazamLibrary";
import ProfileQuizPage from "../pages/Profile/Quiz";
import NeuralNetworks from "../pages/NeuralNetworks";
import DataSets from "../pages/DataSets";
import Quizzes from "../pages/Quizzes/QuizList";
import QuizList from "../pages/Quizzes/QuizList";
import QuizDetails from "../pages/Quizzes/QuizDetails";
import Music from "../pages/Music";
import News from "../pages/News";
import QuizEditCreate from "../pages/Quizzes/QuizEditCreate";
import LandingPage from "../pages/Landing";
import QuizResults from "../pages/Quizzes/QuizResults";
import BrainCoin from "../pages/BrainCoin";
import Videos from "../pages/Videos";
import FeedMobile from "../pages/Feed/FeedMobile";
import PlaylistsDesktop from "../pages/Playlists/PlaylistsDesktop";
import PlaylistListMobile from "../pages/Playlists/PlaylistListMobile";
import LibraryMobile from "../pages/Library/LibraryMobile";
import PlaylistDetailsMobile from "../pages/Playlists/PlaylistDetailsMobile";
import FeedData from "../pages/Admin/FeedData";
import FeedDataUserDetails from "../pages/Admin/FeedData/FeedDataUserDetails";
import FeedDataVideoDetails from "../pages/Admin/FeedData/FeedDataVideoDetails";
import FeedDataNewsDetails from "../pages/Admin/FeedData/FeedDataNewsDetails";
import FeedDataTrackDetails from "../pages/Admin/FeedData/FeedDataTrackDetails";
import Reports from "../pages/Admin/Reports";
import CreateFeedback from "../pages/Feedback/CreateFeedback";
import FeedbackList from "../pages/Admin/Feedback/FeedbackList";
import FeedbackDetails from "../pages/Admin/Feedback/FeedbackDetails";
import {
  getAccessToken,
  isTokenValid,
  removeTokens,
} from "../services/localStorage";
import { history } from "../helpers/history";
import EmailConfirmation from "../pages/Auth/EmailConfirmation";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import TermsOfService from "../pages/Login/TermsOfService";
import PrivacyPolicy from "../pages/Login/PrivacyPolicy";
import PaymentSuccess from "../pages/Payment/PaymentSuccess";
import PaymentCancelled from "../pages/Payment/PaymentCancelled";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAccount } from "wagmi";
import { getUser } from "redux/user/userSlice";
import FAQ from "pages/FAQ";
import Help from "pages/Support";
import Support from "pages/Support";
import MoviesPage from "pages/Movies";
import MovieDetailsPage from "pages/MovieDetails";

/**
 * List of all app URLs.
 */
export const APP_URLS = {
  LANDING_PAGE: "/landing",
  LOGIN: "/login",
  REGISTER: "/register",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_SERVICE: "/terms-of-service",
  VIDEOS: "/videos",
  MUSIC: "/music",
  NEWS: "/news",
  MOVIES: "/movies",
  MOVIE_DETAILS: "/movies/:id",
  PLAYLISTS_DESKTOP: "/playlists/desktop",
  PLAYLISTS_MOBILE: "/playlists/mobile",
  PLAYLIST_DETAILS_MOBILE: "/playlists/:id/mobile",
  PROFILE: "/profile",
  PROFILE_QUIZ: "/profile/quiz/:id",
  GUIDES_GOOGLE_TAKEOUT: "/guides/google-takeout",
  GUIDES_SHAZAM_LIBRARY: "/guides/shazam-library",
  NEURAL_NETWORKS: "/neural-networks",
  DATA_SETS: "/data-sets",
  QUIZZES: "/quizzes",
  QUIZZES_LIST: "/quizzes/list/:id",
  QUIZZES_CREATE: "/quizzes/create",
  QUIZZES_EDIT: "/quizzes/:id/edit",
  QUIZZES_DETAILS: "/quizzes/:id",
  QUIZZES_RESULTS: "/quizzes/:id/results",
  BRAIN_COIN: "/brain-coin",
  LIBRARY_MOBILE: "/library/mobile",
  FEED_DATA: "/feed-data",
  FEED_DATA_USER_DETAILS: "/feed-data/user/:userId",
  FEED_DATA_VIDEO_DETAILS: "/feed-data/video/:videoId",
  FEED_DATA_NEWS_DETAILS: "/feed-data/news/:newsId",
  FEED_DATA_TRACK_DETAILS: "/feed-data/track/:trackId",
  FEED_MOBILE: "/feed/mobile",
  FEED_MOBILE_SPECIFIC: "/feed/:feedItemId/mobile",
  FEED_MOBILE_PLAYLIST: "/feed/playlist/:playlistId/mobile",
  REPORTS_LIST: "/reports",
  FEEDBACK: "/feedback",
  FEEDBACK_LIST: "/feedbacks",
  FEEDBACK_DETAILS: "/feedbacks/:id",
  INDEX: "/",
  FORGOT_PASSWORD: "/auth/forgot-password",
  CONFIRM_EMAIL: "/confirm-email/:token",
  RESET_PASSWORD: "/auth/reset-password/:uid/:token",
  PAYMENT_SUCCESS: "/payment/success",
  PAYMENT_CANCELLED: "/payment/cancelled",
  FAQ: "/faq",
  SUPPORT: "/support",
};

/**
 * List of mobile pages that should hide navigation elements provided by NavBar component.
 */
export const PAGES_WITH_CUSTOM_MOBILE_NAVBAR: Array<string> = [
  APP_URLS.FEED_MOBILE,
];

/**
 * List of desktop pages that should hide navigation elements provided by NavBar component.
 */
export const PAGES_WITH_CUSTOM_DESKTOP_NAVBAR: Array<string> = [];

export default function Navigation() {
  /**
   * Redirect to login page if access token is not present or is invalid.
   */

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const { seqAddress, pendingConnect } = useAppSelector(
    (state) => state.wallet
  );
  const token = getAccessToken();

  useEffect(() => {
    const accessToken = getAccessToken();
    const publicPaths = [APP_URLS.PRIVACY_POLICY, APP_URLS.TERMS_OF_SERVICE];

    if (!accessToken || !isTokenValid(accessToken)) {
      if (
        !publicPaths.includes(location.pathname) &&
        !location.pathname.startsWith("/auth")
      ) {
        history.navigate(APP_URLS.LOGIN);
      }
    }
  }, []);

  useEffect(() => {
    if (token != null) {
      dispatch(getUser());
    }
  }, [token]);

  return (
    <Routes>
      <Route path={APP_URLS.LOGIN} element={<Login />} />
      <Route path={APP_URLS.REGISTER} element={<Onboarding />} />
      <Route path={APP_URLS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={APP_URLS.TERMS_OF_SERVICE} element={<TermsOfService />} />
      <Route path={APP_URLS.CONFIRM_EMAIL} element={<EmailConfirmation />} />
      <Route path={APP_URLS.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={APP_URLS.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={APP_URLS.VIDEOS} element={<Videos />} />
      <Route path={APP_URLS.FEED_MOBILE} element={<FeedMobile />} />
      <Route path={APP_URLS.FEED_MOBILE_SPECIFIC} element={<FeedMobile />} />
      <Route path={APP_URLS.FEED_MOBILE_PLAYLIST} element={<FeedMobile />} />
      <Route path={APP_URLS.MUSIC} element={<Music />} />
      <Route path={APP_URLS.NEWS} element={<News />} />
      <Route path={APP_URLS.MOVIES} element={<MoviesPage />} />
      <Route path={APP_URLS.MOVIE_DETAILS} element={<MovieDetailsPage />} />
      <Route path={APP_URLS.PLAYLISTS_DESKTOP} element={<PlaylistsDesktop />} />
      <Route
        path={APP_URLS.PLAYLISTS_MOBILE}
        element={<PlaylistListMobile />}
      />
      <Route
        path={APP_URLS.PLAYLIST_DETAILS_MOBILE}
        element={<PlaylistDetailsMobile />}
      />
      <Route
        path={APP_URLS.PROFILE}
        element={
          <Profile
            sequenceWalletAddress={seqAddress || null || undefined}
            pendingConnect={pendingConnect}
          />
        }
      />
      <Route path={APP_URLS.PROFILE_QUIZ} element={<ProfileQuizPage />} />
      <Route
        path={APP_URLS.GUIDES_GOOGLE_TAKEOUT}
        element={<GoogleTakeoutGuide />}
      />
      <Route
        path={APP_URLS.GUIDES_SHAZAM_LIBRARY}
        element={<ShazamLibraryGuide />}
      />
      <Route path={APP_URLS.NEURAL_NETWORKS} element={<NeuralNetworks />} />
      <Route path={APP_URLS.DATA_SETS} element={<DataSets />} />
      <Route path={APP_URLS.QUIZZES} element={<Quizzes />} />
      <Route path={APP_URLS.QUIZZES_LIST} element={<QuizList />} />
      <Route path={APP_URLS.QUIZZES_CREATE} element={<QuizEditCreate />} />
      <Route path={APP_URLS.QUIZZES_EDIT} element={<QuizEditCreate />} />
      <Route path={APP_URLS.QUIZZES_DETAILS} element={<QuizDetails />} />
      <Route path={APP_URLS.QUIZZES_RESULTS} element={<QuizResults />} />
      <Route
        path={APP_URLS.BRAIN_COIN}
        element={
          <BrainCoin
            sequenceWalletAddress={seqAddress || null || undefined}
            pendingConnect={pendingConnect}
          />
        }
      />
      <Route path={APP_URLS.LIBRARY_MOBILE} element={<LibraryMobile />} />
      <Route path={APP_URLS.FEED_DATA} element={<FeedData />} />
      <Route
        path={APP_URLS.FEED_DATA_USER_DETAILS}
        element={<FeedDataUserDetails />}
      />
      <Route
        path={APP_URLS.FEED_DATA_VIDEO_DETAILS}
        element={<FeedDataVideoDetails />}
      />
      <Route
        path={APP_URLS.FEED_DATA_NEWS_DETAILS}
        element={<FeedDataNewsDetails />}
      />
      <Route
        path={APP_URLS.FEED_DATA_TRACK_DETAILS}
        element={<FeedDataTrackDetails />}
      />
      <Route path={APP_URLS.REPORTS_LIST} element={<Reports />} />
      <Route path={APP_URLS.FEEDBACK} element={<CreateFeedback />} />
      <Route path={APP_URLS.FEEDBACK_LIST} element={<FeedbackList />} />
      <Route path={APP_URLS.FEEDBACK_DETAILS} element={<FeedbackDetails />} />
      <Route path={APP_URLS.INDEX} element={<Login />} />
      <Route path={APP_URLS.LANDING_PAGE} element={<LandingPage />} />
      <Route path={APP_URLS.PAYMENT_SUCCESS} element={<PaymentSuccess />} />
      <Route path={APP_URLS.PAYMENT_CANCELLED} element={<PaymentCancelled />} />
      <Route path={APP_URLS.FAQ} element={<FAQ />} />
      <Route path={APP_URLS.SUPPORT} element={<Support />} />
    </Routes>
  );
}
