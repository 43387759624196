import LibraryMobileComponent from "components/library/LibraryMobile";
import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useIsSmScreen } from "hooks/useIsSmScreen";
import { getIsSmScreen } from "helpers/responsiveness";
import { APP_URLS } from "../../navigation";

/**
 * Library page for mobile.
 */
export default function LibraryMobile() {
  const isSmScreen = useIsSmScreen();

  if (!getIsSmScreen()) {
    // Force the user to desktop-compatible videos view
    window.location.href = APP_URLS.PLAYLISTS_DESKTOP;
  }

  useEffect(() => {
    document.title = "Library | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <>
        <div className="flex flex-col space-y-2 px-4 py-2" aria-label="Library Mobile Page">
          <div className="text-3xl font-bold text-gray-900">Library</div>
          <div className="w-full flex flex-row rounded-xl border-gray-200 border-[1px] bg-white justify-center items-center">
            <MagnifyingGlassIcon className="inline h-6 text-gray-500 px-2" aria-hidden="true" />
            <input
              type="text"
              className="w-full border-0 rounded-r-full bg-transparent pl-0 focus:ring-0"
              placeholder="Search (placeholder)"
              aria-label="Search"
            />
          </div>
        </div>
        <div className="mt-2">
          <LibraryMobileComponent />
        </div>
      </>
    </PageWrapper>
  );
}
