import { createSlice } from "@reduxjs/toolkit";
import {
  FEED_ITEM_TYPES,
  FeedItemType,
} from "../../types/feed";

interface feedFilterTypesState {
  feedFilterTypes: FeedItemType[];
}

const initialState: feedFilterTypesState = {
  feedFilterTypes: [
    FEED_ITEM_TYPES.NEWS,
    FEED_ITEM_TYPES.TRACK,
    FEED_ITEM_TYPES.VIDEO,
    FEED_ITEM_TYPES.MOVIE,
  ],
};

export const feedFilterTypesSlice = createSlice({
  name: "feedFilterTypes",
  initialState,
  reducers: {
    setFeedFilterTypes(state, action) {
      state.feedFilterTypes = action.payload;
    },
  },
});


export const {
  setFeedFilterTypes,
} = feedFilterTypesSlice.actions;
