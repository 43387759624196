import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import FeedDataVideosList from "components/admin/feedData/lists/FeedDataVideosList";
import FeedDataNewsList from "components/admin/feedData/lists/FeedDataNewsList";
import FeedDataTracksList from "components/admin/feedData/lists/FeedDataTracksList";
import FeedDataUsersList from "components/admin/feedData/lists/FeedDataUsersList";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Page displaying feed data.
 */
export default function FeedData() {
  useEffect(() => {
    document.title = "Feed Data | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col bg-[#1a1f2b] pt-10">
        {/* Header */}
        <div className="bg-[#1e2533] border-b border-gray-700/50">
          <MainHeader title="Feed Data" />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="max-w-[1400px] mx-auto">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
              {/* Users Section */}
              <div className="bg-[#1e2533] rounded-lg border border-gray-700/50 p-6">
                <FeedDataUsersList />
              </div>

              {/* Videos Section */}
              <div className="bg-[#1e2533] rounded-lg border border-gray-700/50 p-6">
                <FeedDataVideosList />
              </div>

              {/* Tracks Section */}
              <div className="bg-[#1e2533] rounded-lg border border-gray-700/50 p-6">
                <FeedDataTracksList />
              </div>

              {/* News Section */}
              <div className="bg-[#1e2533] rounded-lg border border-gray-700/50 p-6">
                <FeedDataNewsList />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </PageWrapper>
  );
}
