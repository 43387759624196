import { StripeNFTProduct } from "../../types/payments";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Modal from "../../components/common/modal";
import {
  fetchCheckoutUrl,
  fetchNFTProducts,
} from "../../redux/payments/paymentsSlice";
import { Button, Spinner } from "flowbite-react";
import NFTProductClickable from "./NFTProductClickable";
import { isDispatchResponseError } from "../../redux/utils";
import NFTDetailsModal from "./NFTDetailsModal";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  walletAddress: string | undefined | null;
}

export default function NFTProductSelectionModal({
  show,
  setShow,
  walletAddress,
}: Props) {
  const dispatch = useAppDispatch();
  const nftProducts = useAppSelector((state) => state.payments.nftProducts);
  const pendingFetchNFTProducts = useAppSelector(
    (state) => state.payments.pendingFetchNFTProducts
  );
  const errorMessagesFetchNFTProducts = useAppSelector(
    (state) => state.payments.errorMessagesFetchNFTProducts
  );
  const pendingFetchCheckoutUrl = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutUrl
  );
  const [selectedProduct, setSelectedProduct] =
    useState<StripeNFTProduct | null>(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsProduct, setDetailsProduct] = useState<StripeNFTProduct | null>(null);
  const [processingProductId, setProcessingProductId] = useState<string | null>(null);

  function onClose() {
    setShow(false);
    setSelectedProduct(null);
  }

  // function onClickNFTProduct(product: StripeNFTProduct) {
  //   setSelectedProduct(product);
  // }

  async function onConfirm(product: StripeNFTProduct) {
    setProcessingProductId(product.id);
    setSelectedProduct(product);
    
    if (!product || !walletAddress) {
      setProcessingProductId(null);
      return;
    }
    const response = await dispatch(
      fetchCheckoutUrl({ priceId: product.price.id, walletAddress })
    );
    
    setProcessingProductId(null);
    
    if (!isDispatchResponseError(response)) {
      window.location.href = response.payload as string;
    }
  }

  useEffect(() => {
    dispatch(fetchNFTProducts());
  }, []);

  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
        hideFooter
        body={
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Please select an NFT to purchase
            </h3>

            {pendingFetchNFTProducts ? (
              <Spinner size="sm" />
            ) : (
              <div className="flex flex-wrap justify-center items-center gap-2">
                {nftProducts?.map((product: StripeNFTProduct) => (
                  <NFTProductClickable
                    key={product.id}
                    data={product}
                    onClick={() => onConfirm(product)}
                    selected={selectedProduct?.id === product.id}
                    isLoading={processingProductId === product.id}
                  />
                ))}
              </div>
            )}

            <div className="flex justify-center gap-4 mt-8">
              {/* <Button
                color="info"
                onClick={onConfirm}
                disabled={!selectedProduct}
              >
                {pendingFetchCheckoutUrl ? <Spinner size="sm" /> : "Checkout"}
              </Button> */}
              <Button color="gray" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        }
      />

      <NFTDetailsModal
        show={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        product={detailsProduct}
        onSelect={() => {
          setSelectedProduct(detailsProduct);
          setShowDetailsModal(false);
        }}
        isSelected={selectedProduct?.id === detailsProduct?.id}
      />
    </>
  );
}
