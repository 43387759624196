import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addItemToPlaylists,
  getMyPlaylists,
  getSharedPlaylists,
  removeItemFromPlaylist,
} from "../../../redux/playlist/playlistSlice";
import PlaylistDrawerItem from "./PlaylistDrawerItem";
import {
  AddItemToPlaylistPayload,
  Playlist,
  RemoveItemFromPlaylistPayload,
} from "../../../types/playlist";
import { FeedItem } from "../../../types/feed";
import ConfirmRemoveFromSavedModal from "./ConfirmRemoveFromSavedModal";
import { handleTouchEnd, handleTouchMove, handleTouchStart } from "./handlers";
import {
  enableTransition,
  getComponentHeight,
  getInitialPlaylistTop,
  setPlaylistHidden,
} from "./utils";
import ModalEditCreatePlaylist from "../../../components/playlist/ModalEditCreatePlaylist";
import { likeFeedItem } from "../../../redux/feed/feedItemsSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  currItemId: FeedItem["id"];
  show: boolean;
  setShow: (val: boolean) => void;
  incrementCurrFeedItemIdx: () => void;
  closeMode?: "drag" | "button";
}

const INITIAL_PLAYLISTS_DRAWER_STYLE = {
  top: getComponentHeight(),
  height: getComponentHeight(),
  transitionDuration: "150ms",
};

/**
 * Component for displaying a list of playlists that the user can add a feed item to.
 */
export default function PlaylistsDrawer({
  currItemId,
  show,
  setShow,
  incrementCurrFeedItemIdx,
  closeMode = "drag",
}: Props) {
  const dispatch = useAppDispatch();
  const [showNewPlaylistModal, setShowNewPlaylistModal] = useState(false);
  const [showRemoveFromSavedModal, setShowRemoveFromSavedModal] =
    useState(false);
  const [prevShow, setPrevShow] = useState(false);
  const defaultPlaylist = useAppSelector((state) =>
    state.playlist.myPlaylists.find((playlist) => playlist.is_default),
  );
  const myPlaylists = useAppSelector((state) =>
    state.playlist.myPlaylists.filter((playlist) => !playlist.is_default),
  );
  const sharedPlaylists = useAppSelector((state) =>
    state.playlist.sharedPlaylists.filter((playlist) => !playlist.is_default),
  );
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const grabBarRef = useRef<HTMLDivElement | null>(null);

  let startY = 0;
  let currY = 0;

  /**
   * Fetches the playlists owned by the user and shared with them.
   */
  function fetchPlaylists() {
    dispatch(getMyPlaylists({ queryParams: { page_size: "100" } }));
    dispatch(getSharedPlaylists({ queryParams: { page_size: "100" } }));
  }

  /**
   * Adds a feed item to the playlist.
   */
  function handleAddToPlaylist(playlist: Playlist) {
    const data: AddItemToPlaylistPayload = {
      playlist_ids: [playlist.id],
      feed_item_id: currItemId,
    };

    dispatch(addItemToPlaylists(data));
    dispatch(likeFeedItem(currItemId));
    incrementCurrFeedItemIdx();

    // Close the drawer
    enableTransition({ ref: drawerRef });
    setShow(false);
    setPlaylistHidden(drawerRef);
  }

  /**
   * Removes a feed item from the playlist.
   */
  async function handleRemoveFromPlaylist(playlist: Playlist) {
    const data: RemoveItemFromPlaylistPayload = {
      playlist_id: playlist.id,
      feed_item_id: currItemId,
    };

    await dispatch(removeItemFromPlaylist(data));
    dispatch(getMyPlaylists({}));
  }

  /**
   * Calculates the height of the playlist list.
   */
  function calculatePlaylistListHeight() {
    const playlistWrapper = document.getElementById("playlistWrapper");
    let height = 0;
    if (playlistWrapper) {
      height = playlistWrapper.offsetHeight;
      const children = Array.from(playlistWrapper.children);
      children.forEach((child, idx) => {
        if (child instanceof HTMLElement) {
          if (idx !== children.length - 1) {
            height -= child.offsetHeight;
          }
        }
      });
    }
    return height + "px";
  }

  /**
   * Handles the click event on the close button.
   */
  function onClickCloseButton() {
    enableTransition({ ref: drawerRef });
    setShow(false);
    setPlaylistHidden(drawerRef);
  }

  /**
   * Sets the height of the playlist drawer based on window height.
   */
  useEffect(() => {
    if (show && !prevShow && drawerRef.current) {
      // React to the parent setting show from false to true
      drawerRef.current.style.height = getComponentHeight() + "px";
      drawerRef.current.style.top = getInitialPlaylistTop() + "px";
    }
    setPrevShow(show);
  }, [show]);

  /**
   * Sets up gesture-related listeners.
   */
  useEffect(() => {
    fetchPlaylists();

    // Declare gesture-related listener callbacks
    function touchMoveCallback(e: TouchEvent) {
      const result = handleTouchMove({
        e,
        currY,
        ref: drawerRef,
      });
      currY = result.currY;
    }
    function touchStartCallback(e: TouchEvent) {
      const result = handleTouchStart({
        e,
        startY,
        currY,
        ref: drawerRef,
      });
      startY = result.startY;
      currY = result.currY;
    }
    function touchEndCallback(e: TouchEvent) {
      handleTouchEnd({
        currY,
        startY,
        ref: drawerRef,
        setShow,
      });
    }

    // Add all gesture-related listeners
    if (grabBarRef.current) {
      grabBarRef.current.addEventListener("touchmove", touchMoveCallback);
      grabBarRef.current.addEventListener("touchstart", touchStartCallback);
      grabBarRef.current.addEventListener("touchend", touchEndCallback);
    }

    return () => {
      // Cleanup
      if (grabBarRef.current) {
        grabBarRef.current.removeEventListener("touchmove", touchMoveCallback);
        grabBarRef.current.removeEventListener(
          "touchstart",
          touchStartCallback,
        );
        grabBarRef.current.removeEventListener("touchend", touchEndCallback);
      }
    };
  }, []);

  return (
    <>
      <div
        id="playlistWrapper"
        className="fixed z-[60] bottom-0 w-full bg-gray-50"
        style={INITIAL_PLAYLISTS_DRAWER_STYLE}
        ref={drawerRef}
        aria-label="Playlist drawer"
      >
        <div className="bg-gray-50 px-2 pb-4 py-2 drop-shadow-lg">
          <div
            className="flex justify-center items-center bg-gray-50"
            ref={grabBarRef}
            aria-label="Grab bar"
          >
            {closeMode === "drag" && (
              <div className="w-12 h-1 bg-gray-300 rounded-full p-4" />
            )}
            {closeMode === "button" && (
              <button
                className="p-1 ml-auto"
                onClick={onClickCloseButton}
                aria-label="Close playlist drawer"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          {defaultPlaylist && (
            <PlaylistDrawerItem
              playlist={defaultPlaylist}
              itemId={currItemId}
              handleAddToPlaylist={handleAddToPlaylist}
              handleRemoveFromPlaylist={() => setShowRemoveFromSavedModal(true)}
            />
          )}
        </div>

        <div className="flex py-4 px-2">
          <div className="font-bold text-xl text-gray-800">Playlists</div>
          <button
            className="font-bold text-xl text-blue-600 ml-auto"
            onClick={() => setShowNewPlaylistModal(true)}
            aria-label="Create new playlist"
          >
            New Playlist
          </button>
        </div>
        <div
          className="space-y-2 px-2 pb-2 overflow-y-scroll"
          style={{ height: calculatePlaylistListHeight() }}
          aria-label="List of playlists"
        >
          {[...myPlaylists, ...sharedPlaylists].map((playlist) => (
            <PlaylistDrawerItem
              playlist={playlist}
              key={playlist.id}
              itemId={currItemId}
              handleAddToPlaylist={handleAddToPlaylist}
              handleRemoveFromPlaylist={handleRemoveFromPlaylist}
            />
          ))}
        </div>
      </div>

      <ModalEditCreatePlaylist
        show={showNewPlaylistModal}
        onClose={() => setShowNewPlaylistModal(false)}
        itemId={currItemId}
      />

      {defaultPlaylist && (
        <ConfirmRemoveFromSavedModal
          showModal={showRemoveFromSavedModal}
          setShowModal={setShowRemoveFromSavedModal}
          itemId={currItemId}
          playlist={defaultPlaylist}
        />
      )}
    </>
  );
}
