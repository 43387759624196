import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  checkEmail,
  generateLoginOTP,
  googleRegister,
  login,
  register,
} from "../../redux/auth/authSlice";
import { history } from "../../helpers/history";
import { getAccessToken, removeTokens } from "../../services/localStorage";
import { APP_URLS } from "../../navigation";
import CoinImage from "images/Landing/Landing-Coin.png";
import { GoogleLoginPayload } from "../../types/auth";
// import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import TextInput from "../../components/common/form/TextInput";
import "react-international-phone/style.css";
import OTPLogin from "./OTPLogin";
import CustomPhoneInput from "../../components/common/form/CustomPhoneInput";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { To } from "react-router-dom";
import {
  useAuthModal,
  useLogout,
  useSignerStatus,
  useUser,
} from "@account-kit/react";

import { useAuth0 } from "@auth0/auth0-react";

const PRIMARY_TEXT_COLOR_CLASS = "text-stone-200";

import {
  type UseAuthenticateResult,
  useAuthenticate,
} from "@account-kit/react";
import Footer from "../../components/Footer/Footer";
import { WalletLoginModal } from "../../components/auth/WalletLoginModal";
import { ConnectWalletModal } from "components/wallet/ConnectWalletModal";

/**
 * Login page.
 */

function MainContent() {
  const {
    useAuthModal,
    useLogout,
    useSignerStatus,
    useUser,
    useSmartAccountClient,
  } = require("@account-kit/react");

  const user = useUser();
  const { openAuthModal } = useAuthModal();
  const signerStatus = useSignerStatus();
  const { logout } = useLogout();
  const { authenticate, authenticateAsync, isPending, error } = useAuthenticate(
    {
      onSuccess: () => {
        history.navigate(APP_URLS.VIDEOS);
      },
      onError: (error) => {
        console.error("Error", error);
      },
    }
  );
  const [email, setEmail] = React.useState("");
  const { client, address } = useSmartAccountClient({
    type: "LightAccount",
  });
  useEffect(() => {});
  return (
    <div className="min-h-100 bg-gradient-to-b from-gray-900 to-gray-800 flex items-flex-start justify-center px-4 mt-16  ">
      <div className="max-w-300 w-full bg-white rounded-xl shadow-2xl p-8">
        <div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={() => authenticate({ type: "email", email })}>
            Login
          </button>
        </div>
        {signerStatus.isInitializing ? (
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : user ? (
          <div className="space-y-6">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-800">Welcome!</h2>
              <div className="mt-2 text-gray-600">
                Connected as:
                <div className="mt-1 font-mono bg-gray-100 rounded-lg p-2 break-all">
                  {address ?? "Anonymous"}
                </div>
              </div>
            </div>
            <button
              onClick={() => logout()}
              className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 transform hover:scale-105"
            >
              Logout
            </button>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-800">
                Welcome to BrainCargo
              </h2>
              <p className="mt-2 text-gray-600">
                Connect your wallet to continue
              </p>
            </div>
            {/* <AuthCard /> */}

            <button
              onClick={openAuthModal}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 transform hover:scale-105 flex items-center justify-center space-x-2"
            >
              <span>Continue</span>
              <svg
                className="w-5 h-5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 4v16m8-8H4"></path>
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default function Login() {
  const dispatch = useAppDispatch();

  const { loginWithRedirect } = useAuth0();

  const loginOtpErrorMessage = useAppSelector(
    (state) => state.auth.generateOTPErrorMessages
  );
  const registerErrorMessage = useAppSelector(
    (state) => state.auth.registerErrorMessages
  );

  const loginErrorMessage = useAppSelector(
    (state) => state.auth.loginErrorMessages
  );

  const { seqAddress, pendingConnect } = useAppSelector(
    (state) => state.wallet
  );

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [isSignInFieldEmail, setIsSignInFieldEmail] = useState(true);
  const [showOTPField, setShowOTPField] = useState(false);

  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);

  const [userExists, setUserExists] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const { authenticate, isPending } = useAuthenticate();
  const [emails, setEmails] = React.useState("");

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { openAuthModal } = useAuthModal();
  const user = useUser();
  const signerStatus = useSignerStatus();
  const { logout } = useLogout();

  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [showFAQDialog, setShowFAQDialog] = useState(false);

  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(null);

  const [showContactInfo, setShowContactInfo] = useState(false);

  const contactInfo = {
    phone: "000 0000 000",
    email: "support@braincargo.com",
    address: "BrainCargo, 1309 Coffeen Avenue STE 1200 Sheridan Wyoming 82801",
  };

  const toggleFAQ = (index: number) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What should I do if I forgot my password?",
      answer:
        "You can click on the 'Need help' button and select 'Forgot Password' to reset your password.",
    },
    {
      question: "How do I unlock my account?",
      answer:
        "If your account is locked, please contact our customer support for assistance.",
    },
    {
      question: "How do I update my password if I want to change it?",
      answer:
        "Click on 'Need Help' and select 'Forgot Email' option to reset your email password.",
    },
    {
      question: "How do I keep my account secure?",
      answer:
        "Use a strong password, enable two-factor authentication, and never share your login credentials.",
    },
  ];

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShowHelpDialog(false);
    }
  };

  const handleFAQOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShowFAQDialog(false);
      setShowContactInfo(false);
    }
  };

  /**
   * Log the user in.
   */
  const handleLogin = async () => {
    try {
      await toast.promise(
        dispatch(login({ email, password })).unwrap(),
        {
          pending: "Logging in...",
          success: "Logged In Successfully",
          error: {
            render({ data }: any) {
              return data?.detail || "Login Failed";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const responseMessage = (response: any) => {
    console.log("response", response);
    const credential = response?.credential;
    const body: GoogleLoginPayload = {
      auth_token: credential,
    };
    dispatch(googleRegister(body));
  };

  const handleRequestCode = async () => {
    try {
      const payload = isSignInFieldEmail ? { email } : { phone };
      const response = await toast.promise(
        dispatch(generateLoginOTP(payload)).unwrap(),
        {
          pending: "Sending OTP...",
          success: {
            render({ data }: any) {
              return data?.message || "OTP Sent";
            },
          },
          error: {
            render({ data }: any) {
              return data?.detail || "OTP Sending Failed";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
      if (response?.status === 200 || response?.status == 201) {
        if (response.status === 201) {
          setOtpToken(response?.token || "");
        }
        setShowOTPField(true);
        setShowOTPForm(true);
      }

      return response;
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleRegister = async () => {
    const registrationPromise = dispatch(
      register({ email, password1: password, password2: password })
    ).unwrap();
    try {
      const response = await toast.promise(
        registrationPromise,
        {
          pending: "Registration in Progress",
          success: {
            render({ data }: any) {
              return data?.message || "Registration Successful";
            },
          },
          error: {
            render({ data }: any) {
              return data?.detail || "Registration Failed. Please try again.";
            },
          },
        },
        {
          autoClose: 5000,
        }
      );
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const handleEmailCheck = async () => {
    const { password_is_set, user_exists } = await dispatch(
      checkEmail({ email })
    ).unwrap();
    setUserExists(user_exists);

    if (password_is_set || !user_exists) {
      setShowPasswordField(true);
    } else {
      await handleRequestCode();
      setShowOTPForm(true);
    }
  };

  const handleLoginOrRegister = async () => {
    if (userExists) {
      await handleLogin();
    } else {
      await handleRegister();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isSignInFieldEmail && !isPossiblePhoneNumber(phone)) {
      setPhoneError("Please Enter a Valid Phone Number");
      return;
    }

    setPhoneError("");

    if (!agreed) {
      alert("You must agree to the Privacy Policy and Terms of Service.");
      return;
    }

    if (isSignInFieldEmail) {
      if (showPasswordField) {
        await handleLoginOrRegister();
      } else {
        await handleEmailCheck();
      }
    } else {
      await handleRequestCode();
    }
  };

  useEffect(() => {
    document.title = "Login | BrainCargo";
    const accessToken = getAccessToken();
    if (accessToken) {
      history.navigate(APP_URLS.VIDEOS);
    }
  }, []);

  const handlePolicyNavigation = (url: string | URL | undefined) => {
    // Use window.open for external links
    window.open(url, "_blank");
  };

  const renderInputField = () => {
    if (isSignInFieldEmail) {
      return (
        <TextInput
          label="Email"
          id="email"
          type="email"
          required
          onChange={(event) => setEmail(event.target.value)}
          placeholder="example@exaple.com"
          value={email}
          readOnly={showPasswordField}
        />
      );
    }

    return (
      <CustomPhoneInput
        value={phone}
        label="Phone Number"
        id="phone"
        type="text"
        required
        onChangePhone={(phone) => {
          setPhone(phone);
        }}
        readOnly={showOTPField}
        placeholder="Phone"
        errorMessages={loginOtpErrorMessage?.phone || phoneError}
      />
    );
  };

  const renderPasswordField = () => {
    if (!showPasswordField) return null;

    return (
      <>
        <TextInput
          label={userExists ? "Password" : "Set Password"}
          value={password}
          id="password"
          type="password"
          required
          onChange={(event) => setPassword(event.target.value)}
          placeholder="***********"
          errorMessages={
            !userExists
              ? registerErrorMessage?.non_field_errors ||
                registerErrorMessage?.password1
              : loginErrorMessage?.detail
          }
        />
        <div className="flex flex-row items-center justify-between">
          {userExists && (
            <div
              onClick={() => {
                if (isSignInFieldEmail) {
                  history.navigate(APP_URLS.FORGOT_PASSWORD);
                } else {
                  handleRequestCode();
                }
              }}
              className="flex justify-end py-2 text-sm text-white hover:underline cursor-pointer"
            >
              Forget Password?
            </div>
          )}
          <div
            onClick={async () => {
              const response = await handleRequestCode();
              if (response?.status === 200 || response?.status == 201) {
                setShowOTPForm(true);
              }
            }}
            className="flex justify-start py-2 text-sm text-white hover:underline cursor-pointer"
          >
            Verify Via OTP
          </div>
        </div>
      </>
    );
  };

  const openFAQ = () => {
    setShowFAQDialog(true);
    setShowContactInfo(false);
  };

  // Main JSX return block
  return (
    <div
      className="relative flex flex-col justify-center p-4 md:p-0 min-h-screen overflow-hidden bg-[#02041a]"
      role="main"
    >
      <div
        className="flex items-center justify-between w-full bg-yellow-500 text-black font-bold p-2 text-sm md:text-base lg:text-lg md:p-3"
        role="alert"
      >
        <div className="flex-1 text-center">
          This is a development server, all data will be lost, this is for
          experimentation and dev only
        </div>
      </div>

      {/* <div
        className="w-full p-4 sm:p-10 m-auto bg-[#02041a] drop-shadow-xl border border-[#fbfbff] rounded-xl lg:max-w-xl"
        role="form"
      > */}
      <div
        className="w-full p-4 sm:p-10 m-auto bg-[#02041a] border border-[#fbfbff] rounded-xl lg:max-w-xl"
        role="form"
      >
        <div className="flex flex-col items-center">
          <h1
            className={`text-xl text-center sm:text-4xl ${PRIMARY_TEXT_COLOR_CLASS} font-extrabold sm:w-2/3 width: -webkit-fill-available;`}
          >
            BRAINCOIN&trade;
          </h1>
          <div className="flex sm:flex-row flex-col sm:items-start items-center sm:justify-center justify-stretch py-4 gap-2">
            {/* <img src={CoinImage} height={100} width={100} alt="BrainCoin" /> */}
            <img
              src="https://braincoin.com/assets/Gold-Brain-Coin-DL9cS1jd.png"
              height={100}
              width={100}
              alt="BrainCoin"
            />
          </div>
        </div>

        

       

        {/* <div className="flex justify-center sm:mt-6 mt-4 gap-x-2">
          <GoogleLogin onSuccess={responseMessage} shape="pill" />
        </div> */}

        {/* <button className="bg-blue-500 text-white p-2 rounded-md" onClick={() => loginWithRedirect()}>Log In</button>; */}

        <div className="flex justify-center items-center w-full p-8">
          <main className="w-full max-w-md">
            <WalletLoginModal
              sequenceWalletAddress={seqAddress}
              pendingConnect={pendingConnect}
            />
          </main>
        </div>

        <p className="mt-8 text-sm font-light text-center text-white">
          {" "}
          Sign in with{" "}
          <button
            onClick={() => {
              setIsSignInFieldEmail((prevState) => !prevState);
              setShowOTPForm(false);
              setShowPasswordField(false);
            }}
            className="font-medium [-webkit-text-fill-color:transparent] bg-[linear-gradient(92.19deg,#cec0f3_.3%,#c38af4_53.07%)] bg-clip-text"
            aria-label="Switch Sign In Method"
          >
            {isSignInFieldEmail ? "Phone Number" : "Email"}
          </button>
        </p>
      </div>
      <Footer />
    </div>
  );
}
