import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchCheckoutUrl as fetchCheckoutUrlService,
  fetchNFTProducts as fetchNFTProductsService,
  fetchCheckoutSessions as fetchCheckoutSessionsService,
  redeemNFT as redeemNFTService,
} from "../../services/paymentsService";
import { CheckoutSession, StripeNFTProduct } from "../../types/payments";
import { ErrorMessages } from "../../types/redux/slice";
import { UserRestricted } from "../../types/user";

interface PaymentsState {
  pendingFetchCheckoutUrl: boolean;
  errorMessagesFetchCheckoutUrl: ErrorMessages;
  pendingFetchNFTProducts: boolean;
  errorMessagesFetchNFTProducts: ErrorMessages;
  pendingFetchCheckoutSessions: boolean;
  errorMessagesFetchCheckoutSessions: ErrorMessages;
  pendingRedeemNFT: boolean;
  errorMessagesRedeemNFT: ErrorMessages;
  url?: string;
  nftProducts: Array<StripeNFTProduct>;
  checkoutSessions: Array<CheckoutSession>;
}

const initialState: PaymentsState = {
  pendingFetchCheckoutUrl: false,
  errorMessagesFetchCheckoutUrl: {},
  pendingFetchNFTProducts: false,
  errorMessagesFetchNFTProducts: {},
  pendingFetchCheckoutSessions: false,
  errorMessagesFetchCheckoutSessions: {},
  pendingRedeemNFT: false,
  errorMessagesRedeemNFT: {},
  url: undefined,
  nftProducts: [],
  checkoutSessions: [],
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCheckoutUrl.pending, (state, action) => {
      state.pendingFetchCheckoutUrl = true;
      state.errorMessagesFetchCheckoutUrl = {};
      state.url = undefined;
    });
    builder.addCase(fetchCheckoutUrl.fulfilled, (state, action) => {
      state.pendingFetchCheckoutUrl = false;
      state.errorMessagesFetchCheckoutUrl = {};
      state.url = action.payload;
    });
    builder.addCase(fetchCheckoutUrl.rejected, (state, action) => {
      state.pendingFetchCheckoutUrl = false;
      state.errorMessagesFetchCheckoutUrl = action.payload || {};
      state.url = undefined;
    });

    builder.addCase(fetchNFTProducts.pending, (state, action) => {
      state.pendingFetchNFTProducts = true;
      state.errorMessagesFetchNFTProducts = {};
      state.nftProducts = [];
    });
    builder.addCase(fetchNFTProducts.fulfilled, (state, action) => {
      state.pendingFetchNFTProducts = false;
      state.errorMessagesFetchNFTProducts = {};
      state.nftProducts = action.payload;
    });
    builder.addCase(fetchNFTProducts.rejected, (state, action) => {
      state.pendingFetchNFTProducts = false;
      state.errorMessagesFetchNFTProducts = action.payload || {};
      state.nftProducts = [];
    });

    builder.addCase(fetchCheckoutSessions.pending, (state, action) => {
      state.pendingFetchCheckoutSessions = true;
      state.errorMessagesFetchCheckoutSessions = {};
      state.nftProducts = [];
    });
    builder.addCase(fetchCheckoutSessions.fulfilled, (state, action) => {
      state.pendingFetchCheckoutSessions = false;
      state.errorMessagesFetchCheckoutSessions = {};
      state.checkoutSessions = action.payload;
    });
    builder.addCase(fetchCheckoutSessions.rejected, (state, action) => {
      state.pendingFetchCheckoutSessions = false;
      state.errorMessagesFetchCheckoutSessions = action.payload || {};
      state.checkoutSessions = [];
    });

    builder.addCase(redeemNFT.pending, (state, action) => {
      state.pendingRedeemNFT = true;
      state.errorMessagesRedeemNFT = {};
      state.nftProducts = [];
    });
    builder.addCase(redeemNFT.fulfilled, (state, action) => {
      state.pendingRedeemNFT = false;
      state.errorMessagesRedeemNFT = {};
    });
    builder.addCase(redeemNFT.rejected, (state, action) => {
      state.pendingRedeemNFT = false;
      state.errorMessagesRedeemNFT = action.payload || {};
    });
  },
});

export const fetchCheckoutUrl = createAsyncThunk<
  string,
  { priceId: string; walletAddress: string },
  { rejectValue: ErrorMessages }
>("payments/createCheckoutSession", async (data, thunkApi) => {
  try {
    const response = await fetchCheckoutUrlService(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const fetchNFTProducts = createAsyncThunk<
  Array<StripeNFTProduct>,
  undefined,
  { rejectValue: ErrorMessages }
>("payments/fetchNFTProducts", async (data, thunkApi) => {
  try {
    const response = await fetchNFTProductsService();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const fetchCheckoutSessions = createAsyncThunk<
  Array<CheckoutSession>,
  undefined,
  { rejectValue: ErrorMessages }
>("payments/fetchCheckoutSessions", async (data, thunkApi) => {
  try {
    const response = await fetchCheckoutSessionsService();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const redeemNFT = createAsyncThunk<
  Array<CheckoutSession>,
  CheckoutSession["id"],
  { rejectValue: ErrorMessages }
>("payments/redeemNFT", async (id, thunkApi) => {
  try {
    const response = await redeemNFTService(id);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
