import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import * as feedService from "../../services/feedService";
import { QueryParams } from "../../types/api";
import { DataSet } from "../../types/dataset";
import {
  FEED_ITEM_TYPES,
  FeedItem,
  FeedItemActions,
  FeedItemActionsPayload,
  FeedItemType,
  UpdateFeedItemPayload,
} from "../../types/feed";
import { ErrorMessages } from "../../types/redux/slice";

interface FeedItemsState {
  feedFilterDataSet: DataSet | null;
  feedItem: FeedItem | null;
  feedItems: Array<FeedItem>;
  searchedFeedItems: Array<FeedItem>;
  likedFeedItems: Array<FeedItem>;
  dislikedFeedItems: Array<FeedItem>;
  errorMessages: ErrorMessages;
  pendingGetRandomFeedItems: boolean;
  pendingGetFeedItem: boolean;
  pendingUpdateFeedItem: boolean;
  pendingSearchForFeedItems: boolean;
  pendingAddFeedItem: boolean;

  // Values related to MobileFeed <-> Navbars communication
  pendingLikeFeedItemMobileFeed: boolean;
  pendingDislikeFeedItemMobileFeed: boolean;
  pendingSkipFeedItemMobileFeed: boolean;
  pendingWatchFeedItemMobileFeed: boolean;
  showLikePopup: boolean;
  showDislikePopup: boolean;
  expandFeedMenu: boolean;
  showPlaylistsDrawer: boolean;
  currFeedItemIdx: number;
}

const initialState: FeedItemsState = {
  feedFilterDataSet: null,
  feedItem: null,
  feedItems: [],
  searchedFeedItems: [],
  likedFeedItems: [],
  dislikedFeedItems: [],
  errorMessages: {},
  pendingGetRandomFeedItems: false,
  pendingGetFeedItem: false,
  pendingUpdateFeedItem: false,
  pendingSearchForFeedItems: false,
  pendingAddFeedItem: false,

  // Values related to MobileFeed <-> Navbars communication
  pendingLikeFeedItemMobileFeed: false,
  pendingDislikeFeedItemMobileFeed: false,
  pendingSkipFeedItemMobileFeed: false,
  pendingWatchFeedItemMobileFeed: false,
  showLikePopup: false,
  showDislikePopup: false,
  expandFeedMenu: false,
  showPlaylistsDrawer: false,
  currFeedItemIdx: 0,
};

export const feedItemsSlice = createSlice({
  name: "feedItems",
  initialState,
  reducers: {
    clearFeedItems(state) {
      state.feedItems = [];
    },
    setFeedItems(state, action) {
      state.feedItems = action.payload;
    },
    clearSearchedFeedItems(state) {
      state.searchedFeedItems = [];
    },
    clearErrorMessages(state) {
      state.errorMessages = {};
    },
    setShowLikePopup(state, action) {
      state.showLikePopup = action.payload;
    },
    setShowDislikePopup(state, action) {
      state.showDislikePopup = action.payload;
    },
    setFeedItem(state, action) {
      state.feedItem = action.payload;
    },
    setExpandFeedMenu(state, action) {
      state.expandFeedMenu = action.payload;
    },
    setShowPlaylistsDrawer(state, action) {
      state.showPlaylistsDrawer = action.payload;
    },
    setCurrFeedItemIdx(state, action) {
      const newIdx = action.payload;
      if (newIdx < state.feedItems.length) {
        state.currFeedItemIdx = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLikedFeedItems.fulfilled, (state, action) => {
      state.likedFeedItems = action.payload;
      state.errorMessages = {};
    });

    builder.addCase(getDislikedFeedItems.fulfilled, (state, action) => {
      state.dislikedFeedItems = action.payload;
      state.errorMessages = {};
    });

    builder.addCase(getRandomFeedItems.fulfilled, (state, action) => {
      // Due to MobileFeed implementation, feedItems are set in the component
      // state.feedItems = action.payload;
      state.pendingGetRandomFeedItems = false;
      state.errorMessages = {};
    });
    builder.addCase(getRandomFeedItems.pending, (state, action) => {
      state.pendingGetRandomFeedItems = true;
    });
    builder.addCase(getRandomFeedItems.rejected, (state, action) => {
      state.pendingGetRandomFeedItems = false;
      state.errorMessages = action?.payload || {};
    });

    builder.addCase(searchForFeedItems.fulfilled, (state, action) => {
      state.searchedFeedItems = action.payload;
      state.pendingSearchForFeedItems = false;
      state.errorMessages = {};
    });
    builder.addCase(searchForFeedItems.pending, (state, action) => {
      state.searchedFeedItems = [];
      state.pendingSearchForFeedItems = true;
    });
    builder.addCase(searchForFeedItems.rejected, (state, action) => {
      state.pendingSearchForFeedItems = false;
      state.errorMessages = action?.payload || {};
    });

    builder.addCase(addFeedItem.fulfilled, (state, action) => {
      state.pendingAddFeedItem = false;
      state.errorMessages = {};
    });
    builder.addCase(addFeedItem.pending, (state, action) => {
      state.pendingAddFeedItem = true;
    });
    builder.addCase(addFeedItem.rejected, (state, action) => {
      state.pendingAddFeedItem = false;
    });

    builder.addCase(updateFeedItem.fulfilled, (state, action) => {
      state.pendingUpdateFeedItem = false;
      state.errorMessages = {};
    });
    builder.addCase(updateFeedItem.pending, (state, action) => {
      state.pendingUpdateFeedItem = true;
    });
    builder.addCase(updateFeedItem.rejected, (state, action) => {
      state.pendingUpdateFeedItem = false;
    });

    builder.addCase(getFeedItem.fulfilled, (state, action) => {
      state.feedItem = action.payload;
      state.pendingGetFeedItem = false;
      state.errorMessages = {};
    });
    builder.addCase(getFeedItem.pending, (state, action) => {
      state.pendingGetFeedItem = true;
    });

    builder.addMatcher(
      isAnyOf(getRandomFeedItem.fulfilled),
      (state, action) => {
        state.feedItem = action.payload;
        state.errorMessages = {};
      },
    );

    builder.addMatcher(
      isAnyOf(
        getFeedItem.rejected,
        getRandomFeedItem.rejected,
        getRandomFeedItems.rejected,
        getLikedFeedItems.rejected,
        getDislikedFeedItems.rejected,
        likeFeedItem.rejected,
        dislikeFeedItem.rejected,
        skipFeedItem.rejected,
        watchFeedItem.rejected,
      ),
      (state, action) => {
        state.errorMessages = action?.payload || {};
      },
    );
  },
});

export const getFeedItem = createAsyncThunk<
  FeedItem,
  { id: string },
  { rejectValue: ErrorMessages }
>("feedItems/getFeedItem", async ({ id }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItem(id);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getRandomFeedItems = createAsyncThunk<
  Array<FeedItem>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedItems/getRandomFeedItems", async (data, thunkApi) => {
  try {
    const response = await feedService.retrieveRandomFeedItems(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const searchForFeedItems = createAsyncThunk<
  Array<FeedItem>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedItems/searchForFeedItems", async (data, thunkApi) => {
  try {
    const response = await feedService.searchForFeedItems(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getRandomFeedItem = createAsyncThunk<
  FeedItem | null,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedItems/getRandomFeedItem", async (data, thunkApi) => {
  try {
    const response = await feedService.retrieveRandomFeedItems(data);
    if (response.length > 0) {
      return response[0];
    }
    return null;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const updateFeedItem = createAsyncThunk<
  FeedItem | null,
  { id: string; data: UpdateFeedItemPayload },
  { rejectValue: ErrorMessages }
>("feedItems/updateFeedItem", async ({ id, data }, thunkApi) => {
  try {
    const response = await feedService.updateFeedItem(id, data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getLikedFeedItems = createAsyncThunk<
  FeedItem[],
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedItems/getLikedFeedItems", async (data, thunkApi) => {
  try {
    const response = await feedService.retrieveLikedFeedItems(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const getDislikedFeedItems = createAsyncThunk<
  FeedItem[],
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedItems/getDislikedFeedItems", async (data, thunkApi) => {
  try {
    const response = await feedService.retrieveDislikedFeedItems(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const likeFeedItem = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/likeFeedItem", async (id, thunkApi) => {
  try {
    await feedService.likeFeedItem(id);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const dislikeFeedItem = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/dislikeFeedItem", async (id, thunkApi) => {
  try {
    await feedService.dislikeFeedItem(id);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const skipFeedItem = createAsyncThunk<
  FeedItem,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/skipFeedItem", async (id, thunkApi) => {
  try {
    return await feedService.skipFeedItem(id);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const watchFeedItem = createAsyncThunk<
  FeedItem,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/watchFeedItem", async (id, thunkApi) => {
  try {
    return await feedService.watchFeedItem(id);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const addFeedItem = createAsyncThunk<
  FeedItem,
  {
    youtube_video_id?: string;
    spotify_track_id?: string;
    bing_news_url?: string;
  },
  { rejectValue: ErrorMessages }
>("feedItems/addFeedItem", async (data, thunkApi) => {
  try {
    return await feedService.addFeedItem(data);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const increaseWatchedFor = createAsyncThunk<
  FeedItemActions,
  FeedItemActionsPayload,
  { rejectValue: ErrorMessages }
>("feedItems/increaseWatchedFor", async (data, thunkApi) => {
  try {
    return await feedService.increaseWatchedFor(data);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const likeFeedItemMobileFeed = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/likeFeedItemMobileFeed", async (id, thunkApi) => {
  try {
    await feedService.likeFeedItem(id);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const dislikeFeedItemMobileFeed = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/dislikeFeedItemMobileFeed", async (id, thunkApi) => {
  try {
    await feedService.dislikeFeedItem(id);
    return true;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const skipFeedItemMobileFeed = createAsyncThunk<
  FeedItem,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/skipFeedItemMobileFeed", async (id, thunkApi) => {
  try {
    return await feedService.skipFeedItem(id);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const watchFeedItemMobileFeed = createAsyncThunk<
  FeedItem,
  string,
  { rejectValue: ErrorMessages }
>("feedItems/watchFeedItemMobileFeed", async (id, thunkApi) => {
  try {
    return await feedService.watchFeedItem(id);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const {
  clearFeedItems,
  setFeedItems,
  clearSearchedFeedItems,
  clearErrorMessages,
  setShowLikePopup,
  setShowDislikePopup,
  setFeedItem,
  setExpandFeedMenu,
  setShowPlaylistsDrawer,
  setCurrFeedItemIdx,
} = feedItemsSlice.actions;
