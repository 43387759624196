import React from "react";
import { TrashIcon } from "@heroicons/react/24/solid";

interface Props {
  onDelete: () => void;
}

/**
 * A trash icon button.
 */
export default function DeleteButton({ onDelete }: Props) {
  return (
    <button
      className="ml-auto p-2 hover:bg-gray-200 dark:hover:bg-gray-700 
        rounded-full transition-colors duration-200"
      onClick={onDelete}
      aria-label="Delete file"
    >
      <TrashIcon className="w-5 h-5 text-red-600 dark:text-red-500" />
    </button>
  );
}
