import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full text-right pr-6 pb-6 pt-6 mt-4 text-sm text-gray-500">
      © {currentYear} Braincargo • All rights reserved
    </footer>
  );
};

export default Footer;
