import React, { useEffect } from "react";
import {
  retrieveUsersDataset,
  retrieveVideoDataset,
} from "redux/feed/feedDatasetSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { APP_URLS } from "navigation";
import { updateFeedItem } from "redux/feed/feedItemsSlice";
import { isDispatchResponseError } from "redux/utils";
import FeedItemDatasetInfo from "../common/FeedItemData";

interface Props {
  videoId: string;
}

export default function FeedDataVideoDetails({ videoId }: Props) {
  const dispatch = useAppDispatch();
  const video = useAppSelector((state) => state.feedDataset.video);
  const pending = useAppSelector(
    (state) => state.feedDataset.retrieveVideoDatasetPending
  );

  function onClickRow(itemId: string) {
    window.location.href = APP_URLS.FEED_DATA_USER_DETAILS.replace(
      ":userId",
      itemId
    );
  }

  function fetchData() {
    if (videoId) {
      dispatch(retrieveVideoDataset({ videoId }));
    }
  }

  async function onHideFeedItem() {
    if (video) {
      const response = await dispatch(
        updateFeedItem({ id: video.id, data: { hidden: !video.hidden } })
      );
      if (!isDispatchResponseError(response)) {
        fetchData();
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [videoId]);

  return (
    <div className="space-y-8 p-6">
      {/* Video Information */}
      <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 overflow-hidden">
        <FeedItemDatasetInfo
          feedItemDataset={video}
          pending={pending}
          onHideFeedItem={onHideFeedItem}
        />
      </div>

      {/* Users Table */}
      <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 p-6">
        <FeedDataTable
          headerText="Users who interacted with this video"
          defaultOrdering="email"
          paginatedList={useAppSelector((state) => state.feedDataset.users)}
          fetchThunkFunction={retrieveUsersDataset}
          pending={useAppSelector(
            (state) => state.feedDataset.retrieveUsersDatasetPending
          )}
          labels={{
            email: "Email",
            username: "Username",
            liked: "Liked",
            disliked: "Disliked",
            watched: "Watched",
            skipped: "Skipped",
          }}
          additionalQueryParams={{ video_interacted_with: videoId }}
          onClickRow={onClickRow}
        />
      </div>
    </div>
  );
}
