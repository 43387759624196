import React, { useEffect, useState } from "react";
import { Label, Select, TextInput } from "flowbite-react";
import FileUpload from "../../components/common/file/FileUpload";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { displayErrors } from "../../helpers/errors";
import { addNeuralNetwork } from "../../redux/neuralNetworks/neuralNetworksSlice";
import { isDispatchResponseError } from "../../redux/utils";
import { NeuralNetworkCategory } from "../../types/neuralNetwork";
import Modal from "../../components/common/modal";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  show: boolean;
  onClose: () => void;
}

/**
 * Popup form for uploading a new Neural Network.
 */
export default function UploadNeuralNetworkFormPopup({ show, onClose }: Props) {
  const dispatch = useAppDispatch();
  const neuralNetworkCategories = useAppSelector(
    (state) => state.neuralNetworks.neuralNetworkCategories
  );
  const errorMessages = useAppSelector(
    (state) => state.neuralNetworks.errorMessages
  );
  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const pendingAddNeuralNetwork = useAppSelector(
    (state) => state.neuralNetworks.pendingAddNeuralNetwork
  );

  /**
   * Render the categories as options for the Select component.
   */
  const renderCategories = () => {
    return neuralNetworkCategories.map(
      (category: NeuralNetworkCategory, idx: number) => (
        <option key={idx} value={category.id}>
          {category.name}
        </option>
      )
    );
  };

  /**
   * Submit the form.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Prepare form data
    const form_data = new FormData();
    form_data.append("name", name);
    form_data.append("category", category);
    if (file) {
      form_data.append("file", file, file.name);
    }

    // Submit Neural Network Create request
    const response = await dispatch(addNeuralNetwork(form_data));

    // Close the popup if Neural Network is successfully created
    if (!isDispatchResponseError(response)) {
      setName("");
      setCategory("");
      setFile(null);
      onClose();
    }
  };

  /**
   * Determine if the submit button should be disabled.
   */
  function isDisabled() {
    return !name || !category || !file;
  }

  /**
   * Pre-select the first category when they are loaded.
   */
  useEffect(() => {
    if (neuralNetworkCategories.length > 0) {
      setCategory(neuralNetworkCategories[0].id);
    }
  }, [neuralNetworkCategories]);

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 
                    transition-all duration-200 ${
                      show ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
    >
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-gray-900/60 dark:bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div
        className="relative w-full max-w-md bg-white dark:bg-[#1e2533] 
                    rounded-2xl shadow-xl transform transition-all duration-200 
                    border border-gray-200 dark:border-white/5"
      >
        {/* Header */}
        <div
          className="flex items-center justify-between p-6 
                     border-b border-gray-200 dark:border-white/5"
        >
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Add a new Neural Network
          </h3>
          <button
            onClick={onClose}
            className="p-2 rounded-lg text-gray-500 hover:text-gray-700 
                     dark:text-gray-400 dark:hover:text-gray-300
                     hover:bg-gray-100 dark:hover:bg-white/5 
                     transition-all duration-200"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Form Body */}
        <div className="p-6 space-y-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Name Input */}
            <div>
              <Label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Neural Network Name
              </Label>
              <TextInput
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="My Convolutional Neural Network"
                className="w-full bg-gray-50 dark:bg-white/5 
                         border-gray-300 dark:border-white/10 
                         text-gray-900 dark:text-white
                         focus:ring-blue-500 focus:border-blue-500 
                         dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {displayErrors(errorMessages.name)}
            </div>

            {/* Category Select */}
            <div>
              <Label
                htmlFor="category"
                className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Category
              </Label>
              <Select
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="w-full bg-gray-50 dark:bg-white/5 
                         border-gray-300 dark:border-white/10 
                         text-gray-900 dark:text-white"
              >
                {renderCategories()}
              </Select>
              {displayErrors(errorMessages.category)}
            </div>

            {/* File Upload */}
            <div>
              <Label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200">
                Upload Neural Network file
              </Label>
              <FileUpload
                file={file}
                setFile={setFile}
                inputId="neural-network-file"
                maxSize="20 MB"
              />
              {displayErrors(errorMessages.file)}
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 p-6 border-t border-gray-200 dark:border-white/5">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200
                     bg-gray-100 dark:bg-white/5 rounded-lg 
                     hover:bg-gray-200 dark:hover:bg-white/10
                     transition-all duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isDisabled() || pendingAddNeuralNetwork}
            className="px-4 py-2 text-sm font-medium text-white
                     bg-blue-600 rounded-lg hover:bg-blue-700
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition-all duration-200
                     flex items-center gap-2"
          >
            {pendingAddNeuralNetwork ? (
              <>
                <span className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                Uploading...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
