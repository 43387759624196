import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "../Search/Search";
import { Bell } from "../icons/Bell";
import "./MainHeader.css";
import { useAppSelector } from "../../redux/hooks";
import { removeTokens } from "../../services/localStorage";
import { useLogout } from "@account-kit/react";
import { APP_URLS } from "../../navigation";
import { history } from "../../helpers/history";
import { Button, Modal } from "flowbite-react";
import axios from "axios";
import { getRefreshToken } from "services/localStorage";
import { post } from "services/apiService";

interface MainHeaderProps {
  title?: string;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  title = "Dashboard",
}) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const notificationCount = 9;

  const user = useAppSelector((state) => state.user.user);
  const { logout } = useLogout();

  const blacklistRefreshToken = async () => {
    try {
      const refreshToken = getRefreshToken();
      console.log("blacklisting refresh token", refreshToken);
      await post("/api/register/blacklist/token/", {
        refresh_token: refreshToken,
      });
    } catch (error) {
      console.error("Error blacklisting refresh token:", error);
    }
  };

  const handleConfirmLogout = async () => {
    try {
      console.log("blacklisting refresh token");
      await blacklistRefreshToken();
      localStorage.removeItem("lastLoginTime");
      logout();
      removeTokens();
      history.navigate(APP_URLS.LOGIN);
    } finally {
      setShowLogoutModal(false);
    }
  };
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <Modal
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        popup
        size="sm"
        className="bg-gray-700"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-300">
              Are you sure you want to logout?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => handleConfirmLogout()}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={() => setShowLogoutModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <header className="app-header">
        <div className="header-content">
          <div className="header-left flex items-center gap-6">
            <div className="flex items-center gap-2 mr-6">
              <img
                src="https://braincoin.com/assets/Gold-Brain-Coin-DL9cS1jd.png"
                alt="BrainCargo Logo"
                className="h-8 w-auto"
              />
              <span className="logo-text">
                BrainCargo
              </span>
            </div>

            {/* <h3 className={`text-2xl font-bold ${isDark ? 'text-white' : 'text-gray-700'} capitalize`}>
              {title}
            </h3> */}
            <Search />
          </div>

          <div className="header-right">
            <div className="notification-wrapper">
              <button
                className={`notification-button `}
              >
                <Bell />
                {notificationCount > 0 && (
                  <span className="notification-badge">
                    {notificationCount}+
                  </span>
                )}
              </button>
            </div>

            <div className="profile-dropdown-container" ref={dropdownRef}>
              <button
                className="profile-button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span className="profile-initial">
                  {user?.username.charAt(0)}
                </span>
              </button>

              {isDropdownOpen && (
                <div className="profile-dropdown">
                  <div className="dropdown-header">
                    <div className="profile-info">
                      <div className="profile-avatar">
                        <span className="profile-initial">
                          {user?.username.charAt(0)}
                        </span>
                      </div>
                      <div className="profile-details">
                        <span className="profile-name">{user?.username}</span>
                        <span className="profile-email">{user?.email}</span>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-menu">
                    <button
                      className="dropdown-item"
                      onClick={() => navigate("/profile")}
                    >
                      <svg
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Profile
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => navigate("/settings")}
                    >
                      <svg
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Settings
                    </button>
                    <div className="dropdown-divider" />
                    <button
                      className="dropdown-item logout"
                      onClick={() => setShowLogoutModal(true)}
                    >
                      <svg
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M16 13v-2H7V8l-5 4 5 4v-3z" />
                        <path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z" />
                      </svg>
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default MainHeader;
