import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import ReportListTable from "components/report/ReportListTable";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Reports list page.
 */
export default function Reports() {
  useEffect(() => {
    document.title = "Reports | BrainCargo";

    // Add dark mode class based on system preference
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.documentElement.classList.add("dark");
    }

    // Listen for system theme changes
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const handleThemeChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    };

    darkModeMediaQuery.addEventListener("change", handleThemeChange);
    return () =>
      darkModeMediaQuery.removeEventListener("change", handleThemeChange);
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col dark:bg-gradient-to-b dark:from-[#1a1f2b] dark:to-[#151923]">
        {/* Header Section */}
        <div className="sticky top-0 z-50 dark:bg-[#1e2533]/80 border-b dark:border-white/5">
          <div className="max-w-[1400px] mx-auto">
            <MainHeader title="Reports" />
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 px-4 py-6 md:px-6 lg:px-8 pt-16">
          <div className="max-w-[1400px] mx-auto">
            {/* Reports Card */}
            <div
              className="bg-white dark:bg-[#1e2533]/80 rounded-xl border dark:border-white/5 
                          shadow-lg dark:shadow-black/20 backdrop-blur-sm 
                          transition-all duration-300"
            >
              {/* Card Header */}
              <div className="p-4 md:p-6 border-b dark:border-white/5">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Reports Overview
                </h2>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Manage and review reported content across the platform
                </p>
              </div>

              {/* Reports Table */}
              <div className="p-4 md:p-6">
                <ReportListTable />
              </div>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <Footer />
      </div>
    </PageWrapper>
  );
}
