import React, { useEffect } from "react";
import PageWrapper from "components/common/pageWrapper";
import Header from "components/common/header";
import { useParams } from "react-router-dom";
import FeedDataTrackDetailsComponent from "components/admin/feedData/details/FeedDataTrackDetails";
import MainHeader from "components/MainHeader/MainHeader";
import Footer from "components/Footer/Footer";

/**
 * Feed data track details page.
 */
export default function FeedDataTrackDetails() {
  const { trackId } = useParams();

  useEffect(() => {
    document.title = "Feed Data | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen flex flex-col bg-[#1a1f2b] pt-10">
        {/* Header */}
        <div className="bg-[#1e2533] border-b border-gray-700/50">
          <MainHeader title="Track Details" />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="max-w-[1400px] mx-auto">
            <div className="bg-[#1e2533] rounded-lg border border-gray-700/50">
              <FeedDataTrackDetailsComponent trackId={trackId || ""} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </PageWrapper>
  );
}
