import React, { useEffect } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import { getIsSmScreen } from "../../helpers/responsiveness";
import { APP_URLS } from "../../navigation";
import PlaylistDetails from "../../components/playlist/mobile/PlaylistDetails";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getPlaylist } from "../../redux/playlist/playlistSlice";
import { useParams } from "react-router-dom";

/**
 * Playlist Details page
 */
export default function PlaylistDetailsMobile() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const playlist = useAppSelector((state) => state.playlist.playlist);

  useEffect(() => {
    document.title = "Playlist | BrainCargo";
  }, []);

  /**
   * Fetch playlist data
   */
  useEffect(() => {
    if (params.id) {
      dispatch(getPlaylist({ id: params.id }));
    }
  }, [params.id]);

  if (!getIsSmScreen()) {
    // Force the user to desktop-compatible videos view
    window.location.href = APP_URLS.PLAYLISTS_DESKTOP;
  }

  return (
    <PageWrapper>
      <>
        <div className="px-8 py-4" aria-label="Playlist Details Container">
          <PlaylistDetails playlist={playlist} />
        </div>
      </>
    </PageWrapper>
  );
}
