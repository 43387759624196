// TermsOfService.tsx

import Footer from "../../components/Footer/Footer";
import React from "react";

const TermsOfService: React.FC = () => {
  return (
    <div style={styles.container}>
      <img
        src="https://braincoin.com/assets/Gold-Brain-Coin-DL9cS1jd.png"
        alt="BrainCargo Logo"
        style={{ width: "100px" }}
      />
      <br />
      <h1 style={styles.title}>Terms of Service</h1>
      <p>
        <strong>Effective Date:</strong> 10/31/2024
      </p>
      <p>
        <strong>Last Updated:</strong> 10/31/2024
      </p>
      <p>
        Welcome to BrainCargo (<strong>"we"</strong>, <strong>"us"</strong>, or{" "}
        <strong>"our"</strong>). These Terms of Service (
        <strong>"Terms"</strong>) govern your access to and use of our platform,
        services, and websites, including{" "}
        <a href="https://www.braincargo.com" style={styles.link}>
          www.braincargo.com
        </a>{" "}
        and{" "}
        <a href="https://www.braincoin.com" style={styles.link}>
          www.braincoin.com
        </a>{" "}
        (collectively, the <strong>"Platform"</strong>). By accessing or using
        our Platform, you agree to be bound by these Terms. If you do not agree
        with any part of these Terms, please do not use our Platform.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>1. Acceptance of Terms</h2>
      <p>
        By registering for, accessing, browsing, or using the Platform, you
        acknowledge that you have read, understood, and agree to be bound by
        these Terms and our{" "}
        <a href="#" style={styles.link}>
          Privacy Policy
        </a>
        , which is incorporated herein by reference.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>2. Eligibility</h2>
      <p>
        You must be at least 13 years of age to use our Platform. By using the
        Platform, you represent and warrant that you meet this age requirement
        and have the legal capacity to enter into these Terms.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>3. Account Registration</h2>
      <h3 style={styles.subheading}>a. Account Creation</h3>
      <p>
        To access certain features of the Platform, you must create an account.
        You agree to:
      </p>
      <ul style={styles.list}>
        <li>
          Provide accurate, current, and complete information during the
          registration process.
        </li>
        <li>Maintain and promptly update your account information.</li>
        <li>Keep your password confidential and secure.</li>
        <li>Notify us immediately of any unauthorized use of your account.</li>
      </ul>

      <h3 style={styles.subheading}>b. Account Responsibility</h3>
      <p>
        You are responsible for all activities that occur under your account. We
        are not liable for any loss or damage arising from your failure to
        comply with these obligations.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>4. User Conduct</h2>
      <p>
        You agree not to engage in any of the following prohibited activities:
      </p>
      <ul style={styles.list}>
        <li>Violating any applicable laws or regulations.</li>
        <li>
          Infringing upon the rights of others, including intellectual property
          rights.
        </li>
        <li>Uploading or distributing any viruses or malicious code.</li>
        <li>Interfering with the security or operation of the Platform.</li>
        <li>
          Attempting to gain unauthorized access to other users' accounts or our
          systems.
        </li>
        <li>
          Engaging in any form of harassment, discrimination, or abusive
          behavior.
        </li>
      </ul>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>5. Intellectual Property Rights</h2>
      <h3 style={styles.subheading}>a. Ownership</h3>
      <p>
        All content, features, and functionality on the Platform, including but
        not limited to text, graphics, logos, and software, are owned by
        BrainCargo or its licensors and are protected by intellectual property
        laws.
      </p>

      <h3 style={styles.subheading}>b. Limited License</h3>
      <p>
        We grant you a limited, non-exclusive, non-transferable license to
        access and use the Platform for personal, non-commercial purposes,
        subject to these Terms.
      </p>

      <h3 style={styles.subheading}>c. Restrictions</h3>
      <p>You agree not to:</p>
      <ul style={styles.list}>
        <li>Modify, copy, or create derivative works based on the Platform.</li>
        <li>
          Reverse engineer or attempt to extract the source code of the
          software.
        </li>
        <li>
          Use the Platform for any commercial purposes without our express
          written consent.
        </li>
      </ul>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>6. User Content</h2>
      <h3 style={styles.subheading}>a. Ownership of Your Content</h3>
      <p>
        You retain ownership of any content, data, or AI models you upload or
        generate on the Platform (<strong>"User Content"</strong>). By
        submitting User Content, you grant us a worldwide, non-exclusive,
        royalty-free license to use, reproduce, and display your User Content
        solely for the purpose of operating and improving the Platform.
      </p>

      <h3 style={styles.subheading}>b. Responsibility for User Content</h3>
      <p>
        You are solely responsible for your User Content. You represent and
        warrant that:
      </p>
      <ul style={styles.list}>
        <li>You have all necessary rights to submit the User Content.</li>
        <li>
          Your User Content does not violate any laws or infringe upon any
          third-party rights.
        </li>
      </ul>

      <h3 style={styles.subheading}>c. Data Privacy</h3>
      <p>
        All User Content is subject to our{" "}
        <a href="#" style={styles.link}>
          Privacy Policy
        </a>
        . We implement advanced encryption and security measures to protect your
        data, but we cannot guarantee absolute security.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>7. Transactions and BrainCoin</h2>
      <h3 style={styles.subheading}>a. BrainCoin Usage</h3>
      <p>
        BrainCoin is the native cryptocurrency used on the Platform. You may
        earn, purchase, or spend BrainCoin in accordance with our Tokenomics
        model.
      </p>

      <h3 style={styles.subheading}>b. Transactions</h3>
      <p>
        All transactions involving BrainCoin are final and non-refundable. You
        are responsible for any taxes or fees associated with your use of
        BrainCoin.
      </p>

      <h3 style={styles.subheading}>c. Compliance</h3>
      <p>
        You agree to comply with all applicable laws and regulations related to
        cryptocurrency transactions, including reporting obligations.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>8. Third-Party Services</h2>
      <p>
        Our Platform may contain links to third-party websites or services. We
        do not endorse or assume responsibility for any third-party content or
        services. Your interactions with third parties are solely between you
        and the third party.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>9. Termination</h2>
      <h3 style={styles.subheading}>a. By You</h3>
      <p>
        You may terminate your account at any time by contacting us at{" "}
        <a href="mailto:support@braincargo.com" style={styles.link}>
          support@braincargo.com
        </a>
        .
      </p>

      <h3 style={styles.subheading}>b. By Us</h3>
      <p>
        We reserve the right to suspend or terminate your account or access to
        the Platform at our discretion, without notice, for any violation of
        these Terms or for any other reason.
      </p>

      <h3 style={styles.subheading}>c. Effect of Termination</h3>
      <p>
        Upon termination, your right to use the Platform will immediately cease.
        Sections that by their nature should survive termination shall continue
        in effect.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>10. Disclaimers</h2>
      <h3 style={styles.subheading}>a. No Warranty</h3>
      <p>
        The Platform is provided on an "<strong>AS IS</strong>" and "
        <strong>AS AVAILABLE</strong>" basis. We disclaim all warranties,
        express or implied, including but not limited to merchantability,
        fitness for a particular purpose, and non-infringement.
      </p>

      <h3 style={styles.subheading}>b. No Guarantee</h3>
      <p>We do not guarantee:</p>
      <ul style={styles.list}>
        <li>
          The accuracy, completeness, or usefulness of any content on the
          Platform.
        </li>
        <li>That the Platform will be uninterrupted, secure, or error-free.</li>
        <li>That any defects will be corrected.</li>
      </ul>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>11. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, BrainCargo shall not be liable
        for any indirect, incidental, special, consequential, or punitive
        damages, including but not limited to loss of profits, data, or use,
        arising out of or in connection with your use of the Platform.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>12. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless BrainCargo, its
        affiliates, officers, directors, employees, and agents from any claims,
        liabilities, damages, losses, or expenses arising out of your use of the
        Platform or violation of these Terms.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>13. Governing Law and Dispute Resolution</h2>
      <h3 style={styles.subheading}>a. Governing Law</h3>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of Wyoming, without regard to its conflict of law principles.
      </p>

      <h3 style={styles.subheading}>b. Arbitration</h3>
      <p>
        Any disputes arising out of or relating to these Terms or the Platform
        shall be resolved through binding arbitration in accordance with the
        rules of American Arbitration Association.
      </p>

      <h3 style={styles.subheading}>c. Class Action Waiver</h3>
      <p>
        You agree to resolve disputes on an individual basis and waive any right
        to participate in a class action lawsuit or class-wide arbitration.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>14. Changes to the Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Any changes will
        be effective immediately upon posting the updated Terms on the Platform.
        Your continued use of the Platform constitutes acceptance of the revised
        Terms.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>15. Miscellaneous</h2>
      <h3 style={styles.subheading}>a. Entire Agreement</h3>
      <p>
        These Terms and our{" "}
        <a href="#" style={styles.link}>
          Privacy Policy
        </a>{" "}
        constitute the entire agreement between you and BrainCargo regarding the
        Platform.
      </p>

      <h3 style={styles.subheading}>b. Severability</h3>
      <p>
        If any provision of these Terms is held to be invalid or unenforceable,
        the remaining provisions shall remain in full force and effect.
      </p>

      <h3 style={styles.subheading}>c. Waiver</h3>
      <p>
        Our failure to enforce any right or provision of these Terms shall not
        be deemed a waiver of such right or provision.
      </p>

      <h3 style={styles.subheading}>d. Assignment</h3>
      <p>
        You may not assign your rights or obligations under these Terms without
        our prior written consent. We may assign our rights and obligations
        without restriction.
      </p>

      <hr style={styles.hr} />

      <h2 style={styles.heading}>16. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact
        us at:
      </p>
      <ul style={styles.list}>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:support@braincargo.com" style={styles.link}>
            support@braincargo.com
          </a>
        </li>
        <li>
          <strong>Address:</strong> BrainCargo, 1309 Coffeen Avenue STE 1200
          Sheridan Wyoming 82801
        </li>
      </ul>

      <hr style={styles.hr} />

      <p>
        <strong>
          By clicking "I Agree" or by using the Platform, you acknowledge that
          you have read, understood, and agree to be bound by these Terms of
          Service.
        </strong>
      </p>

      <Footer />
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    lineHeight: 1.6,
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  } as React.CSSProperties,
  title: {
    textAlign: "center",
    color: "#00539C",
  } as React.CSSProperties,
  heading: {
    color: "#00539C",
    marginTop: "30px",
  } as React.CSSProperties,
  subheading: {
    color: "#00539C",
    marginTop: "20px",
  } as React.CSSProperties,
  link: {
    color: "#00539C",
    textDecoration: "none",
  } as React.CSSProperties,
  list: {
    paddingLeft: "20px",
  } as React.CSSProperties,
  hr: {
    margin: "30px 0",
    border: "none",
    borderTop: "1px solid #ccc",
  } as React.CSSProperties,
};

export default TermsOfService;
