import { Button, Spinner } from "flowbite-react";
import React, { ReactElement } from "react";
import NoImage from "images/No-Image.png";
import { FEED_ITEM_TYPES, FeedItemDataset } from "types/feed";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "redux/hooks";
import { generateYoutubeUrl } from "helpers/youtube";
import { generateSpotifyUrl } from "helpers/spotify";
import { FeedItemDetailsLoader } from "components/common/ShimmerLoader";

interface Props {
  feedItemDataset: FeedItemDataset | null;
  pending: boolean;
  onHideFeedItem: () => void;
}

/**
 * Displays information about a feed item.
 */
export default function FeedItemDatasetInfo({
  feedItemDataset,
  pending,
  onHideFeedItem,
}: Props) {
  const pendingUpdateFeedItem = useAppSelector(
    (state) => state.feedItems.pendingUpdateFeedItem
  );
  const user = useAppSelector((state) => state.user.user);

  function renderRow(label: string, value?: string | number | boolean) {
    let renderedValue: undefined | string | number | boolean | ReactElement =
      value;
    if (typeof value === "boolean") {
      renderedValue = value ? (
        <CheckCircleIcon className="w-6 h-6 text-green-400" aria-label="Yes" />
      ) : (
        <XCircleIcon className="w-6 h-6 text-red-400" aria-label="No" />
      );
    } else if (!value && value !== 0) {
      renderedValue = "-";
    } else if (
      typeof value === "string" &&
      (value.includes("http://") || value.includes("https://"))
    ) {
      renderedValue = (
        <a
          href={value}
          className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
          target="_blank"
          rel="noreferrer"
          aria-label={`Link to ${value}`}
        >
          {value}
        </a>
      );
    }
    return (
      <div className="flex items-center justify-between py-4 px-6 border-b border-gray-700/50">
        <div className="text-gray-400 font-medium">{label}</div>
        <div className="text-gray-200 break-all ml-8">{renderedValue}</div>
      </div>
    );
  }

  function getItemUrl(feedItemDataset: FeedItemDataset) {
    if (feedItemDataset.type === FEED_ITEM_TYPES.VIDEO) {
      return feedItemDataset.youtube_video_id
        ? generateYoutubeUrl(feedItemDataset.youtube_video_id)
        : "-";
    } else if (feedItemDataset.type === FEED_ITEM_TYPES.NEWS) {
      return feedItemDataset.bing_news_url
        ? feedItemDataset.bing_news_url
        : "-";
    } else if (feedItemDataset.type === FEED_ITEM_TYPES.TRACK) {
      return feedItemDataset.spotify_track_id
        ? generateSpotifyUrl(feedItemDataset.spotify_track_id)
        : "-";
    }
    return "-";
  }

  if (pending) {
    return <FeedItemDetailsLoader />;
  }

  if (!feedItemDataset) {
    return (
      <div className="flex justify-center" role="alert" aria-live="assertive">
        Failed to retrieve information about this feed item
      </div>
    );
  }

  return (
    <div className="w-full max-w-3xl mx-auto p-6">
      {/* Image Section */}
      <div className="flex justify-center mb-8">
        <div className="relative">
          <div
            className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-500 
                         rounded-lg opacity-20 blur-lg"
          ></div>
          <img
            className="relative h-48 w-48 object-cover rounded-lg border border-gray-700/50"
            src={feedItemDataset?.image || NoImage}
            alt="Feed item"
          />
        </div>
      </div>

      {/* Details Section */}
      <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 overflow-hidden">
        {/* {renderRow("ID:", feedItemDataset?.id)} */}
        {renderRow("Title:", feedItemDataset?.title)}
        {renderRow("Link:", getItemUrl(feedItemDataset))}
        {renderRow("Likes:", feedItemDataset?.liked_by_count)}
        {renderRow("Dislikes:", feedItemDataset?.disliked_by_count)}
        {renderRow("Watches:", feedItemDataset?.watched_by_count)}
        {renderRow("Skips:", feedItemDataset?.skipped_by_count)}

        {user?.is_superuser && (
          <div className="p-6 bg-[#1e2533]/50">
            {renderRow("Is Hidden:", feedItemDataset?.hidden)}
            <button
              onClick={onHideFeedItem}
              disabled={pendingUpdateFeedItem}
              className="w-full mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 
                       text-white rounded-lg transition-colors duration-200
                       disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {pendingUpdateFeedItem ? (
                <Spinner size="sm" className="mx-auto" />
                // <FeedItemDetailsLoader />
              ) : feedItemDataset?.hidden ? (
                "Show Feed Item"
              ) : (
                "Hide Feed Item"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
