import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as feedService from "../../services/feedService";
import * as usersService from "../../services/usersService";
import { PaginatedList, QueryParams } from "../../types/api";
import { FEED_ITEM_TYPES, FeedItemDataset } from "../../types/feed";
import { ErrorMessages } from "../../types/redux/slice";
import { UserFeedData } from "../../types/user";

const INITIAL_PAGINATED_LIST = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

interface FeedDatasetState {
  video: FeedItemDataset | null;
  videos: PaginatedList<FeedItemDataset>;
  news: FeedItemDataset | null;
  newsItems: PaginatedList<FeedItemDataset>;
  track: FeedItemDataset | null;
  tracks: PaginatedList<FeedItemDataset>;
  user: UserFeedData | null;
  users: PaginatedList<UserFeedData>;

  retrieveVideosDatasetPending: boolean;
  retrieveVideoDatasetPending: boolean;
  retrieveNewsDatasetPending: boolean;
  retrieveNewsItemsDatasetPending: boolean;
  retrieveTrackDatasetPending: boolean;
  retrieveTracksDatasetPending: boolean;
  retrieveUserDatasetPending: boolean;
  retrieveUsersDatasetPending: boolean;

  retrieveVideosDatasetErrors: ErrorMessages;
  retrieveVideoDatasetErrors: ErrorMessages;
  retrieveNewsDatasetErrors: ErrorMessages;
  retrieveNewsItemsDatasetErrors: ErrorMessages;
  retrieveTracksDatasetErrors: ErrorMessages;
  retrieveTrackDatasetErrors: ErrorMessages;
  retrieveUsersDatasetErrors: ErrorMessages;
  retrieveUserDatasetErrors: ErrorMessages;
}

const initialState: FeedDatasetState = {
  video: null,
  videos: { ...INITIAL_PAGINATED_LIST },
  news: null,
  newsItems: { ...INITIAL_PAGINATED_LIST },
  track: null,
  tracks: { ...INITIAL_PAGINATED_LIST },
  user: null,
  users: { ...INITIAL_PAGINATED_LIST },

  retrieveVideosDatasetPending: false,
  retrieveVideoDatasetPending: false,
  retrieveNewsDatasetPending: false,
  retrieveNewsItemsDatasetPending: false,
  retrieveTrackDatasetPending: false,
  retrieveTracksDatasetPending: false,
  retrieveUserDatasetPending: false,
  retrieveUsersDatasetPending: false,

  retrieveVideosDatasetErrors: {},
  retrieveVideoDatasetErrors: {},
  retrieveNewsDatasetErrors: {},
  retrieveNewsItemsDatasetErrors: {},
  retrieveTracksDatasetErrors: {},
  retrieveTrackDatasetErrors: {},
  retrieveUsersDatasetErrors: {},
  retrieveUserDatasetErrors: {},
};

export const feedDatasetSlice = createSlice({
  name: "feedDataset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveVideosDataset.fulfilled, (state, action) => {
      state.retrieveVideosDatasetPending = false;
      state.retrieveVideosDatasetErrors = {};
      state.videos = action.payload;
    });
    builder.addCase(retrieveVideosDataset.pending, (state, action) => {
      state.retrieveVideosDatasetPending = true;
    });
    builder.addCase(retrieveVideosDataset.rejected, (state, action) => {
      state.retrieveVideosDatasetPending = false;
      state.retrieveVideosDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveVideoDataset.fulfilled, (state, action) => {
      state.retrieveVideoDatasetPending = false;
      state.retrieveVideoDatasetErrors = {};
      state.video = action.payload;
    });
    builder.addCase(retrieveVideoDataset.pending, (state, action) => {
      state.retrieveVideoDatasetPending = true;
    });
    builder.addCase(retrieveVideoDataset.rejected, (state, action) => {
      state.retrieveVideoDatasetPending = false;
      state.retrieveVideoDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveTracksDataset.fulfilled, (state, action) => {
      state.retrieveTracksDatasetPending = false;
      state.retrieveTracksDatasetErrors = {};
      state.tracks = action.payload;
    });
    builder.addCase(retrieveTracksDataset.pending, (state, action) => {
      state.retrieveTracksDatasetPending = true;
    });
    builder.addCase(retrieveTracksDataset.rejected, (state, action) => {
      state.retrieveTracksDatasetPending = false;
      state.retrieveTracksDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveTrackDataset.fulfilled, (state, action) => {
      state.retrieveTrackDatasetPending = false;
      state.retrieveTrackDatasetErrors = {};
      state.track = action.payload;
    });
    builder.addCase(retrieveTrackDataset.pending, (state, action) => {
      state.retrieveTrackDatasetPending = true;
    });
    builder.addCase(retrieveTrackDataset.rejected, (state, action) => {
      state.retrieveTrackDatasetPending = false;
      state.retrieveTrackDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveNewsDataset.fulfilled, (state, action) => {
      state.retrieveNewsDatasetPending = false;
      state.retrieveNewsDatasetErrors = {};
      state.news = action.payload;
    });
    builder.addCase(retrieveNewsDataset.pending, (state, action) => {
      state.retrieveNewsDatasetPending = true;
    });
    builder.addCase(retrieveNewsDataset.rejected, (state, action) => {
      state.retrieveNewsDatasetPending = false;
      state.retrieveNewsDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveNewsItemsDataset.fulfilled, (state, action) => {
      state.retrieveNewsItemsDatasetPending = false;
      state.retrieveNewsItemsDatasetErrors = {};
      state.newsItems = action.payload;
    });
    builder.addCase(retrieveNewsItemsDataset.pending, (state, action) => {
      state.retrieveNewsItemsDatasetPending = true;
    });
    builder.addCase(retrieveNewsItemsDataset.rejected, (state, action) => {
      state.retrieveNewsItemsDatasetPending = false;
      state.retrieveNewsItemsDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveUsersDataset.fulfilled, (state, action) => {
      state.retrieveUsersDatasetPending = false;
      state.retrieveUsersDatasetErrors = {};
      state.users = action.payload;
    });
    builder.addCase(retrieveUsersDataset.pending, (state, action) => {
      state.retrieveUsersDatasetPending = true;
    });
    builder.addCase(retrieveUsersDataset.rejected, (state, action) => {
      state.retrieveUsersDatasetPending = false;
      state.retrieveUsersDatasetErrors = action.payload || {};
    });

    builder.addCase(retrieveUserDataset.fulfilled, (state, action) => {
      state.retrieveUserDatasetPending = false;
      state.retrieveUserDatasetErrors = {};
      state.user = action.payload;
    });
    builder.addCase(retrieveUserDataset.pending, (state, action) => {
      state.retrieveUserDatasetPending = true;
    });
    builder.addCase(retrieveUserDataset.rejected, (state, action) => {
      state.retrieveUserDatasetPending = false;
      state.retrieveUserDatasetErrors = action.payload || {};
    });
  },
});

export const retrieveVideosDataset = createAsyncThunk<
  PaginatedList<FeedItemDataset>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveVideosDataset", async ({ queryParams }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemDatasets({
      queryParams: { ...queryParams, types: FEED_ITEM_TYPES.VIDEO },
    });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveVideoDataset = createAsyncThunk<
  FeedItemDataset,
  { videoId: string },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveVideoDataset", async ({ videoId }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemDataset(videoId);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveNewsItemsDataset = createAsyncThunk<
  PaginatedList<FeedItemDataset>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveNewsItemsDataset", async ({ queryParams }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemDatasets({
      queryParams: { ...queryParams, types: FEED_ITEM_TYPES.NEWS },
    });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveNewsDataset = createAsyncThunk<
  FeedItemDataset,
  { newsId: string },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveNewsDataset", async ({ newsId }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemDataset(newsId);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveTracksDataset = createAsyncThunk<
  PaginatedList<FeedItemDataset>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveTracksDataset", async ({ queryParams }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemDatasets({
      queryParams: { ...queryParams, types: FEED_ITEM_TYPES.TRACK },
    });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveTrackDataset = createAsyncThunk<
  FeedItemDataset,
  { trackId: string },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveTrackDataset", async ({ trackId }, thunkApi) => {
  try {
    const response = await feedService.retrieveFeedItemDataset(trackId);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveUsersDataset = createAsyncThunk<
  PaginatedList<UserFeedData>,
  { queryParams?: QueryParams },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveUsersDataset", async ({ queryParams }, thunkApi) => {
  try {
    const response = await usersService.fetchUsersFeedData({ queryParams });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveUserDataset = createAsyncThunk<
  UserFeedData,
  { userId: string },
  { rejectValue: ErrorMessages }
>("feedDataset/retrieveUserDataset", async ({ userId }, thunkApi) => {
  try {
    const response = await usersService.fetchUserFeedData({ userId });
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
