import { CheckoutSession } from "../../types/payments";
import React, { useState } from "react";
import { Button, Spinner } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchCheckoutSessions, redeemNFT } from "../../redux/payments/paymentsSlice";
import { isDispatchResponseError } from "../../redux/utils";
import NFTDetailsModal from "./NFTDetailsModal";

interface Props {
  data: CheckoutSession;
}

/**
 * A component that displays an NFT product(CheckoutSession) and allows it to be clicked.
 */
export default function NFTRedeemableItem({ data }: Props) {
  console.log("NFTRedeemableItem@data", data);
  const dispatch = useAppDispatch();
  const [isClicked, setIsClicked] = React.useState(false);
  const pendingFetchCheckoutSessions = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutSessions
  );
  const pendingRedeemNFT = useAppSelector(
    (state) => state.payments.pendingRedeemNFT
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  function getImageSrc() {
    let image;
    console.log("getImageSrc@data.nft_product.images", data.nft_product.images);
    if (data.nft_product.images) {
      image = data.nft_product.images;
    }

    if (!image) {
      return require("../../images/No-Image.png");
    }
    return image;
  }

  async function handleRedeemNFT() {
    console.log("handleRedeemNFT data", data);
    setIsClicked(true);
    const response = await dispatch(redeemNFT(data.id));
    console.log("handleRedeemNFT response", response);
    if (!isDispatchResponseError(response)) {
      console.log("fetching checkout sessions");
      dispatch(fetchCheckoutSessions());
    }
    setIsClicked(false);
  }

  function renderBrainInfo() {
    if (!data.is_processed) {
      return (
        <>
          If you redeem this NFT, you will also receive{" "}
          <span className="font-medium">
            {data.nft_product.brain.toLocaleString()} BRAIN
          </span>
        </>
      );
    } else if (data.is_refunded) {
      return (
        <span className="font-medium">
          This purchase has been refunded due to an error on our end
        </span>
      );
    } else {
      return (
        <span className="font-medium">
          You have redeemed this NFT and received{" "}
          {data.nft_product.brain.toLocaleString()} BRAIN
        </span>
      );
    }
  }

  function generateOpenSeaLink() {
    console.log(
      "generateOpenSeaLink@data",
      data.minted_nft_id,
      data.wallet_address
    );
    try {
      if (!data.minted_nft_id || !data.wallet_address) {
        return (
          <div className="text-gray-500 text-sm">
            {!data.minted_nft_id
              ? "OpenSea link will be available after minting"
              : "Contract address not available"}
          </div>
        );
      }

      const baseUrl = process.env.REACT_APP_OPENSEA_NFT_ADDRESS;
      console.log("generateOpenSeaLink@baseUrl", baseUrl);
      if (!baseUrl) {
        throw new Error("OpenSea base URL not configured");
      }

      const openSeaUrl = `${baseUrl}${data.wallet_address}/${data.minted_nft_id}`;
      console.log("generateOpenSeaLink@openSeaUrl", openSeaUrl);
      return (
        <div className="mt-2">
          <a
            href={openSeaUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800 font-medium transition-colors"
            onClick={(e) => {
              if (!data.minted_nft_id || !data.wallet_address) {
                e.preventDefault();
                return;
              }
            }}
          >
            <img
              src="/path-to-opensea-logo.svg"
              alt="OpenSea"
              className="w-4 h-4"
            />
            <span>View on OpenSea</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        </div>
      );
    } catch (error) {
      console.error("Error generating OpenSea link:", error);
      return (
        <div className="text-red-500 text-sm">
          Error generating OpenSea link
        </div>
      );
    }
  }

  return (
    <>
      <div 
        className="flex flex-col w-full p-4 rounded-lg border-2 border-gray-200 hover:border-blue-200 transition-colors cursor-pointer"
        onClick={() => setShowDetailsModal(true)}
      >
        {/* NFT Image */}
        <div className="relative aspect-square rounded-lg overflow-hidden mb-4">
          <img
            src={getImageSrc()}
            alt={data.nft_product.name}
            className="object-cover w-full h-full"
          />
        </div>

        {/* NFT Details */}
        <div className="space-y-2">
          <h3 className="text-xl font-bold text-gray-900">
            {data.nft_product.name}
          </h3>

          <div className="text-sm text-gray-600">{renderBrainInfo()}</div>

          {/* NFT ID and OpenSea Link */}
          {data.minted_nft_id && (
            <div className="pt-2 border-t border-gray-100">
              <div className="text-sm text-gray-600">
                NFT ID: {data.minted_nft_id}
              </div>
              {/* {generateOpenSeaLink()} */}
            </div>
          )}

          {/* Description */}
          <div className="text-sm text-gray-600">
            {data.nft_product.description}
          </div>

          {/* Redeem Button */}
          <Button
            className="w-full mt-4"
            color="info"
            disabled={data.is_processed || (isClicked && pendingRedeemNFT)}
            title={data.is_processed ? "You've already redeemed this NFT" : ""}
            onClick={handleRedeemNFT}
          >
            {isClicked && pendingRedeemNFT ? (
              <div className="flex flex-col items-center justify-center">
                <Spinner size="sm" />
                <span className="text-sm mt-1">
                  Please wait (up to 30 seconds)...
                </span>
              </div>
            ) : (
              "Redeem NFT"
            )}
          </Button>
        </div>
      </div>

      <NFTDetailsModal
        show={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        data={data}
      />
    </>
  );
}
