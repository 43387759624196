import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { PAGE_SIZE } from "../../../services/apiService";

interface Props {
  count: number;
  page: number;
  previous: string | null;
  next: string | null;
  pageSize?: number;
  onClickPrev: () => void;
  onClickNext: () => void;
}

/**
 * Pagination component.
 */
export default function PaginationV2({
  count,
  page,
  previous,
  next,
  pageSize = PAGE_SIZE,
  onClickPrev,
  onClickNext,
}: Props) {
  /**
   * Get the number of the first item on the page.
   */
  const getStart = () => {
    if (count === 0) return count;
    return 1 + (page - 1) * pageSize;
  };

  /**
   * Get the number of the last item on the page.
   */
  const getEnd = () => {
    const maxPageVal = page * pageSize;
    const maxTotalVal = count;
    return maxPageVal < maxTotalVal ? maxPageVal : maxTotalVal;
  };

  return (
    <div
      className="flex flex-col sm:flex-row justify-between items-center gap-4 py-4 px-6
                    bg-[#252d3d] border-t border-white/5 rounded-b-xl transition-all duration-300"
    >
      {/* Results Counter */}
      <div className="text-sm text-gray-400">
        Showing <span className="font-medium text-white">{getStart()}</span> -{" "}
        <span className="font-medium text-white">{getEnd()}</span> of{" "}
        <span className="font-medium text-white">{count}</span> results
      </div>

      {/* Navigation Buttons */}
      <div className="flex gap-3">
        <button
          onClick={onClickPrev}
          disabled={!previous}
          className={`
            inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg
            transition-all duration-300 
            ${
              previous
                ? "text-blue-400 bg-blue-500/10 hover:bg-blue-500/20 border border-blue-500/20"
                : "text-gray-500 bg-gray-800/50 cursor-not-allowed"
            }
          `}
          aria-label="Previous page"
        >
          <ArrowLongLeftIcon className="w-5 h-5 mr-2" />
          Previous
        </button>

        <button
          onClick={onClickNext}
          disabled={!next}
          className={`
            inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg
            transition-all duration-300
            ${
              next
                ? "text-blue-400 bg-blue-500/10 hover:bg-blue-500/20 border border-blue-500/20"
                : "text-gray-500 bg-gray-800/50 cursor-not-allowed"
            }
          `}
          aria-label="Next page"
        >
          Next
          <ArrowLongRightIcon className="w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );
}
