import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { setWalletAddress, getWalletAddress, removeWalletAddress } from "../../services/localStorage";

interface WalletState {
  seqAddress: string | null | undefined;
  isConnected: boolean;
  pendingConnect: boolean;
  error: string | null;
}

const initialState: WalletState = {
  seqAddress: getWalletAddress(),
  isConnected: !!getWalletAddress(),
  pendingConnect: false,
  error: null,
};

// Update the type to handle all possible address formats
type WalletAddress = string | `0x${string}` | null | undefined;

// Create async thunks with proper return types
export const setSequenceWalletAddress = createAsyncThunk<string, WalletAddress>(
  "wallet/setAddress",
  async (address) => {
    if (!address) {
      removeWalletAddress();
      return "";
    }
    setWalletAddress(address);
    const data=getWalletAddress();
    return address;
  }
);

export const disconnectWallet = createAsyncThunk<null>(
  "wallet/disconnect",
  async () => {
    removeWalletAddress();
    return null;
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetWalletState: (state) => {
      state.seqAddress = null;
      state.isConnected = false;
      state.pendingConnect = false;
      state.error = null;
      removeWalletAddress();
    },
    updateWalletAddress: (state, action: PayloadAction<WalletAddress>) => {
      state.seqAddress = action.payload || null;
      state.isConnected = !!action.payload;
      setWalletAddress(action.payload || null);
    },
  },
  extraReducers: (builder) => {
    builder
      // Set Wallet Address
      .addCase(setSequenceWalletAddress.pending, (state) => {
        state.pendingConnect = true;
        state.error = null;
      })
      .addCase(setSequenceWalletAddress.fulfilled, (state, action) => {
        state.seqAddress = action.payload || null;
        state.isConnected = !!action.payload;
        state.pendingConnect = false;
        state.error = null;
      })
      .addCase(setSequenceWalletAddress.rejected, (state, action) => {
        state.pendingConnect = false;
        state.error = action.error.message || "Failed to set wallet address";
      })
      // Disconnect Wallet
      .addCase(disconnectWallet.pending, (state) => {
        state.pendingConnect = true;
      })
      .addCase(disconnectWallet.fulfilled, (state) => {
        state.seqAddress = null;
        state.isConnected = false;
        state.pendingConnect = false;
        state.error = null;
      })
      .addCase(disconnectWallet.rejected, (state, action) => {
        state.pendingConnect = false;
        state.error = action.error.message || "Failed to disconnect wallet";
      });
  },
});

export const { resetWalletState, updateWalletAddress } = walletSlice.actions;
export { walletSlice };
export default walletSlice.reducer; 