import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user/userSlice";
import { neuralNetworksSlice } from "./neuralNetworks/neuralNetworksSlice";
import { dataSetsSlice } from "./dataSets/dataSetsSlice";
import { usersSlice } from "./users/usersSlice";
import { spotifySlice } from "./spotify/spotifySlice";
import { youtubeSlice } from "./youtube/youtubeSlice";
import { shazamSlice } from "./shazam/shazamSlice";
import { quizSlice } from "./quiz/quizSlice";
import { playlistSlice } from "./playlist/playlistSlice";
import { feedItemsSlice } from "./feed/feedItemsSlice";
import { newsletterSlice } from "./newsletter/newsletterSlice";
import { brainCoinSlice } from "./brainCoin/brainCoinSlice";
import { feedDatasetSlice } from "./feed/feedDatasetSlice";
import { feedItemReportsSlice } from "./feed/feedItemReportsSlice";
import { feedbackSlice } from "./feedback/feedbackSlice";
import { authSlice } from "./auth/authSlice";
import { paymentsSlice } from "./payments/paymentsSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { feedFilterTypesSlice } from "./feed/feedFilterTypesSlice";
import walletReducer, { walletSlice } from "./wallet/walletSlice";
import moviesReducer from './movies/moviesSlice';

const reducers = combineReducers({
  auth: authSlice.reducer,
  user: userSlice.reducer,
  users: usersSlice.reducer,
  neuralNetworks: neuralNetworksSlice.reducer,
  dataSets: dataSetsSlice.reducer,
  spotify: spotifySlice.reducer,
  youtube: youtubeSlice.reducer,
  shazam: shazamSlice.reducer,
  quiz: quizSlice.reducer,
  playlist: playlistSlice.reducer,
  feedDataset: feedDatasetSlice.reducer,
  feedItems: feedItemsSlice.reducer,
  feedFilterTypes: feedFilterTypesSlice.reducer,
  feedItemReports: feedItemReportsSlice.reducer,
  feedback: feedbackSlice.reducer,
  newsletter: newsletterSlice.reducer,
  brainCoin: brainCoinSlice.reducer,
  payments: paymentsSlice.reducer,
  wallet: walletReducer,
  movies: moviesReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["feedFilterTypes"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
