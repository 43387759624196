import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

/**
 * Payment success page
 */
export default function PaymentSuccess() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col justify-center items-center p-4">
      <div className="max-w-md w-full bg-white rounded-3xl shadow-2xl p-10 space-y-8 transform transition-all animate-fadeIn">
        {/* Company Logo and Name */}
        <div className="flex flex-col items-center space-y-3">
          <img 
            src={require("../../images/Navbar-Icon-Gold.png")}
            alt="Company Logo" 
            className="h-24 w-auto transform hover:scale-105 transition-transform"
            style={{ filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.1))' }}
          />
          <h2 className="text-2xl font-bold text-gray-800 tracking-wide">
            BrainCargo
          </h2>
        </div>

        {/* Success Icon with Animation */}
        <div className="flex justify-center">
          <div className="bg-green-50 rounded-full p-6 shadow-inner animate-bounce-small">
            <CheckCircleIcon className="text-green-500 w-20 h-20" 
              style={{ filter: 'drop-shadow(0 0 8px rgba(34, 197, 94, 0.2))' }}
            />
          </div>
        </div>

        {/* Success Message */}
        <div className="text-center space-y-4">
          <h1 className="text-3xl font-bold text-gray-800 tracking-tight">
            Payment Successful!
          </h1>
          <p className="text-lg text-gray-600 leading-relaxed">
            Thank you for your purchase. Your transaction has been completed successfully.
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col gap-4 pt-6">
          <a
            href="/profile"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-4 px-6 rounded-xl font-semibold text-center transition-all transform hover:scale-[1.02] hover:shadow-lg active:scale-[0.98]"
          >
            Go to Profile
          </a>
        </div>
      </div>
    </div>
  );
}

// Add this to your global CSS or create a new animation
const styles = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes bounce-small {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out;
}

.animate-bounce-small {
  animation: bounce-small 2s infinite ease-in-out;
}
`;
