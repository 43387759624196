import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Modal from "../../components/common/modal";
import { Button, Spinner, Card } from "flowbite-react";
import { fetchCheckoutSessions } from "../../redux/payments/paymentsSlice";
import { CheckoutSession } from "../../types/payments";
import NFTRedeemableItem from "./NFTRedeemableItem";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

export default function NFTRedeemableModal({ show, setShow }: Props) {
  const dispatch = useAppDispatch();
  const checkoutSessions = useAppSelector((state) => state.payments.checkoutSessions);
  const pendingFetchCheckoutSessions = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutSessions
  );

  function onClose() {
    setShow(false);
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideFooter
    //   size="xl"
      body={
        <div className="text-center">
          <h3 className="mb-6 text-2xl font-bold text-gray-800 dark:text-gray-200">
            Your Redeemable NFTs
          </h3>

          {pendingFetchCheckoutSessions ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Spinner size="xl" />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[60vh] overflow-y-auto p-4">
              {checkoutSessions.length === 0 ? (
                <div className="col-span-full text-center text-gray-500 py-8">
                  You don't have any NFTs to redeem
                </div>
              ) : (
                checkoutSessions.map((session: CheckoutSession, index: number) => (
                  <div key={index} className="flex">
                    <NFTRedeemableItem data={session} />
                  </div>
                ))
              )}
            </div>
          )}

          <div className="flex justify-center mt-8 pt-4 border-t">
            <Button
              color="gray"
              onClick={onClose}
              size="lg"
              className="px-8"
            >
              Close
            </Button>
          </div>
        </div>
      }
    />
  );
} 