import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import Header from "../../components/common/header";
import MoreInfo from "../../components/common/moreInfo";
import ConnectedAdvertisersAccordion from "../../components/brainCoin/ConnectedAdvertisersAccordion";
import SequenceWallet from "../../components/brainCoin/SequenceWallet";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";
import { useAppSelector } from "../../redux/hooks";
import { Advertiser, ConnectedAdvertiser } from "../../types/brainCoin";
import { ConnectWalletModal } from "../../components/wallet/ConnectWalletModal";
/**
 * Brain coin page.
 */
export default function BrainCoin({
  sequenceWalletAddress,
  pendingConnect,
}: {
  sequenceWalletAddress: string | null | undefined;
  pendingConnect: boolean;
}) {
  const advertisers = useAppSelector((state) => state.brainCoin.advertisers);

  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const connectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.connectedAdvertisers
  );

  useEffect(() => {
    document.title = "Brain Coin | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <MainHeader title="Brain Coin Wallet" />
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
            {/* Hero Section */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900 px-6 py-8 sm:px-10">
              <h1 className="text-2xl font-bold text-white mb-2">
                Brain Coin Wallet
              </h1>
              <p className="text-blue-100 dark:text-blue-200 max-w-2xl">
                Connect your wallet and manage your advertising preferences to
                earn Brain Coins
              </p>
            </div>

            {/* Main Content */}
            <div className="px-6 py-8 sm:px-10">
              {/* Wallet Section */}
              <div className="mb-12">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
                  Connect Your Wallet
                </h2>
                <div className="flex justify-center">
                  <ConnectWalletModal
                    sequenceWalletAddres={sequenceWalletAddress}
                    pendingConnect={pendingConnect}
                  />
                </div>
                {/* <div className="max-w-xl mx-auto">
                  <SequenceWallet
                    isWalletConnected={isWalletConnected}
                    setIsWalletConnected={setIsWalletConnected}
                  />
                </div> */}
              </div>

              {/* Earn Section */}
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                    Earn Brain Coins
                  </h2>
                  <MoreInfo
                    text="Select advertisers you wish to allow to put up ads on your feed. Please note that selected advertisers will gain access to your data."
                    size={24}
                  />
                </div>
                <div className="max-w-3xl mx-auto">
                  <ConnectedAdvertisersAccordion />
                </div>
              </div>
            </div>

            {/* Stats Section */}
            <div className="bg-gray-50 dark:bg-gray-900 px-6 py-8 sm:px-10 border-t border-gray-200 dark:border-gray-700">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Total Earnings
                  </h3>
                  <p className="text-3xl font-bold text-gray-900 dark:text-white mt-2">
                    {advertisers
                      .map((item) => item.brain_coin_amount)
                      .reduce((a, b) => a + b, 0)}{" "}
                    BC
                  </p>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Active Advertisers
                  </h3>
                  <p className="text-3xl font-bold text-gray-900 dark:text-white mt-2">
                    {advertisers.length}
                  </p>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
                  <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Average Yield
                  </h3>
                  <p className="text-3xl font-bold text-gray-900 dark:text-white mt-2">
                    {connectedAdvertisers.length > 0
                      ? Math.round(
                          (connectedAdvertisers.reduce(
                            (acc, curr) => acc + (curr.ad_percentage as number),
                            0
                          ) /
                            connectedAdvertisers.length) *
                            100
                        )
                      : 0}
                    %
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
