import React, { useState } from "react";
import { Label, TextInput } from "flowbite-react";
import FileUpload from "../../components/common/file/FileUpload";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { displayErrors } from "../../helpers/errors";
import { isDispatchResponseError } from "../../redux/utils";
import { addDataSet, getDataSets } from "../../redux/dataSets/dataSetsSlice";
import Modal from "../../components/common/modal";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  show: boolean;
  onClose: () => void;
}

/**
 * A popup that allows the user to upload a new dataset.
 */
export default function UploadDataSetFormPopup({ show, onClose }: Props) {
  const dispatch = useAppDispatch();
  const errorMessages = useAppSelector((state) => state.dataSets.errorMessages);
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const pendingAddDataSet = useAppSelector(
    (state) => state.dataSets.pendingAddDataSet
  );

  /**
   * Submits the form.
   */
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const form_data = new FormData();
    form_data.append("name", name);
    if (file) {
      form_data.append("file", file, file.name);
    }

    const response = await dispatch(addDataSet(form_data));

    // Close the popup if request succeeded
    if (!isDispatchResponseError(response)) {
      dispatch(getDataSets({}));
      setName("");
      setFile(null);
      onClose();
    }
  };

  /**
   * Determine if the form submit button should be disabled.
   */
  function isSubmitDisabled() {
    return !name || !file;
  }

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 
                    transition-all duration-200 ${
                      show ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
    >
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-gray-900/60 dark:bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div
        className="relative w-full max-w-md bg-white dark:bg-[#1e2533] 
                    rounded-2xl shadow-xl transform transition-all duration-200 
                    border border-gray-200 dark:border-white/5"
      >
        {/* Header */}
        <div
          className="flex items-center justify-between p-6 
                     border-b border-gray-200 dark:border-white/5"
        >
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Add a new Dataset
          </h3>
          <button
            onClick={onClose}
            className="p-2 rounded-lg text-gray-500 hover:text-gray-700 
                     dark:text-gray-400 dark:hover:text-gray-300
                     hover:bg-gray-100 dark:hover:bg-white/5 
                     transition-all duration-200"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Form Body */}
        <div className="p-6 space-y-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Dataset Name */}
            <div>
              <Label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Dataset Name
              </Label>
              <TextInput
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="My Dataset"
                className="w-full bg-gray-50 dark:bg-white/5 
                         border-gray-300 dark:border-white/10 
                         text-gray-900 dark:text-white
                         focus:ring-blue-500 focus:border-blue-500 
                         dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              {displayErrors(errorMessages.name)}
            </div>

            {/* File Upload */}
            <div>
              <Label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200">
                Upload Dataset File
              </Label>
              <FileUpload
                file={file}
                setFile={setFile}
                inputId="dataset-file"
                maxSize="20 MB"
              />
              {displayErrors(errorMessages.file)}
            </div>
          </form>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-3 p-6 border-t border-gray-200 dark:border-white/5">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200
                     bg-gray-100 dark:bg-white/5 rounded-lg 
                     hover:bg-gray-200 dark:hover:bg-white/10
                     transition-all duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isSubmitDisabled() || pendingAddDataSet}
            className="px-4 py-2 text-sm font-medium text-white
                     bg-blue-600 rounded-lg hover:bg-blue-700
                     disabled:opacity-50 disabled:cursor-not-allowed
                     transition-all duration-200
                     flex items-center gap-2"
          >
            {pendingAddDataSet ? (
              <>
                <span className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                Uploading...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
