import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ModalEditCreatePlaylist from "../../components/playlist/ModalEditCreatePlaylist";
import SelectPlaylistPopup from "../../components/playlist/SelectPlaylistPopup";
import PageWrapper from "../../components/common/pageWrapper";
import Track from "../../components/common/track";
import { isDispatchResponseError } from "../../redux/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  FlagIcon,
  ForwardIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import MyPlaylistList from "../../components/playlist/MyPlaylistList";
import SharedPlaylistList from "../../components/playlist/SharedPlaylistList";
import Header from "../../components/common/header";
import { retrieveConnectedAdvertisers } from "../../redux/brainCoin/brainCoinSlice";
import AdByRandomProvider from "../../components/ads/AdByRandomProvider";
import { generateSpotifyUrl } from "../../helpers/spotify";
import {
  dislikeFeedItem,
  getDislikedFeedItems,
  getLikedFeedItems,
  getRandomFeedItem,
  increaseWatchedFor,
  likeFeedItem,
  skipFeedItem,
  watchFeedItem,
} from "../../redux/feed/feedItemsSlice";
import { FEED_ITEM_TYPES } from "../../types/feed";
import initiateSpotify from "../../helpers/spotifyIFrameAPI";
import { ReportFeedItemModal } from "../../components/report/ReportFeedItemModal";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

import SpotifyIcon from "../../components/icons/SpotifyIcon";
/**
 * Music page.
 */
export default function Music() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const feedItem = useAppSelector((state) => state.feedItems.feedItem);
  const likedFeedItems = useAppSelector(
    (state) => state.feedItems.likedFeedItems
  );
  const dislikedFeedItems = useAppSelector(
    (state) => state.feedItems.dislikedFeedItems
  );
  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [showSelectPlaylistPopup, setShowSelectPlaylistPopup] =
    useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  let watchFeedItemTriggered = false;
  let prevPositionSeconds = 0;
  let prevProgressSeconds = 0;

  /**
   * Reloads the page.
   */
  function reloadPage() {
    // We need to refresh the page so that a new spotify player can be created
    window.location.reload();
  }

  /**
   * Mark feedItem as liked in database
   */
  const handleLikeFeedItem = async () => {
    const response = await dispatch(likeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as disliked in database
   */
  const handleDislikeFeedItem = async () => {
    const response = await dispatch(dislikeFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as skipped in database
   */
  const handleSkipFeedItem = async () => {
    const response = await dispatch(skipFeedItem(feedItem?.id || ""));

    if (!isDispatchResponseError(response)) {
      reloadPage();
    }
  };

  /**
   * Mark feedItem as watched in database
   */
  const handleWatchFeedItem = async () => {
    // handleWatchFeedItem is run whenever Spotify player changes its playback state.
    // Without the if statement here the backend would be flooded with requests to flag
    // the feedItem as watched
    if (!watchFeedItemTriggered) {
      await dispatch(watchFeedItem(feedItem?.id || ""));
      watchFeedItemTriggered = true;
    }
  };

  /**
   * Render a list of liked tracks.
   */
  const displayLikedFeedItems = () => {
    if (likedFeedItems.length > 0) {
      return likedFeedItems.map((likedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Track track={likedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No liked tracks added yet!
        </p>
      );
    }
  };

  /**
   * Render a list of disliked tracks.
   */
  const displayDislikedFeedItems = () => {
    if (dislikedFeedItems.length > 0) {
      return dislikedFeedItems.map((dislikedFeedItem, idx) => (
        <li key={idx} className="pb-3 sm:py-4">
          <Track track={dislikedFeedItem} />
        </li>
      ));
    } else {
      return (
        <p className="py-4 text-gray-500 text-sm font-semibold">
          No disliked tracks added yet!
        </p>
      );
    }
  };

  /**
   * Update watched_for state in database.
   */
  function updateWatchedFor() {
    if (progressSeconds > 0 && feedItem) {
      dispatch(
        increaseWatchedFor({
          feed_item: feedItem.id,
          watched_for: progressSeconds,
          watched: true,
        })
      );
      setProgressSeconds(0);
    }
  }

  /**
   * Retrieve data and set up Spotify iframe API.
   */
  useEffect(() => {
    document.title = "Music | BrainCargo";

    // Retrieve data
    dispatch(
      getRandomFeedItem({ queryParams: { types: FEED_ITEM_TYPES.TRACK } })
    );
    dispatch(
      getLikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.TRACK } })
    );
    dispatch(
      getDislikedFeedItems({ queryParams: { types: FEED_ITEM_TYPES.TRACK } })
    );
    dispatch(retrieveConnectedAdvertisers());

    // Set up Spotify iframe API
    const script = document.createElement("script");
    script.src = "https://open.spotify.com/embed/iframe-api/v1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Once a feedItem is loaded, set up Spotify player.
   */
  useEffect(() => {
    initiateSpotify(
      generateSpotifyUrl(feedItem?.spotify_track_id || ""),
      ({
        data,
      }: {
        data: {
          duration: number;
          isBuffering: boolean;
          isPaused: boolean;
          position: number;
        };
      }) => {
        if (!data.isPaused) {
          handleWatchFeedItem();
        }
        const positionSeconds = data.position / 1000;
        const newProgressSeconds = positionSeconds - prevPositionSeconds;
        if (newProgressSeconds > 0) {
          if (newProgressSeconds < 1) {
            // if newProgressSeconds >= 1 then we can assume that the user has seeked forward
            // We need to ignore that case
            setProgressSeconds(prevProgressSeconds + newProgressSeconds);
            prevProgressSeconds = prevProgressSeconds + newProgressSeconds;
          }
        }
        prevPositionSeconds = positionSeconds;
      }
    );
  }, [feedItem]);

  /**
   * Update watched_for state in database when user leaves the page.
   */
  useEffect(() => {
    const onUnload = function (e: BeforeUnloadEvent) {
      updateWatchedFor();
    };

    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [progressSeconds]);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-green-600 to-green-800 dark:from-green-800 dark:to-green-900 rounded-t-2xl p-6">
            <MainHeader title="Music" />
            <p className="text-green-100 dark:text-green-200">
              Discover and manage your music collection
            </p>
          </div>

          {/* Main Content */}
          <div className="bg-white dark:bg-gray-800 rounded-b-2xl shadow-sm transition-all duration-200">
            {!user?.is_spotify_connected ? (
              <div className="p-8 text-center">
                <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  <SpotifyIcon className="w-8 h-8 text-gray-400 dark:text-gray-500" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Connect Spotify
                </h3>
                <p className="text-gray-500 dark:text-gray-400 max-w-sm mx-auto mb-6">
                  Please connect your Spotify account to start discovering and
                  managing music
                </p>
                <Link
                  to="/profile"
                  className="inline-flex items-center px-4 py-2 bg-green-600 hover:bg-green-700 
                           dark:bg-green-500 dark:hover:bg-green-600 text-white rounded-lg 
                           transition-colors duration-200"
                >
                  Go to Profile
                </Link>
              </div>
            ) : (
              <div className="p-6">
                {/* Music Player Section */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                  <div className="lg:col-span-2 space-y-6">
                    {/* Spotify Player */}
                    <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-4">
                      {feedItem ? (
                        <div
                          id="embed-iframe"
                          className="w-full"
                          aria-label="Spotify Player"
                        ></div>
                      ) : (
                        <div className="text-center py-8">
                          <p className="text-gray-500 dark:text-gray-400 font-medium">
                            No track available at this time
                          </p>
                        </div>
                      )}
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-wrap gap-3">
                      <button
                        onClick={handleLikeFeedItem}
                        disabled={!feedItem}
                        className="inline-flex items-center px-4 py-2 bg-green-50 dark:bg-green-900/30 
                                text-green-700 dark:text-green-300 rounded-lg hover:bg-green-100 
                                dark:hover:bg-green-900/50 transition-all duration-200 disabled:opacity-50"
                      >
                        <HandThumbUpIcon className="w-5 h-5 mr-2" />
                        Like
                      </button>
                      <button
                        onClick={handleDislikeFeedItem}
                        disabled={!feedItem}
                        className="inline-flex items-center px-4 py-2 bg-gray-50 dark:bg-gray-700 
                                text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-100 
                                dark:hover:bg-gray-600 transition-all duration-200 disabled:opacity-50"
                      >
                        <HandThumbDownIcon className="w-5 h-5 mr-2" />
                        Dislike
                      </button>
                      <button
                        onClick={handleSkipFeedItem}
                        disabled={!feedItem}
                        className="inline-flex items-center px-4 py-2 bg-gray-50 dark:bg-gray-700 
                                text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-100 
                                dark:hover:bg-gray-600 transition-all duration-200 disabled:opacity-50"
                      >
                        <ForwardIcon className="w-5 h-5 mr-2" />
                        Skip
                      </button>
                      <button
                        onClick={() => setShowReportModal(true)}
                        disabled={!feedItem}
                        className="inline-flex items-center px-4 py-2 bg-red-50 dark:bg-red-900/30 
                                text-red-700 dark:text-red-300 rounded-lg hover:bg-red-100 
                                dark:hover:bg-red-900/50 transition-all duration-200 disabled:opacity-50"
                      >
                        <FlagIcon className="w-5 h-5 mr-2" />
                        Report
                      </button>
                      <button
                        onClick={() => setShowSelectPlaylistPopup(true)}
                        disabled={!feedItem}
                        className="inline-flex items-center px-4 py-2 bg-blue-50 dark:bg-blue-900/30 
                                text-blue-700 dark:text-blue-300 rounded-lg hover:bg-blue-100 
                                dark:hover:bg-blue-900/50 transition-all duration-200 disabled:opacity-50"
                      >
                        <PlusIcon className="w-5 h-5 mr-2" />
                        Add to Playlist
                      </button>
                    </div>

                    {/* Advertisement */}
                    <AdByRandomProvider className="w-full" />

                    {/* Liked and Disliked Tracks */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Liked Tracks
                          </h3>
                          <span
                            className="px-2.5 py-0.5 text-sm bg-green-50 dark:bg-green-900/30 
                                       text-green-700 dark:text-green-300 rounded-full"
                          >
                            {likedFeedItems.length}
                          </span>
                        </div>
                        <div
                          className="divide-y divide-gray-200 dark:divide-gray-700 max-h-[40rem] 
                                    overflow-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600"
                        >
                          {displayLikedFeedItems()}
                        </div>
                      </div>

                      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Disliked Tracks
                          </h3>
                          <span
                            className="px-2.5 py-0.5 text-sm bg-red-50 dark:bg-red-900/30 
                                       text-red-700 dark:text-red-300 rounded-full"
                          >
                            {dislikedFeedItems.length}
                          </span>
                        </div>
                        <div
                          className="divide-y divide-gray-200 dark:divide-gray-700 max-h-[40rem] 
                                    overflow-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600"
                        >
                          {displayDislikedFeedItems()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Playlists Sidebar */}
                  <div className="space-y-4">
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                      <MyPlaylistList smallHeader />
                    </div>
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                      <SharedPlaylistList smallHeader />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Modals */}
        <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        {showSelectPlaylistPopup && feedItem && (
          <SelectPlaylistPopup
            feedItem={feedItem}
            show={showSelectPlaylistPopup}
            onClose={() => setShowSelectPlaylistPopup(false)}
            headerText="Add track to playlist"
          />
        )}
        {feedItem && (
          <ReportFeedItemModal
            show={showReportModal}
            setShow={setShowReportModal}
            feedItem={feedItem}
          />
        )}
        <Footer />
      </div>
    </PageWrapper>
  );
}
