import React, { useEffect, useState } from "react";
import { QuizFormData, QuizQuestionFormData, QUESTION_TYPES } from "../../../../types/quiz";
import QuizQuestionInput from "../../../../components/quiz/form/edit-create/QuizQuestionInput";
import QuizQuestionAnswerOptionInput from "../../../../components/quiz/form/edit-create/QuizQuestionAnswerOptionInput";
import { QuizQuestionAnswerOptionFormData } from "../../../../types/quiz";
import { useAppSelector } from "../../../../redux/hooks";
import { Button, Spinner } from "flowbite-react";
import QuizInput from "../../../../components/quiz/form/edit-create/QuizInput";
import { ErrorMessages, NestedErrorMessages } from "../../../../types/redux/slice";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { ChevronUpIcon, TrashIcon, PlusIcon } from "@heroicons/react/24/solid";

interface Props {
  formData: QuizFormData;
  setFormData: (formData: QuizFormData) => void;
  generateBlankQuestionAnswerData: (
    question: QuizQuestionFormData | undefined
  ) => QuizQuestionAnswerOptionFormData;
}

/**
 * Component for rendering quiz edit/create form inputs.
 */
export default function QuizEditCreateInputs({
  formData,
  setFormData,
  generateBlankQuestionAnswerData,
}: Props) {
  const [collapsedQuestions, setCollapsedQuestions] = useState<boolean[]>([]);
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes
  );
  const pendingRetrieveQuizQuestions = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizQuestions
  );
  const pendingGetNeuralNetworks = useAppSelector(
    (store) => store.neuralNetworks.pendingGetNeuralNetworks
  );
  const quizEditCreateErrorMessages = useAppSelector(
    (store) => store.quiz.quizEditCreateErrorMessages
  );

  /**
   * Updates a quiz question at the given index with provided data.
   */
  function onChangeQuestion(idx: number, data: QuizQuestionFormData) {
    const newFormData = { ...formData };

    // If the question is of a specific type - enforce answer option structure
    if (data.type === QUESTION_TYPES.FREEFORM) {
      data.answer_options = [...FREEFORM_ANSWER_OPTIONS];
    }
    if (data.type === QUESTION_TYPES.RATING) {
      data.answer_options = [...RATING_ANSWER_OPTIONS];
    }
    if (data.type === QUESTION_TYPES.AB) {
      data.answer_options = [...AB_ANSWER_OPTIONS];
    }

    newFormData.questions[idx] = { ...data };
    setFormData(newFormData);
  }

  /**
   * Updates a quiz question answer option at the given index with provided data.
   */
  function onChangeQuestionAnswerOption(
    questionIdx: number,
    answerIdx: number,
    data: QuizQuestionAnswerOptionFormData
  ) {
    const newFormData = { ...formData };
    newFormData.questions[questionIdx].answer_options[answerIdx] = { ...data };
    setFormData(newFormData);
  }

  /**
   * Remove a quiz question at the given index.
   */
  function onRemoveQuestion(questionIdx: number) {
    const newFormData = { ...formData };
    newFormData.questions = [
      ...newFormData.questions.filter((question, idx) => idx !== questionIdx),
    ];
    setFormData(newFormData);
  }

  /**
   * Add a new answer option to a quiz question at the given index.
   */
  function onAddAnotherAnswer(questionIdx: number) {
    const newFormData = { ...formData };
    newFormData.questions[questionIdx].answer_options.push(
      generateBlankQuestionAnswerData(newFormData.questions[questionIdx])
    );
    setFormData(newFormData);
  }

  /**
   * Remove an answer option at the given index.
   */
  function onRemoveAnswer(questionIdx: number, answerIdx: number) {
    const newFormData = { ...formData };
    newFormData.questions[questionIdx].answer_options = newFormData.questions[
      questionIdx
    ].answer_options.filter((answer, idx) => idx !== answerIdx);
    setFormData(newFormData);
  }

  /**
   * Generate title text for the remove answer button.
   */
  function generateRemoveAnswerTitleText(question: QuizQuestionFormData) {
    const type = question.type;
    if (type === QUESTION_TYPES.FREEFORM) {
      return "You cannot remove answers from freeform-type questions";
    }
    if (type === QUESTION_TYPES.RATING) {
      return "You cannot remove answers from rating-type questions";
    }
    if (type === QUESTION_TYPES.AB) {
      return "You cannot remove answers from A or B-type questions";
    }
    return undefined;
  }

  /**
   * Generate title text for the add answer button.
   */
  function generateAddAnswerTitleText(question: QuizQuestionFormData) {
    const type = question.type;
    if (type === QUESTION_TYPES.FREEFORM) {
      return "You cannot add answers to freeform-type questions";
    }
    if (type === QUESTION_TYPES.RATING) {
      return "You cannot add answers to rating-type questions";
    }
    if (type === QUESTION_TYPES.AB) {
      return "You cannot add answers to A or B-type questions";
    }
    return undefined;
  }

  /**
   * Scroll the question at the given index into view.
   */
  function scrollQuestionIntoView(questionIdx: number) {
    document.getElementById(questionIdx.toString())?.scrollIntoView();
  }

  /**
   * Handle collapsing/expanding accordions.
   */
  function handleClickAccordion(idx: number) {
    // Collapse all accordions and toggle the one selected
    const isSelectedAccordionCollapsed = !!collapsedQuestions[idx];
    const newCollapsedQuestions = Array(collapsedQuestions.length).fill(true);
    if (idx >= newCollapsedQuestions.length) {
      return;
    }
    newCollapsedQuestions[idx] = !isSelectedAccordionCollapsed;
    setCollapsedQuestions(newCollapsedQuestions);

    // Since there's an animation on accordion wrapping/unwrapping itself
    // scrollQuestionIntoView has to be delayed
    setTimeout(() => scrollQuestionIntoView(idx), 150);
  }

  /**
   * Check if the question at the given index is collapsed.
   */
  function isQuestionCollapsed(questionIdx: number) {
    const isCollapsed = collapsedQuestions[questionIdx];
    if (isCollapsed === undefined) {
      // This prevents the accordions from expanding when the component is initially rendered
      return true;
    }
    return isCollapsed;
  }

  /**
   * Set all questions to be collapsed except for the last one.
   */
  useEffect(() => {
    const currCollapsedQuestions = [...collapsedQuestions];
    const newCollapsedQuestions = Array(formData.questions.length).fill(true);
    currCollapsedQuestions.forEach((isCollapsed, idx) => {
      // All questions should be collapsed except for the last one
      if (idx >= newCollapsedQuestions.length) {
        return;
      }
      newCollapsedQuestions[idx] = isCollapsed;
    });
    setCollapsedQuestions(newCollapsedQuestions);
  }, [formData]);

  const pending =
    pendingRetrieveQuizQuestions ||
    pendingRetrieveQuizzes ||
    pendingGetNeuralNetworks;

  if (pending) {
    return (
      <div className="flex justify-center">
        <Spinner size="sm" aria-label="Loading spinner" />
      </div>
    );
  }

  return (
    <>
      <div className="px-4 sm:px-6 space-y-8">
        {/* Quiz Basic Info Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg transition-all duration-200">
          <div className="p-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Basic Information
            </h3>
            <QuizInput
              data={formData}
              onChange={(data) => setFormData({ ...data })}
              errors={quizEditCreateErrorMessages as ErrorMessages}
            />
          </div>
        </div>

        {/* Questions Section */}
        {formData.questions.map((question, questionIdx) => {
          const disableAddQuestionButton = [
            QUESTION_TYPES.FREEFORM,
            QUESTION_TYPES.RATING,
            QUESTION_TYPES.AB,
          ].includes(question.type);

          return (
            <div key={questionIdx} id={questionIdx.toString()}>
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg transition-all duration-200">
                <Accordion
                  open={!isQuestionCollapsed(questionIdx)}
                  icon={
                    <ChevronUpIcon
                      className={`${
                        !isQuestionCollapsed(questionIdx) ? "rotate-180" : ""
                      } h-5 w-5 transition-transform text-gray-500 dark:text-gray-400`}
                      aria-label="Toggle question"
                    />
                  }
                  placeholder={""}
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                >
                  <AccordionHeader
                    onClick={() => handleClickAccordion(questionIdx)}
                    className="flex items-center px-6 py-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 
                              transition-colors duration-200 rounded-t-xl"
                    placeholder={""}
                    aria-label={`Accordion header for question ${
                      questionIdx + 1
                    }`}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  >
                    <div className="flex flex-col flex-1">
                      <h4 className="text-base font-semibold text-gray-900 dark:text-white">
                        {question.text || `Question ${questionIdx + 1}`}
                      </h4>
                      <div className="flex items-center gap-3 mt-1">
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {`${question.answer_options.length} answer options`}
                        </span>
                        <span
                          className="text-sm px-2 py-0.5 rounded-full bg-blue-50 dark:bg-blue-900/30 
                                       text-blue-700 dark:text-blue-300"
                        >
                          {`${question.answer_options.length} answer options`}
                        </span>
                      </div>
                    </div>
                  </AccordionHeader>

                  <AccordionBody className="px-6 pb-6 space-y-6">
                    {/* Question Fields */}
                    <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-6">
                      <QuizQuestionInput
                        data={question}
                        onChange={(data) => onChangeQuestion(questionIdx, data)}
                        errors={
                          quizEditCreateErrorMessages?.questions?.[
                            questionIdx
                          ] as ErrorMessages
                        }
                        idx={questionIdx}
                      />
                    </div>

                    {/* Answer Options */}
                    <div className="space-y-4">
                      {question.answer_options.map(
                        (answerOption, answerIdx) => (
                          <div
                            key={answerIdx}
                            className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-6"
                          >
                            <QuizQuestionAnswerOptionInput
                              question={question}
                              answerIdx={answerIdx}
                              data={answerOption}
                              onChange={(data) =>
                                onChangeQuestionAnswerOption(
                                  questionIdx,
                                  answerIdx,
                                  data
                                )
                              }
                              errors={
                                (
                                  quizEditCreateErrorMessages?.questions?.[
                                    questionIdx
                                  ] as NestedErrorMessages
                                )?.answer_options?.[answerIdx] as ErrorMessages
                              }
                            />

                            <div className="mt-4">
                              <button
                                onClick={() =>
                                  onRemoveAnswer(questionIdx, answerIdx)
                                }
                                disabled={disableAddQuestionButton}
                                title={generateRemoveAnswerTitleText(question)}
                                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg
                                       text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/30 
                                       hover:bg-red-100 dark:hover:bg-red-900/50 transition-colors duration-200
                                       disabled:opacity-50 disabled:cursor-not-allowed"
                              >
                                <TrashIcon className="w-4 h-4 mr-2" />
                                Remove Answer
                              </button>
                            </div>
                          </div>
                        )
                      )}

                      {/* Action Buttons */}
                      <div className="flex flex-wrap gap-3 pt-4">
                        <button
                          onClick={() => onAddAnotherAnswer(questionIdx)}
                          disabled={disableAddQuestionButton}
                          title={generateAddAnswerTitleText(question)}
                          className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg
                                   text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/30 
                                   hover:bg-blue-100 dark:hover:bg-blue-900/50 transition-colors duration-200
                                   disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          <PlusIcon className="w-4 h-4 mr-2" />
                          {question.answer_options.length > 0
                            ? "Add Another Answer"
                            : "Add Answer"}
                        </button>

                        <button
                          onClick={() => onRemoveQuestion(questionIdx)}
                          className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg
                                   text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/30 
                                   hover:bg-red-100 dark:hover:bg-red-900/50 transition-colors duration-200"
                        >
                          <TrashIcon className="w-4 h-4 mr-2" />
                          Remove Question
                        </button>
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

const RATING_ANSWER_OPTIONS: QuizQuestionAnswerOptionFormData[] = [
  {
    text: "1 star",
    value: "1",
    position: 1,
    image: null,
    external_image: "",
  },
  {
    text: "2 stars",
    value: "2",
    position: 2,
    image: null,
    external_image: "",
  },
  {
    text: "3 stars",
    value: "3",
    position: 3,
    image: null,
    external_image: "",
  },
  {
    text: "4 stars",
    value: "4",
    position: 4,
    image: null,
    external_image: "",
  },
  {
    text: "5 stars",
    value: "5",
    position: 5,
    image: null,
    external_image: "",
  },
];

const FREEFORM_ANSWER_OPTIONS: QuizQuestionAnswerOptionFormData[] = [
  {
    text: "Answer:",
    value: "answer",
    position: 1,
    image: null,
    external_image: "",
  },
];

const AB_ANSWER_OPTIONS: QuizQuestionAnswerOptionFormData[] = [
  {
    text: "",
    value: "",
    position: 1,
    image: null,
    external_image: "",
  },
  {
    text: "",
    value: "",
    position: 2,
    image: null,
    external_image: "",
  },
];
