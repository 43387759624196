import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ConfirmationPopup from "../../components/common/modal/ConfirmationPopup";
import Pagination from "../../components/common/pagination/Pagination";
import {
  deleteNeuralNetwork,
  getNeuralNetworkCategories,
  getNeuralNetworks,
  selectNeuralNetworks,
} from "../../redux/neuralNetworks/neuralNetworksSlice";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "flowbite-react";
import UploadNeuralNetworkFormPopup from "./UploadNeuralNetworkFormPopup";
import { NeuralNetwork } from "../../types/neuralNetwork";

interface Props {
  headerText?: string;
  subHeaderText?: string;
  submitLabelText?: string;
  headerClassName?: string;
  afterNeuralNetworkSelect?: () => void;
  selectable?: boolean;
  requireSelection?: boolean;
  singularSelection?: boolean;
  hideRemoveButton?: boolean;
  hideAddNeuralNetworkButton?: boolean;
  onClickNetwork?: (networkId: string) => void;
  onlySelected?: boolean;
}

/**
 * A table containing Neural Networks available to be selected by the user.
 */
export default function NeuralNetworksTable({
  headerText = "Select Neural Networks",
  subHeaderText,
  submitLabelText = "Submit Selection",
  headerClassName,
  afterNeuralNetworkSelect,
  selectable = true,
  requireSelection,
  singularSelection,
  hideRemoveButton,
  hideAddNeuralNetworkButton,
  onClickNetwork,
  onlySelected,
}: Props) {
  const dispatch = useAppDispatch();

  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false);
  const [showFormPopup, setShowFormPopup] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string>("");
  const [selectedNetworks, setSelectedNetworks] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);

  const user = useAppSelector((state) => state.user.user);
  const neuralNetworks = useAppSelector(
    (state) => state.neuralNetworks.neuralNetworks
  );
  const pendingSelectNeuralNetworks = useAppSelector(
    (state) => state.neuralNetworks.pendingSelectNeuralNetworks
  );
  const pendingGetNeuralNetworks = useAppSelector(
    (state) => state.neuralNetworks.pendingGetNeuralNetworks
  );
  const pendingDeleteNeuralNetwork = useAppSelector(
    (state) => state.neuralNetworks.pendingDeleteNeuralNetwork
  );

  /**
   * Handle Neural Network selection.
   */
  const onNetworkSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    let newSelectedNetworks = [...selectedNetworks];

    if (singularSelection) {
      // Handle singular selection
      if (checked) {
        newSelectedNetworks = [id];
      } else {
        newSelectedNetworks = [];
      }
    } else {
      // Handle multiple selection
      if (checked) {
        newSelectedNetworks.push(id);
      } else {
        newSelectedNetworks = newSelectedNetworks.filter(
          (networkId) => networkId !== id
        );
      }
    }

    setSelectedNetworks(newSelectedNetworks);
  };

  /**
   * Retrieve Neural Networks.
   */
  const retrieveNeuralNetworks = (url?: string | null) => {
    if (onlySelected) {
      dispatch(
        getNeuralNetworks({ url, queryParams: { assigned_to: user?.id } })
      );
    } else {
      dispatch(getNeuralNetworks({ url }));
    }
  };

  /**
   * Display Neural Network removal confirmation popup.
   */
  const onRemoveClick = (id: string) => {
    setRecordId(id);
    setShowConfirmationPopup(true);
  };

  /**
   * Delete a Neural Network and re-fetch Neural Networks.
   */
  const onNetworkDelete = async () => {
    // Delete a Neural Network
    await dispatch(deleteNeuralNetwork(recordId));
    // Reset "recordId" and "showConfirmationPopup" values
    setRecordId("");
    setShowConfirmationPopup(false);
    // Reset page number
    setPage(0);
    // Fetch neural networks
    retrieveNeuralNetworks();
  };

  /**
   * Submit selected Neural Networks.
   */
  const onNeuralNetworkSelect = async () => {
    const data = {
      neural_networks: selectedNetworks,
    };
    const success = await dispatch(selectNeuralNetworks({ data }));

    // Run optional afterNeuralNetworkSelect function
    if (success && afterNeuralNetworkSelect) {
      afterNeuralNetworkSelect();
    }
  };

  /**
   * Get previous page of Neural Networks.
   */
  const getPreviousPage = () => {
    retrieveNeuralNetworks(neuralNetworks.previous);
    setPage(page - 1);
  };

  /**
   * Get next page of Neural Networks.
   */
  const getNextPage = () => {
    // Load next page on Neural Networks
    retrieveNeuralNetworks(neuralNetworks.next);
    // Increase page number
    setPage(page + 1);
  };

  /**
   * Check if Neural Network was uploaded by user.
   */
  function isNNUploadedByUser(neuralNetwork: NeuralNetwork) {
    return neuralNetwork.uploaded_by === user?.id;
  }

  /**
   * Handle Neural Network click.
   */
  function handleNetworkClick(networkId: string) {
    if (onClickNetwork) {
      onClickNetwork(networkId);
    }
  }

  /**
   * Check if submit button should be disabled.
   */
  function isSubmitDisabled() {
    if (!requireSelection) return false;
    else return !selectedNetworks.length;
  }

  /**
   * Render table rows.
   */
  const displayRecords = () => {
    return neuralNetworks.results.map(
      (neural_network: NeuralNetwork, idx: number) => (
        <tr
          key={idx}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          {selectable && (
            <td className="w-4 p-4">
              <div className="flex items-center">
                <input
                  id={neural_network.id}
                  type="checkbox"
                  onChange={onNetworkSelected}
                  checked={selectedNetworks.includes(neural_network.id)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  aria-label={`Select ${neural_network.name}`}
                />
                <label htmlFor="checkbox-table-1" className="sr-only">
                  Checkbox
                </label>
              </div>
            </td>
          )}

          <th scope="row" className="px-6 py-4 font-medium text-gray-900">
            <a
              href=""
              className="text-blue-600 font-medium underline"
              onClick={() => handleNetworkClick(neural_network.id)}
              aria-label={`View details of ${neural_network.name}`}
            >
              {neural_network.name}
            </a>
          </th>
          <td className="px-6 py-4">{neural_network.category_name}</td>
          {!hideRemoveButton && (
            <td className="px-6 py-4">
              <button
                onClick={() => onRemoveClick(neural_network.id)}
                className="font-medium enabled:text-red-600 enabled:hover:underline"
                disabled={!isNNUploadedByUser(neural_network)}
                title={
                  isNNUploadedByUser(neural_network)
                    ? undefined
                    : "You can only delete your own neural networks"
                }
                aria-label={`Remove ${neural_network.name}`}
              >
                Remove
              </button>
            </td>
          )}
        </tr>
      )
    );
  };

  /**
   * Retrieve Neural Networks and Neural Network Categories.
   * Pre-select Neural Networks assigned to user.
   */
  useEffect(() => {
    retrieveNeuralNetworks();
    dispatch(getNeuralNetworkCategories());

    let newSelectedNetworks: string[] = [];
    if (user?.assigned_neural_networks) {
      newSelectedNetworks = user.assigned_neural_networks;
    }
    setSelectedNetworks(newSelectedNetworks);
  }, [user]);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Section */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900 rounded-2xl p-6 mb-6">
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center space-y-4 lg:space-y-0">
            <div>
              <h1 className="text-2xl font-bold text-white mb-2">
                {headerText}
              </h1>
              {subHeaderText && (
                <p className="text-purple-100 dark:text-purple-200">
                  {subHeaderText}
                </p>
              )}
            </div>

            <div className="flex flex-col sm:flex-row gap-3">
              {!hideAddNeuralNetworkButton && (
                <button
                  onClick={() => setShowFormPopup(true)}
                  className="inline-flex items-center px-4 py-2 bg-white/10 hover:bg-white/20 
                           text-white rounded-lg transition-all duration-200"
                >
                  <PlusCircleIcon className="w-5 h-5 mr-2" />
                  <span>Add Neural Network</span>
                </button>
              )}

              {selectable && (
                <button
                  onClick={onNeuralNetworkSelect}
                  disabled={isSubmitDisabled()}
                  className={`inline-flex items-center px-4 py-2 rounded-lg transition-all duration-200
                    ${
                      isSubmitDisabled()
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-white text-purple-700 hover:bg-purple-50"
                    }`}
                >
                  {pendingSelectNeuralNetworks ? (
                    <Spinner size="sm" />
                  ) : (
                    submitLabelText
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm transition-all duration-200">
          {pendingGetNeuralNetworks ? (
            <div className="flex justify-center p-8">
              <Spinner size="xl" />
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left">
                <thead className="text-xs uppercase bg-gray-50 dark:bg-gray-700">
                  <tr>
                    {selectable && (
                      <th scope="col" className="p-4">
                        <span className="sr-only">Select</span>
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-6 py-3 text-gray-700 dark:text-gray-300"
                    >
                      Network Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-gray-700 dark:text-gray-300"
                    >
                      Category
                    </th>
                    {!hideRemoveButton && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-gray-700 dark:text-gray-300"
                      >
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {neuralNetworks.results.map(
                    (neural_network: NeuralNetwork, idx: number) => (
                      <tr
                        key={idx}
                        className="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-200"
                      >
                        {selectable && (
                          <td className="w-4 p-4">
                            <div className="flex items-center">
                              <input
                                id={neural_network.id}
                                type="checkbox"
                                onChange={onNetworkSelected}
                                checked={selectedNetworks.includes(
                                  neural_network.id
                                )}
                                className="w-4 h-4 text-purple-600 bg-gray-100 dark:bg-gray-700 
                                       border-gray-300 dark:border-gray-600 rounded focus:ring-purple-500"
                              />
                            </div>
                          </td>
                        )}
                        <td className="px-6 py-4">
                          <button
                            onClick={() =>
                              handleNetworkClick(neural_network.id)
                            }
                            className="text-purple-600 dark:text-purple-400 hover:text-purple-800 
                                   dark:hover:text-purple-300 font-medium transition-colors duration-200"
                          >
                            {neural_network.name}
                          </button>
                        </td>
                        <td className="px-6 py-4 text-gray-700 dark:text-gray-300">
                          {neural_network.category_name}
                        </td>
                        {!hideRemoveButton && (
                          <td className="px-6 py-4">
                            <button
                              onClick={() => onRemoveClick(neural_network.id)}
                              disabled={!isNNUploadedByUser(neural_network)}
                              className="text-red-600 dark:text-red-400 hover:text-red-800 
                                     dark:hover:text-red-300 font-medium disabled:opacity-50 
                                     disabled:cursor-not-allowed transition-colors duration-200"
                            >
                              Remove
                            </button>
                          </td>
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Pagination */}
        <div className="mt-6">
          <Pagination
            count={neuralNetworks.count}
            page={page}
            previous={neuralNetworks.previous}
            getPreviousPage={getPreviousPage}
            next={neuralNetworks.next}
            getNextPage={getNextPage}
          />
        </div>
      </div>

      {/* Modals */}
      <ConfirmationPopup
        title="Are you sure you want to delete this Neural Network?"
        show={showConfirmationPopup && !!recordId}
        onClose={() => setShowConfirmationPopup(false)}
        onConfirm={onNetworkDelete}
        pendingConfirm={pendingDeleteNeuralNetwork}
      />

      <UploadNeuralNetworkFormPopup
        show={showFormPopup}
        onClose={() => setShowFormPopup(false)}
      />
    </div>
  );
}
