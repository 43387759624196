import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import {
  getMovieDetails,
  getMovieRecommendations,
  getSimilarUsersAndMovies,
} from "redux/movies/moviesSlice";
import {
  XMarkIcon,
  StarIcon,
  CalendarIcon,
  ClockIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { ShimmerLoader } from "components/common/ShimmerLoader";
import PageWrapper from "components/common/pageWrapper";
import { APP_URLS } from "navigation";
import { history } from "../../helpers/history";

// Add this interface for similar users
interface SimilarUser {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_spotify_connected: boolean;
  is_youtube_connected: boolean;
}

// Add these constants at the top of your file
const FALLBACK_BACKDROP =
  "https://images.unsplash.com/photo-1489599849927-2ee91cede3ba?q=80&w=2940&auto=format&fit=crop";
const FALLBACK_POSTER =
  "https://images.unsplash.com/photo-1485846234645-a62644f84728?q=80&w=2940&auto=format&fit=crop";

export default function MovieDetailsPage() {
  const { id } = useParams();
  console.log("ID", id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [movie, setMovie] = useState<any>(null);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [similarUsers, setSimilarUsers] = useState<SimilarUser[]>([]);
  const [imageLoading, setImageLoading] = useState({
    backdrop: true,
    poster: true,
  });

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        setLoading(true);
        console.log("Respo");

        const response = await dispatch(getMovieDetails(Number(id))).unwrap();
        console.log("Response", response);
        setMovie(response);

        // Fetch recommendations
        const recommendationsResponse = await dispatch(
          getMovieRecommendations(Number(id))
        ).unwrap();
        console.log("Recommendations Response", recommendationsResponse);
        setRecommendations(recommendationsResponse);

        // Fetch similar users
        const similarUsersResponse = await dispatch(
          getSimilarUsersAndMovies()
        ).unwrap();
        console.log("similar", similarUsersResponse);
        setSimilarUsers(similarUsersResponse.similar_users);
      } catch (error) {
        console.error("Error fetching movie details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchMovieDetails();
    }
  }, [id, dispatch]);
  console.log("Movie", movie);
  if (loading) {
    return (
      <PageWrapper>
        <div className="min-h-screen bg-[#1a1f2b] p-4">
          <div className="max-w-7xl mx-auto space-y-8">
            <ShimmerLoader className="h-[400px] w-full rounded-2xl" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <ShimmerLoader className="h-[600px] rounded-xl" />
              <ShimmerLoader className="h-[600px] md:col-span-2 rounded-xl" />
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }

  if (!movie) return null;

  // Add this helper function to generate a random gradient background
  const getRandomGradient = () => {
    const gradients = [
      "from-purple-500 to-indigo-500",
      "from-blue-500 to-teal-500",
      "from-green-500 to-emerald-500",
      "from-yellow-500 to-orange-500",
      "from-red-500 to-pink-500",
      "from-pink-500 to-purple-500",
    ];
    return gradients[Math.floor(Math.random() * gradients.length)];
  };

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-[#1a1f2b]">
        {/* Hero Section */}
        <div className="relative h-[500px]">
          <div className="absolute">
            <img
              src={movie.backdrop_path || FALLBACK_BACKDROP}
              alt={movie.original_title}
              className="w-full h-full object-cover"
            />
            <div
              className="absolute inset-0 bg-gradient-to-t from-gray-50 via-gray-50/80 to-transparent
                          dark:from-[#1a1f2b] dark:via-[#1a1f2b]/80 dark:to-transparent"
            />
          </div>

          {/* Back Button */}
          <button
            onClick={() => history.navigate(APP_URLS.MOVIES)}
            className="absolute top-6 left-6 z-10 p-2 rounded-full bg-black/50 
                   hover:bg-black/70 text-white transition-all duration-200"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>

          {/* Movie Info */}
          <div className="absolute bottom-0 left-0 right-0">
            <div className="max-w-7xl mx-auto px-6 pb-8 flex gap-8">
              <div
                className="relative w-64 h-96 rounded-xl shadow-2xl 
                           ring-1 ring-gray-200 dark:ring-white/10 
                           transform -translate-y-16 overflow-hidden"
              >
                <img
                  src={movie.image_url || FALLBACK_POSTER}
                  alt={movie.original_title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1 pt-8">
                <h1 className="text-5xl font-bold text-gray-900 dark:text-white mb-4">
                  {movie.original_title}
                </h1>
                <div className="flex items-center gap-6 text-gray-600 dark:text-gray-300 mb-6">
                  <div className="flex items-center gap-2">
                    <StarIcon className="w-5 h-5 text-yellow-400" />
                    <span className="font-medium">{movie.vote_average}</span>
                    <span className="text-gray-400">
                      ({movie.vote_count} votes)
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <CalendarIcon className="w-5 h-5" />
                    <span>{movie.year}</span>
                  </div>
                  {movie.runtime > 0 && (
                    <div className="flex items-center gap-2">
                      <ClockIcon className="w-5 h-5" />
                      <span>{movie.runtime} min</span>
                    </div>
                  )}
                </div>
                <p className="text-gray-600 dark:text-gray-300 max-w-3xl leading-relaxed">
                  {movie.overview}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Similar Users Section */}
        {similarUsers.length > 0 && (
          <div className="max-w-7xl mx-auto px-6 py-8 pt-12">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
              Similar Movie Enthusiasts
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {similarUsers.map((user) => (
                <div
                  key={user.id}
                  className="bg-white dark:bg-[#252d3d]/50 backdrop-blur-sm 
                           border border-gray-200 dark:border-white/5 rounded-xl p-4 
                           hover:bg-gray-50 dark:hover:bg-[#252d3d] 
                           transition-all duration-300"
                >
                  {/* User Avatar/Initial */}
                  <div className="flex items-center gap-4">
                    <div
                      className={`
                      w-12 h-12 rounded-full flex items-center justify-center
                      text-lg font-semibold ${getRandomGradient()} text-white
                    `}
                    >
                      {user.username.charAt(0).toUpperCase()}
                    </div>

                    <div className="flex-1">
                      <h3 className="text-gray-900 dark:text-white font-medium">
                        {user.username}
                      </h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {user.email}
                      </p>
                    </div>
                  </div>

                  {/* Connected Platforms */}
                  <div className="mt-4 flex gap-2">
                    {user.is_spotify_connected && (
                      <div
                        className="px-2 py-1 rounded-full bg-green-500/10 
                                   border border-green-500/20 text-green-400 text-xs"
                      >
                        Spotify
                      </div>
                    )}
                    {user.is_youtube_connected && (
                      <div
                        className="px-2 py-1 rounded-full bg-red-500/10 
                                   border border-red-500/20 text-red-400 text-xs"
                      >
                        YouTube
                      </div>
                    )}
                  </div>

                  {/* Interaction Hint */}
                  <div className="mt-4 pt-4 border-t border-gray-200 dark:border-white/5">
                    <button
                      className="w-full py-2 rounded-lg bg-gray-200 dark:bg-gray-700/50 
                                   hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 text-sm
                                   transition-all duration-300"
                    >
                      View Profile
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Content Section */}
        <div className="max-w-7xl mx-auto px-6 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Left Column - Details */}
            <div className="space-y-8">
              {/* Genres */}
              {movie.genres && movie.genres.length > 0 && (
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                    Genres
                  </h2>
                  <div className="flex flex-wrap gap-2">
                    {movie.genres.map((genre: any) => (
                      <span
                        key={genre.id}
                        className="px-4 py-2 rounded-full bg-blue-500/10 text-blue-400
                               border border-blue-500/20"
                      >
                        {genre.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {/* Ratings */}
              <div>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  Ratings
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-4 rounded-xl bg-gray-200 dark:bg-gray-700 border border-gray-200 dark:border-white/5">
                    <div className="text-3xl font-bold text-yellow-400 mb-1">
                      {movie.vote_average}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      TMDB Rating
                    </div>
                  </div>
                  {movie.imdb_rating > 0 && (
                    <div className="p-4 rounded-xl bg-gray-200 dark:bg-gray-700 border border-gray-200 dark:border-white/5">
                      <div className="text-3xl font-bold text-yellow-400 mb-1">
                        {movie.imdb_rating}
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        IMDb Rating
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Additional Details */}
              <div>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  Details
                </h2>
                <div className="space-y-3 text-gray-600 dark:text-gray-300">
                  <div className="flex justify-between">
                    <span className="text-gray-500 dark:text-gray-400">
                      Original Language
                    </span>
                    <span className="uppercase">{movie.original_language}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500 dark:text-gray-400">
                      Release Date
                    </span>
                    <span>
                      {new Date(movie.release_date).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500 dark:text-gray-400">
                      Popularity
                    </span>
                    <span>{movie.popularity}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Recommendations */}
            <div className="md:col-span-2">
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
                Recommended Movies
              </h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                {recommendations
                  ?.slice(0, 8)
                  .map((title: string, index: number) => (
                    <div
                      key={index}
                      className="bg-white dark:bg-[#252d3d]/50 backdrop-blur-sm 
                               border border-gray-200 dark:border-white/5 
                               rounded-xl aspect-[2/3] relative overflow-hidden 
                               group hover:scale-105 transition-all duration-300"
                    >
                      {/* Gradient Background instead of image */}
                      <div
                        className={`absolute inset-0 bg-gradient-to-br ${getRandomGradient()} opacity-90`}
                      />

                      {/* Content */}
                      <div className="absolute inset-0 p-4 flex flex-col justify-between">
                        <div className="flex items-start justify-between">
                          <div className="text-xl font-bold text-gray-900 dark:text-white opacity-40">
                            #{index + 1}
                          </div>
                          <div className="bg-gray-200 dark:bg-gray-700/20 backdrop-blur-sm rounded-full px-2 py-1">
                            <StarIcon className="w-4 h-4 text-yellow-300" />
                          </div>
                        </div>

                        <div>
                          <h3 className="text-gray-900 dark:text-white font-medium text-sm line-clamp-3 mb-2">
                            {title}
                          </h3>
                          <div className="flex items-center gap-2 text-xs text-gray-700 dark:text-gray-300">
                            <PlayIcon className="w-4 h-4" />
                            <span>View Details</span>
                          </div>
                        </div>
                      </div>

                      {/* Hover Effect */}
                      <div className="absolute inset-0 bg-gray-800 dark:bg-gray-200/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    </div>
                  ))}
              </div>

              {/* Show More Button */}
              {recommendations?.length > 8 && (
                <div className="mt-8 text-center">
                  <button
                    onClick={() => {
                      /* Handle show more logic */
                    }}
                    className="px-6 py-2 bg-gray-200 dark:bg-gray-700/10 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 
                             rounded-full backdrop-blur-sm transition-all duration-300
                             hover:scale-105 border border-gray-200 dark:border-white/10"
                  >
                    Show More Recommendations
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
