import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as brainCoinService from "../../services/brainCoinService";
import { Advertiser, ConnectedAdvertiser } from "../../types/brainCoin";
import { ErrorMessages } from "../../types/redux/slice";

interface BrainCoinState {
  advertisers: Array<Advertiser>;
  connectedAdvertisers: Array<ConnectedAdvertiser>;

  retrieveAdvertiserErrorMessages: ErrorMessages;
  retrieveConnectedAdvertiserErrorMessages: ErrorMessages;
  setConnectedAdvertiserErrorMessages: ErrorMessages;

  pendingRetrieveAdvertisers: boolean;
  pendingRetrieveConnectedAdvertisers: boolean;
  pendingSetConnectedAdvertisers: boolean;
}

const initialState: BrainCoinState = {
  advertisers: [],
  connectedAdvertisers: [],

  retrieveAdvertiserErrorMessages: {},
  retrieveConnectedAdvertiserErrorMessages: {},
  setConnectedAdvertiserErrorMessages: {},

  pendingRetrieveAdvertisers: false,
  pendingRetrieveConnectedAdvertisers: false,
  pendingSetConnectedAdvertisers: false,
};

export const brainCoinSlice = createSlice({
  name: "brainCoin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveAdvertisers.pending, (state, action) => {
      state.pendingRetrieveAdvertisers = true;
      state.retrieveAdvertiserErrorMessages = {};
    });
    builder.addCase(retrieveAdvertisers.fulfilled, (state, action) => {
      state.pendingRetrieveAdvertisers = false;
      state.advertisers = action.payload;
    });
    builder.addCase(retrieveAdvertisers.rejected, (state, action) => {
      state.pendingRetrieveAdvertisers = false;
      state.retrieveAdvertiserErrorMessages = action.payload || {};
    });

    builder.addCase(retrieveConnectedAdvertisers.pending, (state, action) => {
      state.pendingRetrieveConnectedAdvertisers = true;
      state.retrieveConnectedAdvertiserErrorMessages = {};
    });
    builder.addCase(retrieveConnectedAdvertisers.fulfilled, (state, action) => {
      state.pendingRetrieveConnectedAdvertisers = false;
      state.connectedAdvertisers = action.payload;
    });
    builder.addCase(retrieveConnectedAdvertisers.rejected, (state, action) => {
      state.pendingRetrieveConnectedAdvertisers = false;
      state.retrieveConnectedAdvertiserErrorMessages = action.payload || {};
    });

    builder.addCase(setConnectedAdvertisers.pending, (state, action) => {
      state.pendingSetConnectedAdvertisers = true;
      state.setConnectedAdvertiserErrorMessages = {};
    });
    builder.addCase(setConnectedAdvertisers.fulfilled, (state, action) => {
      state.pendingSetConnectedAdvertisers = false;
      state.connectedAdvertisers = action.payload;
    });
    builder.addCase(setConnectedAdvertisers.rejected, (state, action) => {
      state.pendingSetConnectedAdvertisers = false;
      state.setConnectedAdvertiserErrorMessages = action.payload || {};
    });
  },
});

export const retrieveAdvertisers = createAsyncThunk<
  Advertiser[],
  undefined,
  { rejectValue: ErrorMessages }
>("brainCoin/retrieveAdvertisers", async (data, thunkApi) => {
  try {
    const response = await brainCoinService.retrieveAdvertisers();
    console.log("::",response);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const retrieveConnectedAdvertisers = createAsyncThunk<
  ConnectedAdvertiser[],
  undefined,
  { rejectValue: ErrorMessages }
>("brainCoin/retrieveConnectedAdvertisers", async (data, thunkApi) => {
  try {
    const response = await brainCoinService.retrieveConnectedAdvertisers();
    console.log("::connected",response);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const setConnectedAdvertisers = createAsyncThunk<
  ConnectedAdvertiser[],
  ConnectedAdvertiser[],
  { rejectValue: ErrorMessages }
>("brainCoin/setConnectedAdvertisers", async (data, thunkApi) => {
  try {
    const response = await brainCoinService.setConnectedAdvertisers(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
