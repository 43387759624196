import { Spinner } from "flowbite-react";
import { displayErrors } from "../../helpers/errors";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { retrieveQuizResults } from "../../redux/quiz/quizSlice";
import {
  QuizResults as QuizResultsType,
  QuizResultsAnswerOption,
  QuizResultsQuestion,
} from "../../types/quiz";

interface Props {
  quizId: string | null;
}

/**
 * Component responsible for rendering quiz results.
 */
export default function QuizResults({ quizId }: Props) {
  const dispatch = useAppDispatch();
  const quizResults = useAppSelector((state) => state.quiz.quizResults);
  const pendingRetrieveQuizResults = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizResults
  );
  const retrieveQuizResultsErrorMessages = useAppSelector(
    (state) => state.quiz.retrieveQuizResultsErrorMessages
  );

  /**
   * Calculates ratio of x to y as a percentage.
   */
  function calculateRatio(x: number, y: number) {
    return ((x / y) * 100).toFixed(2) + "%";
  }

  /**
   * Renders separator between quiz results.
   */
  function renderSeparator() {
    return (
      <div
        className="col-span-2 border-gray-200 dark:border-gray-700 border-b-2"
        role="separator"
      ></div>
    );
  }

  /**
   * Renders summary.
   */
  function renderQuizSummary(quizResults: QuizResultsType) {
    return (
      <div
        className="grid grid-cols-2 gap-x-8 gap-y-4 p-4 
                    bg-white dark:bg-gray-800 
                    border border-gray-200 dark:border-gray-700 
                    shadow-md rounded-lg"
      >
        <div className="text-right text-gray-600 dark:text-gray-300">
          Number of users who have taken this quiz:
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          {quizResults.completed_by_count}
        </div>
        <div className="text-right text-gray-600 dark:text-gray-300">
          Number of individual attempts at the quiz:
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          {quizResults.attempt_count}
        </div>
      </div>
    );
  }

  /**
   * Renders a question.
   */
  function renderQuestion(question: QuizResultsQuestion) {
    return (
      <div
        className="grid grid-cols-2 gap-x-8 gap-y-4 p-4 
                    bg-white dark:bg-gray-800 
                    border border-gray-200 dark:border-gray-700 
                    shadow-md rounded-lg"
      >
        <div className="text-lg text-gray-900 dark:text-white text-center col-span-2 font-bold">
          {question.text}
        </div>
        {renderSeparator()}
        {renderAnswers(question.answer_options)}
      </div>
    );
  }

  /**
   * Renders answers to a question.
   */
  function renderAnswers(answerOptions: Array<QuizResultsAnswerOption>) {
    return answerOptions.map((answerOption, idx) => (
      <React.Fragment key={idx}>
        <div className="text-right text-gray-600 dark:text-gray-300">
          Answer:
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          {answerOption.text}
        </div>

        {answerOption.open_question_values.length > 0 ? (
          <React.Fragment>
            <div className="text-right text-gray-600 dark:text-gray-300">
              Answers provided by users:
            </div>
            <div className="text-gray-700 dark:text-gray-300">
              {answerOption.open_question_values.map((text, idx) => (
                <React.Fragment key={idx}>
                  <div>{text}</div>
                  {idx !== answerOption.open_question_values.length - 1 &&
                    renderSeparator()}
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="text-right text-gray-600 dark:text-gray-300">
              Number of users who have selected this answer:
            </div>
            <div className="text-gray-700 dark:text-gray-300">
              {answerOption.count}
            </div>
          </React.Fragment>
        )}
        <React.Fragment>
          <div className="text-right text-gray-600 dark:text-gray-300">
            Percentage of users taking part in the quiz who have selected this
            answer
          </div>
          <div className="text-gray-700 dark:text-gray-300">
            {calculateRatio(
              answerOption.count,
              quizResults?.attempt_count || 1
            )}
          </div>
        </React.Fragment>
        {idx !== answerOptions.length - 1 && renderSeparator()}
      </React.Fragment>
    ));
  }

  /**
   * Retrieves quiz results whenever quizId changes.
   */
  useEffect(() => {
    if (quizId) {
      dispatch(retrieveQuizResults({ quizId }));
    }
  }, [quizId]);

  /**
   * Renders a loading spinner if quiz results are being retrieved.
   */
  if (pendingRetrieveQuizResults) {
    return (
      <div>
        <Spinner size="sm" aria-label="Loading quiz results" />
      </div>
    );
  }

  /**
   * Renders error messages if there are any.
   */
  if (retrieveQuizResultsErrorMessages) {
    const errorsPresent =
      Object.keys(retrieveQuizResultsErrorMessages).length > 0;
    if (errorsPresent) {
      return (
        <div>
          {Object.keys(retrieveQuizResultsErrorMessages).map((key) => (
            <div key={key}>
              {displayErrors(retrieveQuizResultsErrorMessages[key])}
            </div>
          ))}
        </div>
      );
    }
  }

  /**
   * Renders error message if there are no quiz results.
   */
  if (quizResults === null) {
    return <div>{displayErrors("Failed to retrieve results")}</div>;
  }

  return (
    <div className="space-y-4">
      {renderQuizSummary(quizResults)}
      {quizResults.questions.map((question, idx) => (
        <React.Fragment key={idx}>{renderQuestion(question)}</React.Fragment>
      ))}
    </div>
  );
}
