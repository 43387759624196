import React, { useEffect } from "react";
import {
  retrieveTrackDataset,
  retrieveUsersDataset,
} from "redux/feed/feedDatasetSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { APP_URLS } from "navigation";
import { updateFeedItem } from "redux/feed/feedItemsSlice";
import { isDispatchResponseError } from "redux/utils";
import FeedItemDatasetInfo from "../common/FeedItemData";

interface Props {
  trackId: string;
}

/**
 * Component for displaying feed data details of a track item.
 */
export default function FeedDataTrackDetails({ trackId }: Props) {
  const dispatch = useAppDispatch();
  const track = useAppSelector((state) => state.feedDataset.track);
  const pending = useAppSelector(
    (state) => state.feedDataset.retrieveTrackDatasetPending
  );

  function onClickRow(itemId: string) {
    // Navigate to user details page
    window.location.href = APP_URLS.FEED_DATA_USER_DETAILS.replace(
      ":userId",
      itemId
    );
  }

  function fetchData() {
    if (trackId) {
      dispatch(retrieveTrackDataset({ trackId }));
    }
  }

  async function onHideFeedItem() {
    if (track) {
      const response = await dispatch(
        updateFeedItem({ id: track.id, data: { hidden: !track.hidden } })
      );
      if (!isDispatchResponseError(response)) {
        fetchData();
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [trackId]);

  return (
    <div className="space-y-8 p-6">
      {/* Track Information */}
      <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 overflow-hidden">
        <FeedItemDatasetInfo
          feedItemDataset={track}
          pending={pending}
          onHideFeedItem={onHideFeedItem}
        />
      </div>

      {/* Users Table */}
      <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 p-6">
        <FeedDataTable
          headerText="Users who interacted with this track"
          defaultOrdering="email"
          paginatedList={useAppSelector((state) => state.feedDataset.users)}
          fetchThunkFunction={retrieveUsersDataset}
          pending={useAppSelector(
            (state) => state.feedDataset.retrieveTrackDatasetPending
          )}
          labels={{
            email: "Email",
            username: "Username",
            liked: "Liked",
            disliked: "Disliked",
            watched: "Watched",
            skipped: "Skipped",
          }}
          additionalQueryParams={{ track_interacted_with: trackId }}
          onClickRow={onClickRow}
        />
      </div>
    </div>
  );
}
