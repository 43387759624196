import { APP_URLS } from "../../../navigation";
import React, { ReactNode } from "react";

/**
 * Component that renders a list item used in DesktopNavBar item.
 */
export default function DesktopNavBarItem({
  href,
  icon,
  label,
}: {
  href: string;
  icon: ReactNode;
  label: string;
}) {
  const getOptionBackground = (url: string) => {
    // Handle special cases
    if (
      url === APP_URLS.FEEDBACK &&
      window.location.href.includes(APP_URLS.FEEDBACK_LIST)
    ) {
      // Handle conflicts between /feedback and /feedbacks
      return "bg-white hover:bg-gray-100";
    } else if (window.location.href.includes(url)) {
      return "bg-gray-200 hover:bg-gray-300";
    } else {
      return "bg-white hover:bg-gray-100";
    }
  };

  return (
    <div>
      <a
        href={href}
        className={`flex items-center p-2 text-base font-normal text-gray-700 rounded-lg transition-all hover:text-white  ${getOptionBackground(
          href,
        )}`}
        aria-label={label}
        role="button"
        tabIndex={0}
      >
        {icon}
        <span className="ml-3 text-gray-700 hover:text-black-900">{label}</span>
      </a>
    </div>
  );
}
