import { StripeNFTProduct } from "../../types/payments";
import NoImage from "../../images/No-Image.png";
import React from "react";
import { Spinner } from "flowbite-react";
import styles from "./NFTProductClickable.module.css";

interface Props {
  data: StripeNFTProduct;
  onClick: () => void;
  selected: boolean;
  isLoading?: boolean;
}

export default function NFTProductClickable({
  data,
  onClick,
  selected,
  isLoading,
}: Props) {
  const [imageLoading, setImageLoading] = React.useState(true);

  function getImageSrc() {
    return data.images[0] || NoImage;
  }

  function formatPrice(price: number) {
    return "$" + (price / 100).toLocaleString();
  }
  
  return (
    <div
      className={`${
        selected ? "bg-cyan-700 text-white" : "bg-white"
      } w-60 p-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:cursor-pointer border-2 ${
        selected ? "border-cyan-600" : "border-gray-200"
      } relative`}
      onClick={onClick}
    >
      {isLoading && (
        <div className={styles.loaderContainer}>
          <img
            src="https://braincoin.com/assets/Gold-Brain-Coin-DL9cS1jd.png"
            alt="Loading..."
            className={styles.coinLoader}
          />
          <span className="text-sm text-white-700 font-semibold">
            Processing...
          </span>
        </div>
      )}

      <div className="relative w-full h-48 rounded-lg overflow-hidden mb-4">
        {imageLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
            <Spinner size="lg" />
          </div>
        )}
        <img
          src={getImageSrc()}
          alt={data.name}
          className={`object-contain w-full h-full bg-gray-50 ${
            imageLoading ? "opacity-0" : "opacity-100"
          } transition-opacity duration-300`}
          onLoad={() => setImageLoading(false)}
        />
      </div>
      <div className="font-medium text-center text-lg mb-2">{data.name}</div>
      <div className="font-bold text-center text-xl mb-3">
        {formatPrice(data.price.unit_amount)}
      </div>
      <div
        className={`text-sm ${selected ? "text-cyan-100" : "text-gray-600"}`}
      >
        If you buy this NFT, you will also receive{" "}
        <span className="font-medium">{data.brain.toLocaleString()} BRAIN</span>
      </div>
      <div
        className={`text-sm mt-3 ${
          selected ? "text-cyan-100" : "text-gray-600"
        }`}
      >
        {data.description}
      </div>
    </div>
  );
}
