/**
 * This module provides utility functions for making HTTP requests to the Braincargo API
 * using axios. It includes methods for GET, POST, PUT, PATCH, and DELETE requests,
 * and handles authentication through access tokens. Error handling is integrated with
 * toast notifications and redirects for unauthorized access.
 * Admin notifications are sent through a Lambda function when server errors occur..
 */

import axios from "axios";
import {
  getAccessToken,
  removeTokens,
  getRefreshToken,
  setTokens,
  isTokenValid,
} from "./localStorage";
import { history } from "../helpers/history";
import { QueryParams } from "../types/api";
import { APP_URLS } from "../navigation";
import { toast } from "react-toastify";

/**
 * Default page size for paginating API requests
 */
export const PAGE_SIZE = 8;

/**
 * Base URL for API requests
 */
export const API_BASE_URL =
  "https://staging-api.braincargo.com";
  // process.env.REACT_APP_API_BASE_URL || "https://qa-api.braincargo.com";
  // process.env.REACT_APP_API_BASE_URL || "https://staging-api.braincargo.com";

/**
 * Generate headers for API requests
 */
const generateHeaders = function (contentType = "application/json") {
  const accessToken = getAccessToken();
  return {
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

/**
 * Remove tokens and redirect to login page if response status is 401, otherwise return response data
 */
const handleResponse = async (response: any) => {
  if ([403, 400, 404].includes(response.status)) {
    removeTokens();
    history.navigate(APP_URLS.LOGIN);
  }
  return response.data;
};

const handleError = async (error: any) => {
  if (error.response) {
    const status = error.response.status;

    // Handle 401 separately
    if (status === 401) {
      try {
        // Check if the error is due to an expired token
        const originalRequest = error.config;

        // Prevent infinite retry loops
        if (originalRequest._retry) {
          removeTokens();
          history.navigate(APP_URLS.LOGIN);
          return Promise.reject(error);
        }

        originalRequest._retry = true;

        // If token is invalid or a refresh is needed
        if (!isTokenValid(getAccessToken()) || status === 401) {
          const response = await retryOriginalRequest(error);
          return response.data;
        }
      } catch (refreshError) {
        removeTokens();
        history.navigate(APP_URLS.LOGIN);
        return Promise.reject(refreshError);
      }
    } else if ([403, 400, 404].includes(status)) {
      // removeTokens();
      // history.navigate(APP_URLS.LOGIN);
      toast.error(
        `${status} Error: ${
          error.response.data.message || "Resource not found."
        }`
      );
      console.error(`${status} Error:`, error.response);
    } else if (status >= 500 && status < 600) {
      toast.error("Server error. Please try again later.");
      console.error("Server responded with error:", status);
      await sendNotificationToLambda();
    } else {
      
      console.error("Error occurred:", error.response.status);
    }
  } else if (error.request) {
    toast.error("Server is down or unreachable. Please try again later.");
    console.error("Server is down or unreachable");
    await sendNotificationToLambda();
  } else {
    toast.error("An error occurred. Please try again.");
    console.error("Error in setting up the request", error.message);
  }

  throw error;
};

/**
 * Make a GET request to the API
 */
export const get = async function (
  url: string,
  noHeaders = false,
  contentType?: string,
  queryParams?: QueryParams
) {
  if (!noHeaders && !isTokenValid(getAccessToken())) {
    try {
      await refreshAccessToken();
    } catch (error) {
      await handleError(error);
    }
  }
  const headers = noHeaders ? {} : generateHeaders(contentType);
  const urlObj = new URL(API_BASE_URL + url);

  urlObj.searchParams.set("page_size", PAGE_SIZE.toString());

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const val = queryParams[key];
      if (val) {
        urlObj.searchParams.set(key, val);
      }
    });
  }

  try {
    const response = await axios.get(urlObj.toString(), headers);
    return handleResponse(response);
  } catch (err: any) {
    await handleError(err);
    throw handleResponse(err.response);
  }
};

/**
 * Make a POST request to the API
 */
export const post = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string
) {
  if (!noHeaders && !isTokenValid(getAccessToken())) {
    try {
      await refreshAccessToken();
    } catch (error) {
      await handleError(error);
    }
  }

  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.post(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    await handleError(err);
    throw handleResponse(err.response);
  }
};

/**
 * Make a PUT request to the API
 */
export const put = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string
) {
  if (!noHeaders && !isTokenValid(getAccessToken())) {
    try {
      await refreshAccessToken();
    } catch (error) {
      await handleError(error);
    }
  }

  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.put(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a PATCH request to the API
 */
export const patch = async function (
  url: string,
  body: any,
  noHeaders = false,
  contentType?: string
) {
  if (!noHeaders && !isTokenValid(getAccessToken())) {
    try {
      await refreshAccessToken();
    } catch (error) {
      await handleError(error);
    }
  }

  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.patch(API_BASE_URL + url, body, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

/**
 * Make a DELETE request to the API
 */
export const deleteRequest = async function (
  url: string,
  noHeaders = false,
  contentType?: string
) {
  if (!noHeaders && !isTokenValid(getAccessToken())) {
    try {
      await refreshAccessToken();
    } catch (error) {
      await handleError(error);
    }
  }

  const headers = noHeaders ? {} : generateHeaders(contentType);

  try {
    const response = await axios.delete(API_BASE_URL + url, headers);
    return handleResponse(response);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

const sendNotificationToLambda = async () => {
  const lambdaUrl1 =
    "https://bvcbzxl640.execute-api.us-west-2.amazonaws.com/prod/send-notification-smtp";
  const lambdaUrl2 =
    "https://bvcbzxl640.execute-api.us-west-2.amazonaws.com/prod/ses-sns";

  try {
    const response1 = await axios.get(lambdaUrl1);

    const response2 = await axios.get(lambdaUrl2);

  } catch (error) {
    console.error(
      "Failed to notify admin through one or both endpoints",
      error
    );
  }
};

// Add a flag to prevent multiple refresh attempts at once
let isRefreshing = false;
let failedQueue: Array<{
  resolve: (token: string) => void;
  reject: (error: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token as string);
    }
  });
  failedQueue = [];
};

/**
 * Refresh the access token using refresh token
 */
const refreshAccessToken = async () => {
  try {
    const refreshToken = await getRefreshToken();
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }
    const response = await axios.post(
      `${API_BASE_URL}/api/token/refresh/`,
      { refresh: refreshToken },
      { headers: { "Content-Type": "application/json" } }
    );

    const newAccessToken = response.data.access;
    setTokens({ access: newAccessToken, refresh: refreshToken });
    return newAccessToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};

/**
 * Retry the original request with new access token
 */
const retryOriginalRequest = async (error: any) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });

    // If no refresh is in progress, start one
    if (!isRefreshing) {
      isRefreshing = true;

      refreshAccessToken()
        .then((newToken) => {
          processQueue(null, newToken);
        })
        .catch((err) => {
          processQueue(err, null);
        })
        .finally(() => {
          isRefreshing = false;
        });
    }
  })
    .then((token) => {
      error.config.headers["Authorization"] = `Bearer ${token}`;
      return axios(error.config);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
