import { UserIcon } from "@heroicons/react/24/outline";
import React from "react";
import { User } from "types/user";
import { ShimmerLoader } from "components/common/ShimmerLoader";

interface Props {
  user: User | null;
  pending: boolean;
}

const UserDataShimmer = () => (
  <div className="max-w-2xl mx-auto p-6 space-y-8">
    {/* Avatar & Basic Info Shimmer */}
    <div className="flex flex-col items-center mb-8">
      <div className="relative">
        <div
          className="absolute -inset-4 bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10 
                       rounded-full opacity-50 blur-xl animate-pulse"
        ></div>
        <div className="relative bg-[#252d3d]/50 rounded-full p-6 mb-4 backdrop-blur-sm">
          <ShimmerLoader className="w-16 h-16 rounded-full" />
        </div>
      </div>
      <ShimmerLoader className="h-6 w-48 mb-2" />
      <ShimmerLoader className="h-4 w-32" />
    </div>

    {/* User Details Shimmer */}
    <div className="bg-[#252d3d]/50 rounded-xl border border-white/5 overflow-hidden backdrop-blur-sm">
      {[...Array(4)].map((_, i) => (
        <div
          key={i}
          className="flex items-center justify-between py-4 px-6 border-b border-white/5
                        hover:bg-white/5 transition-all duration-300 ease-in-out"
        >
          <ShimmerLoader className="h-4 w-24" />
          <ShimmerLoader className="h-4 w-48" />
        </div>
      ))}
    </div>
  </div>
);

export default function UserData({ user, pending }: Props) {
  const renderInfoRow = (label: string, value: string | null | undefined) => (
    <div className="flex items-center justify-between py-3 border-b border-gray-700/50">
      <span className="text-gray-400">{label}</span>
      <span className="text-white font-medium">{value || "-"}</span>
    </div>
  );

  if (pending) {
    return <UserDataShimmer />;
  }

  if (!user) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <p className="text-gray-400">Failed to retrieve user information</p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto">
      {/* User Avatar & Basic Info */}
      <div className="flex flex-col items-center mb-8">
        <div className="bg-gray-800 rounded-full p-6 mb-4">
          <UserIcon className="w-16 h-16 text-gray-400" />
        </div>
        <h3 className="text-xl font-semibold text-white mb-1">
          {user.username}
        </h3>
        <p className="text-gray-400">{user.email}</p>
      </div>

      {/* User Details */}
      <div className="bg-gray-800/50 rounded-lg p-6 space-y-3">
        {renderInfoRow("Username", user.username)}
        {renderInfoRow("Email", user.email)}
        {renderInfoRow("First Name", user.first_name)}
        {renderInfoRow("Last Name", user.last_name)}
      </div>
    </div>
  );
}
