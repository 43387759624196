import FileUpload from "../../components/common/file/FileUpload";
import Modal from "../../components/common/modal";
import ShazamIcon from "../../components/icons/ShazamIcon";
import { displayErrors } from "../../helpers/errors";
import { APP_URLS } from "../../navigation";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { uploadShazamLibrary } from "../../redux/shazam/shazamSlice";
import { getUser } from "../../redux/user/userSlice";
import { isDispatchResponseError } from "../../redux/utils";

/**
 * A component that allows the user to connect their Shazam account.
 * It also displays the status of the connection.
 */
export default function ShazamConnection() {
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const errorMessages = useAppSelector((state) => state.shazam.errorMessages);
  const pendingUpload = useAppSelector((state) => state.shazam.pending);
  const dispatch = useAppDispatch();

  async function toggleLibraryModal(e: React.MouseEvent) {
    e.stopPropagation();
    setShowLibraryModal(!showLibraryModal);
  }

  /**
   * Uploads the Shazam library csv file.
   */
  async function handleSubmitLibrary() {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(uploadShazamLibrary(formData));
      if (!isDispatchResponseError(response)) {
        setShowSuccessMessage(true);
        dispatch(getUser());
      } else {
        setShowSuccessMessage(false);
      }
    }
  }

  /**
   * Closes the modal and resets the state.
   */
  function handleCloseModal() {
    setFile(null);
    setShowSuccessMessage(false);
    setShowLibraryModal(false);
  }

  return (
    <>
      <button
        className="flex justify-center space-x-2 items-center p-2 bg-[#e6f0ff] rounded-full border-2 border-[#066bff] hover:cursor-pointer hover:bg-[#cce0ff] select-none"
        onClick={toggleLibraryModal}
      >
        <ShazamIcon className="w-6 h-6" />
        <span className="text-gray-600 text-sm font-semibold">
          {user?.is_shazam_library_uploaded
            ? "Library Uploaded"
            : "Upload Shazam Library"}
        </span>
      </button>
      <Modal
        show={showLibraryModal}
        onClose={handleCloseModal}
        headerText="Upload Shazam Library"
        onSubmit={handleSubmitLibrary}
        submitButtonDisabled={!file || pendingUpload}
        submitButtonPending={pendingUpload}
        body={
          <div className="space-y-4">
            {showSuccessMessage ? (
              <>
                <div className="text-green-500 font-bold">
                  Upload successful!
                </div>
              </>
            ) : (
              <>
                <div>
                  Please upload your Shazam library csv file so that BrainCargo
                  can view tracks you have saved to your library.
                </div>
                <div>
                  Confused about generating the csv file?
                  <br />
                  <a
                    href={APP_URLS.GUIDES_SHAZAM_LIBRARY}
                    className="text-blue-600 hover:underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here to see a guide.
                  </a>
                </div>
                <FileUpload
                  file={file}
                  setFile={setFile}
                  maxSize="50 MB"
                  inputId="shazam-library-upload"
                />
                {displayErrors(errorMessages.file)}
              </>
            )}
          </div>
        }
      ></Modal>
    </>
  );
}
