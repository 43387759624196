import React from "react";
import { useAppSelector } from "../../redux/hooks";
import AdSenseAdvertMd from "./AdSenseAdvertMd";

interface Props {
  className: string;
}

const ADVERTISER_IDS = {
  GOOGLE: process.env.REACT_APP_GOOGLE_ADVERTISER_ID,
};

/**
 * Component for displaying an ad by a random provider based on a percentage.
 */
export default function AdByRandomProvider({ className }: Props) {
  const connectedAdvertisers = useAppSelector(
    (state) => state.brainCoin.connectedAdvertisers
  );

  function selectAdvertiserBasedOnPercentage() {
    const breakPoints: number[] = [];
    let breakPoint = 0;
    connectedAdvertisers.forEach((advertiser) => {
      breakPoint += advertiser.ad_percentage as number;
      breakPoints.push(breakPoint);
    });

    // Random number between 0 - 1
    const randomNumber = Math.random();
    let selectedAdvertiserIndex = -1;
    breakPoints.every((breakpoint, index) => {
      if (randomNumber <= breakpoint) {
        selectedAdvertiserIndex = index;
        // Break out of the loop
        return false;
      }
      return true;
    });

    // Handle each possible advertiser ad component
    if (selectedAdvertiserIndex === -1) {
      return null;
    }

    const selectedAdvertiser = connectedAdvertisers[selectedAdvertiserIndex];
    switch (selectedAdvertiser.advertiser) {
      case ADVERTISER_IDS.GOOGLE:
        return <AdSenseAdvertMd className={className} />;
      default:
        return null;
    }
  }

  return selectAdvertiserBasedOnPercentage();
}
