import React from "react";
import Modal from "../../components/common/modal";
import { CheckoutSession, StripeNFTProduct } from "../../types/payments";
import { Button } from "flowbite-react";

interface Props {
  show: boolean;
  onClose: () => void;
  data?: CheckoutSession;
  product?: StripeNFTProduct | null;
  onSelect?: () => void;
  isSelected?: boolean;
}

export default function NFTDetailsModal({
  show,
  onClose,
  data,
  product,
  onSelect,
  isSelected,
}: Props) {
  const displayData = data?.nft_product || product;
  
  if (!displayData) return null;

  // Helper function to get the image URL
  const getImageUrl = (images: any): string => {
    if (Array.isArray(images)) {
      return images[0];
    }
    if (typeof images === 'string') {
      return images;
    }
    if (images && typeof images === 'object' && 'url' in images) {
      return images.url;
    }
    return ''; // fallback empty string or you could use a default image URL
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
    //   size="xl"
      hideFooter
      body={
        <div className="p-4">
          <div className="flex flex-col md:flex-row gap-6">
            {/* NFT Image */}
            <div className="w-full md:w-1/2">
              <div className="relative aspect-square rounded-lg overflow-hidden">
                <img
                  src={getImageUrl(displayData.images)}
                  alt={displayData.name}
                  className="object-cover w-full h-full"
                  onError={(e) => {
                    e.currentTarget.src = '/path/to/fallback-image.jpg'; // Add your fallback image path
                    e.currentTarget.onerror = null; // Prevent infinite loop
                  }}
                />
              </div>
            </div>

            {/* NFT Details */}
            <div className="w-full md:w-1/2">
              <h3 className="text-2xl font-bold mb-4">{displayData.name}</h3>
              <p className="text-gray-600 mb-4">{displayData.description}</p>
              
              {product && (
                <div className="mb-4">
                  <h4 className="font-semibold mb-2">Price</h4>
                  <p className="text-xl font-bold">
                    ${(product.price.unit_amount / 100).toFixed(2)} {product.price.currency.toUpperCase()}
                  </p>
                </div>
              )}

              <div className="mb-6">
                <h4 className="font-semibold mb-2">Details</h4>
                <ul className="list-disc list-inside text-gray-600">
                  <li>Brain: {displayData.brain}</li>
                  <li>Limited Edition NFT</li>
                  <li>Instant Digital Delivery</li>
                </ul>
              </div>

              <div className="flex gap-4">
                {onSelect && (
                  <Button
                    color={isSelected ? "success" : "info"}
                    onClick={onSelect}
                    className="flex-1"
                  >
                    {isSelected ? "Selected" : "Select NFT"}
                  </Button>
                )}
                <Button
                  color="gray"
                  onClick={onClose}
                  className="flex-1"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
} 