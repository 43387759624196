import FileUpload from "../../components/common/file/FileUpload";
import { Button, Radio, Spinner, Textarea } from "flowbite-react";
import { displayErrors } from "../../helpers/errors";
import React, { useState } from "react";
import { createFeedback } from "../../redux/feedback/feedbackSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { isDispatchResponseError } from "../../redux/utils";
import { CreateFeedbackPayload, FEEDBACK_TYPE_LABELS } from "../../types/feedback";

/**
 * Component that renders a form for creating feedback.
 */
export function CreateFeedbackForm() {
  /**
   * Represents the current stage of the form.
   * 0: select feedback type
   * 1: display input fields
   * 2: confirmation
   */
  const [stage, setStage] = useState(0);
  const [type, setType] = useState<CreateFeedbackPayload["type"] | "">("");
  const [file, setFile] = useState<CreateFeedbackPayload["file"]>(null);
  const [description, setDescription] =
    useState<CreateFeedbackPayload["description"]>("");

  const dispatch = useAppDispatch();
  const errorMessagesCreateFeedback = useAppSelector(
    (state) => state.feedback.errorMessagesCreateFeedback
  );
  const pendingCreateFeedback = useAppSelector(
    (state) => state.feedback.pendingCreateFeedback
  );

  /**
   * Submits the form.
   */
  async function handleSubmit() {
    const data = new FormData();
    if (description) data.append("description", description);
    if (file) data.append("file", file as File);
    if (type) data.append("type", type);
    const response = await dispatch(createFeedback(data));

    if (!isDispatchResponseError(response)) {
      setStage(2);
    }
  }

  /**
   * Determines whether the next button should be enabled.
   */
  function isNextButtonEnabled() {
    if (stage === 0) {
      return type !== "";
    } else if (stage === 1) {
      return description !== "";
    } else {
      return true;
    }
  }

  return (
    <div className="space-y-6 max-w-3xl mx-auto">
      {pendingCreateFeedback ? (
        <div className="flex justify-center items-center min-h-[300px]">
          <Spinner size="xl" className="text-blue-600 dark:text-blue-500" />
        </div>
      ) : (
        <>
          {stage === 0 && (
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                What kind of feedback would you like to provide?
              </h2>
              <div className="space-y-3">
                {Object.keys(FEEDBACK_TYPE_LABELS).map((key) => (
                  <div
                    className="flex items-center p-4 bg-gray-50 dark:bg-gray-800 
                      rounded-lg border border-gray-200 dark:border-gray-700 
                      hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors 
                      duration-200 cursor-pointer"
                    key={key}
                    onClick={() =>
                      setType(key as CreateFeedbackPayload["type"])
                    }
                    role="button"
                    tabIndex={0}
                  >
                    <Radio
                      checked={type === key}
                      onChange={() =>
                        setType(key as CreateFeedbackPayload["type"])
                      }
                      className="mr-3 text-blue-600 focus:ring-blue-500 dark:border-gray-600"
                    />
                    <span className="text-gray-700 dark:text-gray-200">
                      {
                        FEEDBACK_TYPE_LABELS[
                          key as CreateFeedbackPayload["type"]
                        ]
                      }
                    </span>
                  </div>
                ))}
                {displayErrors(errorMessagesCreateFeedback.type)}
              </div>
            </div>
          )}

          {stage === 1 && (
            <div className="space-y-6">
              <div>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  Please provide a description of your feedback
                </h2>
                <Textarea
                  placeholder="Enter your feedback here..."
                  rows={7}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full rounded-lg border-gray-300 dark:border-gray-600 
                    dark:bg-gray-700 dark:text-white focus:ring-blue-500 
                    focus:border-blue-500"
                />
                {displayErrors(errorMessagesCreateFeedback.description)}
              </div>

              <div>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  Would you like to upload a file related to your feedback?
                </h2>
                <FileUpload file={file} setFile={setFile} maxSize="50 MB" />
                {displayErrors(errorMessagesCreateFeedback.file)}
              </div>
            </div>
          )}

          {stage === 2 && (
            <div className="text-center py-12">
              <div className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">
                Thank you for your feedback!
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                We appreciate your input and will review it shortly.
              </p>
            </div>
          )}

          {stage !== 2 && (
            <div className="flex flex-row justify-center space-x-4 border-t dark:border-gray-700 pt-6 mt-8">
              <Button
                className="flex-1 px-6 py-2.5 bg-gray-100 dark:bg-gray-700 text-gray-700 
                  dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 
                  disabled:opacity-50 transition-colors duration-200"
                disabled={stage === 0}
                onClick={() => setStage(stage - 1)}
              >
                Previous
              </Button>

              <Button
                className="flex-1 px-6 py-2.5 bg-blue-600 text-white hover:bg-blue-700 
                  disabled:opacity-50 transition-colors duration-200"
                onClick={stage < 1 ? () => setStage(stage + 1) : handleSubmit}
                disabled={!isNextButtonEnabled()}
              >
                {stage < 1 ? (
                  "Next"
                ) : pendingCreateFeedback ? (
                  <Spinner size="sm" className="mx-auto" />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
