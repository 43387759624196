import React from "react";
import MovieSearch from "components/movies/MovieSearch";
import BulkMovieSearch from "components/movies/BulkMovieSearch";
import PageWrapper from "components/common/pageWrapper";
import MainHeader from "components/MainHeader/MainHeader";

export default function MoviesPage() {
  return (
    <PageWrapper>
      <div className="min-h-screen bg-gradient-to-b dark:from-[#1a1f2b] dark:to-[#151923]">
        <MainHeader />
        <div className="container mx-auto px-4 py-8 pt-16">
          <h1 className="text-2xl font-bold mb-8 dark:text-white">
            Movie Search
          </h1>

          <div className="space-y-8">
            {/* Single Movie Search */}
            <div className="dark:bg-[#1e2533]/80 rounded-xl border dark:border-white/5 p-6">
              <MovieSearch />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
