import { QueryParams } from "../types/api";
import { get, patch, post } from "./apiService";
import {
  FeedItemActionsPayload,
  SubmitFeedItemReportPayload,
  ToggleFeedItemReportStatusPayload,
  UpdateFeedItemPayload,
} from "../types/feed";

// ------------------------------
// ----------- Feed Items -------
// ------------------------------

/**
 * Send a GET request to the API to retrieve a specific feed item
 */
export const retrieveFeedItem = async (id: string) => {
  return await get(`/api/feed/${id}`);
};

/**
 * Send a PATCH request to the API to update a feed item
 */
export const updateFeedItem = async (
  id: string,
  data: UpdateFeedItemPayload,
) => {
  return await patch(`/api/feed/${id}/update/`, data);
};

/**
 * Send a GET request to the API to retrieve random feed items
 */
export const retrieveRandomFeedItems = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feed/random/", undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve feed items based on provided query params
 */
export const searchForFeedItems = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feed/search/", undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve feed items liked by the user
 */
export const retrieveLikedFeedItems = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feed/liked/", undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve feed items disliked by the user
 */
export const retrieveDislikedFeedItems = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feed/disliked/", undefined, undefined, queryParams);
};

/**
 * Send a POST request to the API to like a feed item
 */
export const likeFeedItem = async (id: string) => {
  return await post(`/api/feed/${id}/like/`, {});
};

/**
 * Send a POST request to the API to dislike a feed item
 */
export const dislikeFeedItem = async (id: string) => {
  return await post(`/api/feed/${id}/dislike/`, {});
};

/**
 * Send a POST request to the API to skip a feed item
 */
export const skipFeedItem = async (id: string) => {
  return await post(`/api/feed/${id}/skip/`, {});
};

/**
 * Send a POST request to the API to watch a feed item
 */
export const watchFeedItem = async (id: string) => {
  return await post(`/api/feed/${id}/watch/`, {});
};

/**
 * Send a POST request to the API to add a new feed item
 */
export const addFeedItem = async (data: {
  youtube_video_id?: string;
  spotify_track_id?: string;
  bing_news_url?: string;
}) => {
  return await post("/api/feed/add/", data);
};

// ------------------------------
// ----- Feed Item Datasets -----
// ------------------------------

/**
 * Send a GET request to the API to retrieve feed item datasets
 */
export const retrieveFeedItemDatasets = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feed/dataset/", undefined, undefined, queryParams);
};

/**
 * Send a GET request to the API to retrieve a specific feed item dataset
 */
export const retrieveFeedItemDataset = async (id: string) => {
  return await get(`/api/feed/dataset/${id}/`);
};

// ------------------------------
// ----- Feed Item Actions ------
// ------------------------------

/**
 * Send a POST request to the API to increase the feed item's watch time
 */
export const increaseWatchedFor = async (data: FeedItemActionsPayload) => {
  return await post("/api/feed/actions/increase-watched-for/", data);
};

// ------------------------------
// ----- Feed Item Reports ------
// ------------------------------

/**
 * Send a POST request to the API to create a new feed item report
 */
export const createFeedItemReport = async (
  data: SubmitFeedItemReportPayload,
) => {
  return await post("/api/feed/reports/create/", data);
};

/**
 * Send a PATCH request to the API to toggle the feed item report's is_reviewed status
 */
export const toggleFeedItemReportReviewStatus = async (
  data: ToggleFeedItemReportStatusPayload,
) => {
  return await patch(`/api/feed/reports/${data.id}/`, {
    is_reviewed: data.is_reviewed,
  });
};

/**
 * Send a GET request to the API to retrieve feed item reports
 */
export const retrieveFeedItemReports = async ({
  queryParams,
}: {
  queryParams?: QueryParams;
}) => {
  return await get("/api/feed/reports/", undefined, undefined, queryParams);
};
