import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import Video from "../common/video";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Playlist } from "types/playlist";
import Track from "../common/track";
import News from "../common/news";
import { FEED_ITEM_TYPES } from "../../types/feed";
import { FolderIcon } from "@heroicons/react/24/outline";

const FALLBACK_IMAGE_URL =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjE2OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImdyYWQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMWUyNTMzO3N0b3Atb3BhY2l0eToxIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMTYxZDI5O3N0b3Atb3BhY2l0eToxIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSIxNjgiIGZpbGw9InVybCgjZ3JhZCkiLz48Y2lyY2xlIGN4PSIxNTAiIGN5PSI4NCIgcj0iMzIiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzM4NGI3MCIgc3Ryb2tlLXdpZHRoPSI4Ii8+PHBhdGggZD0iTTEyOCA4NCBMMTgwIDg0IE0xNTAgNjIgTDE1MCAxMDYiIHN0cm9rZT0iIzM4NGI3MCIgc3Ryb2tlLXdpZHRoPSI4Ii8+PHRleHQgeD0iMTUwIiB5PSIxMzYiIGZvbnQtZmFtaWx5PSJzeXN0ZW0tdWksLWFwcGxlLXN5c3RlbSxCbGlua01hY1N5c3RlbUZvbnQsUm9ib3RvLE94eWdlbixVYnVudHUsQ2FudGFyZWxsLHNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMTQiIGZpbGw9IiM2NDc0OGIiIHRleHQtYW5jaG9yPSJtaWRkbGUiPk5vIFRodW1ibmFpbDwvdGV4dD48L3N2Zz4=";

interface Props {
  playlist: Playlist;
}

/**
 * A playlist that can be expanded to show its items.
 */
export default function ExpandablePlaylist({ playlist }: Props) {
  const [open, setOpen] = useState(false);

  /**
   * Toggle the open state of the accordion.
   */
  const handleOpen = () => {
    setOpen(!open);
  };

  /**
   * Render items in the playlist.
   */
  const displayVideos = () => {
    const items = playlist.items;

    if (items.length > 0) {
      return items.map((item, idx) => {
        let innerItem = null;
        if (item.feed_item?.type === FEED_ITEM_TYPES.VIDEO) {
          innerItem = <Video video={item.feed_item} />;
        }
        if (item.feed_item?.type === FEED_ITEM_TYPES.TRACK) {
          innerItem = <Track track={item.feed_item} />;
        }
        if (item.feed_item?.type === FEED_ITEM_TYPES.NEWS) {
          innerItem = <News news={item.feed_item} />;
        }
        return (
          <li key={idx} className="p-4">
            {innerItem}
          </li>
        );
      });
    } else {
      return (
        <p className="p-4 text-gray-500 text-sm font-semibold">
          This playlist has no items!
        </p>
      );
    }
  };

  /**
   * Get the length of the playlist.
   */
  function getPlaylistLength() {
    const length = playlist.items.length;
    return length;
  }

  return (
    <div className="bg-white dark:bg-[#1e2533]/90 rounded-lg overflow-hidden">
      <div
        className="flex items-center justify-between p-3 cursor-pointer
                    hover:bg-gray-50 dark:hover:bg-white/5 transition-all duration-200"
        onClick={() => setOpen(!open)}
      >
        <div className="flex items-center gap-3">
          <div
            className="w-8 h-8 flex-shrink-0 flex items-center justify-center 
                       bg-gray-100 dark:bg-white/5 rounded-lg"
          >
            <FolderIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
          </div>
          <div className="min-w-0">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white truncate">
              {playlist.name}
            </h4>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {playlist.items.length} items
            </p>
          </div>
        </div>
        <ChevronDownIcon
          className={`w-5 h-5 text-gray-400 dark:text-gray-500 transition-transform duration-200
                     ${open ? "rotate-180" : ""}`}
        />
      </div>

      {/* Playlist Items */}
      {open && (
        <div className="border-t border-gray-100 dark:border-white/5">
          <div
            className="max-h-[300px] overflow-y-auto 
                       scrollbar-thin scrollbar-track-transparent 
                       dark:scrollbar-track-gray-900 
                       scrollbar-thumb-gray-200 dark:scrollbar-thumb-gray-700"
          >
            {playlist.items.length > 0 ? (
              <div className="divide-y divide-gray-100 dark:divide-white/5">
                {playlist.items.map((item, idx) => (
                  <div
                    key={idx}
                    className="p-3 hover:bg-gray-50 dark:hover:bg-white/5 
                                transition-all duration-200"
                  >
                    <div className="flex items-center gap-3">
                      <div className="w-20 h-12 flex-shrink-0 rounded-md overflow-hidden">
                        <img
                          src={item.feed_item?.image || FALLBACK_IMAGE_URL}
                          alt={item.feed_item?.title}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            const img = e.target as HTMLImageElement;
                            if (img.src !== FALLBACK_IMAGE_URL) {
                              img.src = FALLBACK_IMAGE_URL;
                            }
                          }}
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <h5
                          className="text-sm font-medium text-gray-900 dark:text-white 
                                   truncate hover:text-blue-500 dark:hover:text-blue-400"
                        >
                          {item.feed_item?.title}
                        </h5>
                        <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                          {item.feed_item?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="py-6 text-center">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  This playlist is empty
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
