import React, { useEffect } from "react";
import {
  retrieveNewsDataset,
  retrieveUsersDataset,
} from "redux/feed/feedDatasetSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { APP_URLS } from "navigation";
import { updateFeedItem } from "redux/feed/feedItemsSlice";
import { isDispatchResponseError } from "redux/utils";
import FeedItemDatasetInfo from "../common/FeedItemData";

interface Props {
  newsId: string;
}

/**
 * Component for displaying feed data details of a news item.
 */
export default function FeedDataNewsDetails({ newsId }: Props) {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => state.feedDataset.news);
  const pending = useAppSelector(
    (state) => state.feedDataset.retrieveNewsDatasetPending
  );

  function onClickRow(itemId: string) {
    // Navigate to user details page
    window.location.href = APP_URLS.FEED_DATA_USER_DETAILS.replace(
      ":userId",
      itemId
    );
  }

  function fetchData() {
    if (newsId) {
      dispatch(retrieveNewsDataset({ newsId }));
    }
  }

  async function onHideFeedItem() {
    if (news) {
      const response = await dispatch(
        updateFeedItem({ id: news.id, data: { hidden: !news.hidden } })
      );
      if (!isDispatchResponseError(response)) {
        fetchData();
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [newsId]);

  return (
    <div className="space-y-8 p-6">
      <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 overflow-hidden">
        <FeedItemDatasetInfo
          feedItemDataset={news}
          pending={pending}
          onHideFeedItem={onHideFeedItem}
        />

        <div className="bg-[#252d3d] rounded-lg border border-gray-700/50 p-6">
          <FeedDataTable
            headerText={"Users who interacted with this news"}
            defaultOrdering="email"
            paginatedList={useAppSelector((state) => state.feedDataset.users)}
            fetchThunkFunction={retrieveUsersDataset}
            pending={useAppSelector(
              (state) => state.feedDataset.retrieveNewsDatasetPending
            )}
            labels={{
              // id: "ID",
              email: "Email",
              username: "Username",
              liked: "Liked",
              disliked: "Disliked",
              watched: "Watched",
              skipped: "Skipped",
            }}
            additionalQueryParams={{ news_interacted_with: newsId }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
}
