import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { patchUser, retrieveUser } from "../../services/userService";
import { ErrorMessages } from "../../types/redux/slice";
import { UpdateUserPayload, User } from "../../types/user";

interface UserState {
  getUserPending: boolean;
  getUserErrorMessages: ErrorMessages;
  updateUserPending: boolean;
  updateUserErrorMessages: ErrorMessages;
  user: User | null;
}

const initialState: UserState = {
  getUserPending: false,
  getUserErrorMessages: {},
  updateUserPending: false,
  updateUserErrorMessages: {},
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      state.getUserPending = true;
      state.getUserErrorMessages = {};
      state.user = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.getUserPending = false;
      state.getUserErrorMessages = {};
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.getUserPending = false;
      state.getUserErrorMessages = action.payload || {};
      state.user = null;
    });

    builder.addCase(updateUser.pending, (state, action) => {
      state.updateUserPending = true;
      state.updateUserErrorMessages = {};
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.updateUserPending = false;
      state.updateUserErrorMessages = {};
      state.user = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateUserPending = false;
      state.updateUserErrorMessages = action.payload || {};
    });
  },
});

export const getUser = createAsyncThunk<
  User,
  void,
  { rejectValue: ErrorMessages }
>("user/getUser", async (data, thunkApi) => {
  try {
    // Request the currently logged-in user with Django API
    const response: User = await retrieveUser();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const updateUser = createAsyncThunk<
  User,
  UpdateUserPayload,
  { rejectValue: ErrorMessages }
>("user/updateUser", async (data, thunkApi) => {
  try {
    // Update user information
    const response: User = await patchUser(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});
