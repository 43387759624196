import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import MyPlaylistList from "../../components/playlist/MyPlaylistList";
import SharedPlaylistList from "../../components/playlist/SharedPlaylistList";
import { getIsSmScreen } from "../../helpers/responsiveness";
import { useIsSmScreen } from "../../hooks/useIsSmScreen";
import { APP_URLS } from "../../navigation";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";
import { FolderIcon, PlusIcon } from "@heroicons/react/24/outline";
import ModalEditCreatePlaylist from "components/playlist/ModalEditCreatePlaylist";
import { useAppSelector } from "redux/hooks";

/**
 * Playlists page for desktop
 */
export default function PlaylistsDesktop() {
  const isSmScreen = useIsSmScreen();


  const [showPlaylistPopup, setShowPlaylistPopup] = useState<boolean>(false);

  const sharedPlaylists = useAppSelector(
    (state) => state.playlist.sharedPlaylists
  );

  const myPlaylists = useAppSelector((state) => state.playlist.myPlaylists);

  useEffect(() => {
    document.title = "Playlists | BrainCargo";
  }, []);

  if (getIsSmScreen() || isSmScreen) {
    window.location.href = APP_URLS.PLAYLISTS_MOBILE;
  }

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Header Section */}
          <div
            className="bg-gradient-to-r from-blue-600 to-blue-800 dark:from-blue-700 dark:to-blue-900 
                         rounded-t-2xl p-8 shadow-lg"
          >
            <MainHeader title="Playlists" />
            <p className="text-blue-50 text-lg mt-2">
              Organize and manage your content collections
            </p>
          </div>

          {/* Main Content */}
          <div className="bg-white dark:bg-gray-800 rounded-b-2xl shadow-lg transition-all duration-200">
            {/* My Playlists Section */}
            <div className="border-b border-gray-200 dark:border-gray-700">
              <div className="p-8">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                      My Playlists
                    </h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                      Your personal content collections
                    </p>
                  </div>
                  <button
                    onClick={() => {setShowPlaylistPopup(true) }}
                    className="inline-flex items-center px-4 py-2 bg-blue-50 dark:bg-blue-900/30 
                             text-blue-700 dark:text-blue-300 rounded-lg hover:bg-blue-100 
                             dark:hover:bg-blue-900/50 transition-all duration-200"
                  >
                    <PlusIcon className="w-5 h-5 mr-2" />
                    Create Playlist
                  </button>
                </div>
                <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-6">
                  <MyPlaylistList />
                </div>
              </div>
            </div>

            {/* Shared Playlists Section */}
            <div className="p-8">
              <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Shared With Me
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  Playlists that others have shared with you
                </p>
              </div>
              <div className="bg-gray-50 dark:bg-gray-900/50 rounded-xl p-6">
                <SharedPlaylistList />
              </div>
            </div>

            {/* Empty State - Only show when both lists are empty */}
            {true && (
              <div className="text-center py-12 px-4">
                <div
                  className="bg-gray-100 dark:bg-gray-800 rounded-full p-5 w-16 h-16 
                              mx-auto mb-4 flex items-center justify-center"
                >
                  <FolderIcon className="w-8 h-8 text-gray-400 dark:text-gray-500" />
                </div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  No playlists yet
                </h3>
                <p className="text-gray-500 dark:text-gray-400 max-w-sm mx-auto mb-6">
                  Create your first playlist to start organizing your favorite
                  content
                </p>
                <button
                  onClick={() => {setShowPlaylistPopup(true) }}
                  className="inline-flex items-center px-5 py-2.5 bg-blue-600 hover:bg-blue-700 
                           dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-lg 
                           transition-colors duration-200"
                >
                  <PlusIcon className="w-5 h-5 mr-2" />
                  Create Your First Playlist
                </button>
              </div>
            )}
          </div>

          {/* Playlist Stats */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            {[
              { title: "Total Playlists", value: myPlaylists.length },
              { title: "Shared With Me", value: sharedPlaylists.length },
              { title: "Total Items", value: myPlaylists.length + sharedPlaylists.length },
            ].map((stat, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 
                                        transition-all duration-200"
              >
                <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  {stat.title}
                </h3>
                <p className="text-3xl font-bold text-gray-900 dark:text-white mt-2">
                  {stat.value}
                </p>
              </div>
            ))}
          </div>
          <ModalEditCreatePlaylist
          show={showPlaylistPopup}
          onClose={() => setShowPlaylistPopup(false)}
        />
        </div>

        <Footer />
      </div>
    </PageWrapper>
  );
}
