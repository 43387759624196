import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import PageWrapper from "components/common/pageWrapper";
import MainHeader from "components/MainHeader/MainHeader";

const faqCategories = [
  {
    id: "account",
    icon: "👤",
    title: "Account Management",
    faqs: [
      {
        question: "What should I do if I forgot my password?",
        answer:
          "You can reset your password by clicking the 'Forgot Password' link on the login page. You'll receive an email with instructions to create a new password. Make sure to check your spam folder if you don't see the email.",
      },
      {
        question: "How do I unlock my account?",
        answer:
          "If your account is locked, please contact our customer support for assistance. This usually happens after multiple failed login attempts for security purposes.",
      },
      {
        question: "How do I update my password if I want to change it?",
        answer:
          "To change your password, go to Profile Settings > Security > Change Password. You'll need to enter your current password and then set a new one.",
      },
      {
        question: "How do I keep my account secure?",
        answer:
          "We recommend using a strong password, enabling two-factor authentication, and never sharing your login credentials. Regularly monitor your account activity and log out from shared devices.",
      },
    ],
  },
  {
    id: "features",
    icon: "⚡",
    title: "Platform Features",
    faqs: [
      {
        question: "What are Brain Coins and how do I earn them?",
        answer:
          "Brain Coins are our platform's virtual currency. You can earn them by completing courses, participating in quizzes, contributing to discussions, and maintaining daily learning streaks.",
      },
      {
        question: "How do Neural Networks work in the platform?",
        answer:
          "Our Neural Networks feature provides personalized learning paths based on your progress and preferences. It analyzes your performance and adapts content difficulty accordingly.",
      },
    ],
  },
  {
    id: "technical",
    icon: "🔧",
    title: "Technical Support",
    faqs: [
      {
        question: "Which browsers are supported?",
        answer:
          "We support the latest versions of Chrome, Firefox, Safari, and Edge. For the best experience, we recommend keeping your browser updated.",
      },
      {
        question: "How can I report a technical issue?",
        answer:
          "You can report technical issues through our Support page or by clicking the 'Report Issue' button in the affected feature. Please include as much detail as possible.",
      },
    ],
  },
];

// Add contact info constant
const contactInfo = {
  phone: "+1 (555) 123-4567",
  email: "support@braincargo.com",
  address: "123 Tech Street, Silicon Valley, CA 94025",
};

export default function FAQPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [openFAQs, setOpenFAQs] = useState<string[]>([]);
  const [showContactInfo, setShowContactInfo] = useState(false);

  const toggleFAQ = (categoryId: string, faqIndex: number) => {
    const faqId = `${categoryId}-${faqIndex}`;
    setOpenFAQs((prev) =>
      prev.includes(faqId)
        ? prev.filter((id) => id !== faqId)
        : [...prev, faqId]
    );
  };

  const filteredCategories = faqCategories.filter(
    (category) => selectedCategory === "all" || category.id === selectedCategory
  );

  // Add handler for contact modal outside click
  const handleContactModalOutsideClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setShowContactInfo(false);
    }
  };

  // Add escape regex function
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  // Add search functionality
  const getFilteredFAQs = () => {
    if (!searchQuery.trim()) {
      return selectedCategory === "all"
        ? faqCategories
        : faqCategories.filter((category) => category.id === selectedCategory);
    }

    const searchTerm = escapeRegExp(searchQuery.toLowerCase().trim());

    return faqCategories
      .map((category) => ({
        ...category,
        faqs: category.faqs.filter(
          (faq) =>
            faq.question.toLowerCase().includes(searchTerm) ||
            faq.answer.toLowerCase().includes(searchTerm)
        ),
      }))
      .filter(
        (category) =>
          (selectedCategory === "all" || category.id === selectedCategory) &&
          category.faqs.length > 0
      );
  };

  // Update highlight text function
  const highlightText = (text: string) => {
    if (!searchQuery.trim()) return text;

    const searchTerm = escapeRegExp(searchQuery.toLowerCase().trim());

    try {
      const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === searchQuery.toLowerCase().trim() ? (
              <span key={index} className="bg-yellow-200 dark:bg-yellow-900">
                {part}
              </span>
            ) : (
              part
            )
          )}
        </>
      );
    } catch (error) {
      // Fallback in case of regex error
      return text;
    }
  };

  // Get filtered results
  const filteredResults = getFilteredFAQs();

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <MainHeader title="Profile"  />
        </div>
        <div className="max-w-4xl mx-auto">
          {/* Header Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              How can we help you?
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-400">
              Search our FAQ for answers to anything you might ask.
            </p>
          </div>

          {/* Search Bar with clear button */}
          <div className="relative mb-8">
            <MagnifyingGlassIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search for answers..."
              className="w-full pl-12 pr-12 py-3 rounded-xl border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {searchQuery && (
              <button
                onClick={() => setSearchQuery("")}
                className="absolute right-4 top-1/2 transform -translate-y-1/2"
              >
                <XMarkIcon className="w-5 h-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300" />
              </button>
            )}
          </div>

          {/* Search Results Count */}
          {searchQuery.trim() && (
            <div className="mb-6 text-gray-600 dark:text-gray-400">
              Found{" "}
              {filteredResults.reduce(
                (acc, category) => acc + category.faqs.length,
                0
              )}{" "}
              results for "{searchQuery}"
            </div>
          )}

          {/* No Results Message */}
          {filteredResults.length === 0 && (
            <div className="text-center py-12">
              <div className="text-gray-400 dark:text-gray-500 text-6xl mb-4">
                🔍
              </div>
              <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
                No results found
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                We couldn't find any FAQs matching your search. Try different
                keywords or{" "}
                <button
                  onClick={() => setSearchQuery("")}
                  className="text-blue-500 hover:underline"
                >
                  clear your search
                </button>
              </p>
            </div>
          )}

          {/* Category Tabs */}
          <div className="flex gap-2 mb-8 overflow-x-auto pb-2">
            <button
              onClick={() => setSelectedCategory("all")}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                selectedCategory === "all"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              }`}
            >
              All
            </button>
            {faqCategories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  selectedCategory === category.id
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
                }`}
              >
                {category.icon} {category.title}
              </button>
            ))}
          </div>

          {/* FAQ Sections with highlighted text */}
          <div className="space-y-8">
            {filteredResults.map((category) => (
              <div
                key={category.id}
                className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-sm"
              >
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  {category.icon} {category.title}
                </h2>
                <div className="space-y-4">
                  {category.faqs.map((faq, index) => (
                    <motion.div
                      key={`${category.id}-${index}`}
                      className="border border-gray-200 dark:border-gray-700 rounded-xl overflow-hidden"
                      initial={false}
                    >
                      <button
                        className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                        onClick={() => toggleFAQ(category.id, index)}
                      >
                        <span className="font-medium text-gray-900 dark:text-white">
                          {highlightText(faq.question)}
                        </span>
                        <ChevronDownIcon
                          className={`w-5 h-5 text-gray-500 transition-transform ${
                            openFAQs.includes(`${category.id}-${index}`)
                              ? "rotate-180"
                              : ""
                          }`}
                        />
                      </button>
                      <AnimatePresence>
                        {openFAQs.includes(`${category.id}-${index}`) && (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: "auto" }}
                            exit={{ height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="overflow-hidden"
                          >
                            <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700/50 text-gray-600 dark:text-gray-300">
                              {highlightText(faq.answer)}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Contact Support Section */}
          <div className="mt-12 text-center bg-gradient-to-r from-blue-500 to-purple-600 rounded-2xl p-8 text-white">
            <h3 className="text-2xl font-bold mb-4">Still have questions?</h3>
            <p className="mb-6">
              Can't find the answer you're looking for? Please chat with our
              friendly team.
            </p>
            <button
              onClick={() => setShowContactInfo(true)}
              className="bg-white text-blue-600 px-6 py-3 rounded-lg font-medium hover:bg-gray-100 transition-colors"
            >
              Contact Support
            </button>
          </div>
        </div>

        {/* Contact Info Modal */}
        <AnimatePresence>
          {showContactInfo && (
            <div
              className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
              onClick={handleContactModalOutsideClick}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2 }}
                className="bg-white dark:bg-gray-800 rounded-2xl w-full max-w-md mx-4 shadow-xl"
              >
                <div className="p-6 border-b border-gray-200 dark:border-gray-700">
                  <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
                      Contact Information
                    </h2>
                    <button
                      onClick={() => setShowContactInfo(false)}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                    >
                      <XMarkIcon className="w-5 h-5 text-gray-500" />
                    </button>
                  </div>
                </div>

                <div className="p-6 space-y-6">
                  <div className="space-y-4">
                    <div className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                      <div className="flex-shrink-0">
                        <PhoneIcon className="w-6 h-6 text-blue-500" />
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Phone
                        </p>
                        <p className="text-gray-900 dark:text-white font-medium">
                          {contactInfo.phone}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                      <div className="flex-shrink-0">
                        <EnvelopeIcon className="w-6 h-6 text-blue-500" />
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Email
                        </p>
                        <p className="text-gray-900 dark:text-white font-medium">
                          {contactInfo.email}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                      <div className="flex-shrink-0">
                        <MapPinIcon className="w-6 h-6 text-blue-500" />
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Address
                        </p>
                        <p className="text-gray-900 dark:text-white font-medium">
                          {contactInfo.address}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6">
                    <p className="text-sm text-gray-500 dark:text-gray-400 text-center">
                      Our support team is available Monday through Friday,
                      <br />
                      9:00 AM to 6:00 PM EST
                    </p>
                  </div>
                </div>

                <div className="p-6 border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-700/50 rounded-b-2xl">
                  <button
                    onClick={() =>
                      (window.location.href = `mailto:${contactInfo.email}`)
                    }
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 px-6 rounded-lg font-medium transition-colors"
                  >
                    Send Email
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </PageWrapper>
  );
}
