import React, { useState } from "react";
import { ShareIcon } from "@heroicons/react/24/outline";
import SharePlaylistsPopup from "./SharePlaylistsPopup";

interface Props {
  additionalClasses?: string;
  compact?: boolean;
}

/**
 * Component used for sharing playlists with other users.
 */
export default function ButtonSharePlaylists({
  additionalClasses = "",
  compact = false,
}: Props) {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <>
      <button
        onClick={() => setShowPopup(true)}
        className={`group inline-flex items-center justify-center gap-2
                   min-w-[120px] px-4 py-2 text-sm font-medium
                   rounded-lg transition-all duration-200
                   text-gray-300 dark:text-gray-300
                   bg-[#1e2533]/10 dark:bg-white/5
                   hover:bg-[#1e2533]/20 dark:hover:bg-white/10
                   focus:outline-none focus:ring-2 focus:ring-gray-500/20
                   ${additionalClasses}`}
        aria-label="Share Playlists"
      >
        <ShareIcon className="w-4 h-4" />
        <span className="whitespace-nowrap text-sm">
          {compact ? "" : "Share Playlists"}
        </span>
      </button>

      <SharePlaylistsPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
      />
    </>
  );
}
