import React from "react";
import { retrieveTracksDataset } from "redux/feed/feedDatasetSlice";
import { useAppSelector } from "redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import { generateSpotifyUrl } from "helpers/spotify";
import { APP_URLS } from "navigation";

/**
 * Component for displaying a list of track-related feed data.
 */
export default function FeedDataTracksList() {
  function onClickRow(itemId: string) {
    // Navigate to track details page
    window.location.href = APP_URLS.FEED_DATA_TRACK_DETAILS.replace(
      ":trackId",
      itemId
    );
  }

  return (
    <FeedDataTable
      headerText="Tracks"
      defaultOrdering="title"
      paginatedList={useAppSelector((state) => state.feedDataset.tracks)}
      fetchThunkFunction={retrieveTracksDataset}
      pending={useAppSelector(
        (state) => state.feedDataset.retrieveTracksDatasetPending
      )}
      labels={{
        // id: "ID",
        title: "Title",
        spotify_track_id: "Link",
        liked_by_count: "Likes",
        disliked_by_count: "Dislikes",
        watched_by_count: "Watches",
        skipped_by_count: "Skips",
      }}
      onClickRow={onClickRow}
      valueOverrides={{
        spotify_track_id: generateSpotifyUrl,
      }}
    />
  );
}
