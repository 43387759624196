import React, { useEffect } from "react";
import {
  useAuthModal,
  useSignerStatus,
  useUser,
  useAccount,
} from "@account-kit/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  alchemyLogin,
  register,
  checkEmail,
  alchemyRegister,
} from "../../redux/auth/authSlice";
import { APP_URLS } from "../../navigation";
import { history } from "../../helpers/history";
import { toast } from "react-toastify";
import { setSequenceWalletAddress } from "../../redux/wallet/walletSlice";
import { getAccessToken } from "../../services/localStorage";

interface WalletLoginModalProps {
  sequenceWalletAddress?: string | null;
  pendingConnect?: boolean;
}

export const WalletLoginModal: React.FC<WalletLoginModalProps> = ({
  sequenceWalletAddress,
  pendingConnect,
}) => {
  const { openAuthModal } = useAuthModal();
  const signerStatus = useSignerStatus();
  const user = useUser();
  const dispatch = useAppDispatch();
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const token = getAccessToken();
  // const {seqAddress,pendingConnect} = useAppSelector(state=>state.wallet);

  const { account, address, isLoadingAccount } = useAccount({
    type: "LightAccount",
    onSuccess: async (account) => {
      if (address) {
        dispatch(setSequenceWalletAddress(address));
      }

      if (user?.email && address) {
        setIsAuthenticating(true);

        try {
          // Check if user exists
          const { user_exists } = await dispatch(
            checkEmail({ email: user.email })
          ).unwrap();

          if (user_exists) {
            await dispatch(
              alchemyLogin({
                email: user?.email,
                eth_address: address,
                wallet_address: address,
              })
            );
          } else {
            await dispatch(
              alchemyRegister({
                email: user?.email,
                eth_address: address,
              })
            );
          }

          setIsAuthenticating(false);
          toast.success("Successfully authenticated!");
        } catch (error) {
          toast.error("Authentication failed. Please try again.");
          setIsAuthenticating(false);
        }
      }
    },
    onError: (error) => {
      setIsAuthenticating(false);
    },
  });
  useEffect(() => {
    if ((user || address) && token) {
      setIsAuthenticating(true);
    } else {
      setIsAuthenticating(false);
    }
  }, [signerStatus, user, address, token]);

  if (isAuthenticating || pendingConnect) {
    return (
      <div
        className="bg-white/95 dark:bg-gray-800/95 rounded-xl p-6 
                    backdrop-blur-lg border border-gray-200 dark:border-gray-700 
                    shadow-xl w-full max-w-md mx-auto"
      >
        <div className="flex flex-col items-center gap-4">
          <div className="h-16 w-16 relative">
            <div className="absolute inset-0 rounded-full border-t-2 border-blue-500 animate-spin"></div>
            <div className="absolute inset-0 rounded-full border-2 border-gray-200 dark:border-gray-700"></div>
          </div>
          <p className="text-2xl font-bold text-gray-900 dark:text-white">
            Authenticating...
          </p>
          <p className="text-gray-600 dark:text-gray-400 text-center">
            Please wait while we authenticate your wallet
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="bg-white/95 dark:bg-gray-800/95 rounded-xl p-6 
                  backdrop-blur-lg border border-gray-200 dark:border-gray-700 
                  shadow-xl w-full max-w-md mx-auto"
    >
      <div className="flex flex-col items-center gap-4">
        <div
          className="h-16 w-16 bg-blue-500/10 rounded-full flex items-center justify-center 
                      ring-2 ring-blue-500/20"
        >
          <svg
            className="h-8 w-8 text-blue-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Connect Wallet
        </h2>
        <p className="text-gray-600 dark:text-gray-400 text-center">
          Connect your wallet to access all features
        </p>
        <button
          onClick={openAuthModal}
          className="mt-4 w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 
                   text-white rounded-lg hover:from-blue-600 hover:to-blue-700 
                   transition-all duration-200 flex items-center justify-center gap-2 
                   shadow-lg shadow-blue-500/20"
        >
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
            />
          </svg>
          Login with Alchemy
        </button>
      </div>
    </div>
  );
};
