import React from "react";
import { Button, Spinner } from "flowbite-react";
import {
  ExclamationCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Modal from ".";

interface Props {
  title: string;
  show: boolean;
  pendingConfirm: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

/**
 * Modal used for confirming actions.
 */
export default function ConfirmationPopup({
  title,
  show,
  pendingConfirm,
  onClose,
  onConfirm,
}: Props) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      hideFooter
      body={
        <div className="p-6 text-center">
          <div
            className="mx-auto flex h-14 w-14 items-center justify-center rounded-full 
                        bg-red-100 dark:bg-red-900/30"
          >
            <ExclamationCircleIcon
              className="h-8 w-8 text-red-600 dark:text-red-400"
              aria-hidden="true"
            />
          </div>

          <h3 className="mt-4 text-lg font-semibold text-gray-900 dark:text-white">
            Delete Neural Network
          </h3>

          <p className="mt-2 text-gray-500 dark:text-gray-400">{title}</p>

          <div className="mt-6 flex justify-center gap-3">
            <button
              onClick={onConfirm}
              disabled={pendingConfirm}
              className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 
                       dark:bg-red-500 dark:hover:bg-red-600 text-white rounded-lg 
                       transition-colors duration-200 disabled:opacity-50"
            >
              {pendingConfirm ? (
                <Spinner size="sm" className="mr-2" />
              ) : (
                <TrashIcon className="w-5 h-5 mr-2" />
              )}
              Delete
            </button>

            <button
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 
                       dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-200 
                       rounded-lg transition-colors duration-200"
            >
              <XMarkIcon className="w-5 h-5 mr-2" />
              Cancel
            </button>
          </div>
        </div>
      }
    />
  );
}
