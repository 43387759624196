import { getIsSmScreen } from "../helpers/responsiveness";
import React, { useEffect, useState } from "react";

/**
 * Hook to check if current screen width is less than 640px(satisfies tailwindcss's "sm:" breakpoint).
 */
export function useIsSmScreen() {
  const [isSmScreen, setIsSmScreen] = useState(false);

  // Listen for window dimension changes
  useEffect(() => {
    const handleResize = () => {
      const isSmScreen = getIsSmScreen();
      setIsSmScreen(isSmScreen);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSmScreen;
}
