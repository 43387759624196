import React, { CSSProperties } from "react";
import { FEED_ITEM_TYPES, FeedItem } from "../../types/feed";
import VideoPlayer from "./video-player/VideoPlayer";
import BingNews from "./news/BingNews";
import SpotifyTrack from "./tracks/SpotifyTrack";
import TMDBMovie from "./movies/TMDBMovie/TMDBMovie";

interface Props {
  id: string;
  className?: string;
  style: CSSProperties;
  data: { playing: boolean; feedItem: FeedItem | null };
  spotifyIFrameAPI: any;
}

/**
 * Scrollable feed element for mobile.
 */
export default function ScrollableMobileFeedElement({
  id,
  className,
  style,
  data,
  spotifyIFrameAPI,
}: Props) {
  // Videos
  if (data.feedItem?.type === FEED_ITEM_TYPES.VIDEO) {
    if (data.feedItem?.youtube_video_id) {
      return (
        <VideoPlayer className={className} id={id} style={style} data={data} />
      );
    }
  }

  // News
  if (data.feedItem?.type === FEED_ITEM_TYPES.NEWS) {
    if (data.feedItem?.bing_news_url) {
      return (
        <BingNews className={className} id={id} style={style} data={data} />
      );
    }
  }

  // Tracks
  if (data.feedItem?.type === FEED_ITEM_TYPES.TRACK) {
    if (data.feedItem?.spotify_track_id) {
      return (
        <SpotifyTrack
          className={className}
          id={id}
          style={style}
          data={data}
          spotifyIFrameAPI={spotifyIFrameAPI}
        />
      );
    }
  }

  // Movies
  if (data.feedItem?.type === FEED_ITEM_TYPES.MOVIE) {
    if (data.feedItem?.tmdb_movie_id) {
      return (
        <TMDBMovie className={className} id={id} style={style} data={data} />
      );
    }
  }

  return <div className={className} style={style} id={id}></div>;
}
