import React, { useEffect } from "react";
import { getMyPlaylists } from "../../redux/playlist/playlistSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ExpandablePlaylist from "../../components/playlist/ExpandablePlaylist";
import ButtonCreatePlaylist from "../../components/playlist/ButtonCreatePlaylist";
import ButtonSharePlaylists from "../../components/playlist/ButtonSharePlaylist";
import { FolderIcon, PlusIcon, ShareIcon } from "@heroicons/react/24/outline";

interface Props {
  smallHeader?: boolean;
}

/**
 * Component for displaying a list of playlists that the user has created.
 */
export default function MyPlaylistList({ smallHeader = false }: Props) {
  const dispatch = useAppDispatch();
  const myPlaylists = useAppSelector((state) => state.playlist.myPlaylists);

  useEffect(() => {
    document.title = "Playlists | BrainCargo";

    // Retrieve a list of playlists
    dispatch(getMyPlaylists({}));
  }, []);

  /**
   * Display a list of playlists.
   */
  const displayMyPlaylists = () => {
    if (myPlaylists.length > 0) {
      return myPlaylists.map((playlist, idx) => (
        <ExpandablePlaylist key={idx} playlist={playlist} />
      ));
    } else {
      return (
        <div className="flex flex-col items-center justify-center py-8 px-4">
          <div className="bg-gray-800/50 p-4 rounded-full mb-4">
            <FolderIcon className="w-8 h-8 text-gray-600" />
          </div>
          <p className="text-gray-400 font-medium text-center">
            No playlists created yet
          </p>
          <p className="text-gray-600 text-sm text-center mt-1">
            Create your first playlist to start organizing videos
          </p>
        </div>
      );
    }
  };

  return (
    <div className="space-y-4">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-white">
            My Playlists
          </h3>
          <span
            className="px-2 py-0.5 text-xs font-medium text-gray-500 dark:text-gray-400 
                        bg-gray-100 dark:bg-white/5 rounded-full"
          >
            {myPlaylists.length} playlists
          </span>
        </div>
      </div>

      {/* Quick Actions */}
      {/* <div className="flex gap-2"> */}
      {/* <div className="flex flex-col sm:flex-row gap-2 w-full"> */}
      <div className="flex flex-wrap gap-2">
      <div className="flex gap-2 w-full">
        <ButtonCreatePlaylist />
        <ButtonSharePlaylists />
      </div>
      </div>

      {/* Playlists */}
      <div className="space-y-2">
        {myPlaylists.length > 0 ? (
          myPlaylists.map((playlist, idx) => (
            <ExpandablePlaylist key={idx} playlist={playlist} />
          ))
        ) : (
          <div className="text-center py-8">
            <div
              className="inline-flex items-center justify-center w-12 h-12 
                         bg-gray-100 dark:bg-white/5 rounded-full mb-3"
            >
              <FolderIcon className="w-6 h-6 text-gray-400 dark:text-gray-600" />
            </div>
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              No playlists yet
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
              Create your first playlist to start organizing videos
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
