import { PlayCircleIcon } from "@heroicons/react/24/outline";
import {
  getFeedItemImage,
  getLogoForStreamingProvider,
  transformStreamingServiceName,
} from "../../../../helpers/feedItem";
import { replaceSpecialChars } from "../../../../helpers/text";
import { APP_URLS } from "../../../../navigation";
import React from "react";
import { addFeedItem } from "../../../../redux/feed/feedItemsSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { isDispatchResponseError } from "../../../../redux/utils";
import { FEED_ITEM_TYPE_LABELS, FeedItem, StreamingProvider } from "../../../../types/feed";

interface Props {
  feedItem: FeedItem;
  setPendingClickSearchItem: (val: boolean) => void;
}

/**
 * Search result for MobileTopNavBarSearchResults.
 */
export default function MobileTopNavBarSearchResult({
  feedItem,
  setPendingClickSearchItem,
}: Props) {
  const dispatch = useAppDispatch();

  async function onClickSearchItem(feedItem: FeedItem) {
    setPendingClickSearchItem(true);
    const response = await dispatch(addFeedItem(feedItem));
    if (!isDispatchResponseError(response) && response.payload) {
      const feedItemId = response.payload.id as FeedItem["id"];
      if (feedItemId)
        window.location.href = APP_URLS.FEED_MOBILE_SPECIFIC.replace(
          ":feedItemId",
          feedItemId,
        );
    } else {
      setPendingClickSearchItem(false);
    }
  }

  function onClickWatch(e: React.MouseEvent, provider: StreamingProvider) {
    e.stopPropagation();
    window.open(provider.link);
  }

  function renderStreamingProviders() {
    const streamingProviders = feedItem.streaming_providers;
    console.log(streamingProviders);
    let headingText: string | undefined;
    let buttonText: string | undefined;
    if (feedItem.type === "movie") {
      headingText = "Where To Watch";
      buttonText = "Watch";
    }
    if (feedItem.type === "track") {
      headingText = "Where To Listen";
      buttonText = "Listen";
    }

    // Don't render if there are no streaming providers
    if (!headingText || !buttonText) return null;
    return (
      <div className="flex flex-col space-y-2 text-left">
        <h4 className="text-lg font-semibold">{headingText}</h4>
        {streamingProviders.map((provider) => {
          return (
            <div
              key={provider.id}
              className="flex flex-row justify-center items-center text-sm gap-4"
            >
              <div className="bg-slate-200 rounded-full p-2">
                <img
                  src={getLogoForStreamingProvider(provider)}
                  className="h-6 w-6"
                  alt={`${provider.service} logo`}
                />
              </div>
              <div className="capitalize">
                <div className="text-base">
                  {transformStreamingServiceName(provider.service)}
                </div>
                <div className="text-xs text-neutral-400">
                  {provider.streaming_type}
                </div>
              </div>
              <div className="ml-auto">
                <button
                  className="border-[1px] border-yellow-500 py-1 px-2 rounded-2xl"
                  onClick={(e) => onClickWatch(e, provider)}
                  aria-label={`Watch on ${provider.service}`}
                >
                  <div className="flex justify-center items-center gap-1">
                    <PlayCircleIcon className="h-6 w-6 text-yellow-500" />
                    <div>{buttonText}</div>
                  </div>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <button
      className="p-4 space-y-2 border-t-2 w-full"
      key={feedItem.title}
      onClick={() => onClickSearchItem(feedItem)}
      aria-label={`Search result for ${feedItem.title}`}
    >
      <div className="flex flex-row items-center w-full gap-4 bg-gray-100">
        <div className="flex flex-col justify-center items-center w-1/6 gap-1">
          <img
            src={getFeedItemImage(feedItem)}
            className="h-16 w-16 rounded-full"
            alt={`${feedItem.title} image`}
          />
          <span className="text-sm font-medium">
            {FEED_ITEM_TYPE_LABELS[feedItem.type]}
          </span>
        </div>
        <div className="flex flex-col justify-center items-start gap-1 w-5/6">
          <span className="text-lg font-semibold">
            {replaceSpecialChars(feedItem.title)}
          </span>
          {feedItem.description && (
            <span className="text-sm">
              {replaceSpecialChars(feedItem.description)}
            </span>
          )}
        </div>
      </div>
      <div>{renderStreamingProviders()}</div>
    </button>
  );
}
