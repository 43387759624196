import React from "react";
import { Feedback } from "types/feedback";
import { FEEDBACK_TYPE_LABELS } from "types/feedback";
import { ShimmerLoader } from "components/common/ShimmerLoader";

interface Props {
  feedback: Feedback | null;
  pending: boolean;
}

const FeedbackDetailsLoader = () => (
  <div className="space-y-6 max-w-3xl mx-auto">
    {[...Array(5)].map((_, i) => (
      <div key={i} className="flex flex-col space-y-2">
        <ShimmerLoader className="h-5 w-32" />
        <ShimmerLoader className="h-10 w-full" />
      </div>
    ))}
  </div>
);

export default function FeedbackDetails({ feedback, pending }: Props) {
  const renderRow = (label: string, value?: string | number | boolean) => {
    let displayValue = value;

    if (typeof value === "boolean") {
      displayValue = value ? "Yes" : "No";
    } else if (!value && value !== 0) {
      displayValue = "-";
    }

    return (
      <div className="space-y-2">
        <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
          {label}
        </div>
        <div
          className="bg-gray-50 dark:bg-[#252d3d] rounded-lg p-3 
                      border border-gray-200 dark:border-white/5"
        >
          {typeof value === "string" &&
          (value.startsWith("http://") || value.startsWith("https://")) ? (
            <a
              href={value}
              className="text-blue-600 dark:text-blue-400 hover:underline break-all"
              target="_blank"
              rel="noreferrer"
            >
              {value}
            </a>
          ) : (
            <div className="text-gray-900 dark:text-gray-200">
              {displayValue}
            </div>
          )}
        </div>
      </div>
    );
  };

  if (pending) {
    return <FeedbackDetailsLoader />;
  }

  if (!feedback) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400 py-8">
        Failed to retrieve feedback information
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto space-y-6">
      {/* Feedback Type Badge */}
      <div className="flex justify-center">
        <span
          className="px-4 py-2 text-sm font-medium rounded-full
                       bg-blue-500/10 text-blue-400 border border-blue-500/20"
        >
          {FEEDBACK_TYPE_LABELS[feedback.type]}
        </span>
      </div>

      {/* Feedback Details Grid */}
      <div className="space-y-6">
        {/* {renderRow("ID", feedback.id)} */}
        {renderRow("Created by", feedback.user.username)}
        {renderRow("Type", FEEDBACK_TYPE_LABELS[feedback.type])}
        {renderRow("File", feedback.file || "No file attached")}
        {renderRow("Description", feedback.description)}
      </div>
    </div>
  );
}
