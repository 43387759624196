import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  searchMovie,
  searchMovies,
  getMovieDetails,
  getMovieRecommendations,
  getSimilarUsersAndMovies,
} from "redux/movies/moviesSlice";
import { ShimmerLoader } from "components/common/ShimmerLoader";
import {
  CalendarIcon,
  ClockIcon,
  StarIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
  FilmIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import MovieDetailsPage from "pages/MovieDetails";

interface MovieDetails {
  id: number;
  title: string;
  overview: string;
  poster_path: string;
  release_date: string;
  vote_average: number;
  genres: Array<{ id: number; name: string }>;
  backdrop_path?: string;
  original_language: string;
  popularity: number;
  imdb_rating: number;
  tagline: string;
  streaming_platforms: Array<string>;
  image_url: string;
  original_title: string;
}

// A more sophisticated fallback image that matches the dark theme and movie context
const FALLBACK_IMAGE_URL =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjQ1MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImdyYWQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMWUyNTMzO3N0b3Atb3BhY2l0eToxIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMTYxZDI5O3N0b3Atb3BhY2l0eToxIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSI0NTAiIGZpbGw9InVybCgjZ3JhZCkiLz48Y2lyY2xlIGN4PSIxNTAiIGN5PSIxNTAiIHI9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiMzODRiNzAiIHN0cm9rZS13aWR0aD0iOCIvPjxwYXRoIGQ9Ik0xMjAgMTUwIEwxOTAgMTUwIE0xNTAgMTIwIEwxNTAgMTkwIiBzdHJva2U9IiMzODRiNzAiIHN0cm9rZS13aWR0aD0iOCIvPjx0ZXh0IHg9IjE1MCIgeT0iMjgwIiBmb250LWZhbWlseT0ic3lzdGVtLXVpLC1hcHBsZS1zeXN0ZW0sQmxpbmtNYWNTeXN0ZW1Gb250LFJvYm90byxPeHlnZW4sVWJ1bnR1LENhbnRhcmVsbCxzYW5zLXNlcmlmIiBmb250LXNpemU9IjI0IiBmaWxsPSIjNjQ3NDhiIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIj5ObyBJbWFnZSBBdmFpbGFibGU8L3RleHQ+PHRleHQgeD0iMTUwIiB5PSIzMTAiIGZvbnQtZmFtaWx5PSJzeXN0ZW0tdWksLWFwcGxlLXN5c3RlbSxCbGlua01hY1N5c3RlbUZvbnQsUm9ib3RvLE94eWdlbixVYnVudHUsQ2FudGFyZWxsLHNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iMTYiIGZpbGw9IiM0YTU1NmMiIHRleHQtYW5jaG9yPSJtaWRkbGUiPkNsaWNrIHRvIHZpZXcgZGV0YWlsczwvdGV4dD48L3N2Zz4=";

export default function MovieSearch() {
  const dispatch = useAppDispatch();
  const { searchResults, loading, error } = useAppSelector(
    (state) => state.movies
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMovie, setSelectedMovie] = useState<MovieDetails | null>(null);
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();
  const [movie, setMovie] = useState<any>(null);
  const [showHint, setShowHint] = useState(false);
  const hintRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Close hint modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        hintRef.current &&
        buttonRef.current &&
        !hintRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowHint(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle ESC key to close modal
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowHint(false);
      }
    };

    if (showHint) {
      document.addEventListener("keydown", handleEscKey);
    }

    return () => document.removeEventListener("keydown", handleEscKey);
  }, [showHint]);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      try {
        // Split by comma and check if it's multiple movies
        const movieQueries = searchQuery
          .split(/,(?![^(]*\))/)
          .map((q) => q.trim());

        if (movieQueries.length > 1) {
          // Multiple movies - parse and use searchMovies
          const movies = movieQueries.map((query) => {
            const match = query.match(/^(.*?)(?:\s*\((\d{4})\))?$/);
            return {
              title: match ? match[1].trim() : query,
              year: match?.[2] || "",
            };
          });
          await dispatch(searchMovies(movies));
        } else {
          // Single movie - use searchMovie
          await dispatch(searchMovie(searchQuery));
        }
      } catch (error) {
        console.error("Search error:", error);
      }
    }
  };

  const handleMovieSelect = async (movieId: number) => {
    try {
      // Get movie details
      const detailsResponse = await dispatch(getMovieDetails(movieId)).unwrap();
      setSelectedMovie(detailsResponse.data);
      setShowDetails(true);

      setMovie(detailsResponse);
      navigate(`/movies/${movieId}`);
      // Get movie recommendations
      const recommendationsResponse = await dispatch(
        getMovieRecommendations(movieId)
      ).unwrap();
      setRecommendations(recommendationsResponse.data);

      // Get similar users and movies (optional)
      await dispatch(getSimilarUsersAndMovies()).unwrap();
      navigate(`/movies/${movieId}`);
    } catch (error) {
      console.error("Error fetching movie details:", error);
    }
  };

  return (
    <div className="space-y-6 max-w-3xl mx-auto">
      {/* Search Form */}
      <form onSubmit={handleSearch} className="space-y-4">
        <div className="relative">
          {/* Search Input */}
          <div className="relative group">
            <MagnifyingGlassIcon
              className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 
                                        text-gray-400 group-hover:text-blue-500 transition-colors duration-200"
            />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Try 'The Matrix (1999), Inception (2010)'"
              className="w-full pl-12 pr-12 py-3 rounded-xl border dark:border-white/5 
                       dark:bg-[#252d3d] dark:text-gray-200 dark:placeholder-gray-400
                       focus:ring-2 focus:ring-blue-500/20 transition-all duration-200"
            />
            <button
              ref={buttonRef}
              type="button"
              onClick={() => setShowHint(!showHint)}
              className="absolute right-4 top-1/2 -translate-y-1/2 p-1 rounded-full
                       hover:bg-gray-100 dark:hover:bg-white/10 transition-all duration-200"
              aria-label="Search hints"
            >
              <InformationCircleIcon
                className="w-5 h-5 text-gray-400 hover:text-blue-500 
                                            transition-colors duration-200"
              />
            </button>
          </div>

          {/* Search Hints Modal */}
          {showHint && (
            <>
              {/* Backdrop */}
              <div
                className="fixed inset-0 bg-black/5 dark:bg-black/20 backdrop-blur-sm z-40"
                onClick={() => setShowHint(false)}
              />

              {/* Modal */}
              <div
                ref={hintRef}
                className="absolute z-50 w-full mt-2 p-4 rounded-xl border dark:border-white/5 
                         bg-white dark:bg-[#1e2533] shadow-xl
                         animate-fadeIn transform-gpu"
              >
                {/* Close button */}
                <button
                  onClick={() => setShowHint(false)}
                  className="absolute top-2 right-2 p-1 rounded-full
                           hover:bg-gray-100 dark:hover:bg-white/10
                           transition-all duration-200"
                >
                  <XMarkIcon className="w-5 h-5 text-gray-400" />
                </button>

                <div className="space-y-4">
                  <div className="flex items-start gap-3">
                    <FilmIcon className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                    <div className="flex-1">
                      <h3 className="font-medium text-gray-900 dark:text-white">
                        Search Options:
                      </h3>
                      <ul className="mt-3 space-y-3">
                        <li className="flex items-center gap-3 text-sm text-gray-600 dark:text-gray-300">
                          <div className="w-2 h-2 rounded-full bg-blue-500/70" />
                          <span>
                            Single movie:{" "}
                            <span className="font-mono bg-gray-100 dark:bg-white/10 px-2 py-0.5 rounded">
                              The Matrix (1999)
                            </span>
                          </span>
                        </li>
                        <li className="flex items-center gap-3 text-sm text-gray-600 dark:text-gray-300">
                          <div className="w-2 h-2 rounded-full bg-blue-500/70" />
                          <span>
                            Multiple movies:{" "}
                            <span className="font-mono bg-gray-100 dark:bg-white/10 px-2 py-0.5 rounded">
                              The Matrix (1999), Inception (2010)
                            </span>
                          </span>
                        </li>
                        <li className="flex items-center gap-3 text-sm text-gray-600 dark:text-gray-300">
                          <div className="w-2 h-2 rounded-full bg-blue-500/70" />
                          <span>
                            Year is optional:{" "}
                            <span className="font-mono bg-gray-100 dark:bg-white/10 px-2 py-0.5 rounded">
                              The Matrix, Inception
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <button
          type="submit"
          disabled={loading}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg
                   hover:bg-blue-600 transition-colors duration-200
                   disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? "Searching..." : "Search"}
        </button>
      </form>

      {/* Error Message */}
      {error && <div className="text-red-500 dark:text-red-400">{error}</div>}

      {/* Search Results */}
      {loading ? (
        <div className="space-y-4">
          <ShimmerLoader className="h-20 w-full" />
          <ShimmerLoader className="h-20 w-full" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {searchResults.map((movie) => (
            <div
              key={movie.id}
              onClick={() => handleMovieSelect(movie.id)}
              className="group relative bg-white dark:bg-[#1e2533]/80 rounded-xl overflow-hidden
                       border dark:border-white/5 transition-all duration-300
                       hover:transform hover:scale-[1.02] hover:shadow-xl cursor-pointer"
            >
              {/* Image Container */}
              <div className="relative aspect-[2/3] overflow-hidden bg-[#1e2533]">
                <img
                  src={
                    movie.image_url || movie.poster_path || FALLBACK_IMAGE_URL
                  }
                  alt={movie.original_title}
                  className="w-full h-full object-cover transition-transform duration-300
                           group-hover:scale-110"
                  onError={(e) => {
                    const img = e.target as HTMLImageElement;
                    if (img.src !== FALLBACK_IMAGE_URL) {
                      img.src = FALLBACK_IMAGE_URL;
                    }
                  }}
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />

                {/* Rating Badge */}
                <div
                  className="absolute top-2 right-2 flex items-center gap-1 px-2 py-1 
                             rounded-full bg-black/50 backdrop-blur-sm"
                >
                  <StarIcon className="w-4 h-4 text-yellow-400" />
                  <span className="text-sm font-medium text-white">
                    {movie.vote_average.toFixed(1)}
                  </span>
                </div>

                {/* Release Year Badge */}
                {movie.release_date && (
                  <div
                    className="absolute top-2 left-2 px-2 py-1 rounded-full 
                               bg-black/50 backdrop-blur-sm"
                  >
                    <span className="text-sm text-white">
                      {new Date(movie.release_date).getFullYear()}
                    </span>
                  </div>
                )}
              </div>

              {/* Content */}
              <div className="p-4 space-y-3">
                <h3
                  className="text-lg font-semibold text-gray-900 dark:text-white 
                             line-clamp-1"
                >
                  {movie.original_title}
                </h3>

                <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
                  {movie.overview}
                </p>

                {/* Genres */}
                {movie.genres && movie.genres.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {movie.genres
                      .slice(0, 3)
                      .map(
                        (genre: {
                          id: React.Key | null | undefined;
                          name:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined;
                        }) => (
                          <span
                            key={genre.id}
                            className="px-2 py-1 text-xs rounded-full
                               bg-blue-500/10 text-blue-400 dark:text-blue-300
                               border border-blue-500/20"
                          >
                            {genre.name}
                          </span>
                        )
                      )}
                    {movie.genres.length > 3 && (
                      <span className="text-xs text-gray-400">
                        +{movie.genres.length - 3} more
                      </span>
                    )}
                  </div>
                )}
              </div>

              {/* Hover Overlay */}
              <div
                className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100
                           transition-opacity duration-300 flex items-center justify-center"
              >
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg
                               transform translate-y-4 group-hover:translate-y-0
                               transition-transform duration-300"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Movie Details Modal */}
      {showDetails && selectedMovie && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="bg-[#1e2533] rounded-2xl max-w-5xl w-full max-h-[90vh] overflow-y-auto">
            {/* Hero Section with Backdrop */}
            <div className="relative h-[400px]">
              <div className="absolute inset-0">
                <img
                  src={selectedMovie.image_url}
                  alt={selectedMovie.original_title}
                  className="w-full h-full object-cover rounded-t-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#1e2533] via-[#1e2533]/80 to-transparent" />
              </div>

              {/* Close Button */}
              <button
                onClick={() => setShowDetails(false)}
                className="absolute top-4 right-4 p-2 rounded-full bg-black/50 hover:bg-black/70 
                         text-white transition-all duration-200 hover:scale-105"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>

              {/* Movie Poster and Basic Info */}
              <div className="absolute bottom-0 left-0 right-0 p-8 flex gap-8">
                <img
                  src={selectedMovie.image_url}
                  alt={selectedMovie.original_title}
                  className="w-48 h-72 rounded-xl shadow-2xl ring-1 ring-white/10"
                />

                <div className="flex-1 space-y-4">
                  <div>
                    <h1 className="text-4xl font-bold text-white mb-2">
                      {selectedMovie.original_title}
                    </h1>
                    <div className="flex items-center gap-4 text-gray-300 text-sm">
                      <span>
                        {new Date(selectedMovie.release_date).getFullYear()}
                      </span>
                      <span>•</span>
                      <div className="flex items-center gap-2">
                        <span className="text-yellow-400">★</span>
                        <span>{selectedMovie.vote_average.toFixed(1)}</span>
                        <span className="text-gray-400">
                          ({selectedMovie.popularity.toFixed(1)} popularity)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="p-8 space-y-8">
              {/* Overview Section */}
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold text-white">Overview</h2>
                <p className="text-gray-300 leading-relaxed">
                  {selectedMovie.overview}
                </p>
              </div>

              {/* Movie Details Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left Column */}
                <div className="space-y-6">
                  {/* Genres */}
                  {selectedMovie.genres && selectedMovie.genres.length > 0 && (
                    <div>
                      <h3 className="text-lg font-medium text-white mb-3">
                        Genres
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {selectedMovie.genres.map((genre) => (
                          <span
                            key={genre.id}
                            className="px-4 py-1.5 rounded-full bg-blue-500/10 text-blue-400
                                     border border-blue-500/20 text-sm"
                          >
                            {genre.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Language and Release Info */}
                  <div>
                    <h3 className="text-lg font-medium text-white mb-3">
                      Details
                    </h3>
                    <div className="space-y-2 text-gray-300">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-400">
                          Original Language:
                        </span>
                        <span className="uppercase">
                          {selectedMovie.original_language}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-gray-400">Release Date:</span>
                        <span>
                          {new Date(
                            selectedMovie.release_date
                          ).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-gray-400">Popularity:</span>
                        <span>{selectedMovie.popularity.toFixed(1)}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column */}
                <div className="space-y-6">
                  {/* Ratings */}
                  <div>
                    <h3 className="text-lg font-medium text-white mb-3">
                      Ratings
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="p-4 rounded-xl bg-[#252d3d] border border-white/5">
                        <div className="text-2xl font-bold text-yellow-400 mb-1">
                          {selectedMovie.vote_average.toFixed(1)}
                        </div>
                        <div className="text-sm text-gray-400">TMDB Rating</div>
                      </div>
                      {selectedMovie.imdb_rating > 0 && (
                        <div className="p-4 rounded-xl bg-[#252d3d] border border-white/5">
                          <div className="text-2xl font-bold text-yellow-400 mb-1">
                            {selectedMovie.imdb_rating.toFixed(1)}
                          </div>
                          <div className="text-sm text-gray-400">
                            IMDb Rating
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Additional Info */}
                  {selectedMovie.tagline && (
                    <div>
                      <h3 className="text-lg font-medium text-white mb-3">
                        Tagline
                      </h3>
                      <p className="text-gray-300 italic">
                        "{selectedMovie.tagline}"
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Streaming Platforms */}
              {selectedMovie.streaming_platforms && (
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-white">
                    Available on
                  </h3>
                  <div className="flex flex-wrap gap-3">
                    {selectedMovie.streaming_platforms.map(
                      (platform: string) => (
                        <span
                          key={platform}
                          className="px-4 py-2 rounded-lg bg-[#252d3d] text-gray-300 text-sm"
                        >
                          {platform}
                        </span>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
