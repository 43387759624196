import React, { useEffect } from "react";
import {
  retrieveNewsItemsDataset,
  retrieveTracksDataset,
  retrieveUserDataset,
  retrieveVideosDataset,
} from "../../../../redux/feed/feedDatasetSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import FeedDataTable from "../common/FeedDataTable";
import UserData from "../common/UserData";
import { generateYoutubeUrl } from "../../../../helpers/youtube";
import { APP_URLS } from "../../../../navigation";
import { formatDateTime, formatSeconds } from "../../../../helpers/date";
import { generateSpotifyUrl } from "../../../../helpers/spotify";

interface Props {
  userId: string;
}

/**
 * Component for displaying feed data details of a user.
 */
export default function FeedDataUserDetails({ userId }: Props) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.feedDataset.user);
  const pending = useAppSelector(
    (state) => state.feedDataset.retrieveUserDatasetPending
  );

  function onClickVideosRow(itemId: string) {
    // Navigate to video details page
    window.location.href = APP_URLS.FEED_DATA_VIDEO_DETAILS.replace(
      ":videoId",
      itemId
    );
  }

  function onClickTracksRow(itemId: string) {
    // Navigate to track details page
    window.location.href = APP_URLS.FEED_DATA_TRACK_DETAILS.replace(
      ":trackId",
      itemId
    );
  }

  function onClickNewsRow(itemId: string) {
    // Navigate to news details page
    window.location.href = APP_URLS.FEED_DATA_NEWS_DETAILS.replace(
      ":newsId",
      itemId
    );
  }

  function fetchData() {
    if (userId) {
      dispatch(retrieveUserDataset({ userId }));
    }
  }

  useEffect(() => {
    fetchData();
  }, [userId]);

  return (
    <div className="space-y-6">
      {/* User Info Card */}
      <div className="bg-[#1e2533] rounded-lg border border-gray-700/50">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-white mb-6">
            User Information
          </h2>
          <UserData user={user} pending={pending} />
        </div>
      </div>

      {/* Interactive Sections Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Videos Section */}
        <div className="bg-[#1e2533] rounded-lg border border-gray-700/50">
          <div className="p-6">
            <FeedDataTable
              headerText="Video Interactions"
              defaultOrdering="title"
              paginatedList={useAppSelector(
                (state) => state.feedDataset.videos
              )}
              fetchThunkFunction={retrieveVideosDataset}
              pending={useAppSelector(
                (state) => state.feedDataset.retrieveVideosDatasetPending
              )}
              labels={{
                title: "Title",
                youtube_video_id: "Video",
                watched_at: "Watched",
                watched_for: "Duration",
                liked: "Liked",
              }}
              additionalQueryParams={{ user_id: userId }}
              onClickRow={onClickVideosRow}
              valueOverrides={{
                youtube_video_id: generateYoutubeUrl,
                watched_at: formatDateTime,
                watched_for: formatSeconds,
              }}
            />
          </div>
        </div>

        {/* Music Section */}
        <div className="bg-[#1e2533] rounded-lg border border-gray-700/50">
          <div className="p-6">
            <FeedDataTable
              headerText="Music Interactions"
              defaultOrdering="title"
              paginatedList={useAppSelector(
                (state) => state.feedDataset.tracks
              )}
              fetchThunkFunction={retrieveTracksDataset}
              pending={useAppSelector(
                (state) => state.feedDataset.retrieveTracksDatasetPending
              )}
              labels={{
                title: "Track",
                spotify_track_id: "Listen",
                watched_at: "Played",
                watched_for: "Duration",
                liked: "Liked",
              }}
              additionalQueryParams={{ user_id: userId }}
              onClickRow={onClickTracksRow}
              valueOverrides={{
                spotify_track_id: generateSpotifyUrl,
                watched_at: formatDateTime,
                watched_for: formatSeconds,
              }}
            />
          </div>
        </div>

        {/* News Section */}
        <div className="col-span-1 lg:col-span-2 bg-[#1e2533] rounded-lg border border-gray-700/50">
          <div className="p-6">
            <FeedDataTable
              headerText="News Interactions"
              defaultOrdering="title"
              paginatedList={useAppSelector(
                (state) => state.feedDataset.newsItems
              )}
              fetchThunkFunction={retrieveNewsItemsDataset}
              pending={useAppSelector(
                (state) => state.feedDataset.retrieveNewsItemsDatasetPending
              )}
              labels={{
                title: "Article",
                bing_news_url: "Read",
                watched_at: "Read At",
                watched_for: "Time Spent",
                liked: "Liked",
              }}
              additionalQueryParams={{ user_id: userId }}
              onClickRow={onClickNewsRow}
              valueOverrides={{
                watched_at: formatDateTime,
                watched_for: formatSeconds,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
