import React, { CSSProperties } from "react";
import NavBar from "../../../components/navigation";
import {
  APP_URLS,
  PAGES_WITH_CUSTOM_DESKTOP_NAVBAR,
  PAGES_WITH_CUSTOM_MOBILE_NAVBAR,
} from "../../../navigation";
import { MOBILE_BOTTOM_NAV_BAR_HEIGHT } from "../../../components/navigation/mobile/bottom/MobileBottomNavBar";
import { MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT } from "../../../components/navigation/mobile/bottom/MobileBottomNavBarFeed";
import { MOBILE_TOP_NAV_BAR_HEIGHT } from "../../../components/navigation/mobile/top/MobileTopNavBar";
import { useIsSmScreen } from "../../../hooks/useIsSmScreen";

interface Props {
  children: JSX.Element;
}

/**
 * A wrapper component for pages.
 */
export default function PageWrapper({ children }: Props) {
  const isSmScreen = useIsSmScreen();

  /**
   * Returns true if the current page implements a custom navbar.
   */
  function isPageWithCustomNavBar() {
    const pathName = window.location.pathname;
    if (isSmScreen) {
      if (PAGES_WITH_CUSTOM_MOBILE_NAVBAR.includes(pathName)) {
        return true;
      }
    } else {
      if (PAGES_WITH_CUSTOM_DESKTOP_NAVBAR.includes(pathName)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns the spacing styles for the page.
   */
  function getSpacingStyles(): CSSProperties {
    const pathName = window.location.pathname;
    if (isPageWithCustomNavBar()) {
      // Handle custom navbar
      if (pathName === APP_URLS.FEED_MOBILE) {
        return {
          paddingTop: MOBILE_TOP_NAV_BAR_HEIGHT,
          paddingBottom: MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT,
        };
      }
    }
    if (isSmScreen) {
      // Default for mobile
      return {
        paddingTop: MOBILE_TOP_NAV_BAR_HEIGHT,
        paddingBottom: MOBILE_BOTTOM_NAV_BAR_HEIGHT,
      };
    }
    // Default for desktop
    return {};
  }

  return (
    <div className="flex flex-col sm:flex-row min-h-screen bg-gray-100">
      {!isPageWithCustomNavBar() && <NavBar />}

      <div
        className={"flex w-full sm:ml-60 sm:px-4"}
        style={getSpacingStyles()}
      >
        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
}
