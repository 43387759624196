import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateUser } from "../../redux/user/userSlice";
import { retrieveQuizAnswers, retrieveQuizzes } from "../../redux/quiz/quizSlice";
import { fetchCheckoutSessions } from "../../redux/payments/paymentsSlice";
import PageWrapper from "../../components/common/pageWrapper";
import Header from "../../components/common/header";
import {
  Button,
  Spinner,
  ToggleSwitch,
  TextInput,
  Modal,
} from "flowbite-react";
import SpotifyConnection from "../../components/connections/SpotifyConnection";
import YoutubeConnection from "../../components/connections/YoutubeConnection";
import ShazamConnection from "../../components/connections/ShazamConnection";
import { ethers } from "ethers";
import WagmiConnector from "../../components/brainCoin/WagmiConnection";
import SequenceWallet from "../../components/brainCoin/SequenceWallet";
import { sequence } from "0xsequence";
import NFTProductSelectionModal from "../../components/nft/NFTProductSelectionModal";
import NFTRedeemableItem from "../../components/nft/NFTRedeemableItem";
import { UserIcon } from "@heroicons/react/24/solid";
import { history } from "../../helpers/history";
import { APP_URLS } from "../../navigation";
import { Quiz } from "../../types/quiz";
import { removeTokens } from "../../services/localStorage";
import { formatDate } from "../../helpers/date";
import { deleteRequest, get, post } from "../../services/apiService";
import NFTRedeemableModal from "../../components/nft/NFTRedeemableModal";
import { useLogout } from "@account-kit/react";
import { WalletLoginModal } from "../../components/auth/WalletLoginModal";
import { ConnectWalletModal } from "../../components/wallet/ConnectWalletModal";
import { setSequenceWalletAddress } from "../../redux/wallet/walletSlice";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";

/**
 * Profile page
 */

export default function Profile({
  sequenceWalletAddress,
  pendingConnect,
}: {
  sequenceWalletAddress: string | null | undefined;
  pendingConnect: boolean;
}) {
  // Redux state
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const quizzes = useAppSelector((state) => state.quiz.quizzes);
  const quizAnswers = useAppSelector((state) => state.quiz.answers);
  const checkoutSessions = useAppSelector(
    (state) => state.payments.checkoutSessions
  );
  const pendingRetrieveQuizzes = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizzes
  );
  const pendingRetrieveQuizAnswers = useAppSelector(
    (state) => state.quiz.pendingRetrieveQuizAnswers
  );
  const pendingFetchCheckoutSessions = useAppSelector(
    (state) => state.payments.pendingFetchCheckoutSessions
  );

  // User related state
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableUser, setEditableUser] = useState({
    username: user?.username || "",
    email: user?.email || "",
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    is_touch_interface_enabled: user?.is_touch_interface_enabled || false,
  });

  // Quiz related state
  const [mainQuiz, setMainQuiz] = useState<Quiz | null>(null);

  // Wallet related state
  const [walletAddress, setWalletAddress] = useState("");
  const [balance, setBalance] = useState<string>("");
  const [generatedNonce, setGeneratedNonce] = useState<string>("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  // Sequence wallet state
  const [isSequenceWalletConnected, setIsSequenceWalletConnected] =
    useState(false);

  // NFT related state
  const [showNFTProductSelectionModal, setShowNFTProductSelectionModal] =
    useState(false);
  const [showRedeemableModal, setShowRedeemableModal] = useState(false);

  // Feature flag
  const FEATURE_FLAG = "true";

  // Add a new state for BrainCoin balance
  const [brainCoinBalance, setBrainCoinBalance] = useState<string>("");

  // Add this state at the top with other states
  const [isWalletLinked, setIsWalletLinked] = useState(false);

  // Add these states at the top
  const [isInitialized, setIsInitialized] = useState(false);

  // Add this state at the top with other states
  const [isLinking, setIsLinking] = useState(false);

  const { logout } = useLogout();

  // Add state for logout modal
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  /**
   * Navigate to quiz page
   */
  function onClickTakeQuiz() {
    console.log("mainQuiz", mainQuiz);
    if (mainQuiz) {
      const url = APP_URLS.PROFILE_QUIZ.replace(":id", mainQuiz.id);
      history.navigate(url);
    }
  }

  /**
   * Render take/retake quiz button
   */
  function renderQuizButton() {
    let buttonContent;
    const pending = pendingRetrieveQuizAnswers || pendingRetrieveQuizzes;
    if (pending) {
      buttonContent = <Spinner size="sm" aria-label="Loading spinner" />;
    } else if (quizAnswers.length === 0) {
      buttonContent = "Take Quiz";
    } else {
      buttonContent = "Retake Quiz";
    }

    return (
      <div>
        <Button
          onClick={onClickTakeQuiz}
          size="md"
          disabled={pending}
          aria-label="Take or Retake Quiz"
        >
          {buttonContent}
        </Button>
        {quizAnswers.length !== 0 && (
          <span className="text-gray-700 dark:text-gray-300 pt-4" >Last taken: {formatDate(quizAnswers[0].updated_at || "")}</span>
        )}
      </div>
    );
  }

  /**
   * Update the logout click handler to show modal
   */
  const onLogoutClick = () => {
    setShowLogoutModal(true);
  };

  /**
   * Add handler for confirming logout
   */
  const handleConfirmLogout = () => {
    localStorage.removeItem("lastLoginTime");
    logout();
    removeTokens();
    history.navigate(APP_URLS.LOGIN);
    setShowLogoutModal(false);
  };

  /**
   * Render checkout sessions representing redeemable NFTs
   */
  function renderNFTRedeemableItems() {
    if (checkoutSessions.length == 0) {
      return (
        <div className="flex items-center justify-center">
          <span className="text-gray-500 text-base font-semibold">
            You don{"'"}t have any NFTs to redeem
          </span>
        </div>
      );
    }
    return checkoutSessions.map((checkoutSession, index) => (
      <NFTRedeemableItem data={checkoutSession} key={index} />
    ));
  }

  /**
   * Retrieve main quiz
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(retrieveQuizzes({ queryParams: { main_quiz: "true" } }));
      } catch (error) {
        console.error("Failed to retrieve quizzes:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  /**
   * Retrieve main quiz answers and extract main quiz data
   */
  useEffect(() => {
    console.log("quizzes", quizzes,"::",quizzes.results.length);

    if (quizzes.results.length > 0) {
      const newMainQuiz = quizzes.results[0];
      // Retrieve answers to the quiz
      dispatch(retrieveQuizAnswers({ quizId: newMainQuiz.id }));
      setMainQuiz(newMainQuiz);
    }
  }, [quizzes]);

  useEffect(() => {
    if (user) {
      setEditableUser({
        username: user.username || "",
        email: user.email || "",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        is_touch_interface_enabled: user.is_touch_interface_enabled || false,
      });
    }
  }, [user]);

  /**
   * Handle touch interface toggle change
   */
  const handleTouchInterfaceChange = (isEnabled: boolean) => {
    setEditableUser((prev) => ({
      ...prev,
      is_touch_interface_enabled: isEnabled,
    }));
  };

  /**
   * Handle input changes
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableUser((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * Save changes
   */
  const handleSaveChanges = () => {
    toast.promise(dispatch(updateUser(editableUser)), {
      pending: "Saving changes...",
      success: "Changes saved successfully!",
      error: "Failed to save changes.",
    });
    setIsEditMode(false);
  };

  useEffect(() => {
    dispatch(fetchCheckoutSessions());
  }, []);

  // Add function to get BrainCoin balance
  const getBrainCoinBalance = async () => {
    try {
      const response = await get(
        "/api/payments/integrate-external-wallet/get_braincoin_balance/",
        false,
        "application/json"
      );
      // Make sure we're accessing the correct property from the response
      const balance = response?.data?.balance || response?.data;

      if (balance !== undefined) {
        setBrainCoinBalance(balance.toString());
      } else {
        setBrainCoinBalance("undefined");
      }
    } catch (error) {
      console.error("Error fetching BrainCoin balance:", error);
      setBrainCoinBalance("undefined");
    }
  };

  // Add an effect to fetch balance when wallet is linked
  useEffect(() => {
    if (isWalletLinked && walletAddress) {
      getBrainCoinBalance();
    }
  }, [isWalletLinked, walletAddress]);

  // // Add this effect to initialize wallet states when user changes or logs in
  // useEffect(() => {
  //   const initializeUserWallets = async () => {
  //     if (!user) {
  //       // Reset all wallet states when no user
  //       setWalletAddress("");
  //       setBalance("");
  //       setBrainCoinBalance("");
  //       setIsWalletLinked(false);
  //       setIsSequenceWalletConnected(false);
  //       dispatch(setSequenceWalletAddress(null));
  //       return;
  //     }

  //     try {
  //       // Get user's current wallet status
  //       const response = await get(
  //         "/api/accounts/current_user",
  //         false,
  //         "application/json"
  //       );
  //       const userEthAddress = response?.eth_address;

  //       if (userEthAddress) {
  //         setIsWalletLinked(true);

  //         // Check if MetaMask is connected with correct address
  //         if (window.ethereum) {
  //           const accounts = await window.ethereum.request({
  //             method: "eth_accounts",
  //           });

  //           if (accounts?.[0]?.toLowerCase() === userEthAddress.toLowerCase()) {
  //             setWalletAddress(accounts[0]);
  //             const balance = await window.ethereum.request({
  //               method: "eth_getBalance",
  //               params: [accounts[0], "latest"],
  //             });
  //             setBalance(ethers.utils.formatEther(balance));
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error initializing user wallets:", error);
  //     }
  //   };

  //   initializeUserWallets();
  // }, [user]);

  // // Add event listener for MetaMask account changes
  // useEffect(() => {
  //   if (window.ethereum && isWalletLinked) {
  //     const handleAccountsChanged = async (accounts: string[]) => {
  //       if (accounts.length === 0) {
  //         // User disconnected MetaMask
  //         setWalletAddress("");
  //         setBalance("");
  //         setBrainCoinBalance("");
  //       } else {
  //         // Account changed, verify if it matches linked wallet
  //         const response = await get(
  //           "/api/accounts/current_user",
  //           false,
  //           "application/json"
  //         );
  //         const userEthAddress = response?.data?.eth_address;

  //         if (userEthAddress?.toLowerCase() === accounts[0].toLowerCase()) {
  //           setWalletAddress(accounts[0]);
  //           await getBalance(accounts[0]);
  //           await getBrainCoinBalance();
  //         } else {
  //           setWalletAddress("");
  //           setBalance("");
  //           setBrainCoinBalance("");
  //           toast.error("Please connect with your linked wallet address");
  //         }
  //       }
  //     };

  //     window.ethereum.on("accountsChanged", handleAccountsChanged);
  //     return () => {
  //       window.ethereum.removeListener(
  //         "accountsChanged",
  //         handleAccountsChanged
  //       );
  //     };
  //   }
  // }, [isWalletLinked]);

  // Handle MetaMask connection
  const connectMetaMask = async () => {
    if (!window.ethereum) {
      toast.error("MetaMask not detected. Please install MetaMask first.");
      window.open("https://metamask.io/download/", "_blank");
      return;
    }

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (accounts && accounts.length > 0) {
        const account = accounts[0];

        // Check if this address is already linked to another user
        const response = await get(
          "/api/accounts/current_user",
          false,
          "application/json"
        );
        const userEthAddress = response?.eth_address;

        if (
          userEthAddress &&
          userEthAddress.toLowerCase() !== account.toLowerCase()
        ) {
          toast.error("This wallet is already linked to another account");
          return;
        }

        const balance = await window.ethereum.request({
          method: "eth_getBalance",
          params: [account, "latest"],
        });

        setWalletAddress(account);
        setBalance(ethers.utils.formatEther(balance));
        toast.success("MetaMask connected successfully!");
      }
    } catch (error: any) {
      console.error("MetaMask connection error:", error);
      if (error.code === 4001) {
        toast.error("You rejected the connection request. Please try again.");
      } else {
        toast.error(error.message || "Failed to connect MetaMask");
      }
    }
  };

  // Handle Sequence wallet connection
  const connectSequenceWallet = async () => {
    if (!user) return;

    try {
      const wallet = sequence.getWallet();
      const connectDetails = await wallet.connect({
        app: "BrainCargo",
        authorize: true,
      });
      const address = connectDetails.session?.accountAddress;

      // Check if this address is already linked to another user
      const response = await get(
        "/api/accounts/current_user",
        false,
        "application/json"
      );
      const userEthAddress = response?.eth_address;

      if (
        userEthAddress &&
        address &&
        userEthAddress.toLowerCase() !== address.toLowerCase()
      ) {
        toast.error("This wallet is already linked to another account");
        await wallet.disconnect();
        return;
      }

      setIsSequenceWalletConnected(true);
      // setSequenceWalletAddress(address);
    } catch (error) {
      console.error("Sequence wallet connection error:", error);
      toast.error("Failed to connect Sequence wallet");
    }
  };

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
        <Modal
          show={showLogoutModal}
          onClose={() => setShowLogoutModal(false)}
          popup
          size="sm"
          className="bg-gray-700"
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <h3 className="mb-5 text-lg font-normal text-gray-300">
                Are you sure you want to logout?
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={handleConfirmLogout}>
                  Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => setShowLogoutModal(false)}>
                  No, cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="container mx-auto px-4 py-8">
          <div className="mb-8">
            <MainHeader title="Profile" />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-4">
              <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <div className="flex flex-col items-center">
                  <div className="relative group">
                    <div className="w-32 h-32 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 p-1">
                      <div className="w-full h-full rounded-full bg-white dark:bg-gray-800 flex items-center justify-center">
                        <UserIcon className="w-16 h-16 text-gray-600 dark:text-gray-300" />
                      </div>
                    </div>
                  </div>

                  <h2 className="mt-4 text-xl font-bold text-gray-800 dark:text-white">
                    {editableUser.first_name} {editableUser.last_name}
                  </h2>
                  <p className="text-gray-500 dark:text-gray-400">
                    {editableUser.username}
                  </p>

                  <div className="mt-6 flex gap-4">
                    <Button
                      onClick={() => setIsEditMode(!isEditMode)}
                      className="rounded-full transition-all duration-200"
                      color={isEditMode ? "gray" : "info"}
                    >
                      {isEditMode ? "Cancel" : "Edit Profile"}
                    </Button>
                    <Button
                      onClick={onLogoutClick}
                      color="failure"
                      className="px-4 py-2 rounded-full transition-all duration-200"
                    >
                      Logout
                    </Button>
                  </div>
                </div>

                <div className="mt-8 space-y-4">
                  {isEditMode ? (
                    <form className="space-y-4">
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Username
                        </label>
                        <TextInput
                          name="username"
                          value={editableUser.username}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Email
                        </label>
                        <TextInput
                          name="email"
                          value={editableUser.email}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          First Name
                        </label>
                        <TextInput
                          name="first_name"
                          value={editableUser.first_name}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Last Name
                        </label>
                        <TextInput
                          name="last_name"
                          value={editableUser.last_name}
                          onChange={handleInputChange}
                          className="w-full rounded-lg"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Touch Interface
                        </span>
                        <ToggleSwitch
                          checked={editableUser.is_touch_interface_enabled}
                          onChange={handleTouchInterfaceChange}
                        />
                      </div>

                      <Button
                        onClick={handleSaveChanges}
                        color="success"
                        className="w-full mt-6"
                      >
                        Save Changes
                      </Button>
                    </form>
                  ) : (
                    <div className="space-y-4">
                      <InfoItem
                        label="Username"
                        value={editableUser.username}
                      />
                      <InfoItem label="Email" value={editableUser.email} />
                      <InfoItem
                        label="First Name"
                        value={editableUser.first_name}
                      />
                      <InfoItem
                        label="Last Name"
                        value={editableUser.last_name}
                      />
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          Touch Interface
                        </span>
                        <ToggleSwitch
                          checked={editableUser.is_touch_interface_enabled}
                          onChange={handleTouchInterfaceChange}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="lg:col-span-8 space-y-8">
              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  Media Preference Quiz
                </h3>
                <div className="flex items-center justify-between">
                  <p className="text-gray-600 dark:text-gray-400">
                    Take our quiz to personalize your experience
                  </p>
                  {renderQuizButton()}
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  Connected Accounts
                </h3>
                <div className="grid gap-4">
                  <ConnectionItem
                    title="Spotify"
                    component={<SpotifyConnection />}
                  />
                  <ConnectionItem
                    title="YouTube"
                    component={<YoutubeConnection />}
                  />
                  <ConnectionItem
                    title="Shazam"
                    component={<ShazamConnection />}
                  />
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  Wallet
                </h3>
                <div className="flex justify-center">
                  <ConnectWalletModal
                    sequenceWalletAddres={sequenceWalletAddress}
                    pendingConnect={pendingConnect}
                  />
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  NFT Management
                </h3>
                <div className="flex flex-col gap-6">
                  <div className="flex justify-center gap-4">
                    <Button
                      onClick={() => setShowNFTProductSelectionModal(true)}
                      disabled={!user?.eth_address}
                      color="info"
                      className="rounded-full px-6"
                    >
                      Buy NFT
                    </Button>
                    <Button
                      onClick={() => setShowRedeemableModal(true)}
                      disabled={!user?.eth_address}
                      color="success"
                      className="rounded-full px-6"
                    >
                      Redeem NFT
                    </Button>
                  </div>
                  {user && user?.eth_address ? (
                    <>
                      <NFTProductSelectionModal
                        show={showNFTProductSelectionModal}
                        setShow={setShowNFTProductSelectionModal}
                        walletAddress={user?.eth_address}
                      />
                      <NFTRedeemableModal
                        show={showRedeemableModal}
                        setShow={setShowRedeemableModal}
                      />
                    </>
                  ) : null}
                </div>
              </section>

              <section className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4">
                  AI Training
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Coming soon...
                </p>
              </section>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}

// Helper Components
const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div className="flex justify-between items-center py-2 border-b border-gray-200 dark:border-gray-700">
    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {label}
    </span>
    <span className="text-sm text-gray-600 dark:text-gray-400">{value}</span>
  </div>
);

const ConnectionItem = ({
  title,
  component,
}: {
  title: string;
  component: React.ReactNode;
}) => (
  <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {title}
    </span>
    {component}
  </div>
);
