import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setTokens } from "../../services/localStorage";
import { history } from "../../helpers/history";
import * as authService from "../../services/authService";
import { ErrorMessages } from "../../types/redux/slice";
import {
  AlchemyLoginPayload,
  AlchemyRegisterPayload,
  AlchemyRegisterResponse,
  AuthTokens,
  checkEmailPayload,
  checkEmailResponse,
  GoogleLoginPayload,
  LoginPayload,
  OTPGenerateResponse,
  PhoneGeneratePayload,
  PhoneRegisterPayload,
  PhoneVerificationPayload,
  RegisterMessageResponse,
  RegisterPayload,
  resetEmailPayload,
  VerifyEmailPayload,
  VerifyEmailResponse,
} from "../../types/auth";
import { APP_URLS } from "../../navigation";
import { dataSetsSlice } from "../../redux/dataSets/dataSetsSlice";

interface AuthState {
  loginPending: boolean;
  loginErrorMessages: ErrorMessages;
  alchemyPending: boolean;
  alchemyErrorMessages: ErrorMessages;
  alchemyRegisterPending: boolean;
  alchemyRegisterErrorMessages: ErrorMessages;
  registerPending: boolean;
  registerErrorMessages: ErrorMessages;
  activeStepIndex: number;
  googleLoginPending: boolean;
  googleLoginErrorMessages: ErrorMessages;
  emailVerified: boolean;
  emailVerificationMessages: ErrorMessages;
  generateOTPPending: boolean;
  generateOTPErrorMessages: ErrorMessages;
  verifyOTPPending: boolean;
  verifyOTPErrorMessages: ErrorMessages;
}

const initialState: AuthState = {
  loginPending: false,
  loginErrorMessages: {},
  alchemyPending: false,
  alchemyErrorMessages: {},
  alchemyRegisterPending: false,
  alchemyRegisterErrorMessages: {},
  registerPending: false,
  registerErrorMessages: {},
  activeStepIndex: 0,
  googleLoginPending: false,
  googleLoginErrorMessages: {},
  emailVerified: false,
  emailVerificationMessages: {},
  generateOTPPending: false,
  generateOTPErrorMessages: {},
  verifyOTPPending: false,
  verifyOTPErrorMessages: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    incrementActiveStepIndex: (state) => {
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loginPending = true;
      state.loginErrorMessages = {};
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginPending = false;
      state.loginErrorMessages = {};
      const { access, refresh } = action.payload;
      setTokens({ access, refresh });
      history.navigate(APP_URLS.VIDEOS);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginPending = false;
      state.loginErrorMessages = action?.payload || {};
    });
    builder.addCase(alchemyLogin.pending, (state) => {
      state.alchemyPending = true;
      state.alchemyErrorMessages = {};
    });
    builder.addCase(alchemyLogin.fulfilled, (state, action) => {
      state.alchemyPending = false;
      state.alchemyErrorMessages = {};
      const { access, refresh } = action.payload;
      setTokens({ access, refresh });
      history.navigate(APP_URLS.VIDEOS);
    });
    builder.addCase(alchemyLogin.rejected, (state, action) => {
      state.alchemyPending = false;
      state.alchemyErrorMessages = action?.payload || {};
    });

    builder.addCase(alchemyRegister.pending, (state, action) => {
      state.alchemyRegisterPending = true;
      state.alchemyRegisterErrorMessages = {};
    });
    builder.addCase(alchemyRegister.fulfilled, (state, action) => {
      state.alchemyRegisterPending = false;
      state.alchemyRegisterErrorMessages = {};
      const { access, refresh } = action.payload;
      setTokens({ access, refresh });
      history.navigate(APP_URLS.VIDEOS);
    });
    builder.addCase(alchemyRegister.rejected, (state, action) => {
      state.alchemyRegisterPending = false;
      state.alchemyRegisterErrorMessages = action?.payload || {};
    });

    builder.addCase(register.pending, (state, action) => {
      state.registerPending = true;
      state.registerErrorMessages = {};
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.registerPending = false;
      state.registerErrorMessages = {};
      const { access, refresh } = action.payload;
      setTokens({ access, refresh });
      history.navigate(APP_URLS.VIDEOS);
    });
    builder.addCase(register.rejected, (state, action) => {
      state.registerPending = false;
      state.registerErrorMessages = action?.payload || {};
    });

    builder.addCase(googleRegister.pending, (state) => {
      state.googleLoginPending = true;
      state.googleLoginErrorMessages = {};
    });
    builder.addCase(googleRegister.fulfilled, (state, action) => {
      state.googleLoginPending = false;
      state.googleLoginErrorMessages = {};

      const { access, refresh } = action.payload;
      setTokens({ access, refresh });
      history.navigate(APP_URLS.VIDEOS);
    });
    builder.addCase(googleRegister.rejected, (state, action) => {
      state.googleLoginPending = false;
      state.googleLoginErrorMessages = action?.payload || {};
    });

    builder
      .addCase(verifyEmail.pending, (state) => {
        state.emailVerified = false;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.emailVerified = true;
        history.navigate(APP_URLS.LOGIN);
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.emailVerified = false;
        state.emailVerificationMessages = action?.payload || {};
        history.navigate(APP_URLS.LOGIN);
      });

    builder.addCase(generateLoginOTP.pending, (state) => {
      state.generateOTPPending = true;
      state.generateOTPErrorMessages = {};
    });

    builder.addCase(generateLoginOTP.fulfilled, (state, action) => {
      state.generateOTPPending = false;
      state.generateOTPErrorMessages = {};
    });

    builder.addCase(generateLoginOTP.rejected, (state, action) => {
      state.generateOTPPending = false;
      state.generateOTPErrorMessages = action.payload || {};
    });

    builder.addCase(verifyOTP.pending, (state) => {
      state.verifyOTPPending = true;
      state.verifyOTPErrorMessages = {};
    });

    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.verifyOTPPending = false;
      state.verifyOTPErrorMessages = {};
    });

    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.verifyOTPPending = false;
      state.verifyOTPErrorMessages = action.payload || {};
    });
  },
});

export const login = createAsyncThunk<
  AuthTokens,
  LoginPayload,
  { rejectValue: ErrorMessages }
>("auth/login", async (data, thunkApi) => {
  try {
    const response = await authService.login(data);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

interface AlchemyLoginData {
  email: string;
  eth_address: string;
  wallet_address: string;
}

export const alchemyLogin = createAsyncThunk<
  AuthTokens,
  AlchemyLoginData,
  { rejectValue: ErrorMessages }
>("auth/alchemyLogin", async (data: AlchemyLoginData, thunkApi) => {
  try {
    const response = await authService.alchemyLogin(data);
      return response;
    } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const alchemyRegister = createAsyncThunk<
  AuthTokens,
  AlchemyRegisterPayload,
  { rejectValue: ErrorMessages }
>("auth/alchemyRegister", async (data, thunkApi) => {
  try {
    const response = await authService.alchemyRegister(data);
    return { access: response.data.access, refresh: response.data.refresh };
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const register = createAsyncThunk<
  AuthTokens,
  RegisterPayload,
  { rejectValue: ErrorMessages }
>("auth/register", async (data, thunkApi) => {
  try {
    const response = await authService.register(data);
    const { access_token, refresh_token } = response;
    return { access: access_token, refresh: refresh_token };
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const verifyEmail = createAsyncThunk<
  VerifyEmailResponse,
  VerifyEmailPayload,
  { rejectValue: ErrorMessages }
>("auth/verifyEmail", async (data, thunkApi) => {
  try {
    return await authService.verifyEmail(data);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const googleRegister = createAsyncThunk<
  AuthTokens,
  GoogleLoginPayload,
  { rejectValue: ErrorMessages }
>("auth/googleRegister", async (data, thunkApi) => {
  try {
    const response = await authService.googleLogin(data);
    const { access_token, refresh_token } = response;
    return { access: access_token, refresh: refresh_token };
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const phoneRegister = createAsyncThunk<
  RegisterMessageResponse,
  PhoneRegisterPayload,
  { rejectValue: ErrorMessages }
>("auth/phoneRegister", async (data, thunkApi) => {
  try {
    const response = await authService.registerPhone(data);
    const { message, status } = response;
    return { message, status };
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const generateLoginOTP = createAsyncThunk<
  OTPGenerateResponse,
  PhoneGeneratePayload,
  { rejectValue: ErrorMessages }
>("auth/generateLoginOTP", async (data, thunkApi) => {
  try {
    const response = await authService.generateLoginOTP(data);
    const { message, status, token } = response;
    return { message, status, token };
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const verifyOTP = createAsyncThunk<
  AuthTokens,
  PhoneVerificationPayload,
  { rejectValue: ErrorMessages }
>("auth/verifyOTP", async (data, thunkApi) => {
  try {
    const response = await authService.verifyOTP(data);
    const { access, refresh } = response;
    return { access, refresh };
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const checkEmail = createAsyncThunk<
  checkEmailResponse,
  checkEmailPayload,
  { rejectValue: ErrorMessages }
>("auth/checkEmail", async (data, thunkApi) => {
  try {
    return await authService.checkEmail(data);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const forgotEmailPassword = createAsyncThunk<
  checkEmailResponse,
  checkEmailPayload,
  { rejectValue: ErrorMessages }
>("auth/forgotEmailPassword", async (data, thunkApi) => {
  try {
    return await authService.forgotEmailPassword(data);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const resetEmailPassword = createAsyncThunk<
  checkEmailResponse,
  resetEmailPayload,
  { rejectValue: ErrorMessages }
>("auth/resetEmailPassword", async (data, thunkApi) => {
  try {
    return await authService.resetEmailPassword(data);
  } catch (err) {
    return thunkApi.rejectWithValue(err as ErrorMessages);
  }
});

export const { incrementActiveStepIndex } = authSlice.actions;
