import { TrashIcon } from "@heroicons/react/24/outline";
import FileUpload from "../../../../components/common/file/FileUpload";
import QuestionImage from "../../../../components/quiz/QuestionImage";
import { Checkbox, Label, Select, TextInput } from "flowbite-react";
import { displayErrors } from "../../../../helpers/errors";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import {
  QUESTION_CATEGORIES,
  QuizQuestionFormData,
  QuizQuestionCategory,
  QUESTION_CATEGORIES_LABELS,
  QUESTION_TYPES,
  QUESTION_TYPES_LABELS,
  QuizQuestionType,
  QUESTION_TYPES_EXPLANATIONS,
} from "../../../../types/quiz";
import { ErrorMessages } from "../../../../types/redux/slice";

interface Props {
  data: QuizQuestionFormData;
  onChange: (data: QuizQuestionFormData) => void;
  errors?: ErrorMessages;
  idx: number;
}

/**
 * Quiz question input fields.
 */
export default function QuizQuestionInput({
  data,
  onChange,
  errors,
  idx,
}: Props) {
  const aggregatedUploadQuestionImageErrorMessages = useAppSelector(
    (state) => state.quiz.aggregatedUploadQuestionImageErrorMessages,
  );
  const [showImageUpload, setShowImageUpload] = useState(
    !!(data.external_image || data.image) || false,
  );
  const randomId = (Math.random() + 1).toString(36).substring(7);
  // Disable if the quiz has no id - meaning it has not been created yet
  const isImageCheckboxDisabled = !data?.id;

  /**
   * Handle input change.
   */
  function handleOnChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) {
    let value: boolean | string | number;
    if (e.target.type === "checkbox") {
      value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
    } else {
      value = e.target.value;
    }
    const key = e.target.name as keyof QuizQuestionFormData;
    const newData = { ...data, [key]: value };
    onChange(newData);
  }

  /**
   * Handle image change.
   */
  function handleImageChange(file: File | null) {
    const newData = { ...data, external_image: "", image: file };
    onChange(newData);
  }

  /**
   * Handle external image change.
   */
  function handleExternalImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const external_image = e.target.value;
    const newData = { ...data, external_image, image: null };
    onChange(newData);
  }

  /**
   * Clear image.
   */
  function clearImage() {
    onChange({ ...data, external_image: "", image: null });
  }

  /**
   * Generate disabled checkbox title.
   */
  function generateDisabledCheckboxTitle() {
    if (isImageCheckboxDisabled) {
      return "You must first submit the question before you can attach an image to it";
    }
    return undefined;
  }

  /**
   * Show image upload input field if the answer already has an image.
   */
  useEffect(() => {
    setShowImageUpload(!!(data.external_image || data.image) || false);
  }, [data]);

  return (
    <div className="space-y-6">
      {/* Question Text */}
      <div>
        <Label className="text-gray-700 dark:text-gray-300 mb-1.5" value="Question Text" />
        {displayErrors(errors?.text as string[])}
        <TextInput
          type="text"
          value={data.text}
          name="text"
          onChange={handleOnChange}
          placeholder="What's your favorite movie?"
          className="w-full bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-600
                    focus:border-blue-500 dark:focus:border-blue-400 focus:ring-blue-500 dark:focus:ring-blue-400"
        />
      </div>

      {/* Question Identifier */}
      <div>
        <Label className="text-gray-700 dark:text-gray-300 mb-1.5" value="Question Identifier" />
        {displayErrors(errors?.value as string[])}
        <TextInput
          type="text"
          value={data.value}
          name="value"
          onChange={handleOnChange}
          placeholder="favorite-movie"
          className="w-full bg-white dark:bg-gray-800"
        />
      </div>

      {/* Question Category & Type */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label className="text-gray-700 dark:text-gray-300 mb-1.5" value="Category" />
          {displayErrors(errors?.category as string[])}
          <Select
            value={data.category}
            name="category"
            onChange={handleOnChange}
            className="w-full bg-white dark:bg-gray-800"
          >
            {Object.keys(QUESTION_CATEGORIES).map((key) => (
              <option key={key} value={key}>
                {QUESTION_CATEGORIES_LABELS[key as QuizQuestionCategory]}
              </option>
            ))}
          </Select>
        </div>

        <div>
          <Label className="text-gray-700 dark:text-gray-300 mb-1.5" value="Question Type" />
          {displayErrors(errors?.type as string[])}
          <Select
            value={data.type}
            name="type"
            onChange={handleOnChange}
            className="w-full bg-white dark:bg-gray-800"
          >
            {Object.keys(QUESTION_TYPES).map((key) => (
              <option
                key={key}
                value={key}
                title={QUESTION_TYPES_EXPLANATIONS[key as QuizQuestionType]}
              >
                {QUESTION_TYPES_LABELS[key as QuizQuestionType]}
              </option>
            ))}
          </Select>
        </div>
      </div>

      {/* Question Settings */}
      <div className="flex flex-wrap items-center gap-6">
        <div>
          <Label className="text-gray-700 dark:text-gray-300 mb-1.5" value="Position" />
          {displayErrors(errors?.position as string[])}
          <TextInput
            type="number"
            min="1"
            value={data.position}
            name="position"
            onChange={handleOnChange}
            className="w-24 bg-white dark:bg-gray-800"
          />
        </div>

        <div className="flex items-center gap-2">
          <Checkbox
            checked={data.required}
            name="required"
            onChange={handleOnChange}
            className="text-blue-600 dark:text-blue-400 focus:ring-blue-500 dark:focus:ring-blue-400"
          />
          <Label className="text-gray-700 dark:text-gray-300" value="Required Question" />
        </div>
      </div>

      {/* Image Upload Section */}
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <Checkbox
            disabled={isImageCheckboxDisabled}
            title={generateDisabledCheckboxTitle()}
            checked={showImageUpload}
            onChange={() => setShowImageUpload(!showImageUpload)}
            className={`${isImageCheckboxDisabled ? 'cursor-not-allowed' : ''} 
                       text-blue-600 dark:text-blue-400`}
          />
          <Label className="text-gray-700 dark:text-gray-300" value="Add Image to Question" />
        </div>

        {showImageUpload && (
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-200 dark:border-gray-700">
            <Label value="Upload image" />
            {displayErrors(
              aggregatedUploadQuestionImageErrorMessages[idx]?.image,
            )}
            <FileUpload
              file={data.image instanceof File ? data.image : null}
              setFile={handleImageChange}
              inputId={randomId}
            />
            <div className="text-center">- or -</div>
            <Label value="Provide a link to the image" />
            {displayErrors(
              aggregatedUploadQuestionImageErrorMessages[idx]?.external_image,
            )}
            <TextInput
              type="text"
              value={data.external_image || ""}
              name="external_image"
              onChange={handleExternalImageChange}
              aria-label="Provide a link to the image"
            />
            {(data.image || data.external_image) && (
              <>
                <div>
                  <Label value="Image preview" />
                </div>
                <div className="relative w-[128px] h-[128px]">
                  <QuestionImage question={data} />
                  <button
                    className="top-1 right-1 absolute"
                    onClick={clearImage}
                    aria-label="Clear image"
                  >
                    <TrashIcon className="w-5 h-5 text-red-700" />
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
