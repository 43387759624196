import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { incrementActiveStepIndex } from "../../../redux/auth/authSlice";
import NeuralNetworksTable from "../../../components/neuralNetworks/NeuralNetworksTable";

/**
 * Neural Networks page
 */
export default function NeuralNetworks() {
  const dispatch = useAppDispatch();

  /**
   * Go to next step after Neural Network selection
   */
  const afterNeuralNetworkSelect = () => {
    dispatch(incrementActiveStepIndex());
  };

  return (
    <div className="flex flex-col justify-center sm:h-screen">
      <div>
        <div className="relative overflow-x-auto border shadow-md sm:rounded-lg">
          <NeuralNetworksTable
            headerText="Select Neural Network"
            subHeaderText="Completing this step will grant you 10 tokens"
            submitLabelText="Continue"
            headerClassName="flex flex-col sm:min-w-[700px] sm:flex-row space-y-1 items-center px-6 py-4 bg-white"
            afterNeuralNetworkSelect={afterNeuralNetworkSelect}
            requireSelection
            singularSelection
          />
        </div>
      </div>
    </div>
  );
}
