import React, { CSSProperties, useEffect, useState } from "react";
import { increaseWatchedFor, watchFeedItem } from "redux/feed/feedItemsSlice";
import { useAppDispatch } from "redux/hooks";
import { FeedItem } from "types/feed";
import TMDBMovieTabs, { TMDB_MOVIE_TABS } from "./common/TMDBMovieTabs";
import TMDBMovieOverview from "./sections/TMDBMovieOverview";
import TMDBMovieCast from "./sections/TMDBMovieCast";
import TMDBMovieWatch from "./sections/TMDBMovieWatch";
import TMDBMovieTrailersClips from "./sections/TMDBMovieTrailersClips";

interface Props {
  id: string;
  className?: string;
  style: CSSProperties;
  data: { playing: boolean; feedItem: FeedItem | null };
}

/**
 * Temporary data for building the component.
 * TODO: Replace with actual data from the backend once google scraping issue is resolved.
 */
const TEMP_DATA: FeedItem = {
  bing_news_url: "",
  description:
    "Sixteen years ago, a mysterious masked ninja unleashes a powerful creature known as the Nine-Tailed Demon Fox on the Hidden Leaf Village Konoha, killing many people. In response, the Fourth Hokage Minato Namikaze and his wife Kushina Uzumaki, the Demon Fox's living prison or Jinchūriki, manage to seal the creature inside their newborn son Naruto Uzumaki. With the Tailed Beast sealed, things continued as normal. However, in the present day, peace ended when a group of ninja called the Akatsuki attack Konoha under the guidance of Tobi, the mysterious masked man behind Fox's rampage years ago who intends on executing his plan to rule the world by shrouding it in illusions.",
  disliked: false,
  hidden: false,
  id: "46e61f5c-812d-4ede-aae9-0b87494d5618",
  image: "https://image.tmdb.org/t/p/w500/xLal6fXNtiJN6Zw6qk21xAtdOeN.jpg",
  liked: false,
  playlists: [],
  ratings: [
    {
      url: "https://www.imdb.com/title/tt0320661",
      score: "7.3/10",
      provider: "IMDb",
    },
    {
      url: "https://www.filmweb.pl/film/Kr%25C3%25B3lestwo%2Bniebieskie-2005-108083",
      score: "7.2/10",
      provider: "Filmweb",
    },
    {
      url: "https://www.cda.pl/video/5086751cb",
      score: "4/5",
      provider: "CDA",
    },
  ],
  skipped: false,
  spotify_track_id: "",
  streaming_providers: [
    {
      created_at: "2024-02-28T09:18:39.412314Z",
      id: "79212ad0-01d5-42df-b898-9bc03892116e",
      link: "https://www.amazon.com/gp/video/detail/B09HZ331JP/ref=atv_dp?language=en",
      price_amount: 3.99,
      price_currency: "USD",
      quality: "uhd",
      service: "prime",
      streaming_type: "rent",
      tmdb_movie_id: "118406",
      updated_at: "2024-02-28T09:18:39.412325Z",
    },
    {
      created_at: "2024-02-28T09:18:39.409666Z",
      id: "029fa5bc-c93a-40b8-a984-3ff8c7a83748",
      link: "https://www.amazon.com/gp/video/detail/B09HZ331JP/ref=atv_dp?language=en",
      price_amount: null,
      price_currency: null,
      quality: "uhd",
      service: "prime",
      streaming_type: "addon",
      tmdb_movie_id: "118406",
      updated_at: "2024-02-28T09:18:39.409698Z,",
    },
  ],
  title: "Road to Ninja: Naruto the Movie",
  tmdb_movie_id: "118406",
  type: "movie",
  watched: false,
  youtube_video_id: "",

  // New fields(to be scraped from google & added to serializer)
  tmdb_data: {
    release_date: "2022-03-01",
    revenue: 771000000,
    runtime: 177,
    genres: [
      {
        id: 35,
        name: "Comedy",
      },
      {
        id: 28,
        name: "Action",
      },
    ],
    videos: [
      {
        iso_639_1: "en",
        iso_3166_1: "US",
        name: "Little Caesars Sneak Peek",
        key: "FqnbVhfniys",
        site: "YouTube",
        size: 1080,
        type: "Featurette",
        official: false,
        published_at: "2022-02-18T16:00:04.000Z",
        id: "6227032c2f1be00068fa6e81",
      },
      {
        iso_639_1: "en",
        iso_3166_1: "US",
        name: "Funeral Scene",
        key: "Jtdh0Tkqfdw",
        site: "YouTube",
        size: 1080,
        type: "Clip",
        official: true,
        published_at: "2022-01-30T20:45:03.000Z",
        id: "61f72df8d7cd06011e7c476c",
      },
      {
        iso_639_1: "en",
        iso_3166_1: "US",
        name: "The Bat and The Cat Trailer",
        key: "u34gHaRiBIU",
        site: "YouTube",
        size: 1080,
        type: "Trailer",
        official: true,
        published_at: "2021-12-27T18:00:27.000Z",
        id: "61ca0378b0422800613dc85f",
      },
    ],
    cast: [
      {
        adult: false,
        gender: 2,
        id: 11288,
        known_for_department: "Acting",
        name: "Robert Pattinson",
        original_name: "Robert Pattinson",
        popularity: 56.792,
        profile_path: "/8A4PS5iG7GWEAVFftyqMZKl3qcr.jpg",
        cast_id: 174,
        character: "Bruce Wayne / The Batman",
        credit_id: "607213782f791500769ddac5",
        order: 0,
      },
      {
        adult: false,
        gender: 1,
        id: 37153,
        known_for_department: "Acting",
        name: "Zoë Kravitz",
        original_name: "Zoë Kravitz",
        popularity: 32.376,
        profile_path: "/A8Ig9UHh8NSiUGg3xChcoF7749w.jpg",
        cast_id: 60,
        character: "Selina Kyle / Catwoman",
        credit_id: "5da4e941afe2240015eeec00",
        order: 1,
      },
      {
        adult: false,
        gender: 2,
        id: 17142,
        known_for_department: "Acting",
        name: "Paul Dano",
        original_name: "Paul Dano",
        popularity: 54.316,
        profile_path: "/as6rmmWKgSrpHs0suNRSbQT8Raw.jpg",
        cast_id: 68,
        character: "Edward Nashton / The Riddler",
        credit_id: "5da8ae96b2681f00168df2ec",
        order: 2,
      },
      {
        adult: false,
        gender: 2,
        id: 2954,
        known_for_department: "Acting",
        name: "Jeffrey Wright",
        original_name: "Jeffrey Wright",
        popularity: 45.672,
        profile_path: "/yGcuHGW4glqRpOPxgiCvjcren7F.jpg",
        cast_id: 51,
        character: "Lt. James Gordon",
        credit_id: "5d89435c172d7f000d4c312d",
        order: 3,
      },
      {
        adult: false,
        gender: 2,
        id: 1241,
        known_for_department: "Acting",
        name: "John Turturro",
        original_name: "John Turturro",
        popularity: 45.122,
        profile_path: "/63zA58rSyJxBMLBdgeMjjETmpDQ.jpg",
        cast_id: 75,
        character: "Carmine Falcone",
        credit_id: "5dd86320ef8b3200128c0eb9",
        order: 4,
      },
      {
        adult: false,
        gender: 2,
        id: 133,
        known_for_department: "Acting",
        name: "Peter Sarsgaard",
        original_name: "Peter Sarsgaard",
        popularity: 40.847,
        profile_path: "/jOc4VjxPaOkWOqnLCxd8BJy9g5i.jpg",
        cast_id: 85,
        character: "District Attorney Gil Colson",
        credit_id: "5deac289daf57c0013e7f0be",
        order: 5,
      },
      {
        adult: false,
        gender: 2,
        id: 1333,
        known_for_department: "Acting",
        name: "Andy Serkis",
        original_name: "Andy Serkis",
        popularity: 35.827,
        profile_path: "/eNGqhebQ4cDssjVeNFrKtUvweV5.jpg",
        cast_id: 73,
        character: "Alfred Pennyworth",
        credit_id: "5dc1f2f6b234b900128d70ae",
        order: 6,
      },
      {
        adult: false,
        gender: 2,
        id: 72466,
        known_for_department: "Acting",
        name: "Colin Farrell",
        original_name: "Colin Farrell",
        popularity: 63.304,
        profile_path: "/yGohLbGy8huibHfT52sbnGoGu7x.jpg",
        cast_id: 72,
        character: "Oswald 'Oz' Cobblepot / The Penguin",
        credit_id: "5dc1f2da871b340013d210db",
        order: 7,
      },
    ],
  },
};

/**
 * Component for displaying a TMDB movie feed item in ScrollableMobileFeed.
 */
export default function TMDBMovie({ id, className, style, data }: Props) {
  const dispatch = useAppDispatch();
  const [blurDate, setBlurDate] = useState<Date | null>(null);
  const [currentTab, setCurrentTab] = useState("Overview");

  /**
   * Increases the watched for time for the feed item.
   */
  function updateWatchedFor(readFor: number) {
    if (data.feedItem && readFor > 0) {
      dispatch(
        increaseWatchedFor({
          feed_item: data.feedItem.id,
          watched_for: readFor,
          watched: true,
        }),
      );
    }
  }

  /**
   * Opens the item in a new tab and sets up variables used by updateWatchedFor.
   */
  useEffect(() => {
    if (data.playing && data.feedItem) {
      console.log("data.feedItem", data.feedItem);
      const url = `https://www.themoviedb.org/movie/${data.feedItem.tmdb_movie_id}`;
      window.open(url);
      setBlurDate(new Date());
      dispatch(watchFeedItem(data.feedItem.id));
    }
  }, [data.playing]);

  /**
   * Updates watched for time when the user navigates back into the app.
   */
  useEffect(() => {
    const onVisibilityChange = function (e: Event) {
      if (!document.hidden && blurDate) {
        const now = new Date();
        const seconds = (now.getTime() - blurDate.getTime()) / 1000;
        updateWatchedFor(seconds);
        setBlurDate(null);
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [blurDate]);

  return (
    <div
      id={id}
      className={`flex flex-col space-y-2 overflow-y-auto p-2 ${className}`}
      style={style}
    >
      <div>
        <TMDBMovieTabs setCurrentTab={setCurrentTab} currentTab={currentTab} />
      </div>

      {currentTab === TMDB_MOVIE_TABS.OVERVIEW && (
        <TMDBMovieOverview feedItem={TEMP_DATA} />
      )}

      {currentTab === TMDB_MOVIE_TABS.CAST && (
        <TMDBMovieCast feedItem={TEMP_DATA} />
      )}

      {currentTab === TMDB_MOVIE_TABS.WATCH && (
        <TMDBMovieWatch feedItem={TEMP_DATA} />
      )}

      {currentTab === TMDB_MOVIE_TABS.TRAILERS_AND_CLIPS && (
        <TMDBMovieTrailersClips feedItem={TEMP_DATA} />
      )}
    </div>
  );

  // return (
  //   <div id={id} className={className} style={style}>
  //     {data.feedItem ? (
  //       <div className="flex flex-col p-8 rounded gap-4 h-full justify-center">
  //         <div className="flex justify-center items-center">
  //           <img
  //             src={data.feedItem.image || ""}
  //             className="h-[150px] rounded"
  //           />
  //         </div>

  //         <div className="text-lg font-bold border-b">
  //           {data.feedItem.title}
  //         </div>

  //         <div className="text-base">
  //           {shortenText(data.feedItem.description || "", 256)}
  //         </div>

  //         <a className="text-blue-600 font-medium cursor-pointer inline text-center p-2">
  //           View on TMDB
  //         </a>

  //         <div className="grid grid-cols-2">
  //           <img src={TMDBLogo} className="h-8 m-auto" />
  //           <div className="font-bold" style={{ color: "#01b4e4" }}>
  //             Powered by The Movie Database
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <div className="text-center">
  //         <p className="bg-gray-200 font-semibold p-6">
  //           Movie not available at this time
  //         </p>
  //       </div>
  //     )}
  //   </div>
  // );
}
