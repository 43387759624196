import React, { useEffect } from "react";
import PageWrapper from "../../components/common/pageWrapper";
import NeuralNetworksTable from "../../components/neuralNetworks/NeuralNetworksTable";
import MainHeader from "../../components/MainHeader/MainHeader";
import Footer from "../../components/Footer/Footer";

/**
 * Neural Networks page
 */
export default function NeuralNetworks() {
  useEffect(() => {
    document.title = "Neural Networks | BrainCargo";
  }, []);

  return (
    <PageWrapper>
      <div className="min-h-screen">
        <div
          className="border dark:border-gray-700 shadow-md lg:rounded-lg 
            bg-white dark:bg-gray-800 pb-6 space-y-4 pt-14 
            transition-colors duration-200"
          aria-label="Neural Networks Page"
        >
          <MainHeader title="Neural Networks" />

          <div className="px-6 space-y-4">
            <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
              Below you will find a list of all neural networks uploaded to the
              BrainCargo database. You can upload new neural networks and select
              existing ones in order to use them in other parts of the
              application.
            </p>
            <NeuralNetworksTable headerText="" />
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
