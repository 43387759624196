import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { movieService } from "services/movieService";
import axios from "axios";
import { get } from "services/apiService";

interface MoviesState {
  searchResults: any[];
  movieDetails: any | null;
  recommendations: any[];
  similarUsersAndMovies: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: MoviesState = {
  searchResults: [],
  movieDetails: null,
  recommendations: [],
  similarUsersAndMovies: null,
  loading: false,
  error: null,
};

export const searchMovie = createAsyncThunk(
  "movies/searchMovie",
  async (query: string) => {
    return await movieService.searchMovie(query);
  }
);

export const searchMovies = createAsyncThunk(
  "movies/searchMovies",
  async (movies: { title: string; year?: string }[]) => {
    return await movieService.searchMovies(movies);
  }
);

export const getMovieDetails = createAsyncThunk(
  "movies/getMovieDetails",
  async (movieId: number) => {
    const response = await get(`/api/tmdb/movies/${movieId}/`);
    return response.data;
  }
);

export const getMovieRecommendations = createAsyncThunk(
  "movies/getMovieRecommendations",
  async (movieId: number) => {
    const response = await get(
      `/api/recommender/recommend/${movieId}/movies/`
    );
    return response.data;
  }
);

export const getSimilarUsersAndMovies = createAsyncThunk(
  "movies/getSimilarUsersAndMovies",
  async () => {
    const response = await get(
      "/api/recommender/recommend/similar_users_and_movies/"
    );
    return response.data;
  }
);

const moviesSlice = createSlice({
  name: "movies",
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
    clearMovieDetails: (state) => {
      state.movieDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Search Movie
      .addCase(searchMovie.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchMovie.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchMovie.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to search movie";
      })
      // Search Multiple Movies
      .addCase(searchMovies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchMovies.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchMovies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to search movies";
      })
      // Get Movie Details
      .addCase(getMovieDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMovieDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.movieDetails = action.payload;
      })
      .addCase(getMovieDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to get movie details";
      })
      // Get Movie Recommendations
      .addCase(getMovieRecommendations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMovieRecommendations.fulfilled, (state, action) => {
        state.loading = false;
        state.recommendations = action.payload;
      })
      .addCase(getMovieRecommendations.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to get movie recommendations";
      })
      // Get Similar Users and Movies
      .addCase(getSimilarUsersAndMovies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSimilarUsersAndMovies.fulfilled, (state, action) => {
        state.loading = false;
        state.similarUsersAndMovies = action.payload;
      })
      .addCase(getSimilarUsersAndMovies.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to get similar users and movies";
      });
  },
});

export const { clearSearchResults, clearMovieDetails } = moviesSlice.actions;
export default moviesSlice.reducer;
