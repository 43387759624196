import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/common/pageWrapper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { history } from "../../../helpers/history";
import { APP_URLS } from "../../../navigation";
import { useParams } from "react-router-dom";
import { displayErrors } from "../../../helpers/errors";
import QuizForm from "../../../components/quiz/form/take-quiz/QuizForm";
import { retrieveQuizzes } from "../../../redux/quiz/quizSlice";
import { Quiz } from "../../../types/quiz";
import Header from "../../../components/common/header";
import MainHeader from "../../../components/MainHeader/MainHeader";
import Footer from "../../../components/Footer/Footer";
import { Spinner } from "flowbite-react";

interface Props {
  successRedirectUrl?: string;
  successMessage?: string | JSX.Element;
  successRedirectDelayMs?: number;
}

const DEFAULT_SUCCESS_MESSAGE = (
  <div className="text-center text-gray-700 dark:text-gray-200">
    <p className="text-lg font-medium">
      Thank you for taking part in the quiz!
    </p>
    <p className="text-sm text-gray-600 dark:text-gray-400">
      You will be redirected to home screen in 5 seconds.
    </p>
  </div>
);

export default function QuizDetails({
  successRedirectUrl = APP_URLS.VIDEOS,
  successMessage = DEFAULT_SUCCESS_MESSAGE,
  successRedirectDelayMs = 5000,
}: Props) {
  const quizzes = useAppSelector((store) => store.quiz.quizzes);
  const pendingRetrieveQuizzes = useAppSelector(
    (store) => store.quiz.pendingRetrieveQuizzes
  );
  const quizzesErrorMessages = useAppSelector(
    (state) => state.quiz.quizzesErrorMessages
  );
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  function onSuccess() {
    setTimeout(
      () => history.navigate(successRedirectUrl),
      successRedirectDelayMs
    );
  }

  function renderErrors() {
    if (quizzesErrorMessages) {
      let errors = quizzesErrorMessages?.id;
      if (
        quizzesErrorMessages?.id &&
        quizzesErrorMessages.id.includes("Enter a valid UUID.")
      ) {
        errors = ["Couldn't find a quiz with provided id."];
      }
      return (
        <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
          <p className="text-red-600 dark:text-red-400">
            {displayErrors(errors)}
          </p>
        </div>
      );
    }
  }

  useEffect(() => {
    document.title = "Quizzes | BrainCargo";
  }, []);

  useEffect(() => {
    if (quizzes.results.length > 0) {
      setQuiz({ ...quizzes.results[0] });
    } else {
      setQuiz(null);
    }
  }, [quizzes]);

  useEffect(() => {
    if (id) {
      dispatch(retrieveQuizzes({ queryParams: { id } }));
    }
  }, [id]);

  return (
    <PageWrapper>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200 pt-14">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl transition-all duration-200">
            <div className="border-b dark:border-gray-700">
              {pendingRetrieveQuizzes ? (
                <div className="p-6 flex items-center space-x-3">
                  <Spinner
                    size="sm"
                    className="text-blue-600 dark:text-blue-400"
                  />
                  <span className="text-gray-600 dark:text-gray-300">
                    Loading quiz...
                  </span>
                </div>
              ) : (
                <div className="p-6">
                  <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                    {quiz?.name || "Unknown Quiz"}
                  </h1>
                  {quiz && (
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                      Please answer all questions below to complete the quiz.
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className="p-6">
              {Object.keys(quizzesErrorMessages).length === 0 ? (
                <div className="space-y-8">
                  <QuizForm
                    onSuccess={onSuccess}
                    successText={successMessage}
                    quiz={quiz}
                  />
                </div>
              ) : (
                <div className="p-4 bg-red-50 dark:bg-red-900/20 rounded-xl border border-red-200 dark:border-red-800">
                  <p className="text-red-600 dark:text-red-400">
                    {renderErrors()}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageWrapper>
  );
}
