import React, { CSSProperties, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PageWrapper from "../../components/common/pageWrapper";
import { MOBILE_TOP_NAV_BAR_HEIGHT } from "../../components/navigation/mobile/top/MobileTopNavBar";
import { MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT } from "../../components/navigation/mobile/bottom/MobileBottomNavBarFeed";
import { retrieveConnectedAdvertisers } from "../../redux/brainCoin/brainCoinSlice";
import { getIsSmScreen } from "../../helpers/responsiveness";
import { APP_URLS } from "../../navigation";
import { useIsSmScreen } from "../../hooks/useIsSmScreen";
import { useParams } from "react-router-dom";
import MobileFeed from "../../components/feed/MobileFeed";
import MobileBottomNavBarFeed from "../../components/navigation/mobile/bottom/MobileBottomNavBarFeed";
import MobileTopNavBar from "../../components/navigation/mobile/top/MobileTopNavBar";
import ScrollableMobileFeed from "../../components/feed/ScrollableMobileFeed";
import MobileBottomNavBarScrollableFeed from "../../components/navigation/mobile/bottom/MobileBottomNavBarScrollableFeed";

/**
 * Feed page for mobile
 */
export default function FeedMobile() {
  const params = useParams();
  const user = useAppSelector((state) => state.user.user);
  const isSmScreen = useIsSmScreen();
  const dispatch = useAppDispatch();
  const showScrollableFeed = user?.is_touch_interface_enabled;

  /**
   * calculate the height of the wrapper div
   */
  function getWrapperStyle(): CSSProperties {
    const screenHeight = screen.height;
    const height =
      screenHeight -
      parseInt(MOBILE_TOP_NAV_BAR_HEIGHT) -
      parseInt(MOBILE_FEED_BOTTOM_NAV_BAR_HEIGHT);
    return {
      height,
    };
  }

  useEffect(() => {
    document.title = "Feed | BrainCargo";
    dispatch(retrieveConnectedAdvertisers());
  }, []);

  if (!getIsSmScreen()) {
    // Force the user to desktop-compatible videos view
    window.location.href = APP_URLS.VIDEOS;
  }

  return (
    <PageWrapper>
      <div style={getWrapperStyle()} aria-label="Mobile Feed">
        <MobileTopNavBar />
        {showScrollableFeed ? (
          <>
            <ScrollableMobileFeed
              feedItemId={params.feedItemId}
              playlistId={params.playlistId}
            />
            <MobileBottomNavBarScrollableFeed />
          </>
        ) : (
          <>
            <MobileFeed
              feedItemId={params.feedItemId}
              playlistId={params.playlistId}
            />
            <MobileBottomNavBarFeed />
          </>
        )}
      </div>
    </PageWrapper>
  );
}
