import axios from "axios";
import { get, post } from "./apiService";

interface MovieSearchItem {
  title: string;
  year?: string;
}

interface MovieSearchRequest {
  movies: MovieSearchItem[];
}

export const movieService = {
  // Search for a single movie
  searchMovie: async (query: string) => {
    const response = await get(
      `/api/tmdb/movies/search/?query=${encodeURIComponent(query)}`
    );
    return response.data;
  },

  // Search for multiple movies
  searchMovies: async (movies: MovieSearchItem[]) => {
    console.log("multi_movies", movies);

    const response = await post("/api/tmdb/movies/search/", { movies: movies });

    console.log("multi_searching", response);
    return response.data;
  },

  // Get movie details
  getMovieDetails: async (movieId: string) => {
    const response = await get(`/api/tmdb/movies/${movieId}/`);
    return response.data;
  },

  // Get movie recommendations
  getMovieRecommendations: async (movieId: string) => {
    const response = await get(`/api/recommender/recommend/${movieId}/movies/`);
    return response.data;
  },

  // Get similar users and movies
  getSimilarUsersAndMovies: async () => {
    const response = await get(
      "/api/recommender/recommend/similar_users_and_movies/"
    );
    return response.data;
  },
};
